/**
 * AG_PC_UP_01 - AG_PC_UP_05
 * Import statements for the User profile component
 */
import React, { useState, useEffect, useContext } from 'react';
import { getUserProfile, getActivityLogs, getOrgDetails, uploadProfile } from '../../Services/Agov/governanceService';
import { logConst } from '../../Configs/logValues';
import { handleUpload } from '../../Configs/BlobContainer';
import { useLocation, useNavigate } from 'react-router'
// import NotFound from '../LMS/NotFound';
import NotFound from '../NotFound/NotFound';
import { agovContext, encryptStorage } from '../../Constant';
import { formatDateTime } from '../../Constant';
import { Loader } from '../Loader/Loader';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import { useProfile } from '../../ProfileContext';

/**
 * AG_PC_UP_06 - AG_PC_UP_14
 * Export function declaration and State variable declarations
 */
export const UserProfile = () => {
    const { employeeId, userId, reportsToId, profileUrl } = useContext(agovContext);

    const { profilePicture, setProfilePicture } = useProfile();

    const location = useLocation()


    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [userData, setUserData] = useState({});
    let [userID, setUserId] = useState(location?.state?.value?.id ?? userId ?? encryptStorage.getItem('userValue'));
    let [employeeID, setEmployeeID] = useState(location?.state?.value?.employee_id ?? encryptStorage.getItem('employeeId') ?? employeeId)
    let [managerID, setManagerID] = useState("")
    const [logData, setLogData] = useState([]);
    const [logCount, setLogCount] = useState(0);
    const [orgEmpData, setOrgEmpData] = useState([]);
    const [orgManagerData, setOrgManagerData] = useState([]);
    const [fileError, setFileError] = useState('');
    const [loadMore, setLoadMore] = useState(5);
    let [isDisable, setIsDisable] = useState(false);
    const [isToast, setIsToast] = useState(false)
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null,
    })
    const [isManagerClicked, setIsManagerClicked] = useState(false);

    useEffect(() => {
        if(loadMore > 5) {
            getActivity();
        }
    }, [loadMore]);

    useEffect(() => {
        fetchOrganization();
        fetchUserProfileDetails();
    }, [isManagerClicked])

    /**
     * AG_PC_UP_15 - AG_PC_UP_14
     * Inside the useEffect hook, check for the userId and employeeId from location state
     * Invoke the fetchUserProfileDetails
     */

    useEffect(() => {
        const userIDfromContext = location?.state?.value?.id;
        const empIDfromContext = location?.state?.value?.employeeid;

        if (userIDfromContext && empIDfromContext) {
            userID = userIDfromContext
            employeeID = empIDfromContext
            setUserId(userID)
            setEmployeeID(employeeID)
        }

        if (employeeId == employeeID || reportsToId == employeeID || employeeID) {
            isDisable = false
            setIsDisable(isDisable);
        } else {
            isDisable = true
            setIsDisable(isDisable);
        }
    }, [])


  useEffect(() => {
    fetchUserProfileDetails();
  }, []);

  /**
   * AG_PC_UP_15, AG_PC_UP_16
   * The invoking of the fetchUserProfileDetails, three methods are called
   */
  const fetchUserProfileDetails = async () => {

    await fetchUser();
    if (!isDisable) {
      await getActivity();
    }
}
    /**
     * AG_PC_UP_17 - AG_PC_UP_30
     * This function is to fetch the user Details of the specified user
     */
    const fetchUser = async () => {
        try {
            const requestBody = {
                user_id: userID
            }
            setIsLoading(true)
            const Data = await getUserProfile(requestBody);
            setIsLoading(false)
            if (Data.statusCode === 200) {
                setUserData(Data?.data[0]);
                if (Data?.data[0].emailId === userId) {
                    setProfilePicture(Data?.data[0].profilePicture)
                }
                setLogCount(Data.totalCount)
            } else {
                navigate('/error')
            }

        } catch (error) {
            navigate('/error')
        }
    }

    /**
     * AG_PC_UP_31 - AG_PC_UP_54
     * This function is to call the activity details of the specified user_id from the state variables
     */
    const getActivity = async () => {
        try {

            function weaveStrings(input1, input2) {
                const reversedInput1 = input1.split('').reverse();
                const input2Chars = input2.split('');
                let result = [];
                let input1Index = 0;
                let input2Index = 0;
                
                while (input2Index < input2Chars.length || input1Index < reversedInput1.length) {
                  if (input2Index < input2Chars.length) {
                    result.push(input2Chars[input2Index++]);
                  }
                  if (input1Index < reversedInput1.length) {
                    result.push(reversedInput1[input1Index++]);
                  }
                }
                 return result.join('');
              }
            
              
            if (!isDisable) {
             
              const  requestBody = {
                    loadMore: loadMore,
                    userId: weaveStrings(userID, encryptStorage.getItem('employeeId'))
                }
                setIsLoading(true)
                const activityData = await getActivityLogs(requestBody);
                setIsLoading(false)
                if (activityData.statusCode === 200) {
                    setLogData(activityData.data)
                    setLogCount(activityData.totalCount)
                } else {
                    navigate('/error')
                }
            }
        } catch (error) {
            navigate('/error')
        }

    }
    /**
     * AG_PC_UP_122 - AG_PC_UP_152
     * Upload the profile pic in the blob and update the blob url to the user.
     * Fetch the newly updated profile pecture of the specified user id.
     */
    const handleProfileUpload = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileSize = Math.round((file.size / (1024 * 1024)) * 100) / 100; // Calculate file size in MB
            const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
            if (!allowedTypes.includes(file.type)) {
                setIsToast(true)
                setToastMessage(
                    {
                        path: "/images/error-toast-icon.svg",
                        header: "Invalid File Format",
                        message: "The selected File should be PNG/JPG/JPEG",
                        color: "error-toast"
                    })
                return
            }
            else if (fileSize > 1) {
                setFileError();
                setIsToast(true)
                setToastMessage(
                    {
                        path: "/images/error-toast-icon.svg",
                        header: "Invalid File Format",
                        message: "Please choose a file that is smaller than [1] MB",
                        color: "error-toast"
                    })
                return
            }
            else {
                setFileError('');
                setIsLoading(true)
                const profileUrl = await handleUpload(file)
                setIsLoading(false)
                const requestBody = {
                    profile: profileUrl,
                    userID: userID
                }
                setIsLoading(true)
                const uploadProfilePicture = await uploadProfile(requestBody)
                setIsLoading(false)

                if (uploadProfilePicture.statusCode === 200) {
                    await fetchUserProfileDetails()
                } else {
                    navigate('/error')
                }

            }
        }
    }


    /**
     * AG_PC_UP_55 - AG_PC_UP_84
     * Bind the log report for the user based on the data in the state variable called logData
     */

    const bindlogReport = () => {
        if (logCount == 0) {
            return (
                <NotFound />
            )
        }
        else {
            if (logCount != 0) {
                return logData?.map((obj, index) => {
                    return (
                        <div className={
                            obj[logConst[obj?.event]?.Category] == "grcTaskManagement" || obj[logConst[obj?.event]?.Category] == "grcManagement" ? "row log-card compliance-log" :
                                obj[logConst[obj?.event]?.Category] == "lmsTaskManagement" || obj[logConst[obj?.event]?.Category] == "lmsManagement" ? "row log-card lms-log" :
                                    obj[logConst[obj?.event]?.Category] == "appManagement" || obj[logConst[obj?.event]?.Category] == "adminManagement" || obj[logConst[obj?.event]?.Category] == "appTaskManagement" ? "row log-card access-governance-log" :
                                        obj[logConst[obj?.event]?.Category] == "adminTaskManagement" ? "row log-card admin-config-log" :
                                            obj[logConst[obj?.event]?.Category] == "riskTaskManagement" || obj[logConst[obj?.event]?.Category] == "riskTaskCategory" ? "row log-card risk-log" :
                                            obj[logConst[obj?.event]?.Category] == "taskManagement" ? "row log-card task-log":  logConst[obj?.event]?.divColor
                        }>
                            <div className="col-md-12 mt-4 pt-3">
                                <p className="font-14 text-black mb-2 mb-lg-0">
                                    <span className="font-bold">{obj[logConst[obj?.event]?.firstname]} </span>{logConst[obj?.event]?.divStatement1} <span className="font-bold">{obj[logConst[obj?.event]?.subEvent]}</span>{logConst[obj.event]?.divStatement2} <span className="font-bold"> {obj[logConst[obj?.event]?.subEventName]} {obj[logConst[obj?.event]?.lastname]}</span>
                                </p>
                            </div>
                            <div className="col-lg mt-lg-3"><span className="font-12 text-black font-bold mb-1 m-lg-0">{logConst[obj?.event]?.description}</span></div>
                            <div className="col-lg mt-lg-3"><span className="font-12 text-color-grey mb-1 m-lg-0"></span>{logConst[obj?.event]?.logtype}</div>
                            <div className="col-lg mt-lg-3"><span className="font-12 text-color-grey mb-1 m-lg-0">{logConst[obj?.event]?.assignedOrPerform} <span className="font-bold text-black">{obj[logConst[obj?.event]?.performedBy]} </span></span></div>
                            <div className="col-lg mt-lg-3"><span className="font-12 text-color-grey font-medium">{formatDateTime(obj[logConst[obj?.event]?.dateTime])}</span></div>
                        </div>

                    )
                }
                )
            }
        }
    }



    /**
     * AG_PC_UP_87 - AG_PC_UP_107
     * Fetch the Organization data of the specified employeeId and their managerId
     * Set state the employee details and managerDetails to the respective state variables
     */
    const fetchOrganization = async () => {

        try {
            const requestBody = {
                employeeId: employeeID
            }
            setIsLoading(true)
            const orgData = await getOrgDetails(requestBody);
            setIsLoading(false)
            if (orgData.statusCode !== 200) {
                navigate('/error')
            }
            setOrgEmpData(orgData.responseData1);
            setOrgManagerData(orgData.responseData2);
        } catch (error) {
            navigate('/error')
        }
    };

    /**
     * AG_PC_UP_108 - AG_PC_UP_111
     * Bind the organzation details as per the data in the orgManager state variable
     * Allign the data in vertical way to get the correct organization chart
     */
    let bindOrganizationChart = (orgManagerData, userData) => {

        try {
            return orgManagerData?.map((obj, index) => {
                return (
                    <div key={index}>
                        <div className="row pointer">
                            <div className="col-md-12 mt-5 pt-4 d-flex align-items-center justify-content-center">
                                <div key={index} className={`org-profile-card ${index === 0 ? "level-1 l1-arw p-1" : "level-2 l2-arw p-2"} position-relative ${userData?.employeeId === obj?.employeeId ? "high-tile" : ''}`} onClick={(e) => employeeDetails(e, obj[0], "manager")} style={{cursor: "pointer"}}>
                                    <img
                                        src={obj[0]?.profile}
                                        alt="Profile picture"
                                        width={48}
                                        height={48}
                                        className="org-display-pic position-absolute"
                                    />
                                    <h4 className="m-0 font-12 font-medium primary-textcolor pt-4">
                                        {obj[0]?.employeeName}
                                    </h4>
                                    <span className="font-12 grey-primary mt-2">
                                        {obj[0]?.employeeDomain && obj[0]?.employeeDomain !== "null" ? obj[0]?.employeeDomain : '--'}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {index !== orgManagerData.length-1 && (
                            <div className="team-structure__middle-container">
                                <div className="vertical-line" style={{ width: '2px', height: '70px', backgroundColor: 'gray', margin: '0 auto' }} />
                            </div>
                        )}
                    </div>
                )
            });
        } catch (error) {
            navigate('/error')
        }
    }


    /**
     * AG_PC_UP_112 - AG_PC_UP_114
     * Bind the employee details from the state variable using this fucntion
     */
    let bindEmployeeDetails = () => {
        let empData = userData;
        try {
            return (
                <div>
                    {orgEmpData.length!==0 && 
                    <div className="team-structure__middle-container">
                                <div className="vertical-line mt-1" style={{ width: '2px', height: '70px', backgroundColor: 'gray', margin: '0 auto' ,}} />
                    </div>}
                    <div className={`row justify-content-${orgEmpData.length <= 4 ? "center" : "start"}`}>
                        {orgEmpData?.map((obj, index) => (
                            <div key={index} className="col-md-3 user-profile-mt">
                                <div className={`org-profile-card level-3 l3-arw p-3 position-relative ${empData?.employeeId === obj.employeeId ? "high-tile" : ''}`} onClick={(e) => employeeDetails(e, obj, "employee")} style={{cursor: "pointer"}}>
                                    <img
                                        src={obj?.profile}
                                        alt="Profile picture"
                                        width={48}
                                        height={48}
                                        className="org-display-pic position-absolute"
                                    />
                                    <h4 className="m-0 font-12 font-medium primary-textcolor pt-4">
                                        {obj?.employeeName}
                                    </h4>
                                    <span className="font-12 grey-primary mt-2">
                                        {obj?.employeeDomain && obj?.employeeDomain !== "null"  ? obj?.employeeDomain : '--'}
                                    </span>
                                </div>
                            </div>
                        ))
                        }

                    </div>
                </div>

            );


        } catch (error) {
            navigate('/error')
        }
    }
    /**
     * This function checks for the employees userType and if the managerId equals with the employeeId
     * Displays the activity Logs accordingly
     */
    const employeeDetails = (e, obj, userType) => {
        if (userType == "manager") {
            if (employeeId == obj.employeeId || obj.managerId == employeeId) {
                setIsDisable(false);
                setIsManagerClicked(false);
            } else {
                setIsDisable(true);
                setLogCount(0);
                setIsManagerClicked(true);
                setLogData([]);
            }
        }
        else if (userType == "employee") {
            if (employeeId == obj.employeeId || obj.managerId == employeeId || employeeID == obj.managerId) {
                setIsDisable(false);
                setIsManagerClicked(false);
            } else {
                setIsDisable(true);
                setIsManagerClicked(false);
                setLogCount(0);
                setLogData([]);
            }
        }
        setUserId(obj.userId)
        setEmployeeID(obj.employeeId)

    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="container-fluid mt-custom" >
                <div className="p-4 p-lg-5">
                    <div className="row">
                        <h2 className="font-24 font-bold primary-textcolor mb-3" >User Profile</h2>
                        <div className="custom-w-25">
                            <div className="container-border px-4 py-4">
                                <div className="d-flex flex-column align-items-center" >
                                    <div className="position-relative">
                                        <img
                                            src={(location?.state?.value?.navigatedFromAG === undefined) || (location?.state?.value?.naviagtedFromAppGrid === undefined) ? userData?.profilePicture : profilePicture}
                                            alt="user profile picture"
                                            width={123}
                                            height={123}
                                            className="border-50"
                                        />

                                        {userId === userID && (
                                            <label className="position-absolute cursor-pointer user-profile-icon d-flex align-items-center justify-content-center">
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input element
                                                    onChange={(e) => { handleProfileUpload(e) }} // Handle file selection
                                                />
                                                <img
                                                    src="images/edit-icon.svg"
                                                    alt="edit"
                                                    className="user-profile-edit-img"
                                                />
                                            </label>
                                        )}

                                    </div>
                                    <h3 className="font-24 font-medium primary-textcolor mt-4">
                                        {userData?.employeeName}
                                    </h3>
                                    <span className="font-12 grey-primary">{userData?.employeeDomain && userData?.employeeDomain !== "null" ? userData?.employeeDomain : "--"}</span>
                                </div>
                                <div className="row mt-4 mt-xl-5">
                                    <div className="col-xl-7 mt-3 mt-xl-0">
                                        <label className="font-12 grey-primary w-100">Reports To</label>
                                        <span className="font-12 font-medium primary-textcolor mt-2">
                                            {userData?.managerName ? userData?.managerName : '--'}
                                        </span>
                                    </div>
                                    <div className="col-xl-5 mt-3 mt-xl-0">
                                        <label className="font-12 grey-primary w-100">Device Name</label>
                                        <span className="font-12 font-medium primary-textcolor mt-2">
                                            {userData?.deviceName ? userData?.deviceName : '--'}
                                        </span>
                                    </div>
                                    <div className="col-xl-7 mt-3 mt-xl-4 pt-xl-2 pb-xl-4">
                                        <label className="font-12 grey-primary w-100">Last Login</label>
                                        <span className="font-12 font-medium primary-textcolor mt-2">
                                            {userData?.lastSign ? formatDateTime(userData?.lastSign) : "--"}
                                        </span>
                                    </div>
                                    <div className="col-xl-5 mt-3 mt-xl-4 pt-xl-2 pb-xl-4">
                                        <label className="font-12 grey-primary w-100">
                                            Account Status
                                        </label>
                                        <span className="font-12 font-medium green-textcolor mt-2">
                                            {userData?.isActive === true ? (
                                                <span style={{ color: 'green' }}>Active</span>
                                            ) : (
                                                <span style={{ color: 'grey' }}>Inactive</span>
                                            )}
                                        </span>
                                    </div>
                                    <span className="col-md-12 border-bottom mt-3 mt-xl-0" />
                                </div>
                                <h3 className="font-16 font-medium primary-textcolor mt-4">
                                    Contact Information
                                </h3>
                                <label className="font-12 grey-primary mt-4 w-100">Email</label>
                                <a
                                    href={`mailto:${userData?.primaryEmail}`}
                                    className="font-12 font-medium"
                                >
                                    {userData?.primaryEmail && userData?.primaryEmail !=="null" ? userData?.primaryEmail : '--'}
                                </a>
                                <label className="font-12 grey-primary mt-4 w-100">User ID</label>
                                <span className="font-12 font-medium primary-textcolor mt-2">
                                    {userData?.employeeId}
                                </span>
                                <label className="font-12 grey-primary mt-4 w-100">Location</label>
                                <span className="font-12 font-medium primary-textcolor mt-2">
                                    {`${userData?.streetAddress && userData?.streetAddress !=="null"? userData?.streetAddress : "--"}, ${userData?.City && userData?.City !=="null" ? userData?.City : "--"}`}
                                </span>
                                <label className="font-12 grey-primary mt-4 w-100">Contact</label>
                                <span className="font-12 font-medium primary-textcolor mt-2">
                                    {userData?.primaryContact && userData?.primaryContact !=="null" ? userData?.primaryContact : '--'}
                                </span>
                            </div>
                        </div>
                        {/* {userProfile side bar ends here} */}
                        <div className="custom-w-75 container-border ">
                            <div className="p-4 pt-3">
                                {/* Tab structure starts here */}
                                <div className="d-flex justify-content-between align-items-center ps-1 custom-tabs">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item ms-4" role="presentation">
                                            <button
                                                className="nav-link active tone-down px-0 font-14 tab-style border-0 d-flex align-items-center me-3 me-xl-5"
                                                id="activity-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#activity"
                                                type="button"
                                                role="tab"
                                                aria-controls="activity"
                                                aria-selected="false"
                                                onClick={() => { getActivity() }}
                                            >
                                                <img
                                                    src="images/activity-icon.svg"
                                                    alt="details"
                                                    width={13}
                                                    className="me-2"
                                                />
                                                Activity
                                            </button>
                                        </li>
                                        <li className="nav-item ms-4" role="presentation">
                                            <button
                                                className="nav-link tone-down px-0 font-14 tab-style border-0 d-flex align-items-center"
                                                id="org-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#org"
                                                type="button"
                                                role="tab"
                                                aria-controls="org"
                                                aria-selected="false"
                                                onClick={async () => { await fetchOrganization() }}
                                            >
                                                <img
                                                    src="images/my-org-active.svg"
                                                    alt="details"
                                                    width={16}
                                                    className="me-2"
                                                />
                                                Organization
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content " id="pills-tabContent">

                                    <div className="tab-pane active " id="activity" role="tabpanel" aria-labelledby="activity-tab">
                                        <div class="ps-3 ">
                                            {logData?.length > 0 || !isDisable ? bindlogReport() : <NotFound />}
                                        </div>
                                        {logData.length < logCount && (
                                            <div className='text-center mt-5 mb-4'>
                                                <button className="primary-btn font-medium" onClick={() => setLoadMore(loadMore + 5)}>Load More</button>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="tab-pane fade pt-2"
                                        id="org"
                                        role="tabpanel"
                                        aria-labelledby="org-tab"
                                    >
                                        <div className="row">

                                            {bindOrganizationChart(orgManagerData, userData)}
                                            {bindEmployeeDetails()}
                                            <div className="">
                                                <div className="row">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isToast ? (
                <>
                    <ToastMessageComponent
                        toastResponse={toastMessage}
                        setIsShowToastMessage={setIsToast}
                    />
                </>
            ) : null}

        </>

    );
};