import React, { useState, useEffect, useContext, createContext } from 'react'
import ControlsGridSidebar from './ControlsGridSidebar'
// import ControlSidebar from './ControlSidebar' 
import { encryptStorage } from '../../Constant'
import ControlsGrid from './controlsGrid'
import ControlDetailsPage from './ControlDetailsPage'
import { agovContext } from '../../Constant'
import { EncryptStorage } from 'encrypt-storage'
import { getControlsGrid } from '../../Services/GRC/GrcService'
import AddControlComponent from './AddControlComponent'
import ControlHeader from './ControlHeader'
import { useNavigate } from 'react-router'
import { Loader } from '../Loader/Loader'
import { useLocation } from 'react-router-dom' 

const ControlsComponent = () => {

  //PC_CC_02 
  //Declare all the required state variables 
  const { employeeId, employeeName, roles, userId } = useContext(agovContext);
  const [controls, setControls] = useState([]);
  const [searchControl, setSearchControl] = useState('');
  const [hideControlInfo, setHideControlInfo] = useState(true);
  const [selectedPrinciple, setSelectedPrinciple] = useState('')
  const [isAuditor, setIsAuditor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hideAddControlPage, setHideAddControlPage] = useState(true);
  const [hideControlsGrid, setHideControlsGrid] = useState(false);
  const [viewAsAuditor, setViewAsAuditor] = useState(encryptStorage.getItem('viewAsAuditor') == "true" ? true : false);
  const [selectedControlId, setSelectedControlId] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [parentState, setParentState] = useState('');
  const [filteredData, setFilteredData] = useState('')
  const [onApply, setOnApply] = useState(false)
  const [onClear, setOnClear] = useState(false)

  const handleParentRerender = () => {
    setParentState(new Date().toLocaleTimeString()); 
  };

  // PC_CC_03 -> useNavigate and useLocation declaration
  const navigate = useNavigate();
  const location = useLocation();


  /**
   * PC_CC_30 - PC_CC_33
   * function that scrolls the data 
   */
  const handleCategoryClick = (trustServiceIndex, categoryIndex) => {
    setActiveCategory(`${trustServiceIndex}-${categoryIndex}`);
  };



  /**
   * PC_CC_09 - PC_CC_19
   * It fetches the data from the backend and set the state of the binding variables with the returned values
   */
  let loadControls = async () => {
    setIsLoading(true);
    let payload = 
      { "searchControl": searchControl, "auditCycleId": encryptStorage.getItem("identifiant"), "startDate": encryptStorage.getItem('commencer'), "endDate": encryptStorage.getItem('fin'), "filter" : filteredData}
    if(location.state){
      payload = 
      { "searchControl": searchControl, "auditCycleId": encryptStorage.getItem("identifiant"), "startDate": encryptStorage.getItem('commencer'), "endDate": encryptStorage.getItem('fin'), "filter" : location.state}

    }
    let controlValues = await getControlsGrid(payload)
    if(!controlValues.responseData){
      navigate('*')
    }
    else{
      setControls(controlValues?.responseData);
      setIsLoading(false);
    }
    
  }

  /**
   * PC_CC_04
   * The useEffect checks the roles and triggers the loadControls() function
   */
  useEffect(() => {

    if (roles?.includes("GRC Admin") || roles?.includes("Super Admin")) {
      setIsAdmin(true);
    }
    else if (roles?.includes("Auditor")) {
      setIsAuditor(true);
    } else {
      navigate('*')
    }


    // encryptStorage.setItem('auditCycleId', 1);
    // encryptStorage.setItem('startDate', '2022-01-01')
    // encryptStorage.setItem('endDate', '2024-07-01');
    loadControls();

  }, [searchControl, viewAsAuditor, onApply, onClear])

  // PC_CC_458 and PC_CC_459 -> handlefilter function is invoked.
  const handleFilter = () =>{
    if(location.state){
      location.state = ''
    }
    setOnApply(!onApply)
  }

  // PC_CC_460 to PC_CC_461 -> handleClear() function is invoked.
  const handleClear = () =>{
    setFilteredData('')
    location.state = ''
    setOnClear(!onClear)
  }

  return (
    <>
      <div className={!isLoading ? "container-fluid mt-4 mt-custom" : "container-fluid"}>
      <Loader isLoading={isLoading} />
        <div className="col-md-12 px-lg-5">
          <div className="d-xl-flex d-block justify-content-between mt-4 mb-3">
            <h1 className="font-bold font-24 primary-textcolor d-flex  align-items-center mb-xl-0 mb-3"> 
              <a onClick={()=>{navigate(-1)}}>
                <img src="/images/back-arrow.svg" alt="Back" className="me-3" style={{cursor : 'pointer'}}/>
              </a>{" "}
               
              <span>SOC 2</span>
            </h1>
            {/*Controls tab structure starts here*/}
            <ControlHeader setSearchControl={setSearchControl} setHideControlsGrid={setHideControlsGrid} setHideAddControlPage={setHideAddControlPage} setHideControlInfo={setHideControlInfo} viewAsAuditor={viewAsAuditor} setViewAsAuditor={setViewAsAuditor} isAuditor={isAuditor} onRerender={handleParentRerender} filteredData={location.state ? location.state : filteredData}/>
            {/*Controls tab structure Ends here*/}
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              {/*Compliance tab starts here*/}
              <div className="d-flex justify-content-between align-items-center ps-1 my-3 mb-4 custom-tabs">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item me-2" role="presentation">
                    <button
                      className="nav-link px-0 active font-14 tab-style rounded-0"
                      id="Compliancecontrols-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Compliancecontrols"
                      type="button"
                      role="tab"
                      aria-controls="Compliancecontrols"
                      aria-selected="true"
                    >
                      <span className="tab-img">
                        {" "}
                        <img
                          src="/images/control-active-icon.svg"
                          className="me-2"
                        />{" "}
                        <img src="/images/control-icon.svg" className="me-2" />
                      </span>
                      Controls
                    </button>
                  </li>
                  <li className="nav-item ms-4 me-2" role="presentation">
                    <button
                      className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                      id="Compliancepolicies-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Compliancepolicies"
                      type="button"
                      role="tab"
                      aria-controls="Compliancepolicies"
                      aria-selected="false"
                      onClick={() => { navigate('/compliance/policyGrid') }}
                    >
                      <span className="tab-img">
                        {" "}
                        <img
                          src="/images/policy-active-icon.svg"
                          className="me-2"
                        />{" "}
                        <img src="/images/policies-icon.svg" className="me-2" />{" "}
                      </span>
                      Policies
                    </button>
                  </li>
                  <li className="nav-item ms-4 me-2" role="presentation">
                    <button
                      className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                      id="Complianceevidences-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Complianceevidences"
                      type="button"
                      role="tab"
                      aria-controls="Complianceevidences"
                      aria-selected="false"
                      onClick={() => { navigate('/compliance/evidenceGrid') }}
                    >
                      <span className="tab-img">
                        {" "}
                        <img
                          src="/images/evidence-active-icon.svg"
                          className="me-2"
                        />{" "}
                        <img src="/images/evidence-icon.svg" className="me-2" />{" "}
                      </span>
                      Evidences
                    </button>
                  </li>
                  <li className="nav-item ms-4" role="presentation">
                    <button
                      className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                      id="Complianceotherdocs-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Complianceotherdocs"
                      type="button"
                      role="tab"
                      aria-controls="Complianceotherdocs"
                      aria-selected="false"
                      onClick={() => { navigate('/compliance/otherDocumentsGrid') }}
                    >
                      <span className="tab-img">
                        {" "}
                        <img
                          src="/images/other-docs-active-icon.svg"
                          className="me-2"
                        />{" "}
                        <img src="/images/other-docs-icon.svg" className="me-2" />{" "}
                      </span>
                      Other Documents
                    </button>
                  </li>
                </ul>
              </div>
              {/* Compliance tab ends here */}
            </div>

            <div className="col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active justify-content-center"
                  id="Compliancecontrols"
                  role="tabpanel"
                  aria-labelledby="Compliancecontrols-tab"
                >
                  <div className="row">
                    {/* <div class="control-lefttab-scroll"> */}
                    <ControlsGridSidebar controls={controls} handleCategoryClick={handleCategoryClick} setHideControlsGrid={setHideControlsGrid} setHideControlInfo={setHideControlInfo} setHideAddControlPage={setHideAddControlPage} />

                    {/* </div> */}

                    {hideControlsGrid == false ?
                      <ControlsGrid controls={controls} activeCategory={activeCategory} setActiveCategory={setActiveCategory} setSelectedControlId={setSelectedControlId} setHideControlsGrid={setHideControlsGrid} setHideControlInfo={setHideControlInfo} setSelectedPrinciple={setSelectedPrinciple} setHideAddControlPage={setHideAddControlPage} isAuditor={isAuditor} viewAsAuditor={viewAsAuditor} setFilteredData = {setFilteredData} handleFilter = {handleFilter} status = {location.state ? location.state : filteredData}  handleClear = {handleClear} />
                      : ''}
                    {hideControlInfo == false ?
                      <ControlDetailsPage selectedControlId={selectedControlId} setHideControlsGrid={setHideControlsGrid} setHideControlInfo={setHideControlInfo} selectedPrinciple={selectedPrinciple} isAuditor={isAuditor} viewAsAuditor={viewAsAuditor} />
                      : ''}
                    {hideAddControlPage == false ?
                      <AddControlComponent setHideAddControlPage={setHideAddControlPage} setHideControlsGrid={setHideControlsGrid} />
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ControlsComponent