/**
 * AG_PC_VF_01 - AG_PC_VF_07
 * Import statements for the VendorFormComponent
 */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { insertVendorDetails, updateVendorDetails, getVendorFormData, deleteVendorContact } from '../../Services/GRC/GrcService';
import { Loader } from '../Loader/Loader';
import { agovContext } from '../../Constant';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';



/**
 * AG_PC_VF_08 - AG_PC_VF_25
 * State variables decarations and parent component declaration 
 */
export const VendorFormComponent = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isAuditor, setIsAuditor] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [vendorGridId, setVendorGridId] = useState('')
    const [editingContactId, setEditingContactId] = useState(null);
    const [editingContactValues, setEditingContactValues] = useState('')
    const [ClickFrom, setClickFrom] = useState('')
    const [deletedContactIds, setDeletedContactIds] = useState([]);
    const [isToast, setIsToast] = useState(false)
    const [venName, setVenName] = useState('')
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null,
    })
    const [errors, setErrors] = useState({});
    const [vendorDetails, setVendorDetails] = useState({
        vendorName: '',
        vendorDescription: '',
        street1: '',
        street2: '',
        city: '',
        zipCode: '',
        country: '',
        state: ''
    });

    let [vendorContact, setVendorContact] = useState([{
        id: 1,
        contactID: '',
        userName: '',
        emailId: '',
        contactNumber: '',
        primaryContact: '',
        userNameErrorMessage: "",
        emailIdErrorMessage: "",
        contactNumberErrorMessage: ""
    }]);

    const [newVendorContact, setNewVendorContact] = useState({
        userName: '',
        emailId: '',
        contactNumber: '',
        primaryContact: false
    });

    const [validateVendorData, setValidateVendorData] = useState({
        vendorNameError: false,
        vendorDescriptionError: false,
        street1Error: false,
        cityError: false,
        zipCodeError: false,
        countryError: false,
        stateError: false
    });

    const [vendorContactError, setVendorContactError] = useState({})

    /**
     * AG_PC_VF_26 - AG_PC_VF_27
     * Check for the roles from the context and check for the vendorGridId and clickFrom the state
     * Routes the functionalities based on the clickfrom and vendorGridId, whether it is a update or add flow.
     */
    useEffect(() => {
        if (roles?.includes("GRC Admin") || roles?.includes("Super Admin")) {
            setIsAdmin(true);
        }
        else if (roles?.includes("Auditor")) {
            setIsAuditor(true);
        } else {
            navigate('/')
        }
        const vendorGridID = location?.state?.vendor_id || '';
        const clickFrom = location?.state?.clickFrom
        setClickFrom(clickFrom)
        setVendorGridId(vendorGridID);

        if (vendorGridID && clickFrom == "vendorEditClick" || clickFrom == "vendorNameClick") {
            fetchData(vendorGridID);
        }
    }, []);


    /**
     *AG_PC_VF_28
     *Get the vendor Information based on the vendorId from the state
     *Set state the VendorDetails and Vendor Contact details to the respective state variables.
     */
    const fetchData = async (vendorGridID) => {
        try {
            const requestData = { vendorId: vendorGridID };
            setIsLoading(true)
            const response = await getVendorFormData(requestData);
            setIsLoading(false)

            // Check if response data exists and has at least one item
            if (response && response.data && response.data.length > 0) {
                const vendorData = response.data[0];

                // Set vendor details state
                setVendorDetails({
                    vendorName: vendorData.vendorName,
                    vendorDescription: vendorData.vendorDescription,
                    street1: vendorData.street1,
                    street2: vendorData.street2,
                    city: vendorData.city,
                    zipCode: vendorData.zipCode,
                    country: vendorData.country,
                    state: vendorData.state
                });

                const VendorContacts = vendorData.vendorContactInfo.map((contact, index) => ({
                    id: index + 1,
                    contactID: contact.contactID,
                    userName: contact.userName,
                    emailId: contact.emailId,
                    contactNumber: contact.contactNumber,
                    primaryContact: contact.primaryContact === 1
                }));

                setVendorContact(VendorContacts);
                setVenName(vendorData.vendorName)
            } else {
                navigate('/error')
            }
        } catch (error) {
            console.error('Error fetching vendor data:', error);
        }
    };

    /**
     * AG_PC_VF_38
     * Based on the ID from the function parameter, that specified row gets enabled to edit the form.
     */
    const handleEditContact = (contactId) => {
        setEditingContactId(contactId);
        setEditingContactValues({ ...vendorContact.find(contact => contact.id === contactId) });

    };


    /**
     * AG_PC_VF_39 - AG_PC_VF_42
     * Based on the contactID that specified row changes are commited to the vendorContact State variable
     * Validation for that specified row takes place.
     */
    const handleUpdateContact = (contactID, contact) => {
        const contactValid = []
        contactValid.push(contact);
        // Validate the new contact details
        const errors = validateVendorContact(contactValid, contactID);
        const contactIndex = vendorContact.findIndex(contact => contact.id === contactID);
        // Make a copy of the existing vendor contact array
        const updatedVendorContacts = [...vendorContact];
        if ((errors.userName || errors.emailId || errors.contactNumber) && (Object.values(errors).some(error => error !== ""))) {
            updatedVendorContacts[contactIndex] = {
                ...updatedVendorContacts[contactIndex],
                userNameErrorMessage: errors.userName,
                emailIdErrorMessage: errors.emailId,
                contactNumberErrorMessage: errors.contactNumber
            };
            setVendorContact(updatedVendorContacts);
            return;
        }

        // Update only the specific fields of the contact being edited
        updatedVendorContacts[contactIndex] = {
            ...updatedVendorContacts[contactIndex],
            userName: updatedVendorContacts[contactIndex].userName,
            emailId: updatedVendorContacts[contactIndex].emailId,
            contactNumber: updatedVendorContacts[contactIndex].contactNumber,
            primaryContact: updatedVendorContacts[contactIndex].primaryContact,
            userNameErrorMessage: "",
            emailIdErrorMessage: "",
            contactNumberErrorMessage: ""
        };

        // Update the vendor contact state with the modified array
        setVendorContact(updatedVendorContacts);

        // Exit edit mode
        setEditingContactId(null);
    };

    /**
     * AG_PC_VF_43 - AG_PC_VF_44
     * The changes are reverted on the specified row based on the contactId when this function gets triggered 
     */
    const handleCancelEdit = (contactId) => {
        // Revert changes made by the user
        setEditingContactId(null); // Exit editing mode
        // Reset the contact values to their original values
        const updatedVendorContact = vendorContact.map(contact =>
            contact.id === contactId ? editingContactValues : contact
        );
        setVendorContact(updatedVendorContact);
    };

    /**
     * AG_PC_VF_45 - AG_PC_VF_47
     * The vendorContact row gets deleted based on the id and the contactId been stored to remove the contact from DB
     * Set state the updatedVendor contact to the vendorContact state variable
     */
    const handleDeleteContact = (id, contactID) => {
        // Add the ID to the list of deleted contact IDs
        setDeletedContactIds(prevDeletedContactIds => [...prevDeletedContactIds, contactID]);
        // Filter out the deleted contact from the vendorContact array
        const updatedContacts = vendorContact.filter(contact => contact.id !== id);
        setVendorContact(updatedContacts);
    };

    /**
     *AG_PC_VF_48 - AG_PC_VF_51
     * Check for the primaryContact check box whether only one has been selected
    */
    const handlePrimaryContactChange = (e, id) => {
        const updatedContacts = vendorContact.map(contact => {
            if (contact.id === id) {
                return { ...contact, primaryContact: e.target.checked };
            } else {
                // Uncheck primaryContact for other rows
                return { ...contact, primaryContact: false };
            }
        });
        setVendorContact(updatedContacts);
    };

    /**
     * AG_PC_VF_55 
     * Validate the vendor contact form while creating a new row every time
     */
    const validateVendorContact = (contact, contactId) => {


        const errors = {};


        if (contactId) {
            const filterData = contact.filter((obj) =>
                obj.id === contactId
            )


            if (!filterData[0].userName || !filterData[0].userName.trim()) {
                errors.userName = 'Username is required';
            }

            if (!filterData[0].emailId || !filterData[0].emailId.trim()) {
                errors.emailId = 'Email is required';
            } else if (!/\S+@\S+\.\S+/.test(filterData[0].emailId)) {
                errors.emailId = 'Invalid email format';
            }

            if (!filterData[0].contactNumber || !filterData[0].contactNumber.trim()) {
                errors.contactNumber = 'Contact number is required';
            } else if (!/^\d+$/.test(filterData[0].contactNumber.trim())) {
                errors.contactNumber = 'Please enter a valid contact number';
            }

            return errors;
        }

        else {

            if (!contact.userName || !contact.userName.trim()) {
                errors.userName = 'Username is required';
            }

            if (!contact.emailId || !contact.emailId.trim()) {
                errors.emailId = 'Email is required';
            } else if (!/\S+@\S+\.\S+/.test(contact.emailId)) {
                errors.emailId = 'Invalid email format';
            }

            if (!contact.contactNumber || !contact.contactNumber.trim()) {
                errors.contactNumber = 'Contact number is required';
            } else if (!/^\d+$/.test(contact.contactNumber.trim())) {
                errors.contactNumber = 'Please enter a valid contact number';
            }
            return errors;
        }

    };
    /**
     * AG_PC_VF_29 - AG_PC_VF_37
     * New row gets added after the required validations and the updated vendor contacts get set stated.
     */
    const handleAddContactRow = (contact) => {

        // Ensure vendorContact state is properly initialized

        const validationErrors = validateVendorContact(contact);
        setErrors(validationErrors);

        // If no errors, proceed with adding the new contact row
        if (Object.keys(validationErrors).length === 0) {
            const newRow = { id: vendorContact.length + 1, ...newVendorContact };
            // Update vendorContact state with the new row
            setVendorContact([newRow, ...vendorContact]);

            // Clear input fields and reset errors
            setNewVendorContact({ userName: '', emailId: '', contactNumber: '', primaryContact: false });
        }
    };

    /**
     * AG_PC_VF_59 
     * The changes are captured when the user make changes on edit and those values been set stated.
     */
    const handleChange = (e, id, field) => {
        const updatedContacts = vendorContact.map(contact => {
            if (contact.id === id) {
                return { ...contact, [field]: e.target.value };
            }
            return contact;
        });

        setVendorContact(updatedContacts);
    };

    /**
     * AG_PC_VF_55 - AG_PC_VF_56
     * Based on the validation the error flags has been set, if any specified validation gets failed
     */
    const validateForm = () => {
        let isValid = true;
        let errors = {}; // Collect errors in an object

        // Check each field for errors
        if (!vendorDetails.vendorName.trim()) {
            errors.vendorNameError = true;
            isValid = false;
        }

        if (!vendorDetails.vendorDescription.trim()) {
            errors.vendorDescriptionError = true;
            isValid = false;
        }

        if (!vendorDetails.street1.trim()) {
            errors.street1Error = true;
            isValid = false;
        }

        if (!vendorDetails.country.trim()) {
            errors.countryError = true;
            isValid = false;
        }

        if (!vendorDetails.state.trim()) {
            errors.stateError = true;
            isValid = false;
        }

        if (!vendorDetails.city.trim()) {
            errors.cityError = true;
            isValid = false;
        }

        if (!vendorDetails.zipCode) {
            errors.zipCodeError = true;
            isValid = false;
        }

        // Update state with all errors at once
        setValidateVendorData(errors);

        return isValid;
    };
    /**
     * AG_PC_VF_61 - AG_PC_VF_128
     * Based on the present of the vendorId the function goes into the insert or update flow
     * Validation for the both vendorForm and vendorContact has been checked
     * If any Id present in the deletedContactIds variable call the delete vendor function to delete the specified records 
     * If the response is success send the toast message accordingly
     */
    const handleSave = async () => {

        const isValid = validateForm();
        const isValidContact = validateVendorContact(vendorContact);
        const keysArray = Object.keys(isValidContact)
        const isPrimaryContactSelected = vendorContact.some(contact => contact.primaryContact);

        if (isValid && !keysArray.length == 0) { // Check if both form and contact are valid
            // Filter out contacts with IDs in the deletedContactIds array
            if (!isPrimaryContactSelected) {
                setIsToast(true)
                setToastMessage(
                    {
                        path: "/images/error-toast-icon.svg",
                        header: "",
                        message: "Atleast one primary contact should be selected",
                        color: "error-toast"
                    })
                return
            }

            if (deletedContactIds.length > 0) {
                const deletedContactIdString = deletedContactIds.join(',');
                const requestBody = { id: deletedContactIdString }
                setIsLoading(true)
                const deleteContact = await deleteVendorContact(requestBody)
                setIsLoading(false)

            }

            const vendorContactDetails = vendorContact.filter(obj => obj.userName !== '');
            const requestPayload = {
                vendorName: vendorDetails.vendorName,
                vendorDescription: vendorDetails.vendorDescription,
                street1: vendorDetails.street1,
                street2: vendorDetails.street2,
                country: vendorDetails.country,
                state: vendorDetails.state,
                city: vendorDetails.city,
                zipCode: vendorDetails.zipCode,
                userId: userId,
                contacts: vendorContactDetails
            };

            try {
                if (vendorGridId) {
                    // Update existing record
                    setIsLoading(true)
                    const response = await updateVendorDetails({ ...requestPayload, vendorId: vendorGridId });
                    setIsLoading(false)
                    if (response.statusCode === 200) {

                        navigate('/compliance/vendor', {
                            state: {
                                toastResponse
                                    : {
                                    path: "/images/Success-icon.svg",
                                    header: "",
                                    message: "Vendor updated Successfully",
                                    color: "success-toast"
                                },
                                isToast: "toast"

                            }
                        });
                    } else {
                        navigate('/error');
                    }
                } else {
                    // Insert new record
                    setIsLoading(true)
                    const response = await insertVendorDetails(requestPayload);
                    setIsLoading(false)

                    if (response.statusCode === 200) {
                        navigate('/compliance/vendor', {
                            state: {
                                toastResponse
                                    : {
                                    path: "/images/Success-icon.svg",
                                    header: "",
                                    message: "Vendor Created Successfully",
                                    color: "success-toast"
                                },
                                isToast: "toast"

                            }
                        });
                    } else {
                        navigate('/error');
                    }
                }
            } catch (error) {
                navigate('/error');

            }
        } else {
            setErrors(validateVendorContact(vendorContact))
        }
    };

    /**
     * AG_PC_VF_106
     * Store the country state and city in a variable and bind int the respective div accordingly
     */
    // For the Country Select component
    const countryOption = Country.getAllCountries().find(option => option.name === vendorDetails.country);
    // For the State Select component
    const stateOption = State?.getStatesOfCountry(countryOption?.isoCode)?.find(option => option.name === vendorDetails.state);
    // For the City Select component
    const cityOption = City.getCitiesOfState(stateOption?.countryCode, stateOption?.isoCode)?.find(option => option.name === vendorDetails.city);

    /**
     * AG_PC_VF_156
     * Navigate the VendorQuestionarie component onclick of the questionarie tab with required state values
     */
    const handleNavigateQuestionarie = async () => {
        navigate('/compliance/vendorQuestions', {
            state: {
                vendor_id: vendorGridId,
                clickFrom: ClickFrom,
                vendorname: vendorDetails.vendorName
            }

        })
    }
    /**
     * AG_PC_VF_159
     * Navigate the VendorQuestionarie component onclick of the questionarie tab with required state values
     */
    const handleNavigateQuestionarieHistory = async () => {
        navigate('/compliance/questionaryhistory', {
            state: {
                vendor_id: vendorGridId,
                clickFrom: ClickFrom,
                vendorname: vendorDetails.vendorName
            }

        })
    }

    return (
        <>
            <Loader isLoading={isLoading} />

            {vendorGridId ? (
                <div>
                    <div className="container-fluid mt-4 mt-custom">
                        <div className="px-5 pb-5">
                            {/*forms starts here*/}
                            <div className="row">
                                {/*heading starts here*/}
                                <div className="col-md-12 mb-4">
                                    <h5 className="font-bold font-24 cursor-pointer" style={{ 'word-break': 'break-all' }}><img className="me-3 mb-1" src="/images/back-arrow.svg" alt="back-arrow" onClick={() => navigate('/compliance/vendor')} />{venName}</h5>
                                </div>
                                {/*heading starts here*/}
                                <div>
                                    <ul className="nav nav-tabs border-0 mb-4" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link border-0 font-14 px-0 me-4 compliance-custom-tab active" id="Primaryinfo-tab" data-bs-toggle="tab" data-bs-target="#Primaryinfo" type="button" role="tab" aria-controls="Primaryinfo" aria-selected="true">
                                                Primary Information
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link border-0 font-14 px-0 compliance-custom-tab me-4" id="Questionnaire-tab" data-bs-toggle="tab" data-bs-target="#Questionnaire" type="button" role="tab" aria-controls="Questionnaire" aria-selected="false" onClick={() => handleNavigateQuestionarie()}>
                                                Questionnaire
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link border-0 font-14 px-0 compliance-custom-tab me-4" id="Queshistory-tab" data-bs-toggle="tab" data-bs-target="#Queshistory" type="button" role="tab" aria-controls="Queshistory" aria-selected="false" onClick={() => handleNavigateQuestionarieHistory()}>
                                                Questionnaire History
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className="col-md-12 mb-4">
                                        <div className="row">
                                            <div className="col-md-6 mt-4">
                                                <label htmlFor="vendor-name" className="form-label font-medium font-14 primary-textcolor mb-1">Vendor Name<span className="required-text">*</span></label>
                                                <input type="text" className={`form-control font-regular font-14 custom-form-control py-2 ${validateVendorData.vendorNameError ? 'is-invalid' : ''}`} id="vendor-name" maxLength={200} placeholder="Enter Vendor Name" value={vendorDetails.vendorName} onChange={(e) => {
                                                    setVendorDetails({ ...vendorDetails, vendorName: e.target.value });

                                                    setValidateVendorData({ ...validateVendorData, vendorNameError: false }); // Reset error state
                                                }} disabled={ClickFrom === 'vendorNameClick' || isAuditor}  />
                                                {validateVendorData.vendorNameError && <div className="invalid-feedback">Vendor name is required.</div>}
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <label htmlFor="description" className="form-label font-medium font-14 primary-textcolor mb-1">Vendor Description<span className="required-text">*</span></label>
                                                <textarea type="text" className={`form-control font-regular font-14 custom-form-control py-2 resize-none ${validateVendorData.vendorDescriptionError ? 'is-invalid' : ''}`} rows={4} id="description" placeholder="Enter Vendor Description" value={vendorDetails.vendorDescription} onChange={(e) => {
                                                    setVendorDetails({ ...vendorDetails, vendorDescription: e.target.value });
                                                    setValidateVendorData({ ...validateVendorData, vendorDescriptionError: false }); // Reset error state
                                                }} disabled={ClickFrom === 'vendorNameClick' || isAuditor}  />
                                                {validateVendorData.vendorDescriptionError && <div className="invalid-feedback">Vendor description is required.</div>}
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="street-1" className="form-label font-medium font-14 primary-textcolor mb-1">Street 1<span className="required-text">*</span></label>
                                                        <input type="text" className={`form-control font-regular font-14 custom-form-control py-2 ${validateVendorData.street1Error ? 'is-invalid' : ''}`} id="street-1" maxLength={200} placeholder="Enter Street 1" value={vendorDetails.street1} onChange={(e) => {
                                                            setVendorDetails({ ...vendorDetails, street1: e.target.value });
                                                            setValidateVendorData({ ...validateVendorData, street1Error: false }); // Reset error state
                                                        }} disabled={ClickFrom === 'vendorNameClick' || isAuditor}  />
                                                        {validateVendorData.street1Error && <div className="invalid-feedback">Street 1 is required.</div>}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="street-2" className="form-label font-medium font-14 primary-textcolor mb-1">Street 2<span className="required-text"></span></label>
                                                        <input type="text" className={`form-control font-regular font-14 custom-form-control py-2`} id="street-2" maxLength={200} placeholder="Enter Street 2" value={vendorDetails.street2} onChange={(e) => setVendorDetails({ ...vendorDetails, street2: e.target.value })} disabled={ClickFrom === 'vendorNameClick' || isAuditor}  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label htmlFor="country" className="form-label font-medium font-14 primary-textcolor mb-1">Country<span className="required-text">*</span></label>
                                                        <Select
                                                            options={Country.getAllCountries()}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.isoCode}

                                                            className={`font-regular font-14 py-2 ${validateVendorData.countryError ? 'is-invalid' : ''}`}
                                                            value={countryOption}
                                                            onChange={(selectedOption) => {
                                                                setSelectedCountry(selectedOption);
                                                                setVendorDetails({ ...vendorDetails, country: selectedOption.name });
                                                                setValidateVendorData({ ...validateVendorData, countryError: false });
                                                            }}
                                                            isDisabled={ClickFrom === 'vendorNameClick' || isAuditor}
                                                            style={{ backgroundColor: (ClickFrom === 'vendorNameClick' || isAuditor)  ? 'var(--bs-secondary-bg) !important' : null }}
                                                        />
                                                        {validateVendorData.countryError && <div className="invalid-feedback">Country is required.</div>}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="state" className="form-label font-medium font-14 primary-textcolor mb-1">State<span className="required-text">*</span></label>
                                                        <Select
                                                            options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.isoCode}
                                                            className={` font-regular font-14 py-2  ${validateVendorData.stateError ? 'is-invalid' : ''}`}

                                                            value={stateOption}
                                                            onChange={(selectedOption) => {
                                                                setSelectedState(selectedOption);
                                                                // test(selectedOption)

                                                                setVendorDetails({ ...vendorDetails, state: selectedOption.name });
                                                                setValidateVendorData({ ...validateVendorData, stateError: false });
                                                            }}
                                                            isDisabled={ClickFrom === 'vendorNameClick' || isAuditor} />
                                                        {validateVendorData.stateError && <div className="invalid-feedback">State is required.</div>}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="city" className="form-label font-medium font-14 primary-textcolor mb-1">City<span className="required-text">*</span></label>
                                                        <Select
                                                            options={City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.name}
                                                            value={cityOption}
                                                            className={`font-regular font-14 py-2 ${validateVendorData.cityError ? 'is-invalid' : ''}`}
                                                            onChange={(selectedOption) => {
                                                                setSelectedCity(selectedOption);
                                                                setVendorDetails({ ...vendorDetails, city: selectedOption.name });
                                                                setValidateVendorData({ ...validateVendorData, cityError: false });
                                                            }}
                                                            isDisabled={ClickFrom === 'vendorNameClick' || isAuditor} />
                                                        {validateVendorData.cityError && <div className="invalid-feedback">City is required.</div>}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="zipcode" className="form-label font-medium font-14 primary-textcolor mb-1">Zipcode<span className="required-text">*</span></label>
                                                        <input
                                                            type="text"
                                                            className={`form-control font-regular font-14 custom-form-control py-2  ${validateVendorData.zipCodeError ? 'is-invalid' : ''}`}
                                                            id="zipcode"
                                                            placeholder="Enter Zipcode"
                                                            value={vendorDetails.zipCode}
                                                            onChange={(e) => {
                                                                // Allow only numbers
                                                                const re = /^\d{0,8}$/;
                                                                if (re.test(e.target.value) || e.target.value === '') {
                                                                    setVendorDetails({ ...vendorDetails, zipCode: e.target.value });
                                                                    setValidateVendorData({ ...validateVendorData, zipCodeError: false }); // Reset error state
                                                                }
                                                            }}
                                                            style={{ marginTop: '6px' }}
                                                            disabled={ClickFrom === 'vendorNameClick' || isAuditor} />
                                                        {validateVendorData.zipCodeError && <div className="invalid-feedback">Zipcode is required.</div>}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*forms ends here*/}
                            {/* Vendor Contact*/}
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <h5 className="font-bold font-20">Vendor Contact</h5>
                                </div>
                                <div className="col-md-12">
                                    <table className="table mb-0 custom-table-grid table-borderless mt-3">
                                        <thead>
                                            <tr>
                                                <th className="font-14 px-4 position-relative">Username

                                                </th>
                                                <th className="font-14 position-relative">Email ID

                                                </th>
                                                <th className="font-14 position-relative">Contact Number

                                                </th>
                                                <th className="font-14 position-relative text-center">Primary Contact

                                                </th>
                                                {!isAuditor && ClickFrom !== 'vendorNameClick' && (
                                                    <th className="font-14 text-center">Action</th>
                                                )}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isAuditor && ClickFrom !== 'vendorNameClick' && (
                                                <tr>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className={`form-control font-regular font-14 custom-form-control py-2 ${errors.userName ? 'is-invalid' : ''}`} placeholder="Enter Username"
                                                            value={newVendorContact.userName}
                                                            onChange={(e) => {
                                                                setNewVendorContact({ ...newVendorContact, userName: e.target.value });
                                                                setErrors({}); // Reset errors
                                                            }}

                                                        />
                                                        {errors.userName && <div className="invalid-feedback">{errors.userName}</div>}
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className={`form-control font-regular font-14 custom-form-control py-2 ${errors.emailId ? 'is-invalid' : ''}`}
                                                            placeholder="Enter Email ID"
                                                            value={newVendorContact.emailId}
                                                            onChange={(e) => {
                                                                setNewVendorContact({ ...newVendorContact, emailId: e.target.value });
                                                                setErrors({}); // Reset errors
                                                            }}

                                                        />
                                                        {errors.emailId && <div className="invalid-feedback">{errors.emailId}</div>}
                                                    </td>
                                                    <td>
                                                        <input
                                                            maxLength={15}
                                                            className={`form-control font-regular font-14 custom-form-control py-2 ${errors.contactNumber ? 'is-invalid' : ''}`}
                                                            placeholder="Enter Contact"
                                                            value={newVendorContact.contactNumber}
                                                            onChange={(e) => {
                                                                const enteredValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                                                                setNewVendorContact({ ...newVendorContact, contactNumber: enteredValue });
                                                                setErrors({}); // Reset errors
                                                            }}
                                                        />
                                                        {errors.contactNumber && <div className="invalid-feedback">{errors.contactNumber}</div>}
                                                    </td>
                                                    <td className="text-center">
                                                        <input
                                                            className="form-check-input custom-check-input"
                                                            type="checkbox"
                                                            checked={newVendorContact.primaryContact}
                                                            onChange={(e) => {
                                                                const { checked } = e.target;
                                                                setNewVendorContact({ ...newVendorContact, primaryContact: checked });
                                                                // Logic to uncheck other checkboxes
                                                                if (checked) {
                                                                    const updatedVendorContact = vendorContact.map(contact => ({
                                                                        ...contact,
                                                                        primaryContact: contact.id === newVendorContact.id ? checked : false,
                                                                    }));
                                                                    setVendorContact(updatedVendorContact);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <img src="/images/add-icon-line.svg" alt="add-icon" onClick={() => handleAddContactRow(newVendorContact)} style={{ cursor: 'pointer' }} />
                                                    </td>
                                                </tr>
                                            )}

                                            {vendorContact.map((contact, index) => (
                                                <tr key={contact.id}>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className={`form-control font-regular font-14 custom-form-control py-2 ${contact?.userNameErrorMessage ? 'is-invalid' : ''}`}
                                                            value={contact.userName}
                                                            onChange={(e) => handleChange(e, contact.id, 'userName')}
                                                            disabled={!editingContactId || editingContactId !== contact.id} // Disable input when not editing or editing another contact
                                                        />
                                                        <div className="invalid-feedback">{contact?.userNameErrorMessage ?? ""}</div>
                                                        {/* {errors.userName && <div className="invalid-feedback">{errors.userName}</div>} */}
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className={`form-control font-regular font-14 custom-form-control py-2 ${contact?.emailIdErrorMessage ? 'is-invalid' : ''}`}
                                                            value={contact.emailId}
                                                            onChange={(e) => handleChange(e, contact.id, 'emailId')}
                                                            disabled={!editingContactId || editingContactId !== contact.id} // Disable input when not editing or editing another contact
                                                        />
                                                        <div className="invalid-feedback">{contact?.emailIdErrorMessage ?? ""}</div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className={`form-control font-regular font-14 custom-form-control py-2 ${contact?.contactNumberErrorMessage ? 'is-invalid' : ''}`}
                                                            maxLength={15}
                                                            value={contact.contactNumber}
                                                            onChange={(e) => handleChange(e, contact.id, 'contactNumber')}
                                                            disabled={!editingContactId || editingContactId !== contact.id} // Disable input when not editing or editing another contact
                                                        />
                                                        <div className="invalid-feedback">{contact?.contactNumberErrorMessage ?? ""}</div>
    

                                                    </td>
                                                    <td className="text-center">
                                                        <input
                                                            className="form-check-input custom-check-input"
                                                            type="checkbox"
                                                            checked={contact.primaryContact}
                                                            onChange={(e) => handlePrimaryContactChange(e, contact.id)}
                                                            disabled={!editingContactId || editingContactId !== contact.id} // Disable checkbox when not editing or editing another contact
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        {!isAuditor && ClickFrom !== 'vendorNameClick' && ( // Check if clickFrom is not equal to 'vendorNameClick'
                                                            <>
                                                                {editingContactId === contact.id ? (
                                                                    <>
                                                                        <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleUpdateContact(contact.id, contact)}>
                                                                            <img src="/images/tick-icon.svg" alt="tick-icon" />
                                                                        </button>
                                                                        <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleCancelEdit(contact.id)}>
                                                                            <img src="/images/cross-icon.svg" alt="cross-icon" />
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleEditContact(contact.id, contact.contactID)}>
                                                                        <img src="/images/edit-icon.svg" alt="edit-icon" />
                                                                    </button>
                                                                )}
                                                                <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleDeleteContact(contact.id, contact.contactID)}>
                                                                    <img src="/images/delete-icon.svg" alt="delete-icon" />
                                                                </button>
                                                            </>
                                                        )}
                                                    </td>

                                                </tr>
                                            ))}

                                        </tbody>

                                    </table>
                                </div>
                                {!isAuditor && ClickFrom !== 'vendorNameClick' && (
                                    <div className="col-md-12 py-5">
                                        <div className="d-flex justify-content-end">
                                            <button type="button" className="primary-btn font-14 font-medium py-2 me-3" onClick={() => { navigate('/compliance/vendor') }}>Cancel</button>
                                            <button type="button" className="dark-btn font-14 font-medium py-2" onClick={() => handleSave()}>Update</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            ) : (

                <div className="container-fluid mt-4 mt-custom">
                    <div className="px-5">
                        {/*forms starts here*/}
                        <div className="row">
                            {/*heading starts here*/}
                            <div className="col-md-12">
                                <h5 className="font-bold font-24 cursor-pointer"><img className="me-3 mb-1" src="/images/back-arrow.svg" alt="back-arrow" onClick={() => navigate('/compliance/vendor')} />Add New Vendor</h5>
                            </div>
                            {/*heading starts here*/}
                            {/*forms*/}
                            <div className="col-md-6 mt-5 mb-4">
                                <label htmlFor="vendor-name" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    Vendor Name<span className="required-text">*</span>
                                </label>
                                <input type="text" maxLength={200} className={`form-control font-regular font-14 custom-form-control py-2 ${validateVendorData.vendorNameError ? 'is-invalid' : ''}`} id="vendor-name" placeholder="Enter Vendor Name" value={vendorDetails.vendorName}
                                    onChange={(e) => {
                                        setVendorDetails({ ...vendorDetails, vendorName: e.target.value });
                                        setValidateVendorData({ ...validateVendorData, vendorNameError: false }); // Reset error state
                                    }} />
                                {validateVendorData.vendorNameError && <span className="invalid-feedback">Vendor Name is required.</span>}
                            </div>

                            <div className="col-md-12 mb-4">
                                <label htmlFor="description" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    Vendor Description<span className="required-text">*</span>
                                </label>
                                <textarea type="text" className={`form-control font-regular font-14 custom-form-control py-2 ${validateVendorData.vendorDescriptionError ? 'is-invalid' : ''}`} rows={4} id="description" placeholder="Enter Vendor Name" defaultValue={""} value={vendorDetails.vendorDescription} onChange={(e) => {
                                    setVendorDetails({ ...vendorDetails, vendorDescription: e.target.value });
                                    setValidateVendorData({ ...validateVendorData, vendorDescriptionError: false }); // Reset error state
                                }} />
                                {validateVendorData.vendorDescriptionError && <span className="invalid-feedback">Vendor Description is required.</span>}
                            </div>

                            <div className="col-md-6 mb-4">
                                <label htmlFor="street-1" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    Street 1<span className="required-text">*</span>
                                </label>
                                <input type="text" maxLength={200} className={`form-control font-regular font-14 custom-form-control py-2 ${validateVendorData.street1Error ? 'is-invalid' : ''}`} id="street-1" placeholder="Enter Street 1" value={vendorDetails.street1} onChange={(e) => {
                                    setVendorDetails({ ...vendorDetails, street1: e.target.value });
                                    setValidateVendorData({ ...validateVendorData, street1Error: false }); // Reset error state
                                }} />
                                {validateVendorData.street1Error && <span className="invalid-feedback">Street 1 is required.</span>}
                            </div>

                            <div className="col-md-6 mb-4 ">
                                <label htmlFor="street-2" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    Street 2<span className="required-text"></span>
                                </label>
                                <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="street-2" maxLength={200} placeholder="Enter Street 2" value={vendorDetails.street2} onChange={(e) => setVendorDetails({ ...vendorDetails, street2: e.target.value })} />
                            </div>

                            <div className={`col-md-3 ${validateVendorData.countryError ? 'has-error' : ''}`}>
                                <label htmlFor="country" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    Country<span className="required-text">*</span>
                                </label>
                                <Select
                                    options={Country.getAllCountries() || []}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.isoCode}
                                    className={`font-regular font-14 py-2 ${validateVendorData.countryError ? 'is-invalid' : ''}`}
                                    value={selectedCountry || ''}
                                    onChange={(selectedOption) => {
                                        setSelectedCountry(selectedOption || null);
                                        setSelectedState(''); // Reset state selection
                                        setSelectedCity(''); // Reset city selection
                                        setVendorDetails({ ...vendorDetails, country: selectedOption?.name || '' });
                                        setValidateVendorData({ ...validateVendorData, countryError: false });
                                    }}
                                />
                                {validateVendorData.countryError && <span className="invalid-feedback">Country is required.</span>}
                            </div>

                            <div className={`col-md-3 ${validateVendorData.stateError ? 'has-error' : ''}`}>
                                <label htmlFor="state" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    State<span className="required-text">*</span>
                                </label>
                                <Select
                                    options={selectedCountry ? State?.getStatesOfCountry(selectedCountry?.isoCode) || [] : [{ name: 'Select Country First', isoCode: '' }]}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.isoCode}
                                    value={selectedState || ''}
                                    className={`font-regular font-14 py-2 ${validateVendorData.stateError ? 'is-invalid' : ''}`}
                                    onChange={(selectedOption) => {
                                        setSelectedState(selectedOption || '');
                                        setSelectedCity(''); // Reset city selection
                                        setVendorDetails({ ...vendorDetails, state: selectedOption?.name || '' });
                                        setValidateVendorData({ ...validateVendorData, stateError: false });
                                    }}
                                />
                                {validateVendorData.stateError && <span className="invalid-feedback">State is required.</span>}
                            </div>

                            <div className={`col-md-3 ${validateVendorData.cityError ? 'has-error' : ''}`}>
                                <label htmlFor="city" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    City<span className="required-text">*</span>
                                </label>
                                <Select
                                    options={selectedState ? City.getCitiesOfState(selectedCountry?.isoCode, selectedState?.isoCode) || [] : [{ name: 'Select State First', isoCode: '' }]}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.name}
                                    value={selectedCity || ''}
                                    className={` font-regular font-14 py-2 ${validateVendorData.cityError ? 'is-invalid' : ''}`}
                                    onChange={(selectedOption) => {
                                        setSelectedCity(selectedOption || '');
                                        setVendorDetails({ ...vendorDetails, city: selectedOption?.name || '' });
                                        setValidateVendorData({ ...validateVendorData, cityError: false });
                                    }}
                                />
                                {validateVendorData.cityError && <span className="invalid-feedback">City is required.</span>}
                            </div>

                            <div className={`col-md-3 mb-4 ${validateVendorData.zipCodeError ? 'has-error' : ''}`}>
                                <label htmlFor="zipcode" className="form-label font-medium font-14 primary-textcolor mb-1">
                                    Zipcode<span className="required-text">*</span>
                                </label>
                                <input
                                    type="text"
                                    className={`form-control font-regular font-14 custom-form-control py-2 ${validateVendorData.zipCodeError ? 'is-invalid' : ''}`}
                                    id="zipcode"
                                    style={{ marginTop: '6px' }}
                                    placeholder="Enter Zipcode"
                                    value={vendorDetails.zipCode}
                                    onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        if (/^\d{0,8}$/.test(enteredValue) || enteredValue === '') {
                                            setVendorDetails({ ...vendorDetails, zipCode: enteredValue });
                                            setValidateVendorData({ ...validateVendorData, zipCodeError: false }); // Reset error state
                                        }
                                    }}
                                />
                                {validateVendorData.zipCodeError && <span className="invalid-feedback">Zipcode is required.</span>}
                            </div>

                            {/*forms ends here*/}
                            {/* Vendor Contact*/}
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <h5 className="font-bold font-20">Vendor Contact</h5>
                                </div>
                                <div className="col-md-12">
                                    <table className="table mb-0 custom-table-grid table-borderless mt-3">
                                        <thead>
                                            <tr>
                                                <th className="font-14 px-4 position-relative">Username

                                                </th>
                                                <th className="font-14 position-relative">Email ID

                                                </th>
                                                <th className="font-14 position-relative">Contact Number

                                                </th>
                                                <th className="font-14 position-relative text-center">Primary Contact

                                                </th>
                                                <th className="font-14 text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className={`form-control font-regular font-14 custom-form-control py-2 ${errors.userName ? 'is-invalid' : ''}`}
                                                        placeholder="Enter Username"
                                                        maxLength={200}
                                                        value={newVendorContact.userName}
                                                        onChange={(e) => {
                                                            setNewVendorContact({ ...newVendorContact, userName: e.target.value });
                                                            setErrors({}); // Clear errors when input value changes
                                                        }}
                                                    />
                                                    {errors.userName && <div className="invalid-feedback">{errors.userName}</div>}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className={`form-control font-regular font-14 custom-form-control py-2 ${errors.emailId ? 'is-invalid' : ''}`} placeholder="Enter Email ID"
                                                        maxLength={100}
                                                        value={newVendorContact.emailId}
                                                        onChange={(e) => {
                                                            setNewVendorContact({ ...newVendorContact, emailId: e.target.value });
                                                            setErrors({}); // Clear errors when input value changes
                                                        }}
                                                    />
                                                    {errors.emailId && <div className="invalid-feedback">{errors.emailId}</div>}
                                                </td>
                                                <td>
                                                    <input
                                                        maxLength={15}
                                                        className={`form-control font-regular font-14 custom-form-control py-2 ${errors.contactNumber ? 'is-invalid' : ''}`}
                                                        placeholder="Enter Contact"
                                                        value={newVendorContact.contactNumber}
                                                        onChange={(e) => {
                                                            const enteredValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                                                            setNewVendorContact({ ...newVendorContact, contactNumber: enteredValue });
                                                            setErrors({}); // Reset errors
                                                        }}
                                                    />
                                                    {errors.contactNumber && <div className="invalid-feedback">{errors.contactNumber}</div>}
                                                </td>
                                                <td className="text-center">
                                                    <input
                                                        className="form-check-input custom-check-input"
                                                        type="checkbox"
                                                        checked={newVendorContact.primaryContact}
                                                        onChange={(e) => {
                                                            const { checked } = e.target;
                                                            setNewVendorContact({ ...newVendorContact, primaryContact: checked });
                                                            // Logic to uncheck other checkboxes
                                                            if (checked) {
                                                                const updatedVendorContact = vendorContact.map(contact => ({
                                                                    ...contact,
                                                                    primaryContact: contact.id === newVendorContact.id ? checked : false,
                                                                }));
                                                                setVendorContact(updatedVendorContact);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <img src="/images/add-icon-line.svg" alt="add-icon" onClick={() => handleAddContactRow(newVendorContact)} style={{ cursor: 'pointer' }} />
                                                </td>
                                            </tr>
                                            {vendorContact.length > 1 && (
                                                <>
                                                    {vendorContact.map((contact, index) => (
                                                        contact.id !== 1 && (
                                                            <tr key={contact.id}>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className={`form-control font-regular font-14 custom-form-control py-2 ${contact?.userNameErrorMessage ? 'is-invalid' : ''}`}
                                                                        value={contact.userName}
                                                                        onChange={(e) => handleChange(e, contact.id, 'userName')}
                                                                        disabled={!editingContactId || editingContactId !== contact.id} // Disable input when not editing or editing another contact
                                                                    />
                                                                    <div className="invalid-feedback">{contact?.userNameErrorMessage ?? ""}</div>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className={`form-control font-regular font-14 custom-form-control py-2 ${contact?.emailIdErrorMessage ? 'is-invalid' : ''}`}
                                                                        value={contact.emailId}
                                                                        onChange={(e) => handleChange(e, contact.id, 'emailId')}
                                                                        disabled={!editingContactId || editingContactId !== contact.id} // Disable input when not editing or editing another contact
                                                                    />
                                                                    <div className="invalid-feedback">{contact?.emailIdErrorMessage ?? ""}</div>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={10}
                                                                        className={`form-control font-regular font-14 custom-form-control py-2 ${contact?.contactNumberErrorMessage ? 'is-invalid' : ''}`}
                                                                        value={contact.contactNumber}
                                                                        onChange={(e) => handleChange(e, contact.id, 'contactNumber')}
                                                                        disabled={!editingContactId || editingContactId !== contact.id} // Disable input when not editing or editing another contact
                                                                    />
                                                                    <div className="invalid-feedback">{contact?.contactNumberErrorMessage ?? ""}</div>
                                                                </td>
                                                                <td className="text-center">
                                                                    <input
                                                                        className="form-check-input custom-check-input"
                                                                        type="checkbox"
                                                                        checked={contact.primaryContact}
                                                                        onChange={(e) => handlePrimaryContactChange(e, contact.id)}
                                                                        disabled={!editingContactId || editingContactId !== contact.id} // Disable checkbox when not editing or editing another contact
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    {editingContactId === contact.id ? (
                                                                        <>
                                                                            <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleUpdateContact(contact.id, contact)}>
                                                                                <img src="/images/tick-icon.svg" alt="tick-icon" />
                                                                            </button>
                                                                            <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleCancelEdit(contact.id)}>
                                                                                <img src="/images/cross-icon.svg" alt="cross-icon" />
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleEditContact(contact.id)}>
                                                                            <img src="/images/edit-icon.svg" alt="edit-icon" />
                                                                        </button>
                                                                    )}
                                                                    <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleDeleteContact(contact.id)}>
                                                                        <img src="/images/delete-icon.svg" alt="delete-icon" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    ))}
                                                </>
                                            )}

                                        </tbody>

                                    </table>
                                </div>
                                <div className="col-md-12 py-5">
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="primary-btn font-14 font-medium py-2 me-3" onClick={() => { navigate('/compliance/vendor') }}>Cancel</button>
                                        <button type="button" className="dark-btn font-14 font-medium py-2" onClick={() => handleSave()}>Add</button>
                                    </div>
                                </div>
                            </div>
                            {/*Vendor Contact */}
                        </div>
                    </div>
                </div >
            )}
            {isToast ? (
                <>
                    <ToastMessageComponent
                        toastResponse={toastMessage}
                        setIsShowToastMessage={setIsToast}
                    />
                </>
            ) : null}
        </>


    )

}


