import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { encryptStorage } from '../../Constant';
import { agovContext } from '../../Constant';
import { controlPopupValues, excelAddControl, generateSasToken } from '../../Services/GRC/GrcService';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import ExcelJS from 'exceljs';

const AddControlComponent = ({ setHideAddControlPage, setHideControlsGrid }) => {
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const navigate = useNavigate();

    const [controlFormData, setControlFormData] = useState({
        "Control_ID": '',
        "Control Name": '',
        "Description": '',
        "Effective from": null,
        "Control Owner": '',
        "controlType": 'custom',
        "Applicable Controls": [],
        "Evidence Documents": [],
        "Policy Documents": []
    });

    const [controlValidationMessages, setControlValidationMessages] = useState({
        "Description": '',
        "ControlOwner": '',
        "EffectiveFrom": '',
        "Control_ID": '',
        "ControlName": '',
        "ApplicableControls": ''
    });


    /**
     * PC_CC_64
     * crate the required state variables
     */
    const [jsonData, setJsonData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [userOptions, setUserOptions] = useState([]);
    const [policyOptions, setPolicyOptions] = useState([]);
    const [evidenceOptions, setEvidenceOptions] = useState([]);
    const [principleOptions, setPrincipleOptions] = useState([]);
    const [hideSingleAddControl, setHideSingleAddControl] = useState(false);
    const [hideExcelAddControl, setHideExcelAddControl] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedFileData, setUploadedFileData] = useState(null);
    const [today, setToday] = useState('');

    /**
     * PC_CC_65 - PC_CC_77
     * loads the data of the options like list of policies, evidences, control-owners and principles 
     */
    useEffect(() => {
        const todayDate = new Date();
        const formattedDate = todayDate.toISOString().split('T')[0];
        setToday(formattedDate);
        loadOptions();
    }, [])

    const validateForm = () => {
        let isValid = true;
        let validationMessages = { ...controlValidationMessages };
        if (controlFormData["Control_ID"] == '') { validationMessages["Control_ID"] = 'This field is required'; isValid = false; } else { validationMessages["Control_ID"] = '' }
        if (controlFormData["Control Name"] == '') { validationMessages["ControlName"] = 'This field is required'; isValid = false; } else { validationMessages["ControlName"] = '' }
        if (controlFormData["Description"] == '') { validationMessages["Description"] = 'This field is required'; isValid = false; } else { validationMessages["Description"] = '' }
        if (controlFormData["Control Owner"] == '') { validationMessages["ControlOwner"] = 'This field is required'; isValid = false; } else { validationMessages["ControlOwner"] = '' }
        if (controlFormData["Effective from"] == null) { validationMessages["EffectiveFrom"] = 'This field is required'; isValid = false; } else { validationMessages["EffectiveFrom"] = '' }
        if (controlFormData["Applicable Controls"].length == 0) { validationMessages["ApplicableControls"] = 'This field is required'; isValid = false; } else { validationMessages["ApplicableControls"] = '' }
        setControlValidationMessages(validationMessages);
        return isValid;
    }


    const loadOptions = async () => {
        setIsLoading(true);
        let result = await controlPopupValues({ "startDate": encryptStorage.getItem('commencer'), "endDate": encryptStorage.getItem('fin') })

        const userValues = result?.responseData?.userDetails?.map(user => ({
            value: user.userId,
            label: user.employeeName
        }));
        setUserOptions(userValues)

        const evidenceValues = result?.responseData?.evidenceDetails?.map(user => ({
            value: user.evidenceName,
            label: user.evidenceName
        }));
        setEvidenceOptions(evidenceValues)

        const policyValues = result?.responseData?.policyDetails?.map(user => ({
            value: user.policyName,
            label: user.policyName
        }));
        setPolicyOptions(policyValues)



        const principleValues = result?.responseData?.principleDetails?.map(user => ({
            value: user.principleId,
            label: user.principleId
        }));
        setPrincipleOptions(principleValues);
        setIsLoading(false)
    }


    /**
     * PC_CC_78 - PC_CC_80
     * The user changes the values in the form
     * */
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setControlFormData(prevState => ({ ...prevState, [id]: value }));
    };

    /**
    * PC_CC_78 - PC_CC_80
    * The user changes the values in the form
    * */
    const handleSelectOwnerChange = (name, selectedOption) => {
        // This will be either a value or an empty string if nothing is selected
        setHideExcelAddControl(true)
        const selectedValue = selectedOption ? selectedOption.value : '';
        setControlFormData(prevState => ({ ...prevState, [name]: selectedValue }));
    };

    /**
    * PC_CC_78 - PC_CC_80
    * The user changes the values in the form
    * */
    const handleSelectChange = (name, selectedOptions) => {
        // Create array of selected values
        setHideExcelAddControl(true)
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setControlFormData(prevState => ({ ...prevState, [name]: selectedValues }));
    };

    /**
     * PC_CC_120 - PC_CC_151
     * This checks the validation of the uploaded file, and handles the values of the uploaded file
     */

    const handleFileChange = async (e) => {
        setHideSingleAddControl(true);
        setUploadedFileData(null);

        if (e.target.files[0]) {
            const file = e.target.files[0];
            const fileType = file.name.split('.').pop();
            if (fileType !== 'xlsx' && fileType !== 'xls') {
                setErrorMessage('Invalid file type. Please upload an Excel file.');
                return;
            }
            else {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const binaryString = event.target.result;
                    const workbook = new ExcelJS.Workbook();
                    workbook.xlsx.load(binaryString).then(() => {
                        const worksheet = workbook.getWorksheet(1); // Assuming data is in the first worksheet
                        const headers = worksheet.getRow(1).values.slice(1); // Start from index 1

                        // Expected headers
                        const expectedHeaders = ["Control_ID", "Control Name", "Description", "Control Owner", "Effective from", "Policy Documents", "Evidence Documents", "Applicable Controls"];

                        // Validate headers
                        if (!arraysEqual(headers, expectedHeaders)) {
                            setErrorMessage('Invalid headers. Please provide headers in the order: Control_ID, Control Name, Description, Control Owner, Effective from, Policy Documents, Evidence Documents, Applicable Controls.');
                            setJsonData(null);
                            return;
                        }

                        const jsonData = [];
                        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
                            if (rowNumber > 1) { // Skipping header row
                                const rowData = {};
                                row.eachCell((cell, colNumber) => {
                                    const header = headers[colNumber - 1];
                                    let cellValue = cell.value;
                                    // Handle empty fields
                                    cellValue = cellValue || "";

                                    // Convert Documents and Applicable Controls to arrays
                                    if (["Evidence Documents", "Policy Documents", "Applicable Controls"].includes(header)) {
                                        cellValue = cellValue.split(',').map(item => item.trim());
                                    }

                                    if (header === "Effective from") {

                                        // Convert the cellValue to a Date object
                                        const dateObject = new Date(cellValue);

                                        // Format the date as "YYYY-MM-DD HH:mm:ss.SSS"
                                        const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')} ${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}:${dateObject.getSeconds().toString().padStart(2, '0')}.${dateObject.getMilliseconds().toString().padStart(3, '0')}`;

                                        // Assign the formatted date back to cellValue
                                        cellValue = formattedDate;
                                    }

                                    rowData[header] = cellValue;
                                });

                                rowData["controlType"] = "custom";
                                jsonData.push(rowData);
                            }
                        });

                        // Additional validation
                        const hasEmptyFields = jsonData.some(obj =>
                            obj["Control_ID"] === "" ||
                            obj["Control Name"] === "" ||
                            obj["Description"] === "" ||
                            obj["Effective from"] === "" ||
                            ("Applicable Controls" in obj && obj["Applicable Controls"].some(control => control === ""))
                        );

                        if (hasEmptyFields) {
                            setErrorMessage('Control ID, Control Name, Description, Effective from and Applicable Controls cannot be empty.');
                            setJsonData(null);
                            return;
                        }

                        const fileData = {
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            url: URL.createObjectURL(file) // This is for previewing the uploaded file
                        };
                        setUploadedFileData(fileData);
                        setJsonData(jsonData);
                        setErrorMessage(null);
                    });
                };

                reader.readAsArrayBuffer(file);
            }
        }
        else {
            setErrorMessage('Select a valid file')
        }
    };

    // const arraysEqual = (arr1, arr2) => {
    //     if (arr1.length !== arr2.length) return false;
    //     for (let i = 0; i < arr1.length; i++) {
    //         if (arr1[i] !== arr2[i]) return false;
    //     }
    //     return true;
    // };

    const handleDeleteFile = () => {
        setJsonData(null);
        setUploadedFileData(null);
    };

    const excelDateToJSDate = (serial) => {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const month = date_info.getMonth() + 1;
        const day = date_info.getDate();
        const year = date_info.getFullYear();

        return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    }
    const arraysEqual = (arr1, arr2) => {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    };


    const handleTextFieldInput = (event) => {
        setHideExcelAddControl(true);
        handleInputChange(event);
    };


    /**
     * PC_CC_81 - PC_CC_102
     * This function checks for the validations and the if the values are viable it returns the values 
     */
    const addControl = async () => {
        let result;
        setIsLoading(true);
        if (hideExcelAddControl == true) {
            if (validateForm()) {
                result = await excelAddControl({ startDate: encryptStorage.getItem('commencer'), endDate: encryptStorage.getItem('fin'), controls: [controlFormData], userId: userId })
                if (result.statusCode == 200) {
                    navigate(0)
                }
                else {
                    let validationMessages = { ...controlValidationMessages };
                    validationMessages["Control_ID"] = 'There is already a control with same ID';
                    setControlValidationMessages(validationMessages);
                }
            }

        }
        if (hideSingleAddControl == true) {
            result = await excelAddControl({ startDate: encryptStorage.getItem('commencer'), endDate: encryptStorage.getItem('fin'), controls: jsonData, userId: userId });
            if (result.statusCode == 200) {
                navigate(0);
            }
            else {
                setErrorMessage('Some wrong data is inserted')
            }
        }
        if (hideSingleAddControl == false || hideExcelAddControl == false) {
            setErrorMessage('Enter data or upload file!!')
        }
        setIsLoading(false);

    }

    /**
     * PC_CC_103 - PC_CC_119
     * This function downloads the template of the add contol
     */
    const downloadTemplate = async () => {
        setIsLoading(true);
        let sasToken = await generateSasToken({ "resourceTypes": "o", "permission": "r" });
        sasToken = sasToken?.responseData;
        let fileUrl = 'https://avacusagovstgprod.blob.core.windows.net/avacusagovblobprod/controls Template.xlsx' + sasToken;
        saveAs(fileUrl, "controls Template.xlsx");
        setIsLoading(false)
    }


    /**
     * PC_CC_152 - PC_CC_154
     * The values will be setted to default values
     */
    const cancelClick = () => {
        // setIsLoading(true);
        // setHideSingleAddControl(false);
        // setHideExcelAddControl(false);
        // setControlFormData({
        //     "Control_ID": '',
        //     "Control Name": '',
        //     "Description": '',
        //     "Effective from": '',
        //     "Control Owner": '',
        //     "controlType": 'custom',
        //     "Applicable Controls": [],
        //     "Evidence Documents": [],
        //     "Policy Documents": []
        // })
        // setControlValidationMessages({
        //     "Description": '',
        //     "ControlOwner": '',
        //     "EffectiveFrom": '',
        //     "Control_ID": '',
        //     "ControlName": '',
        //     "ApplicableControls": ''
        // })
        // setErrorMessage('');
        // setJsonData(null);
        // setIsLoading(false);
        navigate(0);
    }

    return (
        <>
            <div className="col-lg-9 px-3 col-md-12 col-sm-12 border-start admin-rightcontainer-hig control-lefttab-scroll text-start">
                <Loader isLoading={isLoading} />
                <div className="tab-pane  show" id="Comminfo" role="tabpanel" aria-labelledby="Comminfo-tab">
                    <div className="col-md-12 px-4">
                        <div className="d-flex align-items-center">
                            <img src="/images/left-arrow.svg" alt="back" className="me-3" style={{ cursor: 'pointer' }} onClick={() => { setHideAddControlPage(true); setHideControlsGrid(false) }} />
                            <h1 className="font-bold font-24 primary-textcolor mb-0">
                                Add New Control
                            </h1>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {hideSingleAddControl == false ?
                                    <div>
                                        <div className="row mt-4">
                                            <div className="col-md-6 custom-form-padding">
                                                <label htmlFor="Control_ID" className="form-label font-14 font-medium">Control ID<span className="required">*</span>
                                                </label>
                                                <input type="text" className="form-control custom-form-control" id="Control_ID" maxLength={250} placeholder="Example: C-1" value={controlFormData.Control_ID} onChange={handleTextFieldInput} />
                                                <div className="text-danger">{controlValidationMessages.Control_ID}</div>
                                            </div>
                                            <div className="col-md-6 custom-form-padding">
                                                <label htmlFor="Control Name" className="form-label font-14 font-medium">Control Name<span className="required">*</span>
                                                </label>
                                                <input type="text" className="form-control custom-form-control" id="Control Name" maxLength={250} placeholder="Example: Organizational Structure Document" value={controlFormData['Control Name']} onChange={handleTextFieldInput} />
                                                <div className="text-danger">{controlValidationMessages.ControlName}</div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-12 custom-form-padding">
                                                <label htmlFor="Description" className="form-label font-14 font-medium no-resize">Description<span className="required">*</span>
                                                </label>
                                                <textarea type="textarea" className="form-control custom-form-control theme-form font-regular no-resize font-14" value={controlFormData.Description} rows={4} id="Description" placeholder="Example: The Company maintains an organizational chart that describes the organizational structure and reporting lines. " defaultValue={""} onChange={handleTextFieldInput} />
                                                <div className="text-danger">{controlValidationMessages.Description}</div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-6 d-flex">
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="Effective from" className="form-label font-14 font-medium">Effective Date<span className="required">*</span></label>
                                                    <input type="date" min={today} className="form-control custom-date custom-form-control font-14 font-regular" id="Effective from" value={controlFormData['Effective from']} onChange={handleTextFieldInput} onKeyDown={(event) => event.preventDefault()} />
                                                    <div className="text-danger">{controlValidationMessages.EffectiveFrom}</div>
                                                </div>
                                                <div className="col-md-6 px-3">
                                                    <div className="dropdown">
                                                        <label htmlFor="assignee" className="form-label font-medium font-14 primary-textcolor mb-2">Control Owner<span className="required">*</span>
                                                        </label>
                                                        <Select
                                                            id="assignee"
                                                            options={userOptions}
                                                            value={userOptions.find(option => option.value === controlFormData['Control Owner']) || ''}
                                                            onChange={selectedOption => handleSelectOwnerChange('Control Owner', selectedOption)}
                                                        />
                                                        <div className="text-danger">{controlValidationMessages.ControlOwner}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-form-padding">
                                                <label htmlFor="policy-docs" className="form-label font-medium font-14 primary-textcolor">Policy Documents<span>
                                                    <div className="tooltip-container">
                                                        <img src="/images/info-icon.svg" className="ms-1" />
                                                        <div className="tooltip">Select Policy Documents</div>
                                                    </div>
                                                </span>
                                                </label>
                                                <Select
                                                    id="policy-docs"
                                                    options={policyOptions}
                                                    isMulti
                                                    // value={policyOptions.filter(option => controlFormData['Policy Documents']?.includes(option.value))}
                                                    onChange={selectedOptions => {
                                                        //console.log(selectedOptions, "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
                                                        handleSelectChange('Policy Documents', selectedOptions)}}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-6 custom-form-padding">
                                                <label htmlFor="evidence-docs" className="form-label font-medium font-14 primary-textcolor">Evidence Documents
                                                </label>
                                                <Select
                                                    id="evidence-docs"
                                                    options={evidenceOptions}
                                                    isMulti
                                                    // value={evidenceOptions.filter(option => controlFormData['Evidence Documents']?.includes(option.value))}
                                                    onChange={selectedOptions => handleSelectChange('Evidence Documents', selectedOptions)}
                                                />
                                            </div>
                                            <div className="col-md-6 custom-form-padding">
                                                <label htmlFor="appl-controls" className="form-label font-medium font-14 primary-textcolor">Applicable Controls<span className="required">*</span>
                                                </label>
                                                <Select
                                                    id="appl-controls"
                                                    options={principleOptions}
                                                    isMulti
                                                    // value={principleOptions.filter(option => controlFormData['Applicable Controls']?.includes(option.value))}
                                                    onChange={selectedOptions => handleSelectChange('Applicable Controls', selectedOptions)}
                                                />
                                                <div className="text-danger">{controlValidationMessages.ApplicableControls}</div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                <div className="col-md-12 mt-5 custom-or-line" style={{ display: hideSingleAddControl == true || hideExcelAddControl == true ? 'none' : 'block' }}>
                                    <p className="font-14 font-medium text-center">
                                        Or
                                    </p>
                                </div>

                                {hideExcelAddControl == false ?
                                    <div className="col-md-12 mt-4 px-3" style={{ display: hideExcelAddControl ? 'none' : 'block' }}>
                                        <p className="font-14 font-medium">
                                            Use Template for Uploading Controls
                                        </p>
                                        <p className="font-13 font-regular grey-secondary">
                                            Use our template for easy upload of single or
                                            multiple controls
                                            <a href className="font-medium secondary-textcolor text-decoration-none" style={{ cursor: 'pointer' }} onClick={() => { downloadTemplate() }}> Download Template</a>
                                        </p>
                                        <span className="upload-btn-wrapper me-2 d-block cursor-pointer">
                                            <button type="button" className="cust-filter-btn font-14 font-medium primary-color upload-btn">
                                                <img src="/images/upload.svg" alt="browse" className="me-2" />Upload
                                            </button>
                                            <input type="file" name="myfile" className="cursor-pointer upload-btn-input" onChange={(e) => { handleFileChange(e); }} />
                                        </span>

                                        {uploadedFileData != null ?
                                            <div className="d-inline-flex justify-content-between align-items-center border border-radius-8 p-3">
                                                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => window.open(uploadedFileData.url)}>
                                                    <span className="course-thumbnail d-flex">
                                                        <img src="/images/excel-icon.svg" className="w-100" alt="Thumbnail" />  {/* Update the icon based on the file type if necessary */}
                                                    </span>
                                                    <div className="ps-3 align-items-center vertical-align-middle">
                                                        <span className="font-medium font-12 primary-textcolor d-block">{uploadedFileData.name}</span>
                                                        <span className="grey-light font-regular font-12">{(uploadedFileData.size / 1024 / 1024).toFixed(2)} Mb</span>
                                                    </div>
                                                </div>
                                                <div className="btn-group dropend ms-5">
                                                    <button className="btn border-0 p-0 align-top" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="/images/kebab-right.svg" alt="kebab-icon" className=" align-top pt-1" />
                                                    </button>
                                                    <ul className="dropdown-menu custom-dropdown-style custom-dropdown-policy shadow border-0 p-2" style={{}}>
                                                        <li>
                                                            <span className="dropdown-item py-2 font-14 d-flex align-items-center font-regular primary-textcolor px-2 custom-dropdown-li cursor-pointer" onClick={handleDeleteFile}>
                                                                <img src="/images/delete-icon.svg" alt="delete-icon" className="me-2" />Delete
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            : ''}

                                        <div className="text-danger">{errorMessage}</div>
                                    </div>
                                    : ''}
                                <div className="text-end mt-4 mb-2 px-4">
                                    <button type="button" className="primary-btn font-14 font-medium me-2" onClick={() => { cancelClick() }}>
                                        Cancel
                                    </button>
                                    <button type="button" className={(!controlFormData.Control_ID || !controlFormData.Description || !controlFormData["Control Name"] || !controlFormData["Control Owner"] || !controlFormData["Effective from"] || today > controlFormData["Effective from"] || !controlFormData["Applicable Controls"]) && !uploadedFileData ? "disabled-btn font-14 font-medium px-4" : "dark-btn font-14 font-medium px-4"} disabled={(!controlFormData.Control_ID || !controlFormData.Description || !controlFormData["Control Name"] || !controlFormData["Control Owner"] || !controlFormData["Effective from"] || !controlFormData["Applicable Controls"]) && !uploadedFileData} onClick={() => { addControl() }}>
                                        Add Control
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Loader isLoading={isLoading} />
        </>
    )
}

export default AddControlComponent;
