import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFileDetails, defaultThumbnailBlobUrl } from '../../Constant';
import { handleUpload } from '../../Configs/BlobContainer';
import { getCourseDetails, postCourseDetails, updateCourseDetails, getCourseType, getDataForCourseId, deleteCourse, deleteCampaignDetails } from '../../Services/LMS/LmsService';
import CreateCoursePopUp from './CreateCoursePopUp';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import DeletePopup from '../LMS/DeletePopup'
import { isObject } from 'lodash';
import NoDataFound from './NoDataFound';
import NoRecordsPage from './NoRecordsPage';
import { Loader } from '../Loader/Loader';
import { agovContext } from '../../Constant';

//PS_CG_FE_1.11 to 1.192
const CourseGridView = () => {

    const navigate = useNavigate();
    const { userId, roles, employeeName } = useContext(agovContext);

    const courseDetailsObject = {
        userId: userId,
        userName: employeeName,
        courseId: '',
        courseName: '',
        courseDescription: '',
        courseTypeId: '',
        selectedVideos: [],
        deletedVideos: [],
        courseThumbnail: '',
        totalCourseDuration: 0,
        totalVideoCount: 0,
    };

    const toastMessage = {
        path: "",
        header: "",
        message: "",
        color: ""
    };

    // PS_CG_FE_1.18
    const requestDetails = { searchCourseName: '', courseId: '', limit: 8 };
    const [courseRequest, setCourseRequest] = useState(requestDetails);
    const [editCourseDetails, setEditCourseDetails] = useState(courseDetailsObject);
    const [courseDetails, setCourseDetails] = useState([]);
    const [isNoRecordPageVisible, setIsNoRecordPageVisible] = useState(false)
    const [courseTypeDropDownDetails, setCourseTypeDropDownDetails] = useState([]);
    const [isLoadMoreVisible, setIsLoadMoreVisible] = useState(false);
    const [isPopShow, setIsPopShow] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isDeletePopUpVisible, setIsDeletePopUpVisible] = useState(false);
    const [mediaData, setMediaData] = useState(null);
    const [noDataFound, setNoDataFound] = useState(false)
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastDetails, setToastDetails] = useState(toastMessage);
    const [deletedCourseAssigneeCount, setDeletedCourseAssigneeCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [deleteCourseRequest, setDeleteCourseRequest] = useState({
        "userId": userId,
        "userName": employeeName,
        "courseId": "",
        "courseName": ""
    });
    const [courseSearchName, setCourseSearchName] = useState("");


    //PS_CG_FE_1.19
    useEffect(() => {
        if (roles?.includes("Super Admin") || roles?.includes("LMS Admin")) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
        fetchCourseDetails();
    }, [courseRequest]);


    // PS_CG_FE_1.27 to 1.52
    const fetchCourseDetails = async () => {
        setIsLoading(true);
        const result = await getCourseDetails(courseRequest);
        if (result?.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }

        setCourseDetails(result.response);

        const totalcoursesCount = result.totalResultCount;
        if (result?.response?.length === 0) {
            if (courseRequest.searchCourseName) {
                setNoDataFound(true)
            }
            else {
                setIsNoRecordPageVisible(true);
            }
        }
        else {
            setNoDataFound(false);
        }

        // To display the load more 
        if (totalcoursesCount > 8 && result.response.length != totalcoursesCount) {
            setTotalResultCount(result.totalResultCount);
            setIsLoadMoreVisible(true);
        }
        else {
            setTotalResultCount(result.totalResultCount);
            setIsLoadMoreVisible(false);
        }
        setIsLoading(false);
    }


    // PS_CG_FE_1.56 to 1.58 to handle the load more button
    const handleLoadMore = () => {
        setCourseRequest(prevState => ({ ...prevState, limit: prevState.limit + 8 }));
    };


    // PS_CG_FE_1.62 to 1.66 to handle the search bar
    const handleOnKeyDownSearch = (event) => {
        setCourseSearchName(event.target.value.trim() === "" ? "" : event.target.value);
        if (event.key === "Enter") {
            setCourseRequest({
                ...courseRequest,
                searchCourseName: event.target.value.trim() === "" ? "" : event.target.value,
                limit: 8
            });
        }
    };

    const handleOnclickSearch = () => {
        setCourseRequest({
            ...courseRequest,
            searchCourseName: courseSearchName,
            limit: 8
        });
    };

    // PS_CG_FE_1.96 to 1.98 when user clicks on cancel icon on delete pop up 
    const handleClickDeleteIcon = (courseId, courseName, assigneeCount) => {
        setDeleteCourseRequest({
            ...deleteCourseRequest,
            userId: userId,
            userName: employeeName,
            courseId: courseId,
            courseName: courseName
        })
        setDeletedCourseAssigneeCount(assigneeCount);
        setIsDeletePopUpVisible(true);
    };


    //PS_CG_FE_1.121 to 1.128  when user clicks on delete button from delete pop up
    const handleDeleteButtonClick = async () => {
        setIsDeletePopUpVisible(false);
        setIsLoading(true);
        const result = await deleteCourse(deleteCourseRequest);
        if (result.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }
        else {
            if (result?.response.campaignIds?.length !== 0) {
                const deleteCampaignDetailsResult = await deleteCampaignDetails(result.response);
            }

            setToastDetails(prevState => ({
                ...prevState,
                path: "/images/Success-icon.svg",
                header: "Deleted Successfully",
                message: "Course Successfully Deleted.",
                color: "success-toast"
            }));
        }
        setIsLoading(false);
        setIsToastVisible(true);
        setCourseSearchName("");
        setTimeout(() => {
            setCourseRequest(requestDetails);
        }, 3000);
    };

    // PS_CG_FE_1.131 to 1.134  Routes for detailed media view page 
    const handleCourseClick = (courseId) => {
        navigate('/lmsmanagement/viewMedia', { state: { value: { courseId: courseId, videoId: "", campaignId: "", taskId: "" } } });
    }


    // PS_CG_FE_1.105 to 1.107 
    const handleDeletePopUpCancelClick = () => {
        setIsDeletePopUpVisible(false);
    }


    // PS_CG_FE_1.53 to 1.54 to bind the course details under course tab
    const generateCourseDetails = () => {
        return (courseDetails?.map((item, index) => (
            <div key={item.courseId} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5">
                <div className="card border-0 h-100">
                    <span className="card-bg-image d-flex mb-5">
                        <img src={item.courseThumbnail} alt="lms-learning-image" className="card-image-cover w-100" />
                    </span>
                    <a className="card-body text-decoration-none learning-card-pos p-0 w-100" >
                        <div className="mx-2 learning-card">
                            <div className="d-flex w-100 justify-content-between mb-2 align-items-center" >
                                <h5 className="font-14 mb-0 font-medium primary-textcolor content-header-hight me-2 cursor-pointer" onClick={() => handleCourseClick(item.courseId)}>{item.courseName}</h5>
                                <div className="btn-group ">
                                    <button className="btn border-0 p-0 align-top pointer" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="/images/kebab-icon.svg" alt="kebab-icon" className=" align-top pt-1" />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end custom-dropdown-sty border-0 p-2 ">
                                        <li onClick={() => handleCreateCoursePopup(item.courseId)} className="cursor-pointer">
                                            <span className="dropdown-item  font-14 d-flex align-items-center font-regular primary-textcolor custom-dropdown-li py-2 px-2">
                                                <img src="/images/edit-icon.svg" alt="edit-icon" className="me-2" />Edit
                                            </span>
                                        </li>
                                        <li onClick={() => handleClickDeleteIcon(item.courseId, item.courseName, item.totalUserCount)} className="cursor-pointer">
                                            <span className="dropdown-item py-2 font-14 d-flex align-items-center font-regular primary-textcolor px-2 custom-dropdown-li">
                                                <img src="/images/delete-icon.svg" alt="addto-course-icon" className="me-2" /> Delete
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p className="font-12 font-regular mb-0 grey-light  mb-2 linehig  description">{item.courseDescription}</p>
                            <div className="mt-auto">
                                <span className="d-flex align-items-center">
                                    <span>
                                        <img src="/images/clock-icon.svg" alt="clock-icon" />
                                        <span className="ms-1 grey-primary font-12 font-regular">{item.courseTotalDuration}</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        )));
    };


    //PS_CG_FE_1.136 to 1.152 
    const handleCreateCourse = async (request) => {
        if (request.courseId != "") {
            if (request.courseThumbnail == "") {
                request.courseThumbnail = defaultThumbnailBlobUrl;
            }
            if (isObject(request.courseThumbnail)) {
                request.courseThumbnail = await handleUpload(editCourseDetails.courseThumbnail);
            } else {
                request.courseThumbnail = request.courseThumbnail.split('?')[0];
            }
            setIsPopShow(false);
            setIsLoading(true);
            const updateCourseResult = await updateCourseDetails(request);
            if (updateCourseResult.statusCode !== 200) {
                navigate('/error');
                return;
            }
            setToastDetails(prevState => ({
                ...prevState,
                path: "/images/Success-icon.svg",
                header: "successfully Edited",
                message: "course successfully Edited",
                color: "success-toast"
            }))
            setIsToastVisible(true);
            setIsLoading(false);
            setTimeout(() => {
                setCourseRequest(requestDetails);
            }, 3000);
        } else {
            setIsLoading(true)
            if (request.courseThumbnail == "") {
                request.courseThumbnail = defaultThumbnailBlobUrl;
            }
            if (isObject(request.courseThumbnail)) {
                request.courseThumbnail = await handleUpload(editCourseDetails.courseThumbnail);
            }
            else {
                request.courseThumbnail = request.courseThumbnail.split('?')[0];
            }
            const insertCourseResult = await postCourseDetails(request);
            if (insertCourseResult.statusCode !== 200) {
                navigate('/error');
                return;
            }
            setToastDetails(prevState => ({
                ...prevState,
                path: "/images/Success-icon.svg",
                header: "Added Successfully",
                message: "course successfully created",
                color: "success-toast"
            }))
            setIsPopShow(false);
            setIsLoading(false)
            setIsToastVisible(true);
            setTimeout(() => {
                setCourseRequest(requestDetails);
            }, 3000);
        }
    };


    //PS_CG_FE_1.69 to 1.92  To display the course create pop up 
    const handleCreateCoursePopup = async (courseId = "") => {
        setIsLoading(true);
        if (courseId != "") {
            const request = {
                courseId: courseId
            }
            const editCourseDetailsResult = await getDataForCourseId(request);
            const editDetails = editCourseDetailsResult.response;
            if (editCourseDetailsResult.statusCode !== 200) {
                navigate('/error');
                return;
            }
            const selectedVideos = editDetails.selectedVideos.map((video) => ({
                label: video.videoName,
                value: video.videoId,
                duration: video.videoDuration
            }));
            setEditCourseDetails({
                ...editCourseDetails,
                courseId: editDetails.courseId,
                courseName: editDetails.courseName,
                courseDescription: editDetails.courseDescription,
                courseTypeId: editDetails.courseTypeId,
                selectedVideos: selectedVideos,
                courseThumbnail: editDetails.courseThumbnail,
                totalCourseDuration: editDetails.totalCourseDuration,
                totalVideoCount: editDetails.totalVideoCount
            });

            const mediaData = await getFileDetails(editDetails.courseThumbnail);
            setMediaData(mediaData);

        }
        // to get the course type drop down values
        const courseTypeResult = await getCourseType({ userId: userId });
        if (courseTypeResult.statusCode !== 200) {
            navigate('/error');
            return;
        }
        setCourseTypeDropDownDetails(courseTypeResult.response);
        setIsLoading(false);
        setIsPopShow(true);
    }
    return (
        <>
            <Loader isLoading={isLoading} />
            {!isNoRecordPageVisible ? <div className="container-fluid mt-4  mt-custom">
                <div className="col-md-12 px-5">
                    <div className="d-flex justify-content-between mt-4">
                        <h1 className="font-bold font-24 primary-textcolor">
                            Learning Management System
                        </h1>
                    </div>
                    {/*tabs starts here*/}
                    <div className="d-md-block d-lg-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
                        <ul className="nav mb-lg-0 mb-3 nav-pills" id="pills-tab" role="tablist">
                            {isAdmin && (
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0  font-14 tab-style bold-text rounded-0" id="Overview-tab" data-bs-toggle="pill" data-bs-target="#Overview" type="button" role="tab" aria-controls="Overview" aria-selected="true" onClick={() => isAdmin ? navigate('/lmsmanagement') : navigate('/lmsmanagement/myLearnings')}>
                                        <span className="tab-img"> <img src="images/lms-overview-active.svg" alt="filled-icon" className="me-2" />
                                            <img src="/images/lms-overview.svg" alt="Overview-icon" className="me-2" /></span>Overview</button>
                                </li>
                            )}
                            <li className="nav-item ms-4" role="presentation">
                                <button
                                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                                    id="MyLearning-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#MyLearning"
                                    type="button"
                                    role="tab"
                                    aria-controls="MyLearning"
                                    aria-selected="true"
                                    tabIndex={-1}
                                    onClick={() => navigate('/lmsmanagement/myLearnings')}
                                >
                                    <span className="tab-img">
                                        <img src="/images/my-learning-active.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/my-learning-inactive.svg" alt="MyLearning-icon" className="me-2" />
                                    </span>
                                    My Learning
                                </button>
                            </li>
                            {isAdmin && <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 active font-14 tab-style bold-text rounded-0" id="Courses-tab" data-bs-toggle="pill" data-bs-target="#Courses" type="button" role="tab" aria-controls="Courses" aria-selected="false" tabIndex={-1} >
                                    <span className="tab-img"> <img src="/images/course-active.svg" alt="filled-icon" className="me-2" /> <img src="/images/course-inactive.svg" alt="Courses-icon" className="me-2" />
                                    </span>Courses</button>
                            </li>}
                            {isAdmin && <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="Campaigns-tab" data-bs-toggle="pill" data-bs-target="#Campaigns" type="button" role="tab" aria-controls="Campaigns" aria-selected="false" tabIndex={-1} onClick={() => navigate('/lmsmanagement/campaign')}>
                                    <span className="tab-img">
                                        <img src="/images/campaign-active.svg" alt="Campaigns-icon" className="me-2" />
                                        <img src="/images/campaign-inactive.svg" alt="filled-icon" className="me-2" />
                                        Campaigns
                                    </span>
                                </button>
                            </li>}
                            {isAdmin && <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="MediaLibrary-tab" data-bs-toggle="pill" data-bs-target="#MediaLibrary" type="button" role="tab" aria-controls="MediaLibrary" aria-selected="false" tabIndex={-1} onClick={() => navigate('/lmsmanagement/medialibrary')}>
                                    <span className="tab-img">
                                        <img src="/images/media-lib-active.svg" alt="MediaLibrary-icon" className="me-2" />
                                        <img src="/images/media-lib-inactive.svg" alt="filled-icon" className="me-2" />
                                        Media Library
                                    </span>
                                </button>
                            </li>}
                        </ul>
                        {/* Courses tab filter starts here */}
                        <div className="d-flex">
                            <div className="input-group flex-nowrap search-group me-3">
                                <input type="search" className="form-control search-align" placeholder="Search" aria-label="Username" aria-describedby="addon-wrapping"
                                    value={courseSearchName}
                                    onChange={(e) => setCourseSearchName(e.target.value)}
                                    onKeyDownCapture={(e) => handleOnKeyDownSearch(e)}
                                />
                                <button className="input-group-text btn btn-search-custom" id="addon-wrapping"><img src="/images/search-icon.svg" alt="search-icon" onClick={(e) => handleOnclickSearch(e)} /></button>
                            </div>
                            <button type="button" className="dark-btn font-14 font-medium py-2 text-nowrap align-items-center" onClick={() => handleCreateCoursePopup()}>
                                <span><img src="/images/create-course-icon.svg" alt="create-course-icon" className="me-1 " /></span>
                                Create Course
                            </button>
                        </div>
                        {/* Courses tab filter ends here */}
                    </div>
                    {/*tabs ends here*/}
                    {noDataFound ? (
                        <NoDataFound />
                    ) : (
                        <div className="row py-3 position-relative">
                            <div className="" id="pills-tabContent">
                                {/*Courses tab starts here*/}
                                <div className="tab-pane" id="Courses" role="tabpanel" aria-labelledby="Courses-tab">
                                    <div className="row row-cols-1 g-4">
                                        {generateCourseDetails()}
                                    </div>
                                </div>
                                <p class="font-12 mt-2 grey-primary">Showing <span class="font-medium primary-textcolor"> {courseDetails.length} </span>out of <span className="font-medium primary-textcolor">{totalResultCount}</span><span class="font-medium primary-textcolor"></span> items</p>
                                {/*Courses tab ends here*/}
                                <div className="text-center mt-5 mb-4">
                                    {isLoadMoreVisible &&
                                        (<button className="primary-btn font-medium" onClick={() => handleLoadMore()}>Load More</button>)}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div> : <NoRecordsPage
                message={"Currently, there are no courses created, Please create a course."}
                isButtonVisible={true}
                buttonName={"Create Course"}
                handleButtonSubmit={handleCreateCoursePopup}
                isMyLearningTab={false}
                isCourseTab={true}
                isCampaignTab={false}
                isMediaLibraryTab={false}
            />}

            {isPopShow && <CreateCoursePopUp
                setIsPopShow={setIsPopShow}
                courseDetails={postCourseDetails}
                courseType={courseTypeDropDownDetails}
                onSave={handleCreateCourse}
                editCourseDetails={editCourseDetails}
                setEditCourseDetails={setEditCourseDetails}
                mediaData={mediaData}
                setMediaData={setMediaData}
            />}

            {isDeletePopUpVisible && <DeletePopup
                isCourseDelete={true}
                handleClose={handleDeletePopUpCancelClick}
                setIsDeleteCampaignPopupVisible={setIsDeletePopUpVisible}
                handleConfirm={handleDeleteButtonClick}
                assigneeCount={deletedCourseAssigneeCount}
            />}
            {isToastVisible && <ToastMessageComponent
                toastResponse={toastDetails}
                setIsShowToastMessage={setIsToastVisible}
            />}
        </>
    )
}
export default CourseGridView;
