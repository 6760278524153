import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NoDataFound from './NoDataFound';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts'
import NoRecordsPage from './NoRecordsPage';
import { Loader } from '../LMS/LMSTileLoader';
import { agovContext,abbreviateNumber } from '../../Constant';
import { gettileAPIdata, getActiveCampaignApiData, getUpcomingCampaignApiData, getmultiInfoAPIdata, getExportExcelApidata, getUserInfoAPIdata, getAllCourses, getAllRecurrenceConditions } from '../../Services/LMS/LmsService';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ActiveCampaign from './ActiveCampaign'
import UserLearningInformation from './UserLearningInformation'
import UpComingCampaigns from './UpComingCampaigns'
import MultiTab from './MultiTab'






const DashboardOverview = () => {

   const navigate = useNavigate()
   const { userId, roles } = useContext(agovContext);
   
  const location = useLocation();
  const exportExcelPayload = {
    "userId": userId,
    "widget": ""
  }
  // PS_tile_03
  // state variable for tile data 
  const [tileData, setTileData] = useState(null);
 
  const [isLoading, setIsLoading] = useState(false);
  const navigatedCampaignDetails = location?.state?.value;
   //PC_GCDFE_NO_9
/*
* PC_GCDFE_NO_12 - Initialzing the state variable
*/

const [ isAdmin , setIsAdmin]=useState(false)
const [selectedCampaignType, setSelectedCampaignType] = useState('');
const [selectedStatus, setSelectedStatus] = useState('');


  useEffect(() => {
    if (roles?.includes("Super Admin") || roles?.includes("LMS Admin")) {
      setIsAdmin(true);
  } else {
      setIsAdmin(false);
  }
    getTilesInfo();
    // This function is invoked when the component is mounted
  }, []);


  //tile api call happens PS_tile_08

  const getTilesInfo = async () => {
    setIsLoading(true)
    const payload = { userId: userId };
    try {
      const response = await gettileAPIdata(payload);
      if (response && response.statusCode === 200) {
        setTileData(response.responseData); // Store the data using tileData
      } else {
        // Show error message to the user
        console.error('Error fetching tile data: ' + response.statusCode);
      }
    } catch (error) {
      // Error handling logic here
      console.error('Error fetching tile data:', error);

    }
     finally {
      setIsLoading(false)
    }
  };



  return (
    <>

      <div className="container-fluid mt-4 mt-custom bg-dashboard px-0">
         <div className="d-md-block d-lg-flex justify-content-between align-items-center ps-5 my-4 custom-tabs bg-white px-1">
          <ul className="nav mb-lg-0 mb-3 nav-pills" id="pills-tab" role="tablist">

            <li className="nav-item ms-4 mt-2 me-2" role="presentation">
              <button className="nav-link px-0  font-14 tab-style bold-text rounded-0 active" 
               id="Overview-tab" data-bs-toggle="pill"
                data-bs-target="#Overview" type="button"
                 role="tab" aria-controls="Overview" aria-selected="true">
                <span className="tab-img"> 
                  <img src="images/lms-overview-active.svg" alt="filled-icon" className="me-2" />
                  <img src="/images/lms-overview.svg" alt="Overview-icon" className="me-2" />
                  </span>Overview</button>
            </li>

            <li className="nav-item ms-4 mt-2 me-2" role="presentation">
              <button
                className="nav-link px-0 font-14 tab-style rounded-0"
                id="MyLearning-tab"
                data-bs-toggle="pill"
                data-bs-target="#MyLearning"
                type="button"
                role="tab"
                aria-controls="MyLearning"
                aria-selected="true"
                tabIndex={-1}
                onClick={() => { navigate("./myLearnings") }}
              >
                <span className="tab-img">
                  {" "}
                  <img
                    src="/images/my-learning-active.svg"
                    alt="filled-icon"
                    className="me-2"
                  />
                  <img
                    src="/images/my-learning-inactive.svg"
                    alt="MyLearning-icon"
                    className="me-2"
                  />
                </span>
                My Learning
              </button>
            </li>
            <li className="nav-item ms-4 mt-2 me-2 " role="presentation">
              <button
                className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                id="Courses-tab"
                data-bs-toggle="pill"
                data-bs-target="#Courses"
                type="button"
                role="tab"
                aria-controls="Courses"
                aria-selected="false"
                tabIndex={-1}
                onClick={() => { navigate("./course") }}
              >
                <span className="tab-img">
                  {" "}
                  <img
                    src="/images/course-active.svg"
                    alt="filled-icon"
                    className="me-2"
                  />{" "}
                  <img
                    src="/images/course-inactive.svg"
                    alt="Courses-icon"
                    className="me-2"
                  />
                </span>
                Courses
              </button>
            </li>
            <li className="nav-item ms-4 mt-2 me-2" role="presentation">
              <button
                className="nav-link px-0 font-14  tab-style bold-text rounded-0"
                id="Campaigns-tab"
                data-bs-toggle="pill"
                data-bs-target="#Campaigns"
                type="button"
                role="tab"
                aria-controls="Campaigns"
                aria-selected="false"
                tabIndex={-1}
                onClick={() => { navigate("/lmsmanagement/campaign") }}
              >
                <span className="tab-img">
                  <img
                    src="/images/campaign-active.svg"
                    alt="Campaigns-icon"
                    className="me-2"
                  />
                  <img
                    src="/images/campaign-inactive.svg"
                    alt="filled-icon"
                    className="me-2"
                  />
                  Campaigns
                </span>
              </button>
            </li>
            <li className="nav-item ms-4 mt-2 me-2" role="presentation">
              <button
                className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                id="MediaLibrary-tab"
                data-bs-toggle="pill"
                data-bs-target="#MediaLibrary"
                type="button"
                role="tab"
                aria-controls="MediaLibrary"
                aria-selected="false"
                tabIndex={-1}
                onClick={() => { navigate("./medialibrary") }}
              >
                <span className="tab-img">
                  <img
                    src="/images/media-lib-active.svg"
                    alt="MediaLibrary-icon"
                    className="me-2"
                  />
                  <img
                    src="/images/media-lib-inactive.svg"
                    alt="filled-icon"
                    className="me-2"
                  />
                  Media Library
                </span>
              </button>
            </li>
          </ul>
          {/* Campaigns tab filter starts here */}
          {/* <div className="d-flex">
            <div className="input-group flex-nowrap search-group me-3">
              
            </div>
            <div className="ms-2 position-relative">
              
            </div>
            
          </div> */}
          {/* Campaigns tab filter ends here */}
        </div> 

        {/* LMS tab starts here */}
      
        
       
        {/* LMS tab ends here */}

        <div className="custom-container px-5">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-12 mt-4" >
              {/* lmsTile starts here */}
              <div className="row">
                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4 mb-md-3" style={{ position: 'relative' }}>
                  <Loader isLoading={isLoading} />
                  <div className="bg-white p-3 font-medium rm-dashboard-card">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <img src="images/teams-kpi.svg" className="img-size" alt="Users Icon" />
                        <h3 className="d-inline primary-textcolor font-14 ms-2 mb-0">Users</h3>
                      </div>
                      <div className="d-inline">
                        <span className="primary-textcolor font-32">{tileData?.UserTiles?.usersCount}</span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="d-inline me-3">
                        <span className="grey-light font-11">Due</span>
                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tileData?.UserTiles?.dueCount)}</span>
                      </div>
                      <div className="d-inline me-3">
                        <span className="grey-light font-11">Overdue</span>
                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tileData?.UserTiles?.overdueCount)}</span>
                      </div>
                      <div className="d-inline">
                        <span className="grey-light font-11 alert-red">
                          <img src="images/risk-icon-red.svg" alt="risk-icon-red" className="me-1 mb-1" />Risk
                        </span>
                        <span className="primary-textcolor font-13 ms-1 alert-red">{abbreviateNumber(tileData?.UserTiles?.riskCount)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* // ActiveCampaign starts here  */}
                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4 mb-md-3" style={{ position: 'relative' }}>
                <Loader isLoading={isLoading} />
                  <div className="bg-white p-3 font-medium rm-dashboard-card">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <img src="images/active-campaings-kpi.svg" className="img-size" alt="Active Campaigns Icon" />
                        <h3 className="d-inline primary-textcolor font-14 ms-2 mb-0">Active Campaigns</h3>
                      </div>
                      <div className="d-inline">
                        <span className="primary-textcolor font-32">{tileData?.activeCampaignCount?.activeCampaignsCount}</span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="d-inline me-3">
                        <span className="grey-light font-11">Completed</span>
                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tileData?.activeCampaignCount?.completedCount)}</span>
                      </div>
                      <div className="d-inline me-3">
                        <span className="grey-light font-11">Overdue</span>
                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tileData?.activeCampaignCount?.overdueCount)}</span>
                      </div>
                      <div className="d-inline">
                        <span className="grey-light font-11">Upcoming</span>
                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tileData?.activeCampaignCount?.upComing)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4 mb-md-3" style={{ position: 'relative' }}>
                  <Loader isLoading={isLoading} />
                  <div className="bg-white p-3 font-medium rm-dashboard-card margin-tile1">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <img src="images/courses-kpi.svg" className="img-size" alt="Courses Icon" />
                        <h3 className="d-inline primary-textcolor font-14 ms-2 mb-0">Courses</h3>
                      </div>
                      <div className="d-inline">
                        <span className="primary-textcolor font-32">{tileData?.course?.count}</span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="d-inline me-3">
                        <span className="grey-light font-11">Security</span>
                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tileData?.course?.courseTypeId1)}</span>
                      </div>
                      <div className="d-inline">
                        <span className="grey-light font-11">Others</span>
                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tileData?.course?.courseTypeId2)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* lmsTile ends here */}
            </div>
          </div>
          <div className="row pe-0">
            <div className="col-md-12 pe-0">
              <div className="row pe-0 dashboard-widgets">
                {/* the activeCampigns pie chart start here */}
                <div class="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3" style={{ position: 'relative' }}>


                  <ActiveCampaign />
                </div>

                {/* the activeCampigns pie chart ends here */}
                {/**UserLearningInformation  starts here */}
                < UserLearningInformation />

                {/* upcoming Campaigns start here */}



                <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 mb-3">
                  <div className="bg-white py-2 px-3 border-radius-8 position-relative rm-dashboard-card">
                    < UpComingCampaigns />
                  </div>
                </div>


                {/* multiTab start here */}
                <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3">
                  <MultiTab />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

};

export default DashboardOverview;