
import React, { useEffect, useState, useContext } from 'react'
import ControlHistory from './ControlHistory';
import { encryptStorage } from '../../Constant';
import ControlMappings from './ControlMappings';
import ControlAuditComments from './ControlAuditComments';
import InactivePopup from './InactivePopup';
import { agovContext } from '../../Constant'
import { getControlDetails, updateApprovalStatus, updateControlOwner, updateControlStatus } from '../../Services/GRC/GrcService';
import RequestDocument from './RequestDocument';
import { Loader } from '../Loader/Loader';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';


const ControlDetailsPage = ({ selectedControlId, setHideControlsGrid, setHideControlInfo, selectedPrinciple, isAuditor, viewAsAuditor }) => {

    /**
     * PC_CC_157
     * Declare the required state variables
     */
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const [hideControlsMapping, setHideControlsMapping] = useState(false);
    const [hideHistoryTab, setHideHistoryTab] = useState(true);
    const [hideAuditorComments, setHideAuditorComments] = useState(true)
    const [controlInformation, setControlInformation] = useState("");
    const [mappedPolicy, setMappedPolicy] = useState([]);
    const [mappedPrinciples, setMappedPrinciples] = useState([]);
    const [mappedEvidence, setMappedEvidence] = useState([]);
    const [controlOwnerDropDownValues, setControlOwnerDropDownValues] = useState([]);
    const [reRender, setReRender] = useState(false);
    const [isDropdownSelected, setIsDropdownSelected] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState("Selected");
    const [showInActivePopup, setShowInActivePopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ isAdmin , setIsAdmin]=useState(false)
   const navigate = useNavigate();

    /**
     * PC_CC_158 - PC_CC_172
     * The useEffect triggers and brings all the data that is being shown in the control Details page    
     */
    useEffect(() => {
        if (roles?.includes("Auditor")) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
        loadControlDetails();
    }, [reRender]);

    const loadControlDetails = async () => {
        try {
            setIsLoading(true);
            const controlDetailsResp = await getControlDetails({
                "controlId": selectedControlId,
                "auditCycleId": encryptStorage.getItem("identifiant"),
                "startDate": encryptStorage.getItem('commencer'),
                "endDate": encryptStorage.getItem('fin')
            });

            if (controlDetailsResp?.responseData) {
                setControlInformation(controlDetailsResp?.responseData);
                setMappedPolicy(controlDetailsResp?.responseData?.mappedPolicies);
                setMappedPrinciples(controlDetailsResp?.responseData?.mappedPrinciples);
                setMappedEvidence(controlDetailsResp?.responseData?.mappedEvidences);
                setControlOwnerDropDownValues(controlDetailsResp?.responseData?.controlOwnerValues);
                const owner = controlDetailsResp?.responseData?.controlOwnerValues.find(owner => owner.user_id === controlDetailsResp?.responseData?.controlDetails?.controlOwnerId);
                if (owner) {
                    setSelectedOwner(owner);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error in loadControlDetails:", error.message);
        }
    }

    /**
     * PC_CC_330 - PC_CC_341
     * It updates the status of the control
     */
    const inActiveControl = async () => {
        setIsLoading(true);
        await updateControlStatus({
            "reason": "",
            "activeStatus": "active",
            "userId": userId,
            "pausedTill": "current_timestamp",
            "controlId": controlInformation?.controlDetails?.controlId,
            "controlIdentity": controlInformation?.controlDetails?.controlIdentity,
            "controlName": controlInformation?.controlDetails?.controlName
        });
        setReRender(!reRender);
        setIsLoading(false);
    }

    /**
     * PC_CC_292 - PC_CC_306
     * The status of the particular control gets updated over this function 
     */
    const updateStatus = async (e) => {
        setIsLoading(true);
        if (e.target.checked == true) {
            await updateApprovalStatus({ "auditCycleId": encryptStorage.getItem('identifiant'), "controlId": controlInformation?.controlDetails?.controlId, "approvalStatus": "approved", userId: userId, controlName: controlInformation?.controlDetails?.controlName });
            setReRender(!reRender);
        }
        else {
            await updateApprovalStatus({ "auditCycleId": encryptStorage.getItem('identifiant'), "controlId": controlInformation?.controlDetails?.controlId, "approvalStatus": "not-approved" });
            setReRender(!reRender);
        }
        setIsLoading(false);
    }


    /**
     * PC_CC_280 - PC_CC_291
     * Update the owner of the control 
     */
    const changeDropDown = async (userId, ownerName) => {
        setIsLoading(true);
        try {
            // Make the API call to update the control owner
            await updateControlOwner({
                controlOwner: userId,
                controlId: controlInformation.controlDetails.controlId,
                userId: userId,
                controlName: controlInformation?.controlDetails.controlName,
                controlOwnerName: ownerName,
                startDate: encryptStorage.getItem('commencer'),
                endDate: encryptStorage.getItem('fin')
            });

            // Update the selected owner in the state
            const newOwner = controlOwnerDropDownValues.find(owner => owner.user_id === userId);
            if (newOwner) {
                setSelectedOwner(newOwner);
            }
        } catch (error) {
            console.error("Error updating control owner:", error.message);
        } finally {
            // Set loading state and dropdown selection state
            setIsDropdownSelected(false);
            setIsLoading(false);
        }
    };


    const handleClose = () => {
        setShowInActivePopup(!showInActivePopup)
    }

    return (
        <>
            <div className="col-lg-9 px-3 col-md-12 col-sm-12 border-start admin-rightcontainer-hig control-lefttab-scroll text-start">
                <div
                    className="tab-pane"
                    id="Riskassess"
                    role="tabpanel"
                    aria-labelledby="Riskassess-tab"
                >
                    <div className="row">
                        <div className="d-flex justify-content-between mb-3 px-5 pe-0 align-items-center">
                            <div className="d-flex align-items-center">
                                <a className="text-decoration-none me-3 pointer" onClick={() => { navigate(0);  }}>
                                    <img src="/images/left-arrow.svg" style={{cursor : 'pointer'}} alt="left-arrow" />
                                </a>
                                <h1 className="font-bold font-24 primary-textcolor mb-0">
                                    {controlInformation?.controlDetails?.controlName}
                                </h1>
                                <span className="Compliance-outline-bth font-10 font-medium py-1 px-2 ms-3">
                                    {/* {data.mappedPrinciples[0].principleId} */}
                                    {selectedPrinciple}
                                </span>
                            </div>

                            {isAuditor == true ?

                                <div className="form-check">
                                    <input className="form-check-input custom-checkbox" type="checkbox" defaultValue id="flexCheckChecked" checked={controlInformation?.approvalStatus == 'approved' ? true : false} onChange={(e) => { updateStatus(e) }} />
                                    <label className="form-check-label font-regular font-14 primary-textcolor" htmlFor="flexCheckChecked">
                                        Mark as Complete
                                    </label>
                                </div>
                                : ''
                            }
                            {isAuditor === false ?
                                controlInformation?.controlDetails?.controlActiveStatus === 'inactive' ?
                                    <button
                                        type="button"
                                        disabled={viewAsAuditor}
                                        className="cust-active-btn cust-filter-btn font-14 font-regular primary-textcolor download-btn me-2"
                                        onClick={() => {
                                            inActiveControl();
                                        }}
                                    >
                                        <img
                                            src="/images/play-icon.svg"
                                            alt="browse"
                                            className="me-2"
                                        />
                                        Active
                                    </button>
                                    :
                                    <button
                                        type="button"
                                        disabled={viewAsAuditor}
                                        className="cust-active-btn cust-filter-btn font-14 font-regular primary-textcolor download-btn me-2"
                                        onClick={() => setShowInActivePopup(!showInActivePopup)}
                                    >
                                        <img
                                            src="/images/inactive-batch-icon.svg"
                                            alt="browse"
                                            className="me-2"
                                        />
                                        In-Active
                                    </button>
                                : ''}

                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 px-5 mb-5">
                            <p className="font-regular grey-light-color font-14">
                                {controlInformation?.controlDetails?.controlDescription}
                            </p>
                            <div className="row">
                                <div className="col-md-6 my-3">
                                    <label className="font-medium font-14 primary-textcolor mb-1">
                                        ID
                                    </label>
                                    <p className="font-regular grey-light-color font-14 py-2">{controlInformation?.controlDetails?.controlIdentity}</p>
                                </div>
                                <div className="col-md-6 my-3">
                                    <label
                                        htmlFor="Owner"
                                        className="form-label font-medium font-14 primary-textcolor mb-1"
                                    >
                                        Owner
                                    </label>
                                    <Select
                                        className="w-100"
                                        options={[{ value: '', label: 'Select Control Owner' } , ...controlOwnerDropDownValues.map((controlValues) => ({
                                            value: controlValues.user_id,
                                            label: controlValues.employee_name,
                                        }))]}
                                        onChange={(selectedOption) => selectedOption.value ? changeDropDown(selectedOption.value, selectedOption.label) : null}
                                        isSearchable={true}
                                        value={selectedOwner.employee_name && selectedOwner.user_id ? { value: selectedOwner.user_id, label: selectedOwner.employee_name } : ''}
                                        isDisabled={isAdmin} 
                                   />



                                </div>
                            </div>
                            <h5 className="font-medium font-14 primary-textcolor my-3">Details</h5>
                            <ul className="nav nav-tabs border-0 mb-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link border-0 font-14 px-0 me-4 compliance-custom-tab active"
                                        id="MappedElements-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#MappedElements"
                                        type="button"
                                        role="tab"
                                        aria-controls="MappedElements"
                                        aria-selected="true"
                                        onClick={() => { setHideHistoryTab(true); setHideAuditorComments(true); setHideControlsMapping(false) }}
                                    >
                                        Mapped Elements
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link border-0 font-14 px-0 compliance-custom-tab me-4"
                                        id="History-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#History"
                                        type="button"
                                        role="tab"
                                        aria-controls="History"
                                        aria-selected="false"
                                        onClick={() => { setHideHistoryTab(false); setHideAuditorComments(true); setHideControlsMapping(true) }}
                                    >
                                        History
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link border-0 font-14 px-0 compliance-custom-tab me-4"
                                        id="AuditorComments-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#AuditorComments"
                                        type="button"
                                        role="tab"
                                        aria-controls="AuditorComments"
                                        aria-selected="false"
                                        onClick={() => { setHideHistoryTab(true); setHideAuditorComments(false); setHideControlsMapping(true) }}
                                    >
                                        Auditor Comments
                                    </button>
                                </li>
                                {/* PC_CC_431 - PC_CC_433
                                OnClick of request docuemnt it will open a popup
                                 */}
                                <li className='ms-auto'>{isAuditor == true ?
                                    <button type="button" className="btn btn-upload-outline" data-bs-toggle="modal" data-bs-target="#request-document">
                                        Request Document
                                    </button>
                                    : ''}</li>
                            </ul>




                            <div class="tab-content" id="myTabContent">
                                {hideControlsMapping == false ?
                                    < ControlMappings controlInformation={controlInformation} mappedPolicies={mappedPolicy} mappedPrinciples={mappedPrinciples} mappedEvidences={mappedEvidence} isAuditor={isAuditor} viewAsAuditor={viewAsAuditor} reRender={reRender} setReRender={setReRender} /> :
                                    hideHistoryTab == false ?
                                        < ControlHistory controlInformation={controlInformation} /> :
                                        hideAuditorComments == false ?
                                            <ControlAuditComments controlInformation={controlInformation} /> : ''
                                }
                            </div>
                            {showInActivePopup ? <InactivePopup controlInformation={controlInformation} reRender={reRender} setReRender={setReRender} onOpen="Control" onClose={handleClose} /> : <></>}
                            <RequestDocument controlInformation={controlInformation} reRender={reRender} setReRender={setReRender} />

                        </div>
                    </div>
                </div>
            </div>
            <Loader isLoading={isLoading} />
        </>
    )
}

export default ControlDetailsPage
