import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { agovContext, encryptStorage } from '../../Constant';
import JSZip from 'jszip';
import axios from 'axios';
import {
  addExistingAuditCycle,
  addNewAuditCycle,
  addPastAuditCycle,
  deleteAuditCycle,
  downloadAuditEvidences,
  generateSasToken,
  getAuditCycleData,
  insertLog,
  updateAuditCycle,
  uploadReport
} from "../../Services/GRC/GrcService";
import { Loader } from "../Loader/Loader";
import ToastMessageComponent from "../ToastMessageComponent/ToastMessageComponent";
import AddEditPopup from "./AddEditPopup";
import DeletePopup from "./DeletePopup";
import UploadPastAuditReportPopup from "./UploadPastAuditReportPopup";
import UploadReportPopup from "./UploadReportPopup";
import NoDataFound from "../LMS/NoDataFound";
import RiskNoDataFound from "../Risk/RiskNoDataFound";
import { saveAs } from "file-saver";

const AuditCycleGrid = () => {
  // PC_AC_02
  // create the respective state variables

  const navigate = useNavigate();
  const location = useLocation();

  const [auditCycleData, setAuditCycleData] = useState([]);
  const [filterData, setFilterData] = useState({
    auditorId: null,
    searchTerm: "",
    sortOrder: "desc",
    sortColumn: "ac.created_on",
    limit: 10,
  });
  const [count, setCount] = useState(0);
  const [showDeletePopup, setShowDeletePopup] = useState(0);
  const [addEditPopupData, setAddEditPopupData] = useState({
    heading: null,
    subHeading: null,
    sunHeading: null,
    content: null,
    onOpen: null,
  });
  const [addAuditData, setAddAuditData] = useState({
    startDate: null,
    endDate: null,
    auditorId: null,
    auditorFirm: null,
    previousControlOwner: null,
  });
  const [uploadReportData, setUploadReportData] = useState({
    onOpen: null,
    data: null,
    auditCycleId: null,
    reportLink: null
  });
  const [showAddAuditPopup, setShowAddAuditPopup] = useState(false);
  const [showUploadPastAuditPopup, setShowUploadPastAuditPopup] =
    useState(false);
  const [showUploadReportPopup, setShowUploadReportPopup] = useState(false);
  const [auditorDetails, setAuditorDetails] = useState({
    profileUrl: null,
    employeeName: null,
  });
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    path: null,
    header: null,
    message: null,
    color: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { roles, userId } = useContext(agovContext);
  const isAdmin = roles?.includes('Super Admin') || roles?.includes('GRC Admin')

  
  const hasRole = (rolesToCheck) => {
    for (const role of rolesToCheck) {
      if (roles?.includes(role)) {
        return true;
      }
    }
    return false;
  };

  let response;
  /**
   * PC_AC_04
   * The useEffect calls the API to get the data for the grid when ever the filterData changes
   */
  useEffect(() => {
    fetchAuditCycleData();
  }, [filterData]);

  /**
   * PC_AC_09
   * Gets the data of the table according to the user role
   */
  const fetchAuditCycleData = async () => {
    setIsLoading(true)
    let data = filterData;
    if (!isAdmin) {
      data.auditorId = userId;
    }

    try {
      response = await getAuditCycleData(data);
      setAuditCycleData(response.responseData[0]);
      setCount(response.responseData[1][0].Count);
    } catch (error) {
      //console.log(error, "error");
    }
    finally {
      setIsLoading(false)
    }
  };

  /**
   * PC_AC_48
   * set state the searched value in the filterData state variable to
   * call the API according to the filterData
   */
  const handleSearch = () => {
    setFilterData({ ...filterData, searchTerm: filterData.searchTerm.trim() });
  };

  /**
   * PC_AC_107
   * Soft delete the particular audit cycle
   */
  const handleDelete = async () => {
    setIsLoading(true)
    try {
      setShowDeletePopup(0);
      await deleteAuditCycle({ auditCycleId: showDeletePopup });
      await fetchAuditCycleData();
    } catch (error) {
      //console.log(error, "error");
    }
    finally {
      setIsLoading(false)
    }
  };

  /**
 * PC_AC_102
 * Open the delete popup
 */
  const handleDeleteIconClick = (auditCycleId) => {
    setShowDeletePopup(auditCycleId);
  };

  /**
   * PC_AC_102
   * Close the delete popup
   */
  const closeDeletePopup = () => {
    setShowDeletePopup(0);
  };

  /**
   * PC_AC_26
   * Format the date to display
   */
  const formatTimeline = (startDate, endDate) => {
    return `${format(startDate, "MM/dd/yyyy")} - ${format(
      endDate,
      "MM/dd/yyyy"
    )}`;
  };

  /**
   * PC_AC_131
   * The AddAuditPopup component is called, by sending the data to the component according to the click
   */
  const openAddAuditPopup = (event) => {
    if (event === "Add") {
      setAddEditPopupData({
        ...addEditPopupData,
        heading: "Add Audit ・New Timeline",
        subHeading: "Select Audit Timeline ",
        content: "Specify the start and end dates for your audit.",
        onOpen: "Add",
      });
      setShowAddAuditPopup(true);
    } else if (event === "Existing") {
      setAddEditPopupData({
        ...addEditPopupData,
        heading: "Add Audit ・Existing Timeline",
        subHeading: "Audit Timeline ",
        content: null,
        onOpen: "Existing",
      });
      setShowAddAuditPopup(true);
    }
  };

  /**
   * PC_AC_145
   * The AddAuditPopup component is called, by sending the data to the component according to the user
   */
  const openEditAuditPopup = (
    auditCycleName,
    startDate,
    endDate,
    auditorId,
    auditorFirm,
    auditCycleId,
    profileUrl,
    employeeName,
    reportLink
  ) => {
    if (isAdmin) {
      setAddEditPopupData({
        ...addEditPopupData,
        heading: auditCycleName,
        subHeading: "Audit Timeline ",
        content: null,
        onOpen: "Edit",
      });
      setAddAuditData({
        ...addAuditData,
        startDate: startDate,
        endDate: endDate,
        auditorId: auditorId,
        auditorFirm: auditorFirm,
        previousControlOwner: auditCycleId,
      });
      setAuditorDetails({
        ...auditorDetails,
        profileUrl: profileUrl,
        employeeName: employeeName,
      });
      setShowAddAuditPopup(true);
    } else {
      const formattedDate = formatTimeline(startDate, endDate);
      setUploadReportData({
        ...uploadReportData,
        onOpen: "Edit",
        data: formattedDate,
        auditCycleId: auditCycleId,
        reportLink: reportLink
      });
      setShowUploadReportPopup(true);
    }
  };

  /**
   * PC_AC_156
   * Call the API to create a audit cycle according to the user click
   */
  const handleAddAudit = async (data, type) => {
    setShowAddAuditPopup(false);
    if (type === "Add") {
      setIsLoading(true)
      try {
        await addNewAuditCycle(data);
        await fetchAuditCycleData()
        // setShowAddAuditPopup(false);
      } catch (error) {
        //console.log(error, "error");
      }
      finally {
        setIsLoading(false)
      }
    } else if (type === "Existing") {
      setIsLoading(true)
      try {
        await addExistingAuditCycle(data);
        await fetchAuditCycleData()
        // setShowAddAuditPopup(false);
      } catch (error) {
        //console.log(error, "error");
      }
      finally {
        setIsLoading(false)
      }
    } else if (type === "Edit") {
      setIsLoading(true)
      try {
        await updateAuditCycle(data);
        await fetchAuditCycleData()
        // setShowAddAuditPopup(false);
      } catch (error) {
        //console.log(error, "error");
      }
      finally {
        setIsLoading(false)
      }
    }
  };

  /**
   * PC_AC_151
   * close the add audit popup
   */
  const closeAddEditPopup = () => {
    setShowAddAuditPopup(false);
  };

  /**
   * PC_AC_203
   * close the upload past audit popup
   */
  const closeUploadAuditPopup = () => {
    setShowUploadPastAuditPopup(false);
  };

  /**
   * PC_AC_208
   * Call the upload past audit report API
   */
  const handleUploadAudit = async (data, bloburl) => {
    setIsLoading(true)
    const addingData = {
      startDate: data.startDate,
      endDate: data.endDate,
      auditorFirm: data.auditorFirm,
      auditorId: data.auditorId,
      auditCycleName: data.auditCycleName,
      blobLink: bloburl,
      userId: userId,
    };
    try {
      setShowUploadPastAuditPopup(false);
      await addPastAuditCycle(addingData);
      await fetchAuditCycleData()
    } catch (error) {
      //console.log(error, "error");
    }
    finally {
      setIsLoading(false)
    }
  };

  /**
   * PC_AC_60
   * Download the selected file
   */
  const handleFileDownload = async (fileUrl, auditCycleName) => {
    if (fileUrl.split('?')[0]) {
      setIsLoading(true)
      const fileExtension = getFileExtension(fileUrl);

      try {
        // let response = await generateSasToken({ resourceTypes: "o", permission: "r" });
        // const sasToken = response.responseData;
        const url = fileUrl
        saveAs(url, "report." + fileExtension);
        await insertLog({ event: 'reportDownload', eventDescription: 'Report ' + auditCycleName, userId: userId, category: 'grcManagement', riskType: 'download', result: 'Sucess' })
      }
      catch (error) {
        //console.log(error, "error")
      }
      finally {
        setIsLoading(false)
      }
    } else {
      setToastMessage({
        ...toastMessage,
        path: "/images/error-toast-icon.svg",
        header: "Missing File",
        message: "File is not avaliable",
        color: "error-toast",
      });
      setIsToastVisible(true);
    }
  };

  /**
   * PC_AC_29
   * Gets the file type
   */
  // const getFileExtension = (url) => {
  //   const filename = url.substring(url.lastIndexOf("/") + 1);
  //   const extension = filename.split(".").pop();
  //   return extension;
  // };

  const getFileExtension = (url) => {
    // Find the last dot in the URL after the last '/'
    const urlBeforeQueryString = url.split('?')[0];
    const lastDotIndex = urlBeforeQueryString.lastIndexOf('.');

    // Extract the file extension if a dot was found
    if (lastDotIndex === -1 || lastDotIndex === urlBeforeQueryString.length - 1) {
      return null;
    }

    // Extract the file extension
    return urlBeforeQueryString.substring(lastDotIndex + 1); // +1 to exclude the dot
  }

  /**
   * PC_AC_30
   * Close the upload Report popup
   */
  const handleUploadReportClose = () => {
    setShowUploadReportPopup(false);
  };

  /**
   * PC_AC_234
   * Upload the past audit report in the DB by calling the API
   */
  const handleUploadReport = async (data, blobUrl) => {
    setIsLoading(true)
    try {
      setShowUploadReportPopup(false);
      await uploadReport({
        auditCycleId: data.selectedDate,
        userId: userId,
        blobUrl: blobUrl,
        blobName: data.selectedFile.name,
      });
      await fetchAuditCycleData();
    } catch (error) {
      //console.log(error, "error");
    }
    finally {
      setIsLoading(false)
    }
  };

  /**
   * PC_AC_249
   * Open the selected file in new tab
   */
  const openFileInNewWindow = async (file) => {
    if (file) {
      setIsLoading(true)
      try {
        // response = await generateSasToken({
        //   resourceTypes: "o",
        //   permission: "r",
        // });
        // const sasToken = response.responseData;
        const fileUrl = file;

        const encodedUrl = encodeURIComponent(fileUrl);
        const googleViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;

        window.open(googleViewerUrl, '_blank')
      } catch (error) {
        //console.log(error, "error");
      }
      finally {
        setIsLoading(false)
      }
    } else {
      setToastMessage({
        ...toastMessage,
        path: "/images/error-toast-icon.svg",
        header: "Missing File",
        message: "File is not avaliable",
        color: "error-toast",
      });
      setIsToastVisible(true);
    }
  };

  /**
   * PC_AC_227
   * Opens the upload report popup
   */
  const openUploadReport = () => {
    setUploadReportData({
      ...uploadReportData,
      onOpen: "new",
      auditCycleId: null,
      reportLink: null
    });
    setShowUploadReportPopup(true);
  };


  const downloadAndZipFiles = async (apiResponse, startDate, endDate) => {
    const zip = new JSZip();

    for (const category of apiResponse) {
      const categoryFolder = zip.folder(category.categoryId.toString());

      for (const principleAndControl of category.principleAndControl) {
        const principleFolder = categoryFolder.folder(principleAndControl.principle.principleId.toString());

        for (const control of principleAndControl.controls) {
          const controlFolder = principleFolder.folder(control.controlName.toString());

          for (const blobUrl of control.blobUrl) {
            if (blobUrl && blobUrl[0] !== '?') {
              try {
                const response = await axios.get(blobUrl, { responseType: 'blob' });
                const filename = getFileNameFromUrl(blobUrl);
                controlFolder.file(filename, response.data);
              } catch (error) {
                console.error('Error downloading file:', error);
              }
            }
          }
        }
      }
    }


    const zipFileName = `Policies and Evidences.zip`;

    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        saveAs(content, zipFileName);
      })
      .catch((error) => {
        console.error('Error creating zip file:', error);
      });
  };


  const getFileNameFromUrl = (fileUrl) => {
    let parsedUrl = new URL(fileUrl);
    let pathname = parsedUrl.pathname;
    let segments = pathname.split('/');
    let filename = segments[segments.length - 1];
    filename = decodeURIComponent(filename);
    segments = filename.split('-iiiii-');
    return segments[1];
  };

  /**
   * PC_AC_256
   * Navigate to the controls page and also set the startDate, endDate, auditCycleId in the encryptStorage
   */
  const handleAuditCycleClick = (startDate, endDate, auditCycleId) => {
    encryptStorage.setItem("commencer", startDate);
    encryptStorage.setItem("fin", endDate);
    encryptStorage.setItem("identifiant", auditCycleId);
    navigate("/compliance/controls");
  };

  /**
   * PC_AC_256
   * Download all the policies and evidences documents in the particular audit cycle
   */
  const downloadZipFile = async (startDate, endDate) => {
    setIsLoading(true)
    try {
      let result = await downloadAuditEvidences({ startDate, endDate })
      await downloadAndZipFiles(result?.responseData, startDate, endDate);
    }
    catch (error) {
      //console.log(error, "error");
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={!isLoading ? "container-fluid mt-4 mt-custom" : "container-fluid"}>
      {/* <Loader /> */}
      <Loader isLoading={isLoading} />
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-between align-items-center px-4">
            {/*tabs starts here*/}
            {hasRole(["Super Admin", "GRC Admin"]) ? (
              <div className="custom-tabs">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style rounded-0"
                    id="dashboard-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#dashboard"
                    type="button"
                    role="tab"
                    aria-controls="dashboard"
                    aria-selected="true"
                    onClick={() => navigate('/compliance')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/dashboard-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/dashboard-icon.svg"
                        alt="dashboard-icon"
                        className="me-2"
                      />
                    </span >
                    Dashboard
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    onClick={() => navigate("/compliance/AuditCycleGrid")}
                    className="nav-link px-0 active font-14 tab-style bold-text rounded-0"
                    id="audit-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#audit"
                    type="button"
                    role="tab"
                    aria-controls="audit"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/manage-audit-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/manage-audit-icon.svg"
                        alt="manage-audit-icon"
                        className="me-2"
                      />{" "}
                    </span>
                    Manage Audit
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    onClick={() => navigate("/compliance/vendor")}
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="vendors-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#vendors"
                    type="button"
                    role="tab"
                    aria-controls="vendors"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/vendor-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/vendor-icon.svg"
                        alt="vendor-icon"
                        className="me-2"
                      />
                      Vendors
                    </span>
                  </button>
                </li>
              </ul>
            </div>) : 
            (<div className="custom-tabs">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item ms-4" role="presentation">
                <button
                  className="nav-link px-0 active font-14 tab-style bold-text rounded-0"
                  id="audit-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#audit"
                  type="button"
                  role="tab"
                  aria-controls="audit"
                  aria-selected="false"
                  tabIndex={-1}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="/images/manage-audit-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />{" "}
                    <img
                      src="/images/manage-audit-icon.svg"
                      alt="manage-audit-icon"
                      className="me-2"
                    />{" "}
                  </span>
                  Manage Audit
                </button>
              </li>
              <li className="nav-item ms-4" role="presentation">
                <button
                  onClick={() => navigate("/compliance/vendor")}
                  className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                  id="vendors-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#vendors"
                  type="button"
                  role="tab"
                  aria-controls="vendors"
                  aria-selected="false"
                  tabIndex={-1}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="/images/vendor-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />{" "}
                    <img
                      src="/images/vendor-icon.svg"
                      alt="vendor-icon"
                      className="me-2"
                    />
                    Vendors
                  </span>
                </button>
              </li>
            </ul>
          </div>)
            }
            {/*tabs ends here*/}
            {/*Manage audits tab right content here*/}
            <div className="d-flex">
              <div className="input-group flex-nowrap search-group me-2">
                <input
                  type="text"
                  className="form-control search-align"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                  onChange={(event) =>
                    (filterData.searchTerm = event.target.value)
                  }
                  onKeyDownCapture={(e) => {
                    if (e.key == "Enter") {
                      handleSearch();
                    }
                  }}
                />
                <button
                  className="input-group-text btn btn-search-custom"
                  id="addon-wrapping"
                  onClick={() => handleSearch()}
                >
                  <img src="/images/search-icon.svg" alt="search-icon" />
                </button>
              </div>
              {/* <div className="btn-group ">
                <button
                  type="button"
                  className=" dark-btn px-4 dropdown-toggle custom-dropdown-toggle pb-2"
                >
                  <img
                    className="me-2"
                    src="/images/add-icon.svg"
                    alt="add-icon"
                  />{" "}
                  Add Audit
                </button>
                <ul className="custom-dropdown py-3">
                  <li>
                    <span
                      className="dropdown-item mb-2"
                      onClick={() => openAddAuditPopup("Add")}
                    >
                      New Timeline
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item mb-2"
                      onClick={() => openAddAuditPopup("Existing")}
                    >
                      Existing Timeline
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      onClick={() => setShowUploadPastAuditPopup(true)}
                    >
                      Past Audit &amp; Reports
                    </span>
                  </li>
                </ul>
              </div> */}
              {/* </div> */}
              <div class="btn-group">
                {isAdmin ? (
                  <button
                    type="button"
                    class=" dark-btn px-4 dropdown-toggle custom-dropdown-toggle pb-2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      class="me-2"
                      src="/images/add-icon.svg"
                      alt="add-icon"
                    />{" "}
                    Add Audit
                  </button>
                ) : (
                  <button
                    type="button"
                    className="cust-filter-btn font-14  font-regular primary-color upload-btn "
                    onClick={() => openUploadReport()}
                  >
                    <img
                      src="/images/upload.svg"
                      alt="browse"
                      className="me-2"
                    />
                    Upload Report
                  </button>
                )}
                <ul class="dropdown-menu custom-dropdown py-3">
                  <li>
                    <a
                      class="dropdown-item mb-2 cursor-pointer"
                      type="button"
                      onClick={() => openAddAuditPopup("Add")}
                    >
                      New Timeline
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item mb-2 cursor-pointer"
                      onClick={() => openAddAuditPopup("Existing")}
                    >
                      Existing Timeline
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item pointer"
                      onClick={() => setShowUploadPastAuditPopup(true)}
                    >
                      Past Audit &amp; Reports
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row position-relative">
            <div className="tab-content" id="pills-tabContent">
              {/*Dashboard tab*/}
              <div
                className="tab-pane fade px-4"
                id="dashboard"
                aria-labelledby="dashboard-tab"
              >
                <div className="row">
                  <div className="col-md-12">dashboard</div>
                </div>
              </div>
              {/*Dashboard tab*/}
              {/*audit tab*/}
              <div
                className="tab-pane fade active show px-4"
                id="audit"
                aria-labelledby="audit-tab"
              >
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <h5 className="font-medium font-20 mb-4">Manage Audit</h5>
                    {auditCycleData?.length ? <table className="table mb-0 custom-table-grid table-borderless mt-3">
                      <thead>
                        <tr>
                          <th className="font-14 px-4 position-relative">
                            Report Name
                            <span className="position-absolute">
                              <span
                                className="sort-up position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "auditCycleName",
                                    sortOrder: "asc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-up.svg"
                                  alt="sort-up-icon"
                                />
                              </span>{" "}
                              <span
                                className="sort-down position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "auditCycleName",
                                    sortOrder: "desc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-down.svg"
                                  alt="sort-down-icon"
                                />
                              </span>
                            </span>
                          </th>
                          <th className="font-14 position-relative">
                            Auditor
                            <span className="position-absolute">
                              <span
                                className="sort-up position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "auditorName",
                                    sortOrder: "asc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-up.svg"
                                  alt="sort-up-icon"
                                />
                              </span>{" "}
                              <span
                                className="sort-down position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "auditorName",
                                    sortOrder: "desc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-down.svg"
                                  alt="sort-down-icon"
                                />
                              </span>
                            </span>
                          </th>
                          <th className="font-14 position-relative">
                            Auditor Firm
                            <span className="position-absolute">
                              <span
                                className="sort-up position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "auditorFirm",
                                    sortOrder: "asc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-up.svg"
                                  alt="sort-up-icon"
                                />
                              </span>{" "}
                              <span
                                className="sort-down position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "auditorFirm",
                                    sortOrder: "desc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-down.svg"
                                  alt="sort-down-icon"
                                />
                              </span>
                            </span>
                          </th>
                          <th className="font-14 position-relative">
                            Timeline
                            <span className="position-absolute">
                              <span
                                className="sort-up position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "startDate",
                                    sortOrder: "asc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-up.svg"
                                  alt="sort-up-icon"
                                />
                              </span>{" "}
                              <span
                                className="sort-down position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "startDate",
                                    sortOrder: "desc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-down.svg"
                                  alt="sort-down-icon"
                                />
                              </span>
                            </span>
                          </th>
                          <th className="font-14 position-relative">
                            Status
                            <span className="position-absolute">
                              <span
                                className="sort-up position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "auditStatus",
                                    sortOrder: "asc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-up.svg"
                                  alt="sort-up-icon"
                                />
                              </span>{" "}
                              <span
                                className="sort-down position-absolute ms-2 pointer"
                                onClick={() => {
                                  setFilterData({
                                    ...filterData,
                                    sortColumn: "auditStatus",
                                    sortOrder: "desc",
                                  });
                                }}
                              >
                                <img
                                  className="sort-img-size"
                                  src="/images/sort-down.svg"
                                  alt="sort-down-icon"
                                />
                              </span>
                            </span>
                          </th>
                          <th className="font-14 text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {auditCycleData?.map((auditCycleGridData) => (
                          <tr>
                            <td className="font-12 font-medium">
                              <a style={{cursor: 'Pointer'}}
                                className="text-decoration-none pointer"
                                disabled={!auditCycleGridData?.isPast}
                                onClick={() => !auditCycleGridData?.isPast ?
                                  handleAuditCycleClick(
                                    auditCycleGridData?.startDate,
                                    auditCycleGridData?.endDate,
                                    auditCycleGridData?.auditCycleId
                                  ) : null
                                }
                              >
                                {auditCycleGridData?.auditCycleName}
                              </a>
                            </td>
                            <td class="font-12">
                              {auditCycleGridData?.auditorName ? (
                                <div class="d-flex align-items-center">
                                  <img
                                    src={auditCycleGridData?.profileUrl}
                                    class="table-prof"
                                  />
                                  <p class="font-12 primary-textcolor mb-0 ms-2">
                                    {auditCycleGridData?.auditorName}
                                  </p>
                                </div>
                              ) : (
                                "Nil"
                              )}
                            </td>
                            <td className="font-12 wrap-text">
                              {auditCycleGridData?.auditorFirm || "Nil"}
                            </td>
                            <td className="font-12">
                              {formatTimeline(
                                auditCycleGridData?.startDate,
                                auditCycleGridData?.endDate
                              )}
                            </td>
                            <td className="font-12">
                              <span
                                className={
                                  auditCycleGridData?.auditStatus ===
                                    "Completed"
                                    ? "green-dot me-2"
                                    : auditCycleGridData?.auditStatus ===
                                      "In Progress"
                                      ? "orange-dot me-2"
                                      : "grey-dot me-2"
                                }
                              />
                              {auditCycleGridData?.auditStatus}
                            </td>
                            <td className="text-center">
                              <span
                                className="me-2 pointer"
                                onClick={() =>
                                  openEditAuditPopup(
                                    auditCycleGridData?.auditCycleName,
                                    auditCycleGridData?.startDate,
                                    auditCycleGridData?.endDate,
                                    auditCycleGridData?.auditorId,
                                    auditCycleGridData?.auditorFirm,
                                    auditCycleGridData?.auditCycleId,
                                    auditCycleGridData?.profileUrl,
                                    auditCycleGridData?.auditorName,
                                    auditCycleGridData?.reportLink
                                  )
                                }
                              >
                                <img
                                  src="/images/edit-icon.svg"
                                  alt="edit-icon"
                                  style={{cursor:"pointer"}}
                                />
                              </span>
                              {isAdmin ? (
                                <span
                                  className="me-2 pointer"
                                  onClick={() =>
                                    handleDeleteIconClick(
                                      auditCycleGridData?.auditCycleId
                                    )
                                  }
                                >
                                  <img
                                    src="/images/delete-icon.svg"
                                    alt="delete-icon"
                                    style={{cursor:"pointer"}}
                                  />
                                </span>
                              ) : (
                                <span
                                  className="me-2 pointer"
                                  onClick={() =>
                                    handleFileDownload(
                                      auditCycleGridData?.reportLink,
                                      auditCycleGridData?.auditCycleName
                                    )
                                  }
                                >
                                  <img
                                    src="/images/download-icon.svg"
                                    alt="download-icon"
                                    style={{cursor:"pointer"}}
                                  />
                                </span>
                              )}{" "}
                              {isAdmin ? (
                                <span
                                  className="download-toggle pointer"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img
                                    src="/images/download-icon.svg"
                                    alt="download-icon"
                                    style={{cursor:"pointer"}}
                                  />
                                  <ul class="dropdown-menu custom-dropdown py-3">
                                    <li onClick={() =>
                                      handleFileDownload(
                                        auditCycleGridData?.reportLink,
                                        auditCycleGridData?.auditCycleName
                                      )
                                    }>
                                      <a class="dropdown-item mb-2  cursor-pointer">
                                        <img
                                          class="me-2"
                                          src="/images/report-icon.svg"
                                          alt="report"
                                        />
                                        Report
                                      </a>
                                    </li>
                                    {!auditCycleGridData?.isPast ? <li onClick={() => !auditCycleGridData?.isPast ? downloadZipFile(auditCycleGridData?.startDate, auditCycleGridData?.endDate) : null}>
                                      <a class="dropdown-item mb-2 cursor-pointer">
                                        <img
                                          class="me-2"
                                          src="/images/policy-icon.svg"
                                          alt="policy"
                                        />
                                        Policy & Evidence
                                      </a>
                                    </li> : null}
                                  </ul>
                                </span>
                              ) : (
                                <span
                                  className={auditCycleGridData.auditStatus === 'Completed' && auditCycleGridData.reportLink.split('?')[0]? "me-2 pointer" :"me-2 notupload"}
                                  onClick={() => auditCycleGridData.auditStatus === 'Completed' && auditCycleGridData.reportLink.split('?')[0] ?
                                    openFileInNewWindow(
                                      auditCycleGridData.reportLink
                                    ) : null
                                  }
                                  style={auditCycleGridData.auditStatus === 'Completed' && auditCycleGridData.reportLink.split('?')[0]?{filter:"none" }:{filter:"grayscale(100%)"}}
                                >
                                  <img
                                    src="/images/card-pdf-icon.svg"
                                    alt="pdf-icon"
                                  />
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table> : <RiskNoDataFound data={"No Record Found"}/>}
                    {/* {true?<Loader isLoading={}/>:<></>} */}
                    {showAddAuditPopup ? (
                      <AddEditPopup
                        heading={addEditPopupData.heading}
                        subHeading={addEditPopupData.subHeading}
                        content={addEditPopupData.content}
                        onOpen={addEditPopupData.onOpen}
                        count={count}
                        data={addAuditData}
                        auditorData={auditorDetails}
                        onClose={closeAddEditPopup}
                        onSave={handleAddAudit}
                      />
                    ) : null}
                    {showUploadPastAuditPopup ? (
                      <UploadPastAuditReportPopup
                        onClose={closeUploadAuditPopup}
                        onSave={handleUploadAudit}
                      />
                    ) : null}
                    {showUploadReportPopup ? (
                      <UploadReportPopup
                        onOpen={uploadReportData.onOpen}
                        data={uploadReportData.data}
                        auditCycleId={uploadReportData.auditCycleId}
                        reportLink={uploadReportData.reportLink}
                        userId={userId}
                        onClose={handleUploadReportClose}
                        onSave={handleUploadReport}
                      />
                    ) : null}
                    {showDeletePopup ? (
                      <DeletePopup
                        heading="Delete Audit Cycle"
                        content="Are you sure you want to delete this audit cycle? This action will permanently remove the selected audit cycle's contact information."
                        onDelete={handleDelete}
                        onClose={closeDeletePopup}
                      />
                    ) : null}
                    <p className="font-12 grey-primary">
                      Showing{" "}
                      <span className="font-medium primary-textcolor">
                        {count >= filterData.limit ? filterData.limit : count}
                      </span>{" "}
                      {" "}out of{" "}
                      <span className="font-medium primary-textcolor">
                        {count}
                      </span>{" "}
                      items
                    </p>
                    <div className="text-center mt-3">
                      {count > filterData.limit ? (
                        <button
                          className="primary-btn font-medium"
                          onClick={() => {
                            setFilterData({
                              ...filterData,
                              limit: filterData.limit + 10,
                            });
                          }}
                        >
                          Load More
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/*audit tab*/}
            </div>
          </div>
        </div>
      </div>
      {isToastVisible && (
        <ToastMessageComponent
          toastResponse={toastMessage}
          setIsShowToastMessage={setIsToastVisible}
        />
      )}
    </div>
  );
};

export default AuditCycleGrid;
