import { Workbook } from 'exceljs';
import FileSaver from 'file-saver';
import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from "react-router-dom";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { agovContext } from "../../../Constant";
import { getLMSData } from "../../../Services/Agov/governanceService";
import { Loader } from '../widgets/Loader';
import NoRecordFound from '../dashboardNoRecordFound';





const LearningSummary = (LmsData) => {

    const navigate = useNavigate()
    //PS_LMS_07
    // TO get the required user information from localstorage using useContext
    const { employeeName, userId, managerId, roles, employeeId, employeeValue } = useContext(agovContext);
    
    // PS_LMS_05
    //PS_LMS_05 create a state variables with initial data
    let LmsValue = [
        {
            name: "Yet to Start",
            value: 0

        },
        {
            name: "In Progress",
            value: 0

        },
        {
            name: "Completed",
            value: 0
        },
        {
            name: "Overdue",
            value: 0
        }

    ]

    let Sort = {
        ColumnName: "employeeName",
        Order: "asc"
    }

    const [reqLmsDuration, setReqLmsDuration] = useState('Monthly')
    const [totalSumLmsData, setTotalSumLmsData] = useState(0)
    const [userLmsSelection, setUserLmsSelection] = useState(roles?.includes("Super Admin") || roles?.includes("Manager") ? "Team's Learning" : "My Learning")
    const [allLmsTask, setAllLmsTask] = useState(LmsValue)
    const [sortData, setSortData] = useState(Sort)
    const [filter, setFilter] = useState(0)
    const [isExcel, setIsExcel] = useState(false)
    const [excelData, setExcelData] = useState([])
    const [hasMoreload, SetHasMoreLoad] = useState(false)
    const [popUpData, setPopUpData] = useState([])
    const [isPopUp, setIsPopUp] = useState(0)
    const [dateTimeColumnName, setDateTimeColumnName] = useState('start_date');
    const [DownScroll, setDownScroll] = useState(6)
    const [noDataFound, setNodataFound] = useState(true)
    const [isLoading,setIsLoading] = useState(false)
    //Learning Summary




    // PS_LMS_06
    // Set up useEffect with dependencies, triggers the function when user interacts with the application.
    useEffect(() => {
        //PS_LMS_36 the file will be saved
            onInitialPageLoad()  
            onPopupLoad()
            
     }, [userLmsSelection, reqLmsDuration,isExcel,filter, sortData, DownScroll])


    //PS_LMS_07 - 17
    //function onIntialPageLoad triggres and make api call to fetch the data
    // and set states the response in state variables and bind them in HTML 
    // and also gets the response when user interates with the application.

    async function onInitialPageLoad() {

        let payload = {
            userId: userId,
            userLmsSelection: userLmsSelection,
            reqLmsDuration: reqLmsDuration,
            userRole: roles,
            dateTimeColumnName: dateTimeColumnName,
            sort: sortData,
            filter: filter,
            isExcel: isExcel,
            managerId:employeeId,
            DownScroll: DownScroll
        }


        if (filter == 0) {

            setIsLoading(true)
            const LMSResponse = await getLMSData(payload)

            if (LMSResponse && LMSResponse.statusCode == 200) {
                if (LMSResponse.responseData.totalTaskCount != 0) {
                    setNodataFound(false)
                    setTotalSumLmsData(LMSResponse.responseData.totalTaskCount)

                    LmsValue[0].value = LMSResponse.responseData.yetToStart
                    LmsValue[1].value = LMSResponse.responseData.inProgress
                    LmsValue[2].value = LMSResponse.responseData.completed
                    LmsValue[3].value = LMSResponse.responseData.overDue

                    setAllLmsTask(LmsValue)


                    //PS_LMS_34 api data fetching and set stating the state variables
                    if(isExcel)
                    {
                        const ExportToExcel = await formatDataForExcel(LMSResponse.exportToExcel)
                        setExcelData(ExportToExcel)
                        handleExportToExcel(ExportToExcel)
                    }
                    

                }
                else {
                    setNodataFound(true)
                }

            }
            else {
                setNodataFound(true)
                navigate('/error');
            }
            setIsLoading(false)

        }
        
     }

     async function onPopupLoad(){

        let payload = {
            userId: userId,
            userLmsSelection: userLmsSelection,
            reqLmsDuration: reqLmsDuration,
            userRole: roles,
            dateTimeColumnName: dateTimeColumnName,
            sort: sortData,
            filter: filter,
            isExcel: isExcel,
            managerId:employeeId,
            DownScroll: DownScroll
        }

        if(filter!=0)
            {
                //PS_LMS_29-30 based on the scroll the api call will be trigerred
                const popUpDataReaponse = await getLMSData(payload)
                setPopUpData(popUpDataReaponse.responsePopDetails)
                SetHasMoreLoad(isPopUp > DownScroll ? true : false)
                // PS_LMS_28 based on the scroll the downScroll and hasMoreLoad value will be updated 
            }
     }
     



    let userRole = ''
    let COLORS = []

    if (roles?.includes("Super Admin")) {
        userRole = "Super Admin"
    }
    else if (roles?.includes("Manager")) {
        userRole = "Manager"
    }
    else {
        userRole = "User"
    }

    userRole != "User" ? COLORS = ["#ADADE8", "#FACC9E", "#78DC8E", "#F06E9B"] : COLORS = ["#d4d4d4", "#8CD8E8", "#78DC8E", "#FB8484"]


    //PS_LMS_19-20
    //set state the variable reqLmsDuration when the user changes the value in the dropdown  
    function OnHandleLmsDropDown(value) {
        setReqLmsDuration(value)
    }



    //PS_LMS_21-22
    //set state the variable userLmsSelection when the user switched the tab.
    function OnHandleLmsTabSwitch(value) {
        setUserLmsSelection(value)
    }

    //PS_LMS_26-27 make an api call after setstating the filter variable. fetch the response according to the payload.
    async function onHandlePopUp(FilterValue, StatusTotal) {

        setFilter(FilterValue)
        setIsPopUp(StatusTotal)
        

    }

    //PS_LMS_23

    function OnHandleLmsNavigation(value) {
        navigate('/lmsmanagement/myLearnings', {
            state: {
                value: {
                    employeeId: employeeId,
                    status: value,
                    limit: 8,
                    roleValue: roles,
                    searchCampaignName: "",
                    userId: userId,
                    timeline: reqLmsDuration,
                    is_Dashboard: true
                }
            }
        })
    }

    //PS_LMS_32-33 the data will be shorted accoringly

    function onHandleSort(order, column) {
        Sort.ColumnName = column
        Sort.Order = order
        setSortData(Sort)
    }

    //PS_LMS_34 the fuction will be invoked based on the state variable updates
    async function handleExportLogs() {
        if (noDataFound == false) {
            setIsExcel(true)
        }

    }

    function closeBar()
    {
        SetHasMoreLoad(false)
        setFilter(0);
        setDownScroll(6)
        Sort = {
            ColumnName: "employeeName",
            Order: "asc"
        }
        setSortData(Sort)
    }

    // PS_LMS_35 Data needs to be formated according to the requirement

    const formatDataForExcel = (data) => {
        // Assuming data is an array of objects, format it as needed

        return data.map(item => ({
            EmployeeName: item.employee_name,
            CampaignName: item.campaign_name,
            EndDate: item.end_date.split('T')[0],
            TaskStatus:item.is_overdue && item.task_status != "Closed" ? "overDue" : item.task_status,
        }));

    };

    // PS_LMS_35 the excel data will be created and saved in local storage
    async function handleExportToExcel(data){
        try {
            let exportExcelData = data;
            let header = ["EmployeeName", "CampaignName", "EndDate","TaskStatus"];
            let tabName = "LearningSummary";
       
            let workbook = new Workbook();
            let worksheet = workbook.addWorksheet('LearningReport');
       
            worksheet.addRow(header);
       
            exportExcelData.forEach((dataItem) => {
              let rowData = header.map(column => dataItem[column]);
              worksheet.addRow(rowData);
            });
       
            let filename = `${tabName}_${new Date().toISOString().slice(0, 10).replace(/-/g, "")}.xlsx`;
            let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let buffer = await workbook.xlsx.writeBuffer();
            let dataBlob = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(dataBlob, filename);
            setIsExcel(false)
          } catch (error) {
            console.error("Error exporting to Excel:", error);
          }
    }

    return (
        <>
            {userRole == "User" ?
                <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3" style={{ position: 'relative' }}>
                    <Loader isLoading={isLoading} />
                    <div className="bg-white py-2 px-3 border-radius-8 ">
                        <div className="d-flex justify-content-between align-items-center mt-1">
                            <h3 className="font-14 font-medium primary-textcolor mb-0">Learning Summary</h3>
                            <div className="align-items-center d-flex gap-2">
                                <div className="d-flex">
                                    <select className="form-select custom-form-control py-2 font-12 font-regular" onChange={(e) => { OnHandleLmsDropDown(e.target.value) }}>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Today">Today</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="quarterly">Quaterly</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {noDataFound == false ? <div>
                            <div className="row mt-5 px-4">
                                <div className="col-md-6">
                                    <div className="user-risk-image align-items-center">
                                        <PieChart width={300} height={300}>
                                            <Pie
                                                data={allLmsTask}
                                                cx={100}
                                                cy={100}
                                                innerRadius={80}
                                                outerRadius={100}
                                                paddingAngle={0.5}
                                                strokeWidth={2} // Set a thinner stroke width
                                                cornerRadius={4}
                                                dataKey={"value"}
                                            >
                                                <Label value={"Total Course"} position="center" className="PieTotal"/>
                                                <Label value={totalSumLmsData} position="center" dy={25} />
                                                {allLmsTask.map((_, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center mt-5 justify-content-between cursor-pointer">
                                        <div className="align-items-center d-flex align-items-baseline">
                                            <span className="legends legend-open me-2 cursor-pointer" />
                                            <span className="font-12 grey-light text-decoration-none cursor-pointer">Yet
                                                to Start</span>
                                        </div>
                                        <div>
                                            <label className="font-12 secondary-textcolor font-medium cursor-pointer" style={allLmsTask[0].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[0].value != '0' ? OnHandleLmsNavigation("Yet to Start") :console.log("No records Found") }}>{allLmsTask[0].value}</label>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer">
                                        <div className="align-items-center d-flex align-items-baseline">
                                            <span className="legends legend-cat-2 me-2" />
                                            <span className="font-12 grey-light text-decoration-none cursor-pointer">In
                                                Progress</span>
                                        </div>
                                        <div>
                                            <label className="font-12 secondary-textcolor font-medium cursor-pointer" style={allLmsTask[1].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[1].value != '0' ? OnHandleLmsNavigation("In progress") : console.log("No records Found") }}>{allLmsTask[1].value}</label>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer">
                                        <div className="align-items-center d-flex align-items-baseline">
                                            <span className="legends legend-completed me-2" />
                                            <span className="font-12 grey-light text-decoration-none cursor-pointer">Completed</span>
                                        </div>
                                        <div>
                                            <label className="font-12 secondary-textcolor font-medium cursor-pointer" style={allLmsTask[2].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[2].value != '0' ? OnHandleLmsNavigation("Completed") : console.log("No records Found") }}>{allLmsTask[2].value}</label>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer">
                                        <div className="align-items-center d-flex align-items-baseline">
                                            <span className="legends legend-overdue me-2" />
                                            <span className="font-12 grey-light text-decoration-none cursor-pointer">Overdue</span>
                                        </div>
                                        <div>
                                            <label className="font-12 secondary-textcolor font-medium cursor-pointer" style={allLmsTask[3].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[3].value != '0' ? OnHandleLmsNavigation("Overdue") : console.log("No records Found")}}>{allLmsTask[3].value}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : <NoRecordFound data={"No Course Assigned"} />}
                    </div>
                </div> :

                <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3" style={{ position: 'relative' }}>
                    <Loader isLoading={isLoading} />
                    <div className="bg-white py-2 px-3 border-radius-8 rm-dashboard-card">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="font-14 font-medium primary-textcolor mb-0">Learning Summary</h3>
                            <div className="d-flex">
                                {userRole == "User" ? "" :
                                    <>
                                        <button className="advanced-filter-button ms-2 me-2 medium-text customflip-btn mb-2 mb-md-0" type="button" aria-expanded="false" style={!noDataFound? {cursor: "pointer",filter:"none"}:{cursor: "not-allowed" ,filter:"grayscale(80%)"}} onClick={handleExportLogs}>
                                            <img src="images/excel-icon.svg" alt="Filter-icon" />
                                        </button>
                                    </>
                                }
                                <select className="form-select custom-form-control py-2 font-12 font-regular" onChange={(e) => { OnHandleLmsDropDown(e.target.value) }}>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Today">Today</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="quarterly">Quaterly</option>
                                </select>
                            </div>
                        </div>
                        {userRole === "User" ? "" :
                            <div className="d-flex justify-content-between align-items-center ps-1 custom-tabs">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link px-0 active font-14 tab-style rounded-0 d-flex align-items-center justify-content-center" id="information-tab" data-bs-toggle="pill" data-bs-target="#information" type="button" role="tab" aria-controls="information" aria-selected="true" onClick={() => OnHandleLmsTabSwitch("Team's Learning")}>
                                            <span className="tab-img d-flex align-items-center"> <img src="images/my-team-active.svg" alt="filled-icon" className="me-2" />
                                                <img src="images/my-team.svg" alt="information-icon" className="me-2" /></span>Team’s Learning</button>
                                    </li>
                                    <li className="nav-item ms-4" role="presentation">
                                        <button className="nav-link px-0 font-14 tab-style bold-text rounded-0 d-flex align-items-center justify-content-center" id="permissions-tab" data-bs-toggle="pill" data-bs-target="#permissions" type="button" role="tab" aria-controls="permissions" aria-selected="false" tabIndex={-1} onClick={() => OnHandleLmsTabSwitch("My Learning")}>
                                            <span className="tab-img d-flex align-items-center"> <img src="images/media-roll-active.svg" alt="filled-icon" className="me-2" /> <img src="images/media-roll-inactive.svg" alt="permissions-icon" className="me-2" />
                                            </span>My Learning</button>
                                    </li>
                                    {userRole === "Manager" ? "" :
                                    <>
                                        <li className="nav-item ms-4" role="presentation">
                                            <button className="nav-link px-0 font-14 tab-style bold-text rounded-0 d-flex align-items-center justify-content-center" id="overall-tab" data-bs-toggle="pill" data-bs-target="#overall" type="button" role="tab" aria-controls="overall" aria-selected="false" tabIndex={-1} onClick={() => OnHandleLmsTabSwitch("Overall Learning")}>
                                                <span className="tab-img d-flex align-items-center"> <img src="images/media-roll-active.svg" alt="filled-icon" className="me-2" /> <img src="images/media-roll-inactive.svg" alt="overall-icon" className="me-2" />
                                                </span>Overall Learning</button>
                                        </li>
                                        <div className="tooltip-container">
                                            <img src="images/tooltip-icon.svg" style={{marginTop:10, marginLeft:5}}/>
                                            <div className="tooltip1"> View a complete list of all users within your organization who have been assigned to <br/>Learning Management System courses.</div>
                                            </div>
                                            </>}
                                </ul>
                            </div>}
                        {/* Donutdata */}
                        
                            {!noDataFound? <div className="tab-content" id="myTabContent">
                                {/* team's Tab start learning */}
                                <div className={`tab-pane fade ${userLmsSelection == "Team's Learning" ? "show active":""}`} id="information" role="tabpanel" aria-labelledby="information-tab">
                                    <div className="row mt-4 px-4">
                                        <div className="col-md-6">
                                            <div className="user-risk-image align-items-center">
                                                <ResponsiveContainer width="100%" height={200}>
                                                <PieChart >
                                                    <Pie
                                                        data={allLmsTask}
                                                        innerRadius={80}
                                                        outerRadius={100}
                                                        strokeWidth={2} // Set a thinner stroke width
                                                        cornerRadius={4}
                                                        paddingAngle={0.5}
                                                        dataKey={"value"}
                                                    >
                                                        <Label value={"Total Course"} position="center" className="PieTotal"/>
                                                        <Label value={totalSumLmsData} position="center" dy={25} />
                                                        {allLmsTask.map((_, index) => (
                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>

                                                </PieChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="col-md-12 my-2">

                                                <div className="d-flex align-items-center mt-5 justify-content-between cursor-pointer">
                                                    <div className="align-items-center d-flex align-items-baseline">
                                                        <span className="legends legend-cat-1 me-2 cursor-pointer" />
                                                        <span className="font-12 grey-light text-decoration-none cursor-pointer">Yet
                                                            to Start</span>
                                                    </div>

                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer"
                                                      data-bs-toggle={allLmsTask[0].value != 0 ?"modal":""} data-bs-target={allLmsTask[0].value != 0?"#staticBackdrop1":""} style={allLmsTask[0].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() =>{ allLmsTask[0].value != '0' ? onHandlePopUp(1, allLmsTask[0].value) : console.log("No records Found") }}>
                                                        {allLmsTask[0].value}
                                                    </label>


                                                </div>
                                                <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer">
                                                    <div className="align-items-center d-flex align-items-baseline">
                                                        <span className="legends legend-unassigned me-2" />
                                                        <span className="font-12 grey-light text-decoration-none cursor-pointer">In
                                                            Progress</span>
                                                    </div>
                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer"
                                                        data-bs-toggle={allLmsTask[1].value != 0 ?"modal":""} data-bs-target={allLmsTask[1].value != 0?"#staticBackdrop1":""} style={allLmsTask[1].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[1].value != "0" ? onHandlePopUp(2, allLmsTask[1].value): console.log("No records Found") }}>
                                                        {allLmsTask[1].value}
                                                    </label>


                                                </div>
                                                <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer">
                                                    <div className="align-items-center d-flex align-items-baseline">
                                                        <span className="legends legend-completed me-2" />
                                                        <span className="font-12 grey-light text-decoration-none cursor-pointer">Completed</span>
                                                    </div>

                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer"
                                                        data-bs-toggle={allLmsTask[2].value != 0 ?"modal":""} data-bs-target={allLmsTask[2].value != 0?"#staticBackdrop1":""} style={allLmsTask[2].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[2].value != '0' ? onHandlePopUp(4, allLmsTask[2].value) : console.log("No records Found") }}>
                                                        {allLmsTask[2].value}</label>

                                                </div>
                                                <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer">
                                                    <div className="align-items-center d-flex align-items-baseline">
                                                        <span className="legends legend-cat-6 me-2" />
                                                        <span className="font-12 grey-light text-decoration-none cursor-pointer">Overdue
                                                        </span>
                                                    </div>
                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" 
                                                        data-bs-toggle={allLmsTask[3].value != 0 ?"modal":""} data-bs-target={allLmsTask[3].value != 0?"#staticBackdrop1":""} style={allLmsTask[3].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[3].value != '0' ? onHandlePopUp(5, allLmsTask[3].value): console.log("No records Found") }}>
                                                        {allLmsTask[3].value}
                                                    </label>

                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                                
                                {/* team's Tab end learning */}

                                {/* My Learning Navigation */}
                                <div className={`tab-pane fade ${userLmsSelection == "My Learning" ? "show active":""}`} id="permissions" role="tabpanel" aria-labelledby="permissions-tab">
                                    <div className="row  mt-4 px-4">
                                        <div className="col-md-6">
                                            <div className="user-risk-image align-items-center">
                                                <ResponsiveContainer width="100%" height={200}>
                                                <PieChart>
                                                    <Pie
                                                        data={allLmsTask}
                                                        innerRadius={80}
                                                        outerRadius={100}
                                                        strokeWidth={2} // Set a thinner stroke width
                                                        cornerRadius={4}
                                                        paddingAngle={0.5}
                                                        dataKey={"value"}
                                                    >
                                                        <Label value={"Total Course"} position="center" className="PieTotal"/>
                                                        <Label value={totalSumLmsData} position="center" dy={25} />
                                                        {allLmsTask.map((_, index) => (
                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <div className="d-flex align-items-center mt-5 justify-content-between cursor-pointer">
                                                <div className="align-items-center d-flex align-items-baseline">
                                                    <span className="legends legend-cat-1 me-2 cursor-pointer" />
                                                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Yet
                                                        to Start</span>
                                                </div>
                                                <div>
                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" style={allLmsTask[0].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[0].value != '0' ? OnHandleLmsNavigation("Yet to Start") : console.log("No records Found") }}>{allLmsTask[0].value}</label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-2 justify-content-between cursor-pointer">
                                                <div className="align-items-center d-flex align-items-baseline">
                                                    <span className="legends legend-unassigned me-2" />
                                                    <span className="font-12 grey-light text-decoration-none cursor-pointer">In
                                                        Progress</span>
                                                </div>
                                                <div>
                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" style={allLmsTask[1].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[1].value != '0' ? OnHandleLmsNavigation("In progress") : console.log("No records Found") }}>{allLmsTask[1].value}</label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-2 justify-content-between cursor-pointer">
                                                <div className="align-items-center d-flex align-items-baseline">
                                                    <span className="legends legend-completed me-2" />
                                                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Completed</span>
                                                </div>
                                                <div>
                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" style={allLmsTask[2].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[2].value != '0' ? OnHandleLmsNavigation("Completed") : console.log("No records Found") }}>{allLmsTask[2].value}</label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-2 justify-content-between cursor-pointer">
                                                <div className="align-items-center d-flex align-items-baseline">
                                                    <span className="legends legend-cat-6 me-2" />
                                                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Overdue
                                                    </span>
                                                </div>
                                                <div>
                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" style={allLmsTask[3].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() => { allLmsTask[3].value != '0' ? OnHandleLmsNavigation("Overdue") : console.log("No records Found") }}>{allLmsTask[3].value}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* My Learning Navigation */}


                                {/* Over-all Campaign click widgets  */}
                                <div className={`tab-pane fade ${userLmsSelection == "Overall Learning" ? "show active":""}`} id="overall" role="tabpanel" aria-labelledby="overall-tab">
                                    <div className="row mt-4 px-4">
                                        <div className="col-md-6">
                                            <div className="user-risk-image align-items-center">
                                                <ResponsiveContainer width="100%" height={200}>
                                                <PieChart>
                                                    <Pie
                                                        data={allLmsTask}
                                                        cx="50%"
                                                        cy="50%"
                                                        innerRadius={80}
                                                        outerRadius={100}
                                                        paddingAngle={0.5}
                                                        strokeWidth={2} // Set a thinner stroke width
                                                        cornerRadius={4}
                                                        dataKey={"value"}
                                                    >
                                                        <Label value={"Total Course"} position="center" className="PieTotal"/>
                                                        <Label value={totalSumLmsData} position="center" dy={25} />
                                                        {allLmsTask.map((_, index) => (
                                                            <Cell key={`cell-${index}`} 
                                                            fill={COLORS[index % COLORS.length]}
                                                            stroke={"none"}  // Set stroke to none to blend the borders
                                                            strokeWidth={100} /> 
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                            </div>
                                        </div>
                                        {/* lengends  */}
                                        <div className="col-md-6">
                                            {/* <div className="col-md-12 my-2"> */}
                                                {/* yet to start */}
                                                <div className="d-flex align-items-center mt-5 justify-content-between cursor-pointer">
                                                    <div className="align-items-center d-flex align-items-baseline">
                                                        <span className="legends legend-cat-1 me-2 cursor-pointer" />
                                                        <span className="font-12 grey-light text-decoration-none cursor-pointer">Yet
                                                            to Start</span>
                                                    </div>


                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer"
                                                        data-bs-toggle={allLmsTask[0].value != 0 ?"modal":""} data-bs-target={allLmsTask[0].value != 0?"#staticBackdrop1":""} style={allLmsTask[0].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} onClick={() =>{ allLmsTask[0].value != '0' ? onHandlePopUp(1, allLmsTask[0].value) : console.log("No records Found") }}>
                                                        {allLmsTask[0].value}
                                                    </label>




                                                </div>
                                                {/* In progress */}
                                                <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer">
                                                    <div className="align-items-center d-flex align-items-baseline">
                                                        <span className="legends legend-unassigned me-2" />
                                                        <span className="font-12 grey-light text-decoration-none cursor-pointer">In
                                                            Progress</span>
                                                    </div>

                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" data-bs-toggle={allLmsTask[1].value != 0 ?"modal":""} style={allLmsTask[1].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} data-bs-target={allLmsTask[1].value != 0?"#staticBackdrop1":""} onClick={() =>{ allLmsTask[1].value != '0' ? onHandlePopUp(2, allLmsTask[1].value) : console.log("No records Found") }}>
                                                        {allLmsTask[1].value}</label>


                                                </div>
                                                {/* compeleted */}
                                                <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer">
                                                    <div className="align-items-center d-flex align-items-baseline">
                                                        <span className="legends legend-completed me-2" />
                                                        <span className="font-12 grey-light text-decoration-none cursor-pointer">Completed</span>
                                                    </div>
                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" data-bs-toggle={allLmsTask[2].value != 0 ?"modal":""} style={allLmsTask[2].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} data-bs-target={allLmsTask[2].value != 0?"#staticBackdrop1":""} onClick={() =>{ allLmsTask[2].value != '0' ? onHandlePopUp(4, allLmsTask[2].value) : console.log("No records Found") }}>
                                                        {allLmsTask[2].value}</label>


                                                </div>

                                                {/* overdue */}
                                                <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer">
                                                    <div className="align-items-center d-flex align-items-baseline">
                                                        <span className="legends legend-cat-6 me-2" />
                                                        <span className="font-12 grey-light text-decoration-none cursor-pointer">Overdue
                                                        </span>
                                                    </div>
                                                    <label className="font-12 secondary-textcolor font-medium cursor-pointer" data-bs-toggle={allLmsTask[3].value != 0 ?"modal":""} style={allLmsTask[3].value != '0'?{cursor:"pointer", filter:"none" , opacity:1}:{cursor:"not-allowed",filter:"grayscale(80%)",opacity:0.5}} data-bs-target={allLmsTask[3].value != 0?"#staticBackdrop1":""} onClick={() =>{ allLmsTask[3].value != '0' ? onHandlePopUp(5, allLmsTask[3].value) : console.log("No records Found") }}>
                                                        {allLmsTask[3].value}</label>

                                                </div>
{/* 
                                            </div> */}


                                        </div>
                                    </div>
                                </div>
                                {/* Over-all Campaign click widgets  */}

                                {/* Modal */}
                                <div className="col-md-12 my-2">



                                    <div className={`modal fade ${popUpData.length != 0 ? 'show' : ''}`} id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden={!filter} style={{ diaplay: popUpData.length != 0 ? 'block' : 'none' }}>
                                        <div className="modal-dialog modal-dialog-centered custom-popup-width">
                                            <div className="modal-content custom-popup-width popup-wrap-content">
                                                <div className="modal-header d-flex justify-content-between align-items-center pb-2 pt-3 pe-2 border-0">
                                                    <h5 className="modal-title font-14 font-medium primary-textcolor" id="staticBackdropLabel">
                                                        Team Learning Summary</h5>
                                                    <div className="d-flex flex-row align-items-center">
                                                        <button type="button" className="btn btn-close me-3" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeBar()} />
                                                    </div>
                                                </div>
                                                <div className="modal-body px-3 pb-4 pt-0">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive table-scroll" id="LMSStatus">
                                                            <InfiniteScroll
                                                                dataLength={popUpData.length}
                                                                next={() => {
                                                                    setDownScroll(DownScroll + 6)
                                                                }}
                                                                hasMore={hasMoreload}
                                                                scrollThreshold={0.9}
                                                                scrollableTarget="LMSStatus"
                                                            >

                                                                <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                                                                    <thead>
                                                                        <tr className="sticky-top">
                                                                            <th className="font-12 px-4 position-relative font-medium">Username
                                                                                <span className="position-absolute">
                                                                                    <a href="#" className="sort-up position-absolute ms-2"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => { onHandleSort("ASC", "employeeName") }} /></a> <a href="#" className="sort-down position-absolute ms-2"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => { onHandleSort("DESC", "employeeName") }} /></a>
                                                                                </span>
                                                                            </th>
                                                                            <th className="font-12 position-relative font-medium">Campaigns
                                                                                <span className="position-absolute">
                                                                                    <a href="#" className="sort-up position-absolute ms-2">
                                                                                        <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => { onHandleSort("ASC", "Campaigns") }} /></a> <a href="#" className="sort-down position-absolute ms-2"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => { onHandleSort("DESC", "Campaigns") }} /></a>
                                                                                </span>
                                                                            </th>
                                                                            <th className="font-12 position-relative font-medium">End Date
                                                                                <span className="position-absolute">
                                                                                    <a href="#" className="sort-up position-absolute ms-2"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => { onHandleSort("ASC", "End Date") }} /></a> <a href="#" className="sort-down position-absolute ms-2"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => { onHandleSort("DESC", "End Date") }} /></a>
                                                                                </span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {popUpData.map((value, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <img src={value.profileImages} className="table-prof" alt="profile" />
                                                                                        <p className="font-12 font-regular primary-textcolor mb-0 ms-2">
                                                                                            {value.employee_name} </p>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="font-12 font-regular primary-textcolor">{value.campaign_name}</td>
                                                                                <td className="font-12 font-regular primary-textcolor">{value.end_date}</td>
                                                                            </tr>
                                                                        ))}


                                                                    </tbody>
                                                                </table>
                                                            </InfiniteScroll>
                                                        </div>
                                                        <div className="mt-3">
                                                            <p className="font-12 grey-primary m-0">Showing <span className="font-medium primary-textcolor">{isPopUp}</span> items</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div> : <NoRecordFound data={"No Course Assigned"}/>}
                        {/* Donutdata */}

                    </div>

                </div>}





        </>
    )
}

export default LearningSummary