import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMediaDetails, getCourseByMediaType, postAddOnCourseDetails } from '../../Services/LMS/LmsService';
import { agovContext, convertDuration } from '../../Constant';
import AddToCoursePopUp from './AddToCoursePopUp';
import NoDataFound from './NoDataFound';
import NoRecordsPage from './NoRecordsPage';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import { Loader } from "../Loader/Loader";



//PS_AVG_FE_1.11 to 1.125 
const MediaLibraryGridView = () => {
    const navigate = useNavigate();
    const { userId, employeeName, roles } = useContext(agovContext);

    const requestDetails = {
        'userId': userId,
        'userName': employeeName,
        'searchMediaName': '',
        'limit': 8,
        'selectedCourse': ''
    };

    const toastMessage = {
        path: "/images/Success-icon.svg",
        header: "Added Successfully",
        message: "Successfully Added.",
        color: "success-toast"
    }

    //PS_AVG_FE_1.15
    const [mediaRequest, setMediaRequest] = useState(requestDetails);
    const [toastDetails, setToastDetails] = useState(toastMessage);
    const [mediaDetails, setMediaDetails] = useState([]);
    const [uploadFile, setUploadFile] = useState('');
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [CourseOptions, setCourseOptions] = useState([]);
    const [isPopVisible, setIsPopVisible] = useState(false);
    const [isLoadMoreVisible, setIsLoadMoreVisible] = useState(false);
    const [selectedVideoId, setselectedVideoId] = useState('');
    const [noDataFound, setNoDataFound] = useState(false);
    const [isNoRecordPageVisible, setIsNoRecordPageVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [mediaSearchValue, setMediaSearchValue] = useState("");
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [selectedVideoDuration, setSelectedVideoDuration] = useState('')
    //PS_AVG_FE_1.16
    useEffect(() => {
        if (roles?.includes("Super Admin") || roles?.includes("LMS Admin")) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
        fetchMediaDetails();
    }, [mediaRequest]);


    // PS_AVG_FE_1.21 to 1.40 fetch the media details from Backend 
    const fetchMediaDetails = async () => {
        setIsLoading(true);
        const result = await getMediaDetails(mediaRequest);
        const demo = {
            "statusCode": 200,
            "totalResultCount": 7,
            "response": []
        }
        if (result.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
        }
        setMediaDetails(result.response);
        if (result.response.length === 0) {
            if (mediaRequest.searchMediaName) {
                setNoDataFound(true)
            }
            else {
                setIsNoRecordPageVisible(true)
            }
        }
        else {
            setNoDataFound(false)
        }

        // To display the load more 
        if (result.totalResultCount > 8 && result.response.length != result.totalResultCount) {
            setTotalResultCount(result.totalResultCount);
            setIsLoadMoreVisible(true);
        }
        else {
            setTotalResultCount(result.totalResultCount);
            setIsLoadMoreVisible(false)
        }
        setIsLoading(false);
    };


    // PS_AVG_FE_1.62 to 1.76 To validate the uploaded files
    const handleUploadFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileSize = Math.round((file.size / (1024 * 1024)) * 100) / 100;

            const allowedTypes = ['video/mp4', 'application/pdf'];
            if (!allowedTypes.includes(file.type)) {
                setIsToastVisible(true)
                setToastDetails(prevState => ({
                    ...prevState,
                    path: "/images/error-toast-icon.svg",
                    header: "Upload Error",
                    message: "Invalid file format. Please upload a valid file (e.g. MP4 or pdf)",
                    color: "error-toast"
                }));
                return
            }
            if (file.type === 'application/pdf' && fileSize > 50) {
                setIsToastVisible(true)
                setToastDetails(prevState => ({
                    ...prevState,
                    path: "/images/error-toast-icon.svg",
                    header: "Upload Error",
                    message: "Please choose a file that is smaller than [50] MB.",
                    color: "error-toast"
                }));
                return
            }
            else if (file.type === 'video/mp4' && fileSize > 500) {
                setIsToastVisible(true)
                setToastDetails(prevState => ({
                    ...prevState,
                    path: "/images/error-toast-icon.svg",
                    header: "Upload Error",
                    message: "Please choose a file that is smaller than [500] MB.",
                    color: "error-toast"
                }));
                return
            }
            setUploadFile(file);
            navigate('/lmsmanagement/createmedia', { state: { value: { fileDetails: file } } });
        }
    };

    //PS_AVG_FE_1.77 to 1.81 handle clicks on edit icon from media library
    const handleMediaEdit = async (videoId) => {
        navigate('/lmsmanagement/createmedia', { state: { value: { videoId: videoId } } });
    };

    const handleOnKeyDownSearch = (event) => {
        setMediaSearchValue(event.target.value.trim() === "" ? "" : event.target.value);
        if (event.key === "Enter") {
            setMediaRequest({
                ...mediaRequest,
                searchMediaName: event.target.value.trim() === "" ? "" : event.target.value,
                limit: 8
            });
        }
    };

    const handleOnclickSearch = () => {
        setMediaRequest({
            ...mediaRequest,
            searchMediaName: mediaSearchValue,
            limit: 8
        });
    };

    const handleLoadMore = () => {
        setMediaRequest(prevState => ({ ...prevState, limit: prevState.limit + 8 }));
    };


    // PS_AVG_FE_1.82 to 1.101 when user clicks on add to course option
    const handleAddToCourse = async (courseTypeId, videoId, duration) => {
        setIsLoading(true);
        setselectedVideoId(videoId);
        setSelectedVideoDuration(duration);
        const request = {
            courseTypeId: courseTypeId,
            videoId: videoId,
        };
        const result = await getCourseByMediaType(request);

        if (result.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }

        setCourseOptions(result.response.map((course) => {
            return {
                label: course.courseName,
                value: course.courseId,
                duration: course.courseDuration
            }
        }));
        setIsLoading(false);
        setIsPopVisible(true);
    };

    // PS_AVG_FE_1.106 to 1.125
    const handleClickOnAddButton = async (data) => {
        try {
            setIsLoading(true);
            const request = {
                "userName": employeeName,
                "videoId": selectedVideoId,
                "selectedCourseIds": data
            }
            const response = await postAddOnCourseDetails(request);
            if (response.statusCode !== 200) {
                setIsLoading(false);
                navigate('/error');
                return;
            }
            setIsPopVisible(false);
            setIsToastVisible(true);
            setIsLoading(false);
        }
        catch {
            navigate('/error');
        }
    };

    const handleClickCancelButton = async () => {
        setIsPopVisible(false)
    }


    //PS_AVG_FE_1.46 to bind the media details under media library tab
    const generateMediaDetails = () => {
        return (mediaDetails?.map((item, index) => (
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5">
                <div className="card border-0 h-100">
                    <span className="card-bg-image d-flex mb-5">
                        <img src={item.videoThumbnail} alt="lms-learning-image" className="card-image-cover w-100" />
                    </span>
                    <span className="card-body text-decoration-none learning-card-pos p-0  w-100 ">
                        <div className="mx-2 learning-card">
                            <div className="d-flex w-100 justify-content-between mb-2">
                                <h5 className="font-14 mb-0 font-medium primary-textcolor content-header-hight me-2 pointer" onClick={() => navigate('/lmsmanagement/viewmedia', { state: { value: { courseId: "", videoId: item.videoId, campaignId: "", taskId: "" } } })}>{item.videoName}</h5>
                                <div className="btn-group  ">
                                    <button className="btn border-0 p-0 align-top" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="/images/kebab-icon.svg" alt="kebab-icon" className=" align-top pt-2" />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end custom-dropdown-sty  border-0 p-2">
                                        <li onClick={() => handleMediaEdit(item.videoId)}>
                                            <span className="dropdown-item  font-14 d-flex align-items-center font-regular primary-textcolor custom-dropdown-li py-2 px-2">
                                                <img src="/images/edit-icon.svg" alt="edit-icon" className="me-2" />Edit
                                            </span>
                                        </li>
                                        <li onClick={() => handleAddToCourse(item.courseTypeId, item.videoId, item.totalDuration)}>
                                            <span className="dropdown-item py-2 font-14 d-flex align-items-center font-regular primary-textcolor px-2 custom-dropdown-li">
                                                <img src="/images/addto-course-icon.svg" alt="addto-course-icon" className="me-2" /> Add to Course
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p className="font-12 font-regular mb-0 grey-light  mb-2 linehig description">{item.videoDescription}</p>
                            <div className="mt-auto">
                                <span className="d-flex align-items-center">

                                    {item.isDocument == 1 ?
                                        <span>
                                            <img src="/images/card-pdf-icon.svg" alt="pdf-icon" />
                                            <span className="ms-1 grey-primary font-12 font-regular">PDF</span>
                                        </span>
                                        :
                                        <span>
                                            <img src="/images/clock-icon.svg" alt="clock-icon" />
                                            <span className="ms-1 grey-primary font-12 font-regular">{convertDuration(item.totalDuration)}</span>
                                        </span>
                                    }
                                </span>
                            </div>
                        </div>
                    </span>
                </div >
            </div >

        )));
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            {!isNoRecordPageVisible ? <div className="container-fluid mt-4 mt-custom">
                <div className="col-md-12 px-5">
                    <div className="d-flex justify-content-between mt-4">
                        <h1 className="font-bold font-24 primary-textcolor">
                            Learning Management System
                        </h1>
                    </div>
                    {/* tabs starts here */}
                    <div className="d-md-block d-lg-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
                        <ul className="nav mb-lg-0 mb-3 nav-pills" id="pills-tab" role="tablist">
                        {isAdmin && (
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0  font-14 tab-style bold-text rounded-0" id="Overview-tab" data-bs-toggle="pill" data-bs-target="#Overview" type="button" role="tab" aria-controls="Overview" aria-selected="true"onClick={() => isAdmin ? navigate('/lmsmanagement') : navigate('/lmsmanagement/myLearnings')}>
                                        <span className="tab-img"> <img src="images/lms-overview-active.svg" alt="filled-icon" className="me-2" />
                                            <img src="/images/lms-overview.svg" alt="Overview-icon" className="me-2" /></span>Overview</button>
                                </li>
                            )}
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style rounded-0" id="MyLearning-tab" data-bs-toggle="pill" data-bs-target="#MyLearning" type="button" role="tab" aria-controls="MyLearning" aria-selected="true" onClick={() =>  navigate('/lmsmanagement/myLearnings')}>
                                    <span className="tab-img"> <img src="/images/my-learning-active.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/my-learning-inactive.svg" alt="MyLearning-icon" className="me-2" /></span>My Learning</button>
                            </li>

                            {isAdmin && (<li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="Courses-tab" data-bs-toggle="pill" data-bs-target="#Courses" type="button" role="tab" aria-controls="Courses" aria-selected="false" tabIndex={-1} onClick={() => navigate('/lmsmanagement/course')}>
                                    <span className="tab-img"> <img src="/images/course-active.svg" alt="filled-icon" className="me-2" /> <img src="/images/course-inactive.svg" alt="Courses-icon" className="me-2" />
                                    </span>Courses</button>
                            </li>)}

                            {isAdmin && (<li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="Campaigns-tab" data-bs-toggle="pill" data-bs-target="#Campaigns" type="button" role="tab" aria-controls="Campaigns" aria-selected="false" tabIndex={-1} onClick={() => navigate('/lmsmanagement/campaign')} >
                                    <span className="tab-img">
                                        <img src="/images/campaign-active.svg" alt="Campaigns-icon" className="me-2" />
                                        <img src="/images/campaign-inactive.svg" alt="filled-icon" className="me-2" />
                                        Campaigns
                                    </span>
                                </button>
                            </li>)}

                            {isAdmin && (<li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0 active" id="MediaLibrary-tab" data-bs-toggle="pill" data-bs-target="#MediaLibrary" type="button" role="tab" aria-controls="MediaLibrary" aria-selected="true" tabIndex={-1}>
                                    <span className="tab-img">
                                        <img src="/images/media-lib-active.svg" alt="MediaLibrary-icon" className="me-2" />
                                        <img src="/images/media-lib-inactive.svg" alt="filled-icon" className="me-2" />
                                        Media Library
                                    </span>
                                </button>
                            </li>)}
                        </ul>

                        {/* Media Library tab filter starts here */}
                        <div className="d-flex">
                            <div className="input-group flex-nowrap search-group me-3">
                                <input type="text" className="form-control search-align" placeholder="Search" aria-label="Username" aria-describedby="addon-wrapping"
                                    value={mediaSearchValue}
                                    onChange={(e) => setMediaSearchValue(e.target.value)}
                                    onKeyDownCapture={(e) => handleOnKeyDownSearch(e)}
                                />
                                <button className="input-group-text btn btn-search-custom" id="addon-wrapping"><img src="/images/search-icon.svg" alt="search-icon" onClick={() => handleOnclickSearch()} /></button>
                            </div>
                            <label className="dark-btn font-14 font-medium py-2 text-nowrap align-items-center pointer">
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    accept=".pdf, .mp4"
                                    onChange={handleUploadFile}
                                />
                                <span><img src="/images/create-course-icon.svg" alt="create-course-icon" className="me-1" /></span>
                                Upload
                            </label>
                        </div>

                        {/* Media Library tab filter ends here */}
                    </div>
                    {/* tabs ends here */}
                    {noDataFound ? (
                        <NoDataFound />
                    ) : (
                        <div className="row py-3 position-relative">
                            <div className="tab-content" id="pills-tabContent">
                                {/* Media Library tab starts here */}
                                <div className="tab-pane fade active show" id="MediaLibrary" role="tabpanel" aria-labelledby="MediaLibrary-tab">
                                    <div className="row row-cols-1 g-4">
                                        {generateMediaDetails()}
                                    </div>
                                </div>
                                <p class="font-12 mt-2 grey-primary">Showing <span class="font-medium primary-textcolor"> {mediaDetails.length} </span>out of <span className="font-medium primary-textcolor">{totalResultCount}</span><span class="font-medium primary-textcolor"></span> items</p>
                            </div>
                        </div>
                    )}
                    {/* Media Library tab end */}
                    <div className="text-center mt-5 mb-4">
                        {isLoadMoreVisible && (
                            <button className="primary-btn font-medium" onClick={() => handleLoadMore()}>Load More</button>
                        )}
                    </div>
                </div>
            </div> : <NoRecordsPage
                message={"No videos have been added. Please upload videos to create a campaign."}
                isButtonVisible={true}
                buttonName={"Upload"}
                handleButtonSubmit={handleUploadFile}
                isMyLearningTab={false}
                isCourseTab={false}
                isCampaignTab={false}
                isMediaLibraryTab={true}
            />}

            {/* <AddToCoursePopUp /> */}
            <AddToCoursePopUp
                isVisible={isPopVisible}
                courses={CourseOptions}
                onSave={handleClickOnAddButton}
                onClose={handleClickCancelButton}
                selectedVideoDuration={selectedVideoDuration}
            />
            {isToastVisible &&
                <ToastMessageComponent
                    toastResponse={toastDetails}
                    setIsShowToastMessage={setIsToastVisible} >
                </ToastMessageComponent>}

        </>

    );
};

export default MediaLibraryGridView;