import React, { useState, useEffect, useContext } from 'react';
import { getRiskSecurityRecommendations, getRiskTilesInfo, getRiskTaskTrends, getHeatMapInfo, getRiskTrends, getRiskEvents, getRiskDetailsByFilter,getExternalDomain,getExportExternalDomain } from '../../Services/Risk/RiskService';
import { useNavigate } from 'react-router-dom';
import AddZero from './AddZeroComponent';
import Heatmap from "react-heatmap-grid";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Label
} from "recharts";
import LineEchartGraph from './riskLineTrends';
import { PieChart, Pie, Cell } from 'recharts';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoDataFound from '../LMS/NoDataFound';
import { Loader } from '../Loader/Loader';
import { RiskLoader } from '../Risk/riskLoader';
import { agovContext,abbreviateNumber } from '../../Constant';
import RiskNoDataFound from './RiskNoDataFound';
import { Workbook } from 'exceljs';
import FileSaver from 'file-saver';
import PopupLoader from '../Risk/securityPopUp'






const RiskOverview = () => {
    //variables declaration
    const navigate = useNavigate();
    const { roles,userId } = useContext(agovContext);
    /**PS_HM_07 - Heat Map variables declaration */
    const impactLevels = ["High", "Medium", "Low"];
    const likelihoodLevels = ["Low", "Medium", "High"];
    const [riskHeatMapInfo, setRiskHeatMapInfo] = useState([]);
    const [heatTrendDuration, setHeatTrendDuration] = useState('Today');
    const taskTrendDurationArr = ['Today', 'Weekly', 'Monthly'];
    const [riskTaskTrend, setRiskTaskTrend] = useState([]);
    /**PS_TT_04 - Task Trend State Variables Declaration */
    const [taskTrendDuration, setTaskTrendDuration] = useState('Today');
    const [transFormedRiskTaskTrend, setTransformedRiskTaskTrend] = useState([]);
    const [totalTrendTaskCount, setTotalTrendTaskCount] = useState(0);
    /**PS_RT_07 - Risk Trend State Variable Declared */
    const [riskEventTrend, setRiskEventTrendInfo] = useState([]);
    const [aggregatedData, setAggregatedData] = useState([]);
    /**PS_PE_07 Declaration of pieChart State variables */
    const [pieTrendDuration, setPieTrendDuration] = useState('Today');
    const [selectedEventType, setSelectedEventType] = useState('userRisk');
    const [riskSubEventInfo, setRiskSubEventInfo] = useState([]);
    const [selectedSubEventType, setSelectedSubEventType] = useState('');
    const [selectedSubEventName, setSelectedSubEventName] = useState('')
    const [showEventPopup, setShowEventPopup] = useState(false);

    /**PS_PE_07 Declaration of popup State variables */
    const [eventPopUpData, setEventPopupData] = useState([]);
    const [eventTotalCount, setEventTotalCount] = useState(0);
    const [popUpLimit, setPopupLimit] = useState(10);
    const [isLoading, setIsLoading] = useState(false)
    const [heatisLoading, setHeatIsLoading] = useState(false)
    const [taskTrendsisLoading, setTaskTrendsIsLoading] = useState(false)
    const [riskEventisLoading, setRiskEventIsLoading] = useState(false)
    const [byDetailsisLoading, setByDetailsIsLoading] = useState(false)
    const [eventPage, setEventPage] = useState(1);
    const [hasMoreEvent, setHasMoreEvent] = useState(false);

    //security recommndations PS_SP_07 state variables initializTION
    let PopUpsort={
        column:"external_activity",
        order:"DESC"
    }
    const [ispopUpOpen,setIspopUpOpen] = useState(0)
    const [popUpData, setPopupData] = useState([]);
    const [totalCountPopUp, setTotalCountPopUp] = useState(0);
    const [sort,setSort]=useState(PopUpsort)
    const [eventPopupPage, setEventPopUpPage] = useState(7);
    const [popupIsLoading, setPopupIsLoading] = useState(false)
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    //PS_SP_08 USE EFFECT TO INVOKE THE FUNCTION WHEN THE SATE CHANGES

    useEffect(() => {
        if(ispopUpOpen)
            {
                InitialPopUpLoad();
            }
        
      }, [sort, eventPopupPage]);


    /**PS_SR_04 - SecurityRecommendations State Variables Declaration */
    const [riskSecurityInfo, setRiskSecuritytInfo] = useState({
        isCompliantCount: 0,
        noMfaCount: 0,
        unchangedPasswordCount: 0,
        nonComplianceCount: 0,
        overDueCount: 0,
        externalDomainBlocks: 0
    });
    const [showSecurityRecommendationPopup, setShowRecommendationPopup] = useState(false);
    const [riskEventsInfo, setRiskEventInfo] = useState({ eventsRiskCounts: [], riskTypeCount: [] })
    const [tilesDetails, setTilesDetails] = useState({
        userCounts: null,
        networkCount: null,
        deviceStat: null,
        applicationStats: null
    });
    const [sortKey, setSortKey] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    

    //dropdown changes
    const durationDropdown = taskTrendDurationArr.map((option) => (
        <option key={option} value={option}>
            {option}
        </option>
    ));

    /**PS_HM_50 - Function to handle the heat Map Duration */
    const handleHeatDurationChange = (e) => {
        setHeatTrendDuration(e.target.value);
    };

    /**PS_TT_28 the task duration gets updated */
    const handleTaskDropdownChange = (e) => {
        setTaskTrendDuration(e.target.value)
    }

    /**PS_PE_39 the pie duration gets updated */
    const handlePieDropdownChange = (e) => {
        setPieTrendDuration(e.target.value)
    }

    /**PS_SG_35 Function to handle the pie Count Click */
    const handleRiskEventClick = (event, eventName) => {
        setSelectedSubEventType(event);
        setSelectedSubEventName(eventName)
    };

    /**PS_PE_09 the popup gets triggered */
    const handleExpandEvent = () => {
        setShowEventPopup(true);
        switch (selectedEventType) {
            case 'emailRisk':
                setSortKey('employee_name');
                break;
            case 'deviceRisk':
                setSortKey('di.device_name');
                break;
            case 'appRisk':
                setSortKey('applicationName');
                break;
            default:
                setSortKey('Name');
                break;
        }
        fetchRiskSubEventGrid(true);

    };





    // mockValues 


    const heatmockResponse = [
        { combination: 'High Impact - Low Likelihood', count_of_cases: 10 },
        { combination: 'High Impact - Medium Likelihood', count_of_cases: 15 },
        { combination: 'High Impact - High Likelihood', count_of_cases: 20 },
        { combination: 'Medium Impact - Low Likelihood', count_of_cases: 8 },
        { combination: 'Medium Impact - Medium Likelihood', count_of_cases: 12 },
        { combination: 'Medium Impact - High Likelihood', count_of_cases: 18 },
        { combination: 'Low Impact - Low Likelihood', count_of_cases: 5 },
        { combination: 'Low Impact - Medium Likelihood', count_of_cases: 7 },
        { combination: 'Low Impact - High Likelihood', count_of_cases: 9 }
    ];

    const data = [
        { result: 'High', month: '2024-06', severityCount: 40 },
        { result: 'High', month: '2024-03', severityCount: 2 },
        { result: 'High', month: '2024-12', severityCount: 42 },
        { result: 'Medium', month: '2024-06', severityCount: 16 },
        { result: 'Medium', month: '2024-03', severityCount: 10 },
        { result: 'Medium', month: '2024-12', severityCount: 58 },
        { result: 'Low', month: '2024-06', severityCount: 16 },
        { result: 'Low', month: '2024-03', severityCount: 16 },
        { result: 'Low', month: '2024-12', severityCount: 126 },
        { result: 'Low', month: '2024-11', severityCount: 126 }
    ];
    const responseData2 = {
        "High": {
            "total_task_count": 10,
            "completed_count": 20,
            "in_progress_count": 15,
            "unassigned_count": 17,
            "awaiting_confirmation_count": 10
        },
        "Medium": {
            "total_task_count": 51,
            "completed_count": 45,
            "in_progress_count": 48,
            "unassigned_count": 14,
            "awaiting_confirmation_count": 54
        },
        "Low": {
            "total_task_count": 14,
            "completed_count": 10,
            "in_progress_count": 11,
            "unassigned_count": 14,
            "awaiting_confirmation_count": 11
        },
        "total_task_count": 10

    };

    const userSubGridData =
        [
            {
                "Name": "Trisha K",
                "profile_url": null,
                "result": "High",
                "risk_count": 1
            },
            {
                "Name": "Trisha K",
                "profile_url": null,
                "result": "Medium",
                "risk_count": 1
            },
            {
                "Name": "Rohan Rambabu",
                "profile_url": null,
                "result": "Medium",
                "risk_count": 1
            },
            {
                "Name": "Rohan Rambabu",
                "profile_url": null,
                "result": "Low",
                "risk_count": 7
            },
            {
                "Name": "tokyo k",
                "profile_url": null,
                "result": "High",
                "risk_count": 1
            }
        ]

    const deviceSubGridData = [{
        "employee_name": "John Doe",
        "device_name": "Mobile Phone",
        "is_active": true,
        "profile_url": "https://example.com/johndoe"
    },
    {
        "employee_name": "Alice Smith",
        "device_name": "Laptop",
        "is_active": true,
        "profile_url": "https://example.com/alicesmith"
    },
    {
        "employee_name": "Bob Johnson",
        "device_name": "Tablet",
        "is_active": false,
        "profile_url": "https://example.com/bobjohnson"
    }
    ]

    const emailSUbGridData = [{
        "employee_name": "John Doe",
        "primary_email": "john@example.com",
        "risk_count": 3,
        "profileUrl": "https://example.com/johndoe"
    },
    {
        "employee_name": "Alice Smith",
        "primary_email": "alice@example.com",
        "risk_count": 1,
        "profileUrl": "https://example.com/alicesmith"
    },
    {
        "employee_name": "Bob Johnson",
        "primary_email": "bob@example.com",
        "risk_count": 0,
        "profileUrl": "https://example.com/bobjohnson"
    }
    ]

    const applicationSubGridData = [{
        "applicationName": "Application1",
        "appRiskCount": 5,
        "severity": "High"
    },
    {
        "applicationName": "Application2",
        "appRiskCount": 3,
        "severity": "Medium"
    },
    {
        "applicationName": "Application3",
        "appRiskCount": 2,
        "severity": "Low"
    },
    {
        "applicationName": "Application4",
        "appRiskCount": 2,
        "severity": "Low"
    },
    {
        "applicationName": "Application5",
        "appRiskCount": 2,
        "severity": "Low"
    }
    ]

    //graph Data Formatting



    const getStartAndEndDates = (input) => {
        // Extract month and year from the input
        /**PS_RT_45 */
        const [month, year] = input.split(", ");
        const monthIndex = new Date(Date.parse(month + " 1, 2000")).getMonth(); // Get the month index (0-indexed)

        // Create start and end dates
        /**PS_RT_47 */
        const startOfMonth = new Date(year, monthIndex, 1);
        const endOfMonth = new Date(year, monthIndex + 1, 0);

        // Check if the provided input is a full month or a partial month
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        let fromDate, toDate;


        /**PS_RT_48 */
        if (currentMonth === monthIndex && currentYear.toString() === year) {
            // If the provided input is the current month, set fromDate to the start of the month
            fromDate = new Date(year, monthIndex, 1).toISOString();
            toDate = new Date().toISOString(); // End date is the current date
        } else {
            // If the provided input is not the current month, set fromDate to the start of the month
            fromDate = startOfMonth.toISOString();
            toDate = new Date(endOfMonth.getFullYear(), endOfMonth.getMonth(), endOfMonth.getDate(), 23, 59, 59, 999).toISOString();
        }
        /**PS_RT_49 */
        return { fromDate, toDate };
    }

    /**PS_SG_41 - Function to return the generated from Date and toDate  */
    const updateDates = (option) => {
        const currentDate = new Date();
        let fromDate, toDate;

        /**PS_SG_42 - Using switchCase we generate the from Date and toDate clauses using built in date functions */
        switch (option) {
            /**PS_SG_43 */
            case 'Today':
                fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999).toISOString();
                toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999).toISOString();
                break;
            /**PS_SG_44 */
            case 'Weekly':
                const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1));
                const endOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6));
                fromDate = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate()).toISOString();
                toDate = new Date(endOfWeek.getFullYear(), endOfWeek.getMonth(), endOfWeek.getDate(), 23, 59, 59, 999).toISOString();
                break;
            /**PS_SG_45 */
            case 'Monthly':
                function formatDateToISOString(date) {
                    const offset = date.getTimezoneOffset();
                    const offsetHours = Math.abs(Math.floor(offset / 60)).toString().padStart(2, '0');
                    const offsetMinutes = Math.abs(offset % 60).toString().padStart(2, '0');
                    const offsetSign = offset > 0 ? '-' : '+';
                    
                    return date.getFullYear() +
                        '-' + (date.getMonth() + 1).toString().padStart(2, '0') +
                        '-' + date.getDate().toString().padStart(2, '0') +
                        'T' + date.getHours().toString().padStart(2, '0') +
                        ':' + date.getMinutes().toString().padStart(2, '0') +
                        ':' + date.getSeconds().toString().padStart(2, '0') +
                        '.' + date.getMilliseconds().toString().padStart(3, '0') +
                        offsetSign + offsetHours + ':' + offsetMinutes;
                }
                const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                fromDate = formatDateToISOString(startOfMonth);
                toDate = new Date(endOfMonth.getFullYear(), endOfMonth.getMonth(), endOfMonth.getDate(), 23, 59, 59, 999).toISOString();
                break;
            default:
                break;
        }
        /**PS_SG_46 - return the generated fromDate and toDate */
        return { fromDate, toDate };
    };




    /** Define colors for each risk type
         /**PS_PE_36 */
    const setPieEventColour = (events) => {
        const eventColors = {};
        let colorMapping = {}
        //        // Assign colors based on selected risk type

        switch (selectedEventType) {
            case 'userRisk':
                colorMapping = {
                    "ActblaclistedIp": '#D58EF4',
                    "mfaFail": '#8CD8E8',
                    "MultiFailLogin": '#ADADE8',
                    "multipleGeoLocation": '#D176B8',
                    "overdueCampaign": '#FACC9E',
                    "loginUnusualHours": '#F06E9B',
                    "untypicalTravel": '#AB83D6'
                };
                break;
            case 'appRisk':
                colorMapping = {
                    "highVolAppUsage": '#ADADE8',
                    "massDelete": '#8CD8E8',
                    "massdownload": '#D58EF4'
                };
                break;

            case 'emailRisk':
                colorMapping = {
                    "externalEmailShare": '#78DC8E',
                    "Phishining": '#D58EF4',
                    "malware": '#8CD8E8',
                    "spam": '#ADADE8',
                    "susUrlClick": '#F06E9B'
                };
                break;

            case 'deviceRisk':
                colorMapping = {
                    "antiMalwareDisable": '#F06E9B',
                    "antiVirusDisable": '#8CD8E8',
                    "bitLockerDisable": '#D58EF4',
                    "realTimeNetworkProtectionDisable": '#FACC9E',
                    "rootedOrJailbroken": '#ADADE8'
                };
                break;

            default:
                break;

        }

        if (events.length > 0) {
            events?.forEach((event) => {
                const eventType = event.event;
                eventColors[eventType] = colorMapping[eventType] || '#F06E9B' // Assign a random color if no mapping found
            }
            )
        }


        return eventColors;
    };



    /**PS_SR_26 - Toggling the SecurityRecommendations PopUp Visibility State */
    const expandToggleSecurityRecommendations = () => {
        setShowRecommendationPopup(true);
    };


    //api Calls

    /**PS_TI_08 - Creating a async function for fetching the tiles Data  */
    const getRiskTilesData = async () => {
        let body = {
            "userRoles": roles
        }
        try {
            const response = await getRiskTilesInfo(body);
            if (response.statusCode === 200) {
                /**PS_TI_22 - Setting the tilesData */
                setTilesDetails({
                    userCounts: response.responseData.userCounts || 0,
                    networkCount: response.responseData.networkCount || 0,
                    deviceStat: response.responseData.deviceStat || 0,
                    applicationStats: response.responseData.applicationStats || 0
                });

            }
        } catch (error) {
            console.error('Error fetching data:', error);
            handleApiError('An error occurred while fetching data.');
        }
    };

    /**PS_SR_08 Declaring a async function to fetch the securityInfo */
    const getSecurityInfo = async () => {
        let body = {
            "userRoles": roles
        }
        try {
            const response = await getRiskSecurityRecommendations(body);
            /**PS_SR_22 && PS_SR_23- Storing the response and setting the counts */
            if (response && response.statusCode === 200) {
                setRiskSecuritytInfo(response.responseData.RecommendationCount || []);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    /**PS_TT_08 - declaring the async function to fetch getRiskTaskTrendInfo */
    const getRiskTaskTrendsInfo = async () => {
        setTaskTrendsIsLoading(true)
        try {
            let body = {
                duration: taskTrendDuration,
                userRoles: roles

            }
            /**PS_TT_23 Storing the response and setting the respective state variables */
            const response = await getRiskTaskTrends(body);
            if (response && response.statusCode === 200) {
                setRiskTaskTrend(response.responseData || []);
                setTotalTrendTaskCount(response.responseData.total_task_count || 0)
                //use responseData2 for mock             

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            setTaskTrendsIsLoading(false)
        }
    };

    /**PS_HM_11 - function to fetch the risk Heat Map Info */
    const getRiskHeatMapDetails = async () => {
        setHeatIsLoading(true)
        try {
            let body = {
                duration: heatTrendDuration,
                userRoles: roles

            }
            //PS_HM_32 - Check the status and set the data in states
            const response = await getHeatMapInfo(body);
            if (response && response.statusCode === 200) {
                //use heatmockResponse for mock
                setRiskHeatMapInfo(response.responseData || []);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            setHeatIsLoading(false)
        }
    };

    /**PS_RT_11 - async function to fetch the getRiskTrends Data */
    const getRiskTrendDetails = async () => {
        try {
            let body = {
                userRoles: roles
            }
            /**PS_RT_28 - The responseData is held by response Variable */
            const response = await getRiskTrends(body);
            if (response && response.statusCode === 200) {
                setRiskEventTrendInfo(response.responseData || []);
                // Extract unique months

                if (response.responseData.length > 0) {

                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    /**PS_PE_12 - Invoking the async function to fetch riskEventsInfo */
    const getRiskEventsInfo = async () => {
        setRiskEventIsLoading(true)
        try {
            let body = {
                duration: pieTrendDuration,
                riskType: selectedEventType,
                userRoles: roles
            }
            /**PS_PE_29 - Storing the response */
            const response = await getRiskEvents(body);
            /**PS_PE_31 setting the value in state variable after checking for status */
            if (response && response.statusCode === 200) {
                setRiskEventInfo(response.responseData || []);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            setRiskEventIsLoading(false)
        }
    };


    /**PS_SG_14 - Declare a async function as fetchRiskSubEventGrid */
    const fetchRiskSubEventGrid = async (riskPopup) => {
        setByDetailsIsLoading(true)
        try {
            let body = {
                userRoles: roles,
                duration: pieTrendDuration,
                eventType: selectedEventType,
                eventSubType: selectedSubEventType,
                riskPopup: riskPopup || showEventPopup,
                page: eventPage,
                limit: popUpLimit,
                sortOrder: sortOrder,
                sortKey: sortKey,
            }
            /**PS_SG_28 - Storing the response */
            const response = await getRiskDetailsByFilter(body);
            if (response && response.statusCode === 200) {
                /**PS_SG_29 Traversing the statusCode and setting the Value in state variables */
                if (riskPopup || showEventPopup) {
                    /**PS_PE_32 Setting the popUpData and it's totalRecord Count */
                    /**PS_PE_33 */
                    setEventPopupData(response.responseData.eventData || []);
                    setEventTotalCount(response.responseData.totalCount || 0);
                    /**PS_PE_35 */
                    if (response.responseData.totalCount > eventPage * 10) {
                        setHasMoreEvent(true); // There's more data
                    } else {
                        setHasMoreEvent(false); // No more data
                    }
                }
                else {
                    setRiskSubEventInfo(response.responseData || [])
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            setByDetailsIsLoading(false)
        }
    };


    useEffect(() => {
        initialLoad()
    }, []);

    const initialLoad = async () => {
        setIsInitialLoading(true)
        setIsLoading(true)
        await getRiskTilesData();
        await getSecurityInfo();
        await getRiskTaskTrendsInfo();
        await getRiskHeatMapDetails();
        await getRiskTrendDetails();
        await getRiskEventsInfo();
        await fetchRiskSubEventGrid()
        setIsLoading(false)
        setIsInitialLoading(false)
    }



    //dependency useEffects

    /**PS_RT_28 - The responseData is formatted by mapping out the unique Months */
    useEffect(() => {
        /**PS_RT_29 creation of uniqueMonths and removing duplicates */
        const uniqueMonths = [...new Set(riskEventTrend.map(item => item.month))];
        /**PS_RT_30 - Sorting the months in order */
        uniqueMonths.sort();
        /**PS_RT_32 Setting the aggregated Data */
        const aggregatingData = uniqueMonths.map(month => {
            const highItems = riskEventTrend.filter(item => item.month === month && item.result === "High");
            const mediumItems = riskEventTrend.filter(item => item.month === month && item.result === "Medium");
            const lowItems = riskEventTrend.filter(item => item.month === month && item.result === "Low");

            const totalHighCount = highItems.reduce((acc, cur) => acc + cur.severityCount, 0);
            const totalMediumCount = mediumItems.reduce((acc, cur) => acc + cur.severityCount, 0);
            const totalLowCount = lowItems.reduce((acc, cur) => acc + cur.severityCount, 0);


            return { month, High: totalHighCount, Medium: totalMediumCount, Low: totalLowCount };
        });
        setAggregatedData(aggregatingData)
    }, [riskEventTrend])

    /**PS_HM_50 To fetch the heat Map details based on the selected Duration */
    useEffect(() => {
        if(!isInitialLoading){
        getRiskHeatMapDetails();
        }
    }, [heatTrendDuration])

    /**PS_TT_28 the task duration gets updated */
    useEffect(() => {
        if(!isInitialLoading){
        getRiskTaskTrendsInfo();
        }
    }, [taskTrendDuration])

    /**PS_PE_43 To Refresh the values when the riskType Changes */
    useEffect(() => {
        if(!isInitialLoading){
        setSelectedSubEventType('');
        getRiskEventsInfo();
        fetchRiskSubEventGrid();
        setPopupLimit(10);
        setEventPage(1);
        }
    }, [selectedEventType]);

    /**PS_PE_41 - the api calls gets rendered for the change in pieTrendDuration */
    useEffect(() => {
        if(!isInitialLoading){
        getRiskEventsInfo();
        fetchRiskSubEventGrid();
        }
    }, [pieTrendDuration]);

    /**PS_TT_24 - Set the transformed form of Data for riskTask Details */
    useEffect(() => {
        setTransformedRiskTaskTrend(taskTransformingData(riskTaskTrend))
    }, [riskTaskTrend]);

    /**PS_SG_35 Refresh the values on change of the subRiskType Count */
    useEffect(() => {
        if(!isInitialLoading){
        setPopupLimit(10);
        setEventPage(1)
        fetchRiskSubEventGrid();
        }
    }, [selectedSubEventType]);

    /**PS_PE_39 Trigger the fetchRiskSubEventGrid based on user-actions */
    useEffect(() => {
        if(!isInitialLoading){
        fetchRiskSubEventGrid(true);
        }
    }, [popUpLimit, sortKey, sortOrder]);


    /**PS_PE_42 Function to set the desired sort in the Risk Details Scroll */
    const handleSortClicked = (columnName, order) => {
        setSortKey(columnName);
        setSortOrder(order);
    }

    /**PS_SG_33 Function to render the userRows in the Risk Event Details */
    const renderUserRows = (userData, showSortIcons) => {
        return (
            <>
                <thead className={showSortIcons ? "sticky-top" : ""}>
                    <tr>
                        <th className="font-14 px-4 position-relative">Username
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("Name", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("Name", "desc")} />
                                </span>
                            )}
                        </th>
                        <th className="font-14 position-relative text-end">#Activities
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("risk_count", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("risk_count", "desc")} />
                                </span>
                            )}
                        </th>
                        <th className="font-14 position-relative">Severity
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("result", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("result", "desc")} />
                                </span>
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {userData.map((row, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={row.profile_url} className="table-prof" />
                                    <p className="font-12 font-medium primary-textcolor mb-0 ms-2">
                                        {row.Name}
                                    </p>
                                </div>
                            </td>
                            <td className="font-12 text-end">{row.risk_count}</td>
                            <td className="font-12">{row.result}</td>
                        </tr>
                    ))}
                </tbody>
            </>
        );
    };

    /**PS_SG_33 Function to render the deviceRows in the Risk Event Details */
    const renderDeviceRows = (deviceSubGridData, showSortIcons) => {
        return (
            <>
                <thead>
                    <tr>
                        <th className="font-14 px-4 position-relative">Device Name
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("di.device_name", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("di.device_name", "desc")} />
                                </span>
                            )}
                        </th>
                        <th className="font-14 position-relative ">UserName
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("ud.employee_name", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("ud.employee_name", "desc")} />
                                </span>
                            )}
                        </th>
                        <th className="font-14 position-relative">Device Status
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("di.is_active", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("di.is_active", "desc")} />
                                </span>
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {deviceSubGridData.map((row, index) => (
                        <tr key={index}>
                            <td className="font-12 ">{row.device_name}</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={row.profile_url} className="table-prof" />
                                    <p className="font-12 font-medium primary-textcolor mb-0 ms-2">
                                        {row.employee_name}
                                    </p>
                                </div>
                            </td>
                            <td className="font-12 ">{row.is_active ? 'Active' : 'Inactive'}</td>
                        </tr>
                    ))}
                </tbody>
            </>
        );
    };

    /**PS_SG_33 Function to render the emailRows in the Risk Event Details */
    const renderEmailRows = (emailSUbGridData, showSortIcons) => {
        return (
            <>
                <thead>
                    <tr>
                        <th className="font-14 px-4 position-relative">Username
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("employee_name", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("employee_name", "desc")} />
                                </span>
                            )}
                        </th>
                        <th className="font-14 position-relative ">Email ID
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("primary_email", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("primary_email", "desc")} />
                                </span>
                            )}
                        </th>
                        <th className="font-14 position-relative">#Email
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("risk_count", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("risk_count", "desc")} />
                                </span>
                            )}
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {emailSUbGridData.map((row, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={row.profileUrl} className="table-prof" />
                                    <p className="font-12 font-medium primary-textcolor mb-0 ms-2">
                                        {row.employee_name}
                                    </p>
                                </div>
                            </td>
                            <td className="font-12 ">{row.primary_email}</td>
                            <td className="font-12 ">{row.risk_count}</td>
                        </tr>
                    ))}
                </tbody>
            </>
        );
    };

    /**PS_SG_33 Function to render the applicationRows in the Risk Event Details */
    const renderApplicationRows = (appData, showSortIcons) => {
        return (
            <>
                <thead>
                    <tr>
                        <th className="font-14 px-4 position-relative">Application
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("applicationName", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("applicationName", "desc")} />
                                </span>
                            )}
                        </th>
                        <th className="font-14 position-relative ">#Activities
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("appRiskCount", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("appRiskCount", "desc")} />
                                </span>
                            )}
                        </th>
                        <th className="font-14 position-relative">Severity
                            {showSortIcons && (
                                <span className="position-absolute cursor-pointer">
                                    <img className="sort-img-size sort-up position-absolute ms-2" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => handleSortClicked("severity", "asc")} />
                                    <img className="sort-img-size sort-down position-absolute ms-2" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => handleSortClicked("severity", "desc")} />
                                </span>
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {appData.map((row, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex align-items-center">
                                    <p className="font-12 font-medium primary-textcolor mb-0 ms-2">
                                        {row.applicationName}
                                    </p>
                                </div>
                            </td>
                            <td className="font-12 ">{row.appRiskCount}</td>
                            <td className="font-12 ">{row.severity}</td>
                        </tr>
                    ))}
                </tbody>
            </>
        );
    };


    /**PS_TT_24 - Function to transform the riskTaskTrendInfo based on stacked Chart */
    const taskTransformingData = (taskTrendData) => {
        const transformedData = Object.keys(taskTrendData).filter((each) => each != 'total_task_count').map((key) => {
            return {
                name: key,
                completed_count: taskTrendData[key].completed_count,
                in_progress_count: taskTrendData[key].in_progress_count,
                unassigned_count: taskTrendData[key].unassigned_count,
                awaiting_confirmation_count: taskTrendData[key].awaiting_confirmation_count,
            };
        });
        return transformedData
    }


    /**PS_HM_38 - Setting the heat Map colours based on the colour matrix */
    const getImpactColor = (x, y, z) => {
        const impactLikelihood = `${impactLevels[z]} - ${likelihoodLevels[y]}`;
        const colorScale = ["#FFD18D", "#FFB291", "#FF8F8F"];
        const colorMapping = {
            'High - High': 2,
            'High - Medium': 2,
            'High - Low': 1,
            'Medium - High': 2,
            'Medium - Low': 0,
            'Medium - Medium': 1,
            'Low - Low': 0,
            'Low - Medium': 0,
            'Low - High': 1
        };

        return colorScale[colorMapping[impactLikelihood] || 0];

    }



    const getIndex = (combination) => {
        // ... handling error checking and return default values as needed
        if (combination) {
            let [impact, , , likelihood] = combination.split(" ");
            if (impact == 'low') {
                impact = "Low"
            }
            if (likelihood == 'low') {
                likelihood = "Low"
            }

            const impactIndex = impactLevels.indexOf(impact);
            const likelihoodIndex = likelihoodLevels.indexOf(likelihood);
            return [impactIndex, likelihoodIndex];
        }

    }

    /**PS_HM_35 - Declaration of the heat map matrix to render heat Map  */

    const matrix = Array.from({ length: impactLevels.length }, () =>
        Array.from({ length: likelihoodLevels.length }, () => 0)
    );

    for (const item of riskHeatMapInfo) {
        const [impactIndex, likelihoodIndex] = getIndex(item.combination);
        if (impactIndex !== -1 && likelihoodIndex !== -1) {
            matrix[impactIndex][likelihoodIndex] = item.count_of_cases;
        }
    }
    //render table function calls





    //handling user actions call

    /**PS_SG_40 - the handleViewlogs function is triggered to navigate to the event Logs page with filters */
    const handleViewLogs = () => {
        const formattedDates = updateDates(pieTrendDuration);
        /**PS_SG_47 Now set the generated dates */
        const { fromDate, toDate } = formattedDates;
        /**PS_SG_48 - navigated to the eventLogs page along with filters */
        navigate('/riskManagement/eventLogs', { state: { fromDate: fromDate, toDate: toDate, duration: pieTrendDuration, category: selectedEventType, subCategory: selectedSubEventType } });
    }

    //PS_SR_29 navigation to the report logs tab
    const handleSecurityRecommendation = () => {
        setSelectedEventType("userRisk")
        setSelectedSubEventType("campaignOverdue")
        navigate('/riskManagement/eventLogs', { state: { category:"userRisk" , subCategory: "campaignOverdue" } });
    }

    //PS_SR_28 navigation to the all device tab
    const handleSecurityRecommendationDevice = () => {
        navigate('/riskManagement/allDevices', { state: { NonComplaint:0 } });
    }

    //PS_SR_31 navigation to the all applications tab
    const handleSecurityRecommendationApplication = () => {
        navigate('/riskManagement/allApplications', { state: { NonComplaint:"Non Complaint" } });
    }

    //PS_SR_30 navigation to the all user tab
    // const handleSecurityRecommendationUsers = () => {
    //     navigate('/riskManagement/allUsers', { state: { isMfa:0 } });
    // }



    /**PS_PE_43 - set the newly selected eventType     */
    const handleRiskTypeChange = (eventType) => {
        if (selectedEventType !== eventType) {
            setSelectedEventType(eventType);
        }
    };

    /**PS_RT_43 -the function receives the activeLabel that was clicked */
    const handleLineEventClick = (activeLabel) => {
        const { fromDate, toDate } = getStartAndEndDates(activeLabel);
        /**PS_RT_50 Navigating to the eventLogs Page with Filters */
        navigate('/riskManagement/eventLogs', { state: { fromDate: fromDate, toDate: toDate, category: '', subCategory: ''} })

    };

    /**PS_TT_29 the function to navigate to the task with filters passed */
    const navigateToTrends = () => {
        const riskTaskTrendDate = updateDates(taskTrendDuration)
        navigate('/taskmanagement', { state: { fromDate: riskTaskTrendDate.fromDate, toDate: riskTaskTrendDate.toDate, tasktab: "allTasks", categoryId: "3" } }); // Navigate to the desired path with taskTrendDuration parameter
    };


    const handleApiError = (errorMessage) => {
        if (errorMessage === 'Not Accessible for this User Role') {
            // Navigate to login page or user restricted screen
            navigate('/login');
        } else {
            // Navigate to 404 not found page
            navigate('/404');;
        }
    };


    /**PS_HM_44 - Function to handle the heat Map click and navigate to the eventLogs page */
    const handleHeatMapClick = (x, y) => {
        const impactLikelihood = `${impactLevels[y]} - ${likelihoodLevels[x]}`;
        const riskDate = updateDates(heatTrendDuration);
        navigate('/riskManagement/eventLogs', { state: { fromDate: riskDate.fromDate, toDate: riskDate.toDate, impact: impactLevels[y], likelihood: likelihoodLevels[x] ,duration:heatTrendDuration } }); // Navigate to the desired path with taskTrendDuration parameter

    };

    /**PS_TI_25 - When user clicks on the risk count in the tiles, then pass the respective `cardName` as parameter to the `handleTilesClick()` function. */
    const handleTilesClick = (cardName) => {
        const navigationObject = {};
        let pathName = ''
        /**PS_TI_26 - setting the pathName and setting the risk */
        switch (cardName) {
            case 'UserCounts':
                navigationObject.riskUserCountsInfo = true;
                pathName = 'riskManagement/allUsers'
                break;
            case 'DeviceStat':
                navigationObject.riskDeviceStatInfo = true;
                pathName = 'riskManagement/allDevices'
                break;
            case 'ApplicationStats':
                navigationObject.riskApplicationStatsInfo = true;
                pathName = 'riskManagement/allApplications'
                break;
            default:
                break;
        }
        navigate(`/${pathName}`, { state: navigationObject });

    };
    const handleInfoNavigate = (info, value) => {

        let pathName = ''
        const navigateState = {}
        switch (info) {
            case 'user':
                navigateState.isActive = value?.isActive
                navigateState.isMfa = value?.isMfa                
                pathName = 'riskManagement/allUsers'
                break;
            case 'device':
                navigateState.NonComplaint = value
                pathName = 'riskManagement/allDevices'
                break;
            case 'application':
                navigateState.sanctioned = value
                pathName = 'riskManagement/allApplications'
                break;
            default:
                break;
        }        
        navigate(`/${pathName}`, { state: navigateState });

    };

    /**PS_TT_24 Function to return the shortened version of the number passed */
    const abbreviateNumbertask = (num) => {
        if (Math.abs(num) < 999) {
            return num.toString();
        }
        if (Math.abs(num) < 999999) {
            if (num % 1000 === 0) {
                return (Math.sign(num) * (Math.abs(num) / 1000)) + 'K';
            } else {
                return (Math.sign(num) * (Math.abs(num) / 1000).toFixed(3)) + 'K';
            }
        }
        return (Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1)) + 'M';
    }

    /**PS_PE_33 Function to get event count by event name */
    const getEventCount = (eventName) => {
        const eventObject = riskEventsInfo.riskTypeCount.find(event => event.event === eventName);
        return eventObject ? eventObject?.event_count : 0;
    };


    /**PS_PE_32 Function to return the Updated RiskType Verbage */
    const getEventType = (riskType) => {
        const riskTypeMap = {
            emailRisk: 'Email Events',
            userRisk: 'User Events',
            deviceRisk: 'Device Events',
            appRisk: 'Application Events'
        };

        // Check if the riskType exists in the riskTypeMap
        if (riskTypeMap.hasOwnProperty(riskType)) {
            return riskTypeMap[riskType];
        } else {
            return ''; // Default value if riskType is not recognized
        }
    }

    /**PS_PE_36 Invoking the setPieEventColour Function */
    const colors = setPieEventColour(riskEventsInfo?.riskTypeCount);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>

                    <div className="custom-tooltip-Risk-Dashboard" >
                        <p className="labeltitle">{label}</p>
                        {payload.map((entry, index) => (
                            <p key={`item-${index}`} style={{ color: entry.color }}>
                                {entry.name}: {entry.value}
                            </p>
                        ))}
                    </div>
                </>
            );
        }

        return null;
    };
    let isActiveSet = false;


    //security recomendations popup PS_SP_10 the function for the api call will be initialized 

    const InitialPopUpLoad = async () => { 
        setIspopUpOpen(1);
        const payload = {
          sort,
          eventPopupPage,
          userId: userId,
          userRole: roles
        };
        setPopupIsLoading(true)
        const response = await getExternalDomain(payload);

        //PS_SP_23 - PS_SP_28 the response will be captured and set stated in variables


        if (response.status === true && response.data) {
          setPopupData(response.data.externalDomains);
          setTotalCountPopUp(response.data.totalCount);
          //PS_SP_30-35 the infinite scroll  
          if (response.data.totalCount > eventPopupPage) {
            setHasMoreEvent(true);
          } else {
            setHasMoreEvent(false);
          }
          setPopupIsLoading(false)
        } else {
          console.error("No data found");
        }
      };
        
      

      const closePopUp = async() => {
        
        setIspopUpOpen(0)
        setPopupData([])
        setTotalCountPopUp(0)
        setHasMoreEvent(false)
        setEventPopUpPage(7)
        PopUpsort.column="external_activity"
        PopUpsort.order="DESC"
        setSort(PopUpsort)
        

      }
    //html render part

    function onHandleSort(order, column) {
        PopUpsort.column = column
        PopUpsort.order = order
        setSort(PopUpsort)
    }

    //PS_SP_36 - 43 the api call for the excel data will be made 

    const handleExportExcel = async () => {
        const payload = {
            sort,
            userId: userId,
            userRole: roles
          };
        const response = await getExportExternalDomain(payload);
        const excelData = formatDataForExcel(response.data.externalDomains);
        handleExportToExcel(excelData);
      };

      const formatDataForExcel = (data) => {
        // Assuming data is an array of objects, format it as needed

        return data.map(item => ({
           
            DomainName: item.external_domains,
            Activities: item.external_activity,
        }));

    };

    //PS_SP_44 - 50 the excel data that need to be stored in local

    async function handleExportToExcel(data){
        try {
            let exportExcelData = data;
            let header = ["DomainName", "Activities"];
            let tabName = "External Domain";
       
            let workbook = new Workbook();
            let worksheet = workbook.addWorksheet('LearningReport');
       
            worksheet.addRow(header);
       
            exportExcelData.forEach((dataItem) => {
              let rowData = header.map(column => dataItem[column]);
              worksheet.addRow(rowData);
            });
       
            let filename = `${tabName}_${new Date().toISOString().slice(0, 10).replace(/-/g, "")}.xlsx`;
            let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let buffer = await workbook.xlsx.writeBuffer();
            let dataBlob = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(dataBlob, filename);
          } catch (error) {
            console.error("Error exporting to Excel:", error);
          }
    }
    return (
        <>
            <Loader isLoading={isLoading} />

            <div>
                <div className="container-fluid mt-4 mt-custom">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between mt-4 px-5">
                            <h1 className="font-bold font-24 primary-textcolor">
                                Risk Management
                            </h1>
                        </div>
                        {/*tabs starts here*/}
                        <div className="d-flex justify-content-between align-items-center my-3 px-5 custom-tabs">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link px-0 active font-14 tab-style rounded-0" id="overview-tab" data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">
                                        <span className="tab-img"> <img src="/images/dashboard-icon-filled.svg" alt="filled-icon" className="me-2" />
                                            <img src="/images/dashboard-icon.svg" alt="overview-icon" className="me-2" /></span>Overview</button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-users-tab" data-bs-toggle="pill" data-bs-target="#all-users" type="button" role="tab" aria-controls="all-users"
                                        aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/allUsers') }}>
                                        <span className="tab-img"> <img src="/images/people-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/people-icon.svg" alt="all-users-icon" className="me-2" />
                                        </span>All Users</button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-apps-tab" data-bs-toggle="pill" data-bs-target="#all-apps" type="button" role="tab" aria-controls="all-apps" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/allApplications') }}>
                                        <span className="tab-img"> <img src="/images/app-icon-filled.svg" alt="filled-icon" className="me-2" />
                                            <img src="/images/app-icon.svg" alt="all-apps-icon" className="me-2" />All
                                            Applications</span></button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-devices-tab" data-bs-toggle="pill" data-bs-target="#all-devices" type="button" role="tab" aria-controls="all-devices" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/allDevices') }}  >
                                        <span className="tab-img"> <img src="/images/device-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/device-icon.svg" alt="all-devices-icon" className="me-2" />All Devices</span></button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="event-logs-tab" data-bs-toggle="pill" data-bs-target="#event-logs" type="button" role="tab" aria-controls="event-logs" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/eventLogs') }}>
                                        <span className="tab-img"> <img src="/images/document-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/document-icon.svg" alt="event-logs-icon" className="me-2" />Event Logs</span></button>
                                </li>
                            </ul>
                        </div>
                        {/*tabs ends here*/}
                        <div className="row position-relative">
                            <div className="tab-content px-0" id="pills-tabContent">
                                {/*Overview tab*/}
                                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                    <div className="container-fluid bg-dashboard">
                                        <div className="custom-container px-5">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-lg-12 mt-4">
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 mb-md-3">
                                                            <div className="bg-white p-3 font-medium rm-dashboard-card">
                                                                <div className="d-flex justify-content-between mb-3">
                                                                    <div className="d-inline">
                                                                        <img src="/images/users-icon.svg" className="img-size" alt="Users Icon" />
                                                                        <h3 className="d-inline primary-textcolor font-14 ms-2">Users
                                                                        </h3>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        {/**PS_TI_23 - Bind the recorded counts data from API in the HTML by passing the number to the AddLeadingZero component  */}
                                                                        <span className="secondary-textcolor font-32" onClick={() => { handleInfoNavigate('user',{isActive:`''`}) }} style={{ cursor: "pointer" }}><AddZero number={tilesDetails?.userCounts?.total}></AddZero></span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="d-inline">
                                                                        <span className="grey-light font-11">Active</span>
                                                                        <span className="secondary-textcolor font-13 ms-1" onClick={() => { handleInfoNavigate('user', {isActive:`Active`}) }} style={{ cursor: "pointer" }}>{tilesDetails?.userCounts?.active != 0 ? abbreviateNumber(tilesDetails?.userCounts?.active) : 0}</span>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <span className="grey-light font-11">Inactive</span>
                                                                        <span className="secondary-textcolor font-13 ms-1" onClick={() => { handleInfoNavigate('user', {isActive:`Inactive`}) }} style={{ cursor: "pointer" }}>{tilesDetails?.userCounts?.inactive != 0 ? abbreviateNumber(tilesDetails?.userCounts?.inactive) : 0}</span>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <button type="button" className="font-11 red-textcolor border-0 bg-white" onClick={() => handleTilesClick("UserCounts")}><img className="me-1 align-text-top" src="/images/risk-icon-red.svg" alt="risk-icon" />Risk</button>
                                                                        <span className="primary-textcolor font-13 ms-1 red-textcolor" onClick={() => handleTilesClick("UserCounts")} style={{ cursor: "pointer" }}>{tilesDetails?.userCounts?.highScore != 0 ? abbreviateNumber(tilesDetails?.userCounts?.highScore) : 0}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 mb-md-3">
                                                            <div className="bg-white p-3 font-medium rm-dashboard-card">
                                                                <div className="d-flex justify-content-between mb-3">
                                                                    <div className="d-inline">
                                                                        <img src="/images/applications-icon.svg" className="img-size" alt="Users Icon" />
                                                                        <h3 className="d-inline primary-textcolor font-14 ms-2">
                                                                            Applications</h3>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <span className="secondary-textcolor font-32" onClick={() => { handleInfoNavigate('application', '') }} style={{ cursor: "pointer" }}><AddZero number={tilesDetails.applicationStats?.total_count}></AddZero></span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="d-inline">
                                                                        <span className="grey-light font-11">Sanctioned</span>
                                                                        <span className="secondary-textcolor font-13 ms-1" onClick={() => { handleInfoNavigate('application', `Sanctioned`) }} style={{ cursor: "pointer" }}>{tilesDetails.applicationStats?.authorized_logins_count != 0 ? abbreviateNumber(tilesDetails.applicationStats?.authorized_logins_count) : 0}</span>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <span className="grey-light font-11">Unsanctioned</span>
                                                                        <span className="secondary-textcolor font-13 ms-1" onClick={() => { handleInfoNavigate('application', `Unsanctioned`) }} style={{ cursor: "pointer" }}>{tilesDetails.applicationStats?.unsanctioned_count != 0 ? abbreviateNumber(tilesDetails.applicationStats?.unsanctioned_count) : 0}</span>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <button type="button" className="font-11 red-textcolor border-0 bg-white" onClick={() => handleTilesClick("ApplicationStats")}><img className="me-1 align-text-top" src="/images/risk-icon-red.svg" alt="risk-icon" />Risk</button>
                                                                        <span className="primary-textcolor font-13 ms-1 red-textcolor" onClick={() => handleTilesClick("ApplicationStats")} style={{ cursor: "pointer" }}>{tilesDetails?.applicationStats?.risk_count != 0 ? abbreviateNumber(tilesDetails?.applicationStats?.risk_count) : 0} </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 mb-md-3">
                                                            <div className="bg-white p-3 font-medium rm-dashboard-card">
                                                                <div className="d-flex justify-content-between mb-3">
                                                                    <div className="d-inline">
                                                                        <img src="/images/Devices-icon.svg" className="img-size" alt="Users Icon" />
                                                                        <h3 className="d-inline primary-textcolor font-14 ms-2">Devices
                                                                        </h3>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <span className="primary-textcolor font-32"><AddZero number={tilesDetails?.deviceStat?.total}></AddZero></span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="d-inline">
                                                                        <span className="grey-light font-11">Corporate</span>
                                                                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tilesDetails?.deviceStat?.corporate)}</span>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <span className="grey-light font-11">Personal</span>
                                                                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tilesDetails?.deviceStat?.personal)}</span>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <button type="button" className="font-11 red-textcolor bg-white border-0" onClick={() => handleTilesClick("DeviceStat")}><img className="me-1 align-text-top" src="/images/risk-icon-red.svg" alt="risk-icon" />Risk</button>
                                                                        <span className="secondary-textcolor font-13 ms-1 red-textcolor" onClick={() => handleTilesClick("DeviceStat")} style={{ cursor: "pointer" }}>{abbreviateNumber(tilesDetails?.deviceStat?.risk)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 mb-md-3">
                                                            <div className="bg-white p-3 font-medium rm-dashboard-card">
                                                                <div className="d-flex justify-content-between mb-3">
                                                                    <div className="d-inline">
                                                                        <img src="/images/Network-icon.svg" className="img-size" alt="Users Icon" />
                                                                        <h3 className="d-inline primary-textcolor font-14 ms-2">Network
                                                                        </h3>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <span className="primary-textcolor font-32"><AddZero number={tilesDetails?.networkCount?.total}></AddZero></span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="col-md-4">
                                                                        <span className="grey-light font-11">Inside</span>
                                                                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tilesDetails?.networkCount?.internal)}</span>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <span className="grey-light font-11">Outside</span>
                                                                        <span className="primary-textcolor font-13 ms-1">{abbreviateNumber(tilesDetails?.networkCount?.external)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pe-0">
                                                    <div className="col-md-12 pe-0">
                                                        <div className="row pe-0 dashboard-widgets">
                                                            <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3" style={{position:"relative"}}>
                                                                <RiskLoader isLoading={heatisLoading}/>
                                                                <div className="bg-white py-2 px-3 border-radius-8 ">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h3 className="font-14 font-medium primary-textcolor mb-0">Risk
                                                                            Heat Map</h3>
                                                                        <div className="align-items-center d-flex gap-2">
                                                                            <div className="align-items-baseline d-flex">
                                                                                <span className="legends legend-low me-1" />
                                                                                <label className="me-2 font-10 grey-light">Low</label>
                                                                            </div>
                                                                            <div className="align-items-baseline d-flex">
                                                                                <span className="legends legend-medium me-1" />
                                                                                <label className="me-2 font-10 grey-light">Medium</label>
                                                                            </div>
                                                                            <div className="align-items-baseline d-flex">
                                                                                <span className="legends legend-high me-1" />
                                                                                <label className="me-2 font-10 grey-light">High</label>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <select className="form-select custom-form-control py-2 font-12 font-regular" value={heatTrendDuration} onChange={(e) => handleHeatDurationChange(e)}>
                                                                                    {durationDropdown}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="py-3 px-2 chart-image img-fluid" style={{ fontSize: '15px', maxWidth: '85%', marginLeft: '54px', marginTop: '20px' }}>
                                                                        <div style={{ float: 'left', marginLeft: '-80px', marginTop: '95px', transform: 'rotate(-90deg)', fontWeight: 'bold' }}>Impact</div>


                                                                        <Heatmap class="chart-image img-fluid"
                                                                            xLabels={likelihoodLevels}
                                                                            yLabels={impactLevels}
                                                                            data={matrix}
                                                                            squares={false} // Rectangular shape
                                                                            height={70}
                                                                            xLabelsLocation="bottom"
                                                                            yLabelsLocation="right"
                                                                            xLabelWidth={80}
                                                                            cellStyle={(background, value, min, max, x, y, z) => ({
                                                                                background: getImpactColor(x, y, z),
                                                                                fontSize: "12px",
                                                                                color: "#333",
                                                                                borderRadius: "8px",
                                                                                margin: "1px",
                                                                            })}
                                                                            cellRender={(value) => (value > 0 ? value : "-")}
                                                                            onClick={handleHeatMapClick}
                                                                        />



                                                                        {/* Static label for x-axis */}
                                                                        <div style={{ marginLeft: '257px', marginTop: '20px', fontWeight: 'bold' }}>Likelihood</div>                                                              </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3" style={{ position: "relative" }}>
                                                                <RiskLoader isLoading={taskTrendsisLoading} />
                                                                <div className="bg-white py-2 px-3 border-radius-8 ">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h3 className="font-14 font-medium primary-textcolor mb-0">Task
                                                                            Trends</h3>
                                                                        <div className="align-items-center d-flex gap-3">
                                                                            <div className="d-flex align-items-center">
                                                                                <label className="font-12 me-1">Total Task</label>
                                                                                <span className="font-14 font-medium">{abbreviateNumber(totalTrendTaskCount)}</span>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <select className="form-select custom-form-control py-2 font-12 font-regular" value={taskTrendDuration} onChange={(e) => handleTaskDropdownChange(e)}>
                                                                                    {durationDropdown}
                                                                                </select>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <a className="font-12 secondary-textcolor font-medium cursor-pointer text-decoration-none" onClick={() => navigateToTrends()}>View
                                                                                    Task <img src="/images/right-arw-blue.svg" alt="right-arrow" /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="">
                                                                        <ResponsiveContainer width={'100%'} height={300}>
                                                                            <BarChart
                                                                                data={transFormedRiskTaskTrend}
                                                                                margin={{
                                                                                    top: 10,
                                                                                    right: 10,
                                                                                    left: 10,
                                                                                    bottom: 10,
                                                                                }}
                                                                            >
                                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                                <XAxis dataKey="name" scale="none" padding={{ left: 120, right: 120 }} />
                                                                                <YAxis />
                                                                                <Tooltip content={<CustomTooltip />} />
                                                                                <Bar
                                                                                    dataKey="completed_count"
                                                                                    stackId="a"
                                                                                    fill="#78DC8E"
                                                                                    name="Completed"
                                                                                    barSize={20} // Adjust the width of the bars here
                                                                                />
                                                                                <Bar
                                                                                    dataKey="in_progress_count"
                                                                                    stackId="a"
                                                                                    fill="#ACDBFD"
                                                                                    name="In Progress"
                                                                                    barSize={20} // Adjust the width of the bars here
                                                                                />
                                                                                <Bar
                                                                                    dataKey="unassigned_count"
                                                                                    stackId="a"
                                                                                    fill="#FACC9E"
                                                                                    name="Unassigned"
                                                                                    barSize={20} // Adjust the width of the bars here
                                                                                />
                                                                                <Bar
                                                                                    dataKey="awaiting_confirmation_count"
                                                                                    stackId="a"
                                                                                    fill="#ADADE8"
                                                                                    name="Awaiting Confirmation"
                                                                                    barSize={20} // Adjust the width of the bars here
                                                                                />
                                                                            </BarChart>
                                                                        </ResponsiveContainer>

                                                                    </div>
                                                                    <div className="align-items-center justify-content-center d-flex gap-2">
                                                                        <div className="align-items-baseline d-flex">
                                                                            <span className="legends legend-completed me-1" />
                                                                            <label className="me-2 font-10 grey-light">Completed</label>
                                                                        </div>
                                                                        <div className="align-items-baseline d-flex">
                                                                            <span className="legends legend-inprogress me-1" />
                                                                            <label className="me-2 font-10 grey-light">In
                                                                                Progress</label>
                                                                        </div>
                                                                        <div className="align-items-baseline d-flex">
                                                                            <span className="legends legend-unassigned me-1" />
                                                                            <label className="me-2 font-10 grey-light">Unassigned</label>
                                                                        </div>
                                                                        <div className="align-items-baseline d-flex">
                                                                            <span className="legends legend-awaiting me-1" />
                                                                            <label className="me-2 font-10 grey-light">Awaiting
                                                                                Confirmation</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3" style={{position:"relative"}}>
                                                                <RiskLoader isLoading={riskEventisLoading}/> 
                                                                <div className="bg-white py-2 px-3 border-radius-8">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h3 className="font-14 font-medium primary-textcolor mb-0">Risk
                                                                            Events</h3>
                                                                        <div className="d-flex">
                                                                            <select className="form-select custom-form-control py-2 font-12 font-regular" value={pieTrendDuration} onChange={(e) => handlePieDropdownChange(e)}>
                                                                                {durationDropdown}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <ul className="nav nav-tabs border-0 dashboard-tabs mt-2" id="myTab" role="tablist" style={{ position: "relative", zIndex: 10 }}>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link active  font-12 grey-light pt-0 pb-2" id="user-tab" data-bs-toggle="tab" data-bs-target="#user" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={() => handleRiskTypeChange('userRisk')}>Users
                                                                                <span className="badges border-radius-8 red-textcolor  font-10">{riskEventsInfo && riskEventsInfo.eventsRiskCounts[0]?.UserRiskCount !== undefined && (
                                                                                    abbreviateNumber(riskEventsInfo.eventsRiskCounts[0].UserRiskCount)
                                                                                )}
                                                                                </span></button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link  font-12 grey-light pt-0 pb-2" id="application-tab" data-bs-toggle="tab" data-bs-target="#application-risk" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={() => handleRiskTypeChange('appRisk')}>Application
                                                                                <span className="badges border-radius-8 red-textcolor  font-10">
                                                                                    {abbreviateNumber(riskEventsInfo?.eventsRiskCounts[0]?.AppRiskCount)}
                                                                                </span></button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link  font-12 grey-light pt-0 pb-2" id="devices-tab" data-bs-toggle="tab" data-bs-target="#network-risk" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={() => handleRiskTypeChange('deviceRisk')}>Devices <span className="badges border-radius-8 red-textcolor  font-10">{abbreviateNumber(riskEventsInfo?.eventsRiskCounts[0]?.DeviceRiskCount)}</span></button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link  font-12 grey-light pt-0 pb-2" id="email-tab" data-bs-toggle="tab" data-bs-target="#emails" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={() => handleRiskTypeChange('emailRisk')}>Emails <span className="badges border-radius-8 red-textcolor  font-10">{abbreviateNumber(riskEventsInfo?.eventsRiskCounts[0]?.EmailCount)}</span></button>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="tab-content" id="myTabContent">
                                                                        <div className="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab" >
                                                                            {riskEventsInfo?.eventsRiskCounts[0]?.UserRiskCount > 0 ? (
                                                                                <div className="row align-items-center px-4">
                                                                                    <div className="col-md-6">
                                                                                        {riskEventsInfo.riskTypeCount.some(item => item.event_count > 0) && (
                                                                                            <ResponsiveContainer width="100%" height={300}>
                                                                                                <PieChart>
                                                                                                    <Pie
                                                                                                        data={riskEventsInfo?.riskTypeCount}
                                                                                                        dataKey="event_count"
                                                                                                        nameKey="event"
                                                                                                        innerRadius={60} // Set innerRadius for donut chart
                                                                                                        outerRadius={80}
                                                                                                        fill="#8884d8"
                                                                                                        strokeWidth={2} // Set a thinner stroke width
                                                                                                        cornerRadius={4} // Set the cornerRadius for curved edges
                                                                                                    >
                                                                                                        {riskEventsInfo?.riskTypeCount?.map((entry, index) => (
                                                                                                            <Cell key={`cell-${index}`} fill={colors[entry.event]} />
                                                                                                        ))}
                                                                                                        <Label
                                                                                                            position="center"
                                                                                                            fill="#8E8E8E"
                                                                                                            fontSize={12}
                                                                                                            content={({ viewBox }) => {
                                                                                                                const eventType = getEventType(selectedEventType);
                                                                                                                const userRiskCount = riskEventsInfo?.eventsRiskCounts[0]?.UserRiskCount || 0;

                                                                                                                return (
                                                                                                                    <text
                                                                                                                        x={viewBox.cx}
                                                                                                                        y={viewBox.cy}
                                                                                                                        fill="#8E8E8E"
                                                                                                                        fontSize={12}
                                                                                                                        textAnchor="middle"
                                                                                                                        dominantBaseline="central"
                                                                                                                    >
                                                                                                                        <tspan x={viewBox.cx} dy={-10}>
                                                                                                                            {eventType}
                                                                                                                        </tspan>
                                                                                                                        <tspan x={viewBox.cx} dy={20} fontWeight="bold">
                                                                                                                            {userRiskCount}
                                                                                                                        </tspan>
                                                                                                                    </text>
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    </Pie>
                                                                                                </PieChart>
                                                                                            </ResponsiveContainer>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-1 me-2 cursor-pointer" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Multiple Password Fails</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("MultiFailLogin", "Multiple Password Fails")}><AddZero number={getEventCount('MultiFailLogin')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-2 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Multiple MFA Fail</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("mfaFail", "Multiple MFA Fail")}><AddZero number={getEventCount('mfaFail')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-3 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Untypical Travel Activity</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("untypicalTravel", "Untypical Travel Activity")}><AddZero number={getEventCount('untypicalTravel')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-4 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Activity from a Blacklisted IP</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("ActblaclistedIp", "Activity from a Blacklisted IP")}><AddZero number={getEventCount('ActblaclistedIp')}></AddZero></label>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-5 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Login from Multiple Geolocations</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("multipleGeoLocation", "Login from Multiple Geolocations")}><AddZero number={getEventCount('multipleGeoLocation')}></AddZero></label>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-6 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Login on Unusual Hours</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("loginUnusualHours", "Login on Unusal Hours")}><AddZero number={getEventCount('loginUnusualHours')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-7 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Overdue Campaigns</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("campaignOverdue", "Overdue Campaigns")}><AddZero number={getEventCount('overdueCampaign')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <RiskNoDataFound data={"No Risk User Found"} />
                                                                            )}

                                                                        </div >
                                                                        <div className="tab-pane fade" id="application-risk" role="tabpanel" aria-labelledby="application-risk-tab">
                                                                            {riskEventsInfo?.eventsRiskCounts[0]?.AppRiskCount > 0 ? (
                                                                                <div className="row align-items-center px-4">
                                                                                    <div className="col-md-6">
                                                                                        {riskEventsInfo?.riskTypeCount?.length > 0 && (
                                                                                            <ResponsiveContainer width="100%" height={300}>
                                                                                                <PieChart>
                                                                                                    <Pie
                                                                                                        data={riskEventsInfo?.riskTypeCount}
                                                                                                        dataKey="event_count"
                                                                                                        nameKey="event"
                                                                                                        innerRadius={60} // Set innerRadius for donut chart
                                                                                                        outerRadius={80}
                                                                                                        fill="#8884d8"
                                                                                                        strokeWidth={2} // Set a thinner stroke width
                                                                                                        cornerRadius={4} // Set the cornerRadius for curved edges


                                                                                                    >
                                                                                                        {riskEventsInfo?.riskTypeCount?.map((entry, index) => (
                                                                                                            <Cell key={`cell-${index}`} fill={colors[entry.event]} />
                                                                                                        ))}
                                                                                                        <Label
                                                                                                            position="center"
                                                                                                            fill="#8E8E8E"
                                                                                                            fontSize={12}
                                                                                                            content={({ viewBox }) => {
                                                                                                                const eventType = getEventType(selectedEventType);
                                                                                                                const appRiskCount = riskEventsInfo?.eventsRiskCounts[0]?.AppRiskCount || 0;

                                                                                                                return (
                                                                                                                    <text
                                                                                                                        x={viewBox.cx}
                                                                                                                        y={viewBox.cy}
                                                                                                                        fill="#8E8E8E"
                                                                                                                        fontSize={12}
                                                                                                                        textAnchor="middle"
                                                                                                                        dominantBaseline="central"
                                                                                                                    >
                                                                                                                        <tspan x={viewBox.cx} dy={-10}>
                                                                                                                            {eventType}
                                                                                                                        </tspan>
                                                                                                                        <tspan x={viewBox.cx} dy={20} fontWeight="bold">
                                                                                                                            {appRiskCount}
                                                                                                                        </tspan>
                                                                                                                    </text>
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    </Pie>
                                                                                                </PieChart>
                                                                                            </ResponsiveContainer>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-1 me-2 cursor-pointer" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Mass Download</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("massdownload ", "Mass Download")}><AddZero number={getEventCount('massdownload')}></AddZero></label>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-2 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Mass Delete</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("massDelete", "Mass Delete")}><AddZero number={getEventCount('massDelete')}></AddZero></label>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-4 me-1" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">New High Volume</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("highVolAppUasage ", "New High Volume")}><AddZero number={getEventCount('highVolAppUasage')}></AddZero></label>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <RiskNoDataFound data={"No Risk Application Found"} />
                                                                            )}
                                                                        </div>
                                                                        <div className="tab-pane fade" id="network-risk" role="tabpanel" aria-labelledby="network-risk-tab">
                                                                            {riskEventsInfo?.eventsRiskCounts[0]?.DeviceRiskCount > 0 ? (
                                                                                <div className="row align-items-center px-4">
                                                                                    <div className="col-md-6">
                                                                                        {riskEventsInfo?.riskTypeCount?.length > 0 && (
                                                                                            <ResponsiveContainer width="100%" height={300}>
                                                                                                <PieChart>
                                                                                                    <Pie
                                                                                                        data={riskEventsInfo?.riskTypeCount}
                                                                                                        dataKey="event_count"
                                                                                                        nameKey="event"
                                                                                                        innerRadius={60} // Set innerRadius for donut chart
                                                                                                        outerRadius={80}
                                                                                                        fill="#8884d8"
                                                                                                        strokeWidth={2} // Set a thinner stroke width
                                                                                                        cornerRadius={4} // Set the cornerRadius for curved edges
                                                                                                    >
                                                                                                        {riskEventsInfo?.riskTypeCount?.map((entry, index) => (
                                                                                                            <Cell key={`cell-${index}`} fill={colors[entry.event]} />
                                                                                                        ))}
                                                                                                        <Label
                                                                                                            position="center"
                                                                                                            fill="#8E8E8E"
                                                                                                            fontSize={12}
                                                                                                            content={({ viewBox }) => {
                                                                                                                const eventType = getEventType(selectedEventType);
                                                                                                                const deviceRiskCount = riskEventsInfo.eventsRiskCounts[0].DeviceRiskCount || 0;

                                                                                                                return (
                                                                                                                    <text
                                                                                                                        x={viewBox.cx}
                                                                                                                        y={viewBox.cy}
                                                                                                                        fill="#8E8E8E"
                                                                                                                        fontSize={12}
                                                                                                                        textAnchor="middle"
                                                                                                                        dominantBaseline="central"
                                                                                                                    >
                                                                                                                        <tspan x={viewBox.cx} dy={-10}>
                                                                                                                            {eventType}
                                                                                                                        </tspan>
                                                                                                                        <tspan x={viewBox.cx} dy={20} fontWeight="bold">
                                                                                                                            {deviceRiskCount}
                                                                                                                        </tspan>
                                                                                                                    </text>
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    </Pie>
                                                                                                </PieChart>
                                                                                            </ResponsiveContainer>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        {/* <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-1 me-1 cursor-pointer" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Rooted or Jailbroken</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("rootedOrJailbroken", "Rooted or Jailbroken")}><AddZero number={getEventCount('rootedOrJailbroken')}></AddZero></label>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-2 me-1" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Anti-Virus Disabled</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("antiVirusDisable", "Anti-Virus Disabled")}><AddZero number={getEventCount('antiVirusDisable')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-3 me-1" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Bit Locker Disabled</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("bitLockerDisable", "Bit Locker Disabled")}><AddZero number={getEventCount('bitLockerDisable')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-6 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Anti-Malware Disabled</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("antiMalwareDisable", "Anti-Malware Disabled")}><AddZero number={getEventCount('antiMalwareDisable')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-7 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Real-Time Network Protection Disabled</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("realTimeNetworkProtectionDisable", "Real-Time Network Protection Disabled")}><AddZero number={getEventCount('realTimeNetworkProtectionDisable')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <RiskNoDataFound data={"No Device User Found"} />
                                                                            )}
                                                                        </div>
                                                                        <div className="tab-pane fade" id="emails" role="tabpanel">
                                                                            {riskEventsInfo?.eventsRiskCounts[0]?.EmailCount > 0 ? (
                                                                                <div className="row align-items-center px-4">
                                                                                    <div className="col-md-6">
                                                                                        <div className="user-risk-image align-items-center">
                                                                                            {riskEventsInfo?.riskTypeCount?.length > 0 && (
                                                                                                <ResponsiveContainer width="100%" height={300}>
                                                                                                    <PieChart>
                                                                                                        <Pie
                                                                                                            data={riskEventsInfo?.riskTypeCount}
                                                                                                            dataKey="event_count"
                                                                                                            nameKey="event"
                                                                                                            innerRadius={60} // Set innerRadius for donut chart
                                                                                                            outerRadius={80}
                                                                                                            fill="#8884d8"
                                                                                                            strokeWidth={2} // Set a thinner stroke width
                                                                                                            cornerRadius={4} // Set the cornerRadius for curved edges


                                                                                                        >
                                                                                                            {riskEventsInfo?.riskTypeCount?.map((entry, index) => (
                                                                                                                <Cell key={`cell-${index}`} fill={colors[entry.event]} />
                                                                                                            ))}
                                                                                                            <Label
                                                                                                                position="center"
                                                                                                                fill="#8E8E8E"
                                                                                                                fontSize={12}
                                                                                                                content={({ viewBox }) => {
                                                                                                                    const eventType = getEventType(selectedEventType);
                                                                                                                    const emailRiskCount = riskEventsInfo.eventsRiskCounts[0].EmailCount || 0;

                                                                                                                    return (
                                                                                                                        <text
                                                                                                                            x={viewBox.cx}
                                                                                                                            y={viewBox.cy}
                                                                                                                            fill="#8E8E8E"
                                                                                                                            fontSize={12}
                                                                                                                            textAnchor="middle"
                                                                                                                            dominantBaseline="central"
                                                                                                                        >
                                                                                                                            <tspan x={viewBox.cx} dy={-10}>
                                                                                                                                {eventType}
                                                                                                                            </tspan>
                                                                                                                            <tspan x={viewBox.cx} dy={20} fontWeight="bold">
                                                                                                                                {emailRiskCount}
                                                                                                                            </tspan>
                                                                                                                        </text>
                                                                                                                    );
                                                                                                                }}
                                                                                                            />

                                                                                                        </Pie>
                                                                                                    </PieChart>
                                                                                                </ResponsiveContainer>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-1 me-1 cursor-pointer" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Spam</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("spam", "Spam")}><AddZero number={getEventCount('spam')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-2 me-1" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Malware</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("malware", "Malware")}><AddZero number={getEventCount('malware')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-4 me-1" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Phishing</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("Phishining", "Phishing")}><AddZero number={getEventCount('Phishining')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-6 me-2" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">Suspicious URL Clicks</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("susUrlClick", "Suspicious URL Clicks")}><AddZero number={getEventCount('susUrlClick')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                                                                                            <div className="align-items-center d-flex align-items-baseline">
                                                                                                <span className="legends legend-cat-external me-1" />
                                                                                                <span className="font-10 grey-light text-decoration-none cursor-pointer">External Email Shared</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => handleRiskEventClick("externalEmailShare", "External Email Shared")} ><AddZero number={getEventCount('externalEmailShare')}></AddZero></label>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <RiskNoDataFound data={"No Risk Email Found"} />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3">
                                                                <div className="bg-white py-2 px-3 border-radius-8" style={{ position: "relative", height: "100%", display: "flex", flexDirection: "column" }}>
                                                                    {!showEventPopup && <RiskLoader isLoading={byDetailsisLoading} />}
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div className="align-items-center d-flex gap-2">
                                                                            <h3 className="font-14 font-medium primary-textcolor mb-1 mt-3">
                                                                                Risk Event Details
                                                                            </h3>
                                                                            {selectedSubEventType && (
                                                                                <span className="pwd-badge font-10 px-3 py-2 font-medium mt-2">
                                                                                    {selectedSubEventName}
                                                                                    <img
                                                                                        className="ms-2 cursor-pointer"
                                                                                        src="/images/close-icon-purple.svg"
                                                                                        onClick={() => { setSelectedSubEventType('') }}
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        {riskSubEventInfo.length > 0 && (
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                src="/images/expand-icon.svg"
                                                                                alt="expand-icon"
                                                                                onClick={() => handleExpandEvent()}
                                                                            />
                                                                        )}
                                                                    </div>

                                                                    <div style={{ flex: 1, overflow: 'hidden' }}>
                                                                    {riskSubEventInfo.length > 0 ? (
                                                                        <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
                                                                        <table className="table text-nowrap mb-0 mt-4 custom-table-grid table-borderless dashboard-table">
                                                                            {selectedEventType === "userRisk" && renderUserRows(riskSubEventInfo, false)}
                                                                            {selectedEventType === "appRisk" && renderApplicationRows(riskSubEventInfo, false)}
                                                                            {selectedEventType === "emailRisk" && renderEmailRows(riskSubEventInfo, false)}
                                                                            {selectedEventType === "deviceRisk" && renderDeviceRows(riskSubEventInfo, false)}
                                                                        </table>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="d-flex align-items-center justify-content-center h-100">
                                                                        <div className="text-center">
                                                                            <img src="/images/No Data Found - Risk.png" alt="no-rec-icon" />
                                                                            <h5 className="font-24 font-medium primary-textcolor mt-4">No data match your search</h5>
                                                                        </div>
                                                                        </div>
                                                                    )}
                                                                    </div>

                                                                    <div className="d-flex align-items-center justify-content-end mt-auto">
                                                                    <a 
                                                                        className="font-12 secondary-textcolor font-medium cursor-pointer text-decoration-none" 
                                                                        onClick={() => { handleViewLogs() }}
                                                                    >
                                                                        View Logs <img src="/images/right-arw-blue.svg" alt="right-arrow" />
                                                                    </a>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3">
                                                                <div className="bg-white py-3 px-3 border-radius-8 ">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h3 className="font-14 font-medium primary-textcolor mb-0">Risk
                                                                            Trends</h3>
                                                                        <div className="align-items-center d-flex gap-2">
                                                                            <div className="align-items-baseline d-flex">
                                                                                <span className="legends legend-low me-1" />
                                                                                <label className="me-2 font-10 grey-light">Low</label>
                                                                            </div>
                                                                            <div className="align-items-baseline d-flex">
                                                                                <span className="legends legend-medium me-1" />
                                                                                <label className="me-2 font-10 grey-light">Medium</label>
                                                                            </div>
                                                                            <div className="align-items-baseline d-flex">
                                                                                <span className="legends legend-high me-1" />
                                                                                <label className="me-2 font-10 grey-light">High</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex py-4 px-2">
                                                                        {/**PS_RT_34 - pass the data 'aggregatedData' to the riskLineChart Component */}
                                                                        {aggregatedData ? (
                                                                            <LineEchartGraph data={aggregatedData} onDataClick={handleLineEventClick} />
                                                                        ) : (<div> </div>)}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3">
                                                                <div className="dashboard-carousel p-3 border-radius-8 d-flex flex-column">
                                                                    <div className="d-flex justify-content-between">
                                                                        <h3 className="font-14 font-medium primary-textcolor mb-0">
                                                                            Security Recommendations</h3>
                                                                        <img className="cursor-pointer" src="/images/expand-icon.svg" alt="expand-icon" onClick={() => expandToggleSecurityRecommendations()} />
                                                                    </div>
                                                                    {Object.values(riskSecurityInfo).some(value => value > 0) ?
                                                                        (<div id="security-carousel" className="carousel slide mt-4" data-bs-ride="carousel">
                                                                            {Object.values(riskSecurityInfo).filter(value => value > 0).length > 1 && (
                                                                                <div className="carousel-indicators custom-carousel-indicators align-items-center">
                                                                                    <button className="carousel-control-prev align-items-end custom-carousel-control" type="button" data-bs-target="#security-carousel" data-bs-slide="prev">
                                                                                        <span className="carousel-control-prev-icon carousel-arrow-indicators" aria-hidden="true" />
                                                                                        <span className="visually-hidden">Previous</span>
                                                                                    </button>
                                                                                    {/* Loop to render buttons dynamically */}
                                                                                    {Object.keys(riskSecurityInfo).slice(0, Object.values(riskSecurityInfo).filter(value => value > 0).length).map((_, index) => (
                                                                                        <button
                                                                                            key={index}
                                                                                            type="button"
                                                                                            data-bs-target="#security-carousel"
                                                                                            data-bs-slide-to={index}
                                                                                            className={index === 0 ? "active" : ""}
                                                                                            aria-current={index === 0 ? "true" : "false"}
                                                                                            aria-label={`Slide ${index + 1}`}
                                                                                        />
                                                                                    ))}

                                                                                    <button className="carousel-control-next align-items-end custom-carousel-control" type="button" data-bs-target="#security-carousel" data-bs-slide="next">
                                                                                        <span className="carousel-control-next-icon carousel-arrow-indicators" aria-hidden="true" />
                                                                                        <span className="visually-hidden">Next</span>
                                                                                    </button>
                                                                                </div>
                                                                            )}


                                                                            <div className="carousel-inner carousel-inner-height">
                                                                                {riskSecurityInfo?.isCompliantCount > 0 && (
                                                                                    <div className={`carousel-item ${!isActiveSet ? "active" : ""}`}>
                                                                                        {isActiveSet = true}
                                                                                        <p className="font-medium font-40 secondary-textcolor">
                                                                                            <AddZero number={riskSecurityInfo?.isCompliantCount}></AddZero>
                                                                                        </p>
                                                                                        <p className="font-medium font-12 mt-5">Non-Compliant Devices Usage</p>
                                                                                        <p className="font-12 primary-textcolor line-height-24 w-75">
                                                                                            Implement strict controls to monitor and restrict non-compliant devices usage.
                                                                                        </p>
                                                                                    </div>
                                                                                )}

                                                                                {riskSecurityInfo?.nonComplianceCount > 0 && (
                                                                                    <div className={`carousel-item ${!isActiveSet ? "active" : ""}`}>
                                                                                        {isActiveSet = true}
                                                                                        <p className="font-medium font-40 secondary-textcolor">
                                                                                            <AddZero number={riskSecurityInfo?.nonComplianceCount}></AddZero>
                                                                                        </p>
                                                                                        <p className="font-medium font-12 mt-5">Non-Compliant Application Usage</p>
                                                                                        <p className="font-12 primary-textcolor line-height-24 w-75">
                                                                                            Implement strict controls to monitor and restrict non-compliant application usage.
                                                                                        </p>
                                                                                    </div>
                                                                                )}

                                                                                {riskSecurityInfo?.noMfaCount > 0 && (
                                                                                    <div className={`carousel-item ${!isActiveSet ? "active" : ""}`}>
                                                                                        {isActiveSet = true}
                                                                                        <p className="font-medium font-40 secondary-textcolor">
                                                                                            <AddZero number={riskSecurityInfo?.noMfaCount}></AddZero>
                                                                                        </p>
                                                                                        <p className="font-medium font-12 mt-5">Multi Factor Authentication Disabled</p>
                                                                                        <p className="font-12 primary-textcolor line-height-24 w-75">
                                                                                            Two user accounts lack Multi-factor Authentication. Enhance security by enforcing MFA.
                                                                                        </p>
                                                                                    </div>
                                                                                )}

                                                                                {riskSecurityInfo?.unchangedPasswordCount > 0 && (
                                                                                    <div className={`carousel-item ${!isActiveSet ? "active" : ""}`}>
                                                                                        {isActiveSet = true}
                                                                                        <p className="font-medium font-40 secondary-textcolor">
                                                                                            <AddZero number={riskSecurityInfo?.unchangedPasswordCount}></AddZero>
                                                                                        </p>
                                                                                        <p className="font-medium font-12 mt-5">Users haven’t changed their password for past 30 days</p>
                                                                                        <p className="font-12 primary-textcolor line-height-24 w-75">
                                                                                            Enforce them to change the password for their user account.
                                                                                        </p>
                                                                                    </div>
                                                                                )}

                                                                                {riskSecurityInfo?.overDueCount > 0 && (
                                                                                    <div className={`carousel-item ${!isActiveSet ? "active" : ""}`}>
                                                                                        {isActiveSet = true}
                                                                                        <p className="font-medium font-40 secondary-textcolor">
                                                                                            <AddZero number={riskSecurityInfo?.overDueCount}></AddZero>
                                                                                        </p>
                                                                                        <p className="font-medium font-12 mt-5">LMS OverDue - Security Courses</p>
                                                                                        <p className="font-12 primary-textcolor line-height-24 w-75">
                                                                                            Send reminders and prioritize completion of overdue security courses in the LMS.
                                                                                        </p>
                                                                                    </div>
                                                                                )}

                                                                                {riskSecurityInfo?.externalDomainBlocks > 0 && (
                                                                                    <div className={`carousel-item ${!isActiveSet ? "active" : ""}`}>
                                                                                        {isActiveSet = true}
                                                                                        <p className="font-medium font-40 secondary-textcolor">
                                                                                            <AddZero number={riskSecurityInfo?.externalDomainBlocks}></AddZero>
                                                                                        </p>
                                                                                        <p className="font-medium font-12 mt-5">Top risky External Domains</p>
                                                                                        <p className="font-12 primary-textcolor line-height-24 w-75">
                                                                                            Implement email filtering and monitoring for top risky external domains.
                                                                                        </p>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                        </div>) : (<div className="row justify-content-center riskNoDataFoundPadding" >    <div className="col-md-10 col-lg-5 col-sm-12 text-center py-4">
                                                                            <span>
                                                                                <img src="/images/No Data Found - Risk.png" alt="no-rec-icon" />
                                                                            </span>
                                                                            <h5 className="font-24 font-medium primary-textcolor mt-4">No Security Recommendations Found </h5>
                                                                        </div></div>
                                                                        )}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Overview table*/}
                            </div>
                        </div>
                        {/*Event documents table*/}
                    </div>
                </div>
                {showSecurityRecommendationPopup && (
                    <div className={`modal fade ${ispopUpOpen == 0 ? 'show' : ''}`} aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
                        <div className="modal-dialog audit-custom-popup-width">
                            {<div className="modal-content">
                                {/*modal content starts here*/}
                                <div className="modal-header border-0 pb-3 px-4">
                                    <h5 className="modal-title font-20 font-bold" id="exampleModalLabel">Security Recommendations</h5>
                                    <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowRecommendationPopup(false)} />
                                </div>
                                {Object.values(riskSecurityInfo).some(value => value > 0) ?
                                    (<div className="modal-body security-popup border-0 font-16 px-4">
                                        <div className="row">
                                            {riskSecurityInfo?.noMfaCount > 0 && (
                                                <div className="col-md-12 mb-3 cursor-pointer security-list-container" onClick={() => { handleInfoNavigate('user', {isMfa:0}) }}>
                                                    <div className="security-list p-4">
                                                        <p className="font-14 font-medium"><AddZero number={riskSecurityInfo?.noMfaCount}></AddZero> Users have their Multi Factor Authentication Disabled</p>
                                                        <p className="font-regular font-12"> Enhance security by enforcing MFA,
                                                            adding an extra layer to the authentication process for a robust security posture.
                                                        </p>
                                                    </div>
                                                </div>)}
                                            {riskSecurityInfo?.unchangedPasswordCount > 0 && (
                                                <div className="col-md-12 mb-3 cursor-pointer security-list-container">
                                                    <div className="security-list p-4">
                                                        <p className="font-14 font-medium"><AddZero number={riskSecurityInfo?.unchangedPasswordCount}></AddZero> Users haven’t changed their password for past 30 days</p>
                                                        <p className="font-regular font-12">Enforce them to change the password for their user account
                                                        </p>
                                                    </div>
                                                </div>)}
                                            {riskSecurityInfo?.unApprovedApplication > 0 && (
                                                <div className="col-md-12 mb-3 cursor-pointer security-list-container">
                                                    <div className="security-list p-4">
                                                        <p className="font-14 font-medium"><AddZero number={riskSecurityInfo?.unApprovedApplication}></AddZero> Non-Compliant Application Usage</p>
                                                        <p className="font-regular font-12">Implement strict controls to monitor and restrict non-compliant application usage.
                                                        </p>
                                                    </div>
                                                </div>)}
                                            {riskSecurityInfo?.isCompliantCount > 0 && (
                                                <div className="col-md-12 mb-3 cursor-pointer security-list-container" onClick={() => { handleSecurityRecommendationDevice() }}>
                                                    <div className="security-list p-4">
                                                        <p className="font-14 font-medium"><AddZero number={riskSecurityInfo?.isCompliantCount}></AddZero> Non-Compliant Devices Usage</p>
                                                        <p className="font-regular font-12">Implement strict controls to monitor and restrict non-compliant devices usage
                                                        </p>
                                                    </div>
                                                </div>)}
                                            {riskSecurityInfo?.nonComplianceCount > 0 && (
                                                <div className="col-md-12 mb-3 cursor-pointer security-list-container" onClick={() => { handleSecurityRecommendationApplication() }}>
                                                    <div className="security-list p-4">
                                                        <p className="font-14 font-medium"><AddZero number={riskSecurityInfo?.nonComplianceCount}></AddZero> Non-Compliant Application Usage</p>
                                                        <p className="font-regular font-12">Implement strict controls to monitor and restrict non-compliant application usage.
                                                        </p>
                                                    </div>
                                                </div>)}
                                            {riskSecurityInfo?.overDueCount > 0 && (
                                                <div className="col-md-12 mb-3 cursor-pointer security-list-container" onClick={() => { handleSecurityRecommendation() }}>
                                                    <div className="security-list p-4" >
                                                        <p className="font-14 font-medium"><AddZero number={riskSecurityInfo?.overDueCount}></AddZero> LMS OverDue - Security Courses</p>
                                                        <p className="font-regular font-12">Send reminders and prioritize completion of overdue security courses in the LMS
                                                        </p>
                                                    </div>
                                                </div>)}
                                            {riskSecurityInfo?.externalDomainBlocks > 0 && (
                                                <div className="col-md-12 mb-3 cursor-pointer security-list-container" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" onClick={() => { InitialPopUpLoad() }}>
                                                    <div className="security-list p-4">
                                                        <p className="font-14 font-medium"><AddZero number={riskSecurityInfo?.externalDomainBlocks}></AddZero> Top risky External Domains</p>
                                                        <p className="font-regular font-12">Implement email filtering and monitoring for top risky external domains.
                                                        </p>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>) : (<div className="row justify-content-center riskNoDataFoundPadding" >    <div className="col-md-10 col-lg-5 col-sm-12 text-center py-4">
                                        <span>
                                            <img src="/images/No Data Found - Risk.png" alt="no-rec-icon" />
                                        </span>
                                        <h5 className="font-24 font-medium primary-textcolor mt-4">No Security Recommendations Found </h5>
                                    </div></div>
                                    )}
                                {/*modal content ends here*/}
                            </div>}
                        </div>
                    </div>
                )}


                {/* Modal */}
                {
                    showEventPopup && (
                        <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
                            <div className="modal-dialog audit-custom-popup-width">
                                <div className="modal-content py-2">
                                    {/*modal content starts here*/}
                                    <div className="modal-header border-0 pb-3 px-4">
                                        <div className="d-flex">
                                            <h5 className="modal-title font-20 font-bold me-3" id="exampleModalLabel">Risk Event Details</h5>
                                            {selectedSubEventType && <div className="task-badge font-bold d-flex align-items-center font-12 p-2" >
                                                {selectedSubEventName}</div>}
                                        </div>
                                        <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                            setShowEventPopup(false)
                                        }} />
                                    </div>
                                    <div className="modal-body  border-0 font-16 px-4">
                                        {/* <div className="row"> */}
                                        <div className="col-md-12 mb-3">
                                            {eventPopUpData.length > 0 ? (<div id='scrollID10' className="security-popup" style={{ maxHeight: "480px" }}>
                                                <InfiniteScroll

                                                    dataLength={eventPopUpData.length}
                                                    scrollThreshold={0.9}
                                                    scrollableTarget="scrollID10"
                                                    // style={{ overflow: "inherit" }}
                                                    next={() => { setEventPage(eventPage + 1); setPopupLimit(popUpLimit + 10); }} // Increment page when scrolling down
                                                    hasMore={hasMoreEvent}
                                                >
                                                    {/**PS_PE_39 -If the user scrolls down the popUp, then invoking the next set of data if available */}
                                                    <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                                                        {/* {renderUserRows(eventPopUpData, true)} */}
                                                        {selectedEventType === "userRisk" && (
                                                            renderUserRows(eventPopUpData, true)
                                                        )}
                                                        {selectedEventType === "appRisk" && (
                                                            renderApplicationRows(eventPopUpData, true)
                                                        )}
                                                        {selectedEventType === "emailRisk" && (
                                                            renderEmailRows(eventPopUpData, true)
                                                        )}
                                                        {selectedEventType === "deviceRisk" && (
                                                            renderDeviceRows(eventPopUpData, true)
                                                        )}

                                                    </table>

                                                </InfiniteScroll>
                                            </div>) : (<RiskNoDataFound data={"No Risk Users"} />)}

                                            <div className="mt-3">
                                                <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor">{eventTotalCount}</span> items</p>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                    {/*modal content ends here*/}
                                </div>
                            </div>
                        </div>


                    )
                }
                {/*security recommendations*/ }
                {<div
                        className={`modal fade ${ispopUpOpen != 0 ? 'show' : ''}`}
                        id="staticBackdrop1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content custom-popup-width custom-external-domain">
                            <div className="modal-header d-flex justify-content-between align-items-center pb-2 pt-3 pe-2 border-0">
                            <h5
                                className="modal-title font-20 font-bold primary-textcolor"
                                id="staticBackdropLabel"
                            >
                                <button className="border-0 bg-transparent" 
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={()=>{closePopUp()}}>
                                <img src="images/left-arrow.svg" />
                                </button>{" "}
                                External Domain
                            </h5>
                            <div className="d-flex flex-row align-items-center">
                                <button
                                className="advanced-filter-button medium-text customflip-btn me-3"
                                type="button"
                                aria-expanded="false"
                                onClick={()=>{totalCountPopUp ?handleExportExcel() : setTotalCountPopUp(0) }}
                                style={{cursor:totalCountPopUp==0? "not-allowed": "pointer", filter: totalCountPopUp==0? "grayscale(80%)": "none"}}
                                >
                                <img src="images/excel-icon.svg" alt="Filter-icon" />
                                </button>
                                <button
                                type="button"
                                className="btn btn-close me-3"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={()=>{closePopUp()}}
                                />
                            </div>
                            </div>
                            
                            <div className="modal-body px-3 pb-4 pt-2">
                            <div className="table-responsive table-scroll" id="LMSStatus" >
                             
                            <InfiniteScroll
                                        dataLength={popUpData.length}
                                        next={() => {
                                            setEventPopUpPage(eventPopupPage + 7)
                                        }}
                                        hasMore={hasMoreEvent}
                                        scrollThreshold={0.9}
                                        scrollableTarget="LMSStatus"
                             >
                                <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                                <thead>
                                    <tr className="sticky-top">
                                    <th className="font-12 px-4 position-relative font-medium">
                                        Domain Name
                                        <span className="position-absolute">
                                        <a
                                            href="#"
                                            className="sort-up position-absolute ms-2"
                                            
                                        >
                                            <img
                                            className="sort-img-size"
                                            src="images/sort-up.svg"
                                            alt="sort-up-icon"
                                            onClick={() => { onHandleSort("ASC", "external_domains") }}
                                            />
                                        </a>{" "}
                                        <a
                                            href="#"
                                            className="sort-down position-absolute ms-2"
                                            
                                        >
                                            <img
                                            className="sort-img-size"
                                            src="images/sort-down.svg"
                                            alt="sort-down-icon"
                                            onClick={() => { onHandleSort("DESC", "external_domains") }}
                                            />
                                        </a>
                                        </span>
                                    </th>
                                    <th className="font-12 position-relative font-medium text-end">
                                        Activities
                                        <span className="position-absolute">
                                        <a
                                            href="#"
                                            className="sort-up position-absolute ms-2"
                                            
                                        >
                                            <img
                                            className="sort-img-size"
                                            src="images/sort-up.svg"
                                            alt="sort-up-icon"
                                            onClick={() => { onHandleSort("ASC", "external_activity") }}
                                            />
                                        </a>{" "}
                                        <a
                                            href="#"
                                            className="sort-down position-absolute ms-2"
                                            
                                        >
                                            <img
                                            className="sort-img-size"
                                            src="images/sort-down.svg"
                                            alt="sort-down-icon"
                                            onClick={() => { onHandleSort("DESC", "external_activity") }}
                                            />
                                        </a>
                                        </span>
                                    </th>
                                    </tr>
                                </thead>
                                {
                                
                                    popupIsLoading ? <PopupLoader isLoading={popupIsLoading} />:
                                <tbody>
                                    
                                    {popUpData && popUpData.map((dataSet, setIndex) => (
                                        
                                        <tr key={`${setIndex}`}>
                                            <td className="font-12 font-regular primary-textcolor">
                                            {dataSet.external_domains}
                                            </td>
                                            <td className="font-12 font-regular primary-textcolor text-end">
                                            {dataSet.external_activity}
                                            </td>
                                        </tr>
                                        
                                    ))}
                                    
                                </tbody>

                                }
                                </table>
                                </InfiniteScroll>
                            </div>
                            <div className="mt-3">
                                <p className="font-12 grey-primary m-0">
                                Showing{" "}
                                <span className="font-medium primary-textcolor">{totalCountPopUp}</span>{" "}
                                items
                                </p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>}
                            </div >
        </>

    )
};

export default RiskOverview;
