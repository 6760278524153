//PC_GSC_1 to PC_GSC_5
import React, { useState, useEffect, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import { getAdminPermission, updateUserRoles } from '../../Services/Admin/AdminService';
import NoDataFound from '../Admin/adminNoRecFound';
import { agovContext, encryptStorage } from '../../Constant';
import { Loader } from '../Loader/Loader';

//PC_GSC_6 to PC_GSC_21
function AdminPermissionGrid() {
  const { userId, reportsToId, employeeName } = useContext(agovContext);

  const [orderColn, setOrderColn] = useState('employee_name ASC');
  const [permissionValue, setPermissionValue] = useState([]);
  const [count, setCount] = useState(0);
  const [loadMore, setLoadMore] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [department, setDepartment] = useState([]);
  const [role, setRole] = useState([]);
  const [totalRec, setTotalRec] = useState(0)
  const [hidePopUp, setHidePopUp] = useState(true);

  const [editUserId, setEditUserId] = useState(null)
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rolesOptions, setRolesOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  let filterPermissionObj = {
    filterDepartment: "",
    filterRole: "",
    searchValue: searchText,
    sortColn: orderColn,
    updateLoadMore: loadMore
  }
  const [filter, setFilter] = useState(filterPermissionObj);
  const [onClear, setOnClear] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    fetchAdminPermission();
  }, [orderColn, loadMore, onClear]);
  //PC_GSC_22 to PC_GSC_25
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value, searchValue: "" });
  };
  //PC_GSC_26 to PC_GSC_27
  const handleLoadMore = () => {
    setLoadMore(prevLoadMore => prevLoadMore + 10); // Increment loadMore by 5
  };
  //PC_GSC_28 to PC_GSC_29
  const handleSearch = () => {
    fetchAdminPermission();
  };
  //PC_GSC_30 to PC_GSC_32
  const handleClear = () => {
    let clearObj = {
      filterDepartment: "",
      filterRole: ""
    }
    setFilter(clearObj);
    fetchAdminPermission();
    setOnClear(!onClear)
  };
  //PC_GSC_33
  //roles
  const transformedResponse = role?.map(item => ({
    value: item.role_id,
    label: item.role_name
  }));
  //PC_GSC_34 to PC_GSC_35
  const handleEditClick = (value) => {
    setEditUserId(value.employee_id);
    setIsEditing(true);
    setRolesOptions(transformedResponse)
  };
  //PC_GSC_36 to PC_GSC_37
  const handleCrossClick = () => {
    navigate(0)
    // setIsEditing(false);
    // setEditUserId(null);
    // setPermissionValue((prevUsers) =>
    //   prevUsers.map((user) => ({
    //     ...user,
    //     roles: role[user.employee_id] || user.roles
    //   }))
    // );
    // setErrorMessage('');
    // fetchAdminPermission();
    
  };
  //PC_GSC_38 to PC_GSC_44
  const handleTickClick = async (employeeId, userName, updatedRoles) => {
    try {
      setIsLoading(true);
      // Perform actions for tick icon
      let roles = updatedRoles;
      if (roles.length == 0) {
        setErrorMessage('There is no roles')
        setIsLoading(false);
        return
      }
      let updateRoleObj = {
        employeeId: employeeId,
        userName: userName,
        roles: roles,
        userId: userId,
        managerId: reportsToId,
        appliedBy: employeeName,
        appliedOn: userName
      }

      const updatePermissionRoles = await updateUserRoles({ updateRoleObj })
      setEditUserId(null);
      setPermissionValue([...permissionValue]);
      setIsEditing(false);
      setErrorMessage('');
      fetchAdminPermission()
    }
    catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  //PC_GSC_45 to PC_GSC_54
  const handleRolesChange = (selectedRoles, userId) => {
    // Check if 'Super Admin' or 'User' is selected
    const isSuperAdminSelected = selectedRoles.some((role) => role.label === 'Super Admin');
    const isUserSelected = selectedRoles.some((role) => role.label === 'User');
    // Update disabled property based on selected roles
    const updatedRolesOptions = rolesOptions.map((option) => {
      const isSuperAdminOrUser = role => role.label === 'Super Admin' || role.label === 'User';
      const isDisabled = (isSuperAdminSelected || isUserSelected) && !isSuperAdminOrUser(option);
      return { ...option, disabled: isDisabled };
    });
    // Update roles options and user data
    setRolesOptions(updatedRolesOptions);
    // If 'Super Admin' or 'User' is selected, uncheck and disable all other roles
    // If both 'Super Admin' and 'User' are selected, keep only the first selected role
    const filteredSelectedRoles = isSuperAdminSelected || isUserSelected
      ? [selectedRoles.find(role => role.label === 'Super Admin') || selectedRoles.find(role => role.label === 'User') || selectedRoles[0]]
      : selectedRoles;
    const updatedUsers = permissionValue.map((user) =>
      user.employee_id === userId ? { ...user, roles: filteredSelectedRoles } : user
    );
    setPermissionValue(updatedUsers);
    // Clear the error message on role change
    setErrorMessage('');
  };
  //PC_GSC_55 to PC_GSC_79
  const fetchAdminPermission = async () => {
    setIsLoading(true);
    let filterPermissionObj = { ...filter }
    filterPermissionObj.sortColn = orderColn
    filterPermissionObj.updateLoadMore = loadMore
    filterPermissionObj.searchValue = searchText
    try {
      let result = await getAdminPermission(filterPermissionObj);
      if (result.statusCode === 200) {
        let permissionGrid = result.responseData.gridData;
        let count = result.responseData.count;
        let roles = result.responseData.roles;
        let department = result.responseData.domain;
        setPermissionValue(permissionGrid);
        setTotalRec(permissionGrid.length)
        setCount(count);
        setDepartment(department);
        setRole(roles);
      } else {
        //console.log('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  function bindDepartment() {
    return (
      department?.map((value, index) => {
        return (
          <option value={value.employee_domain}
            name="filterDepartment">{value.employee_domain}</option>
        )
      })
    )
  }

  function bindRoles() {
    return (
      role?.map((value, index) => {
        return (
          <option value={value.role_id}
            name="filterRole">{value.role_name}</option>
        )

      })
    )
  }

  function gridDynamicBind() {
    return (
      permissionValue?.map((value, index) => {

        return (
          <tr>
            <td className="font-12">
              <div className="d-flex align-items-center">
                <img src={value.profile_url} className="table-prof" />
                <p className="font-13 font-medium secondary-textcolor mb-0 ms-2 cursor-pointer" onClick={() => {
                  //get userid using useContext
                  navigate(`/userprofile`, { state: { value: { id: value.user_id, employeeid: value.employee_id } } })
                }}>{value?.employee_name}</p>
              </div>
            </td>
            <td className="font-13">{value?.employee_domain}</td>
            <td className="font-13">
              {value.employee_id === editUserId && isEditing ? (
                <>
                  <MultiSelect
                    options={rolesOptions}
                    // // Custom styles to hide the clear icon
                    styles={{
                      multiValueRemove: (base) => ({
                        ...base,
                        display: 'none', // Hide the clear icon
                      }),
                    }}
                    value={value.roles}

                    onChange={(selectedRoles) =>
                      handleRolesChange(selectedRoles, value.employee_id)
                    }
                    labelledBy="Select"
                    hasSelectAll={false}
                    isCreatable={true}
                  />
                  {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                </>
              ) : (
                value?.roles?.map((role) => role?.label).join(", ")
              )}
            </td>
            <td className="text-center" >
              {value?.employee_id === editUserId && isEditing ? (
                <>
                  <a className="me-3" onClick={() => { handleTickClick(value?.employee_id, value?.employee_name, value?.roles) }}>
                    <img src="/images/tick-icon.svg" alt="tick-icon" />
                  </a>
                  <a className="me-3" onClick={handleCrossClick}>
                    <img src="/images/cross-icon.svg" alt="cross-icon" />
                  </a>
                </>
              ) : (
                <a className="me-2" onClick={() => { 
                  if (value?.employee_id !== encryptStorage.getItem('employeeId')) {
                      handleEditClick(value); 
                  }
              }}>
                  <img src="/images/edit-icon.svg" alt="edit-icon" />
                </a>
              )}
            </td>
          </tr>
        )
      }
      )
    )
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="container-fluid mt-4 mt-custom">
        <div className="col-md-12">
          <div className="px-3 px-xl-5">
            <div className="d-flex justify-content-between mt-4">
              <h1 className="font-bold font-24 primary-textcolor">
                Admin Configurations
              </h1>
            </div>
            {/*tabs starts here*/}
            <div className="d-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link px-0  font-14 tab-style rounded-0"
                    id="information-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#information"
                    type="button"
                    role="tab"
                    aria-controls="information"
                    aria-selected="true"
                    onClick={() => navigate('/adminmanagement')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/information-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/information-icon.svg"
                        alt="information-icon"
                        className="me-2"
                      />
                    </span>
                    Information
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 active font-14 tab-style bold-text rounded-0"
                    id="permissions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#permissions"
                    type="button"
                    role="tab"
                    aria-controls="permissions"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/permission-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/permission-icon.svg"
                        alt="permissions-icon"
                        className="me-2"
                      />
                    </span>
                    Permissions
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="configurations-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#configurations"
                    type="button"
                    role="tab"
                    aria-controls="configurations"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/accessReview')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/key-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/key-icon.svg"
                        alt="configurations-icon"
                        className="me-2"
                      />
                      Configurations
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="app-owner-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#app-owner-config"
                    type="button"
                    role="tab"
                    aria-controls="app-owner-config"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/appOwnerConfig')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/app-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />
                      <img
                        src="/images/app-icon.svg"
                        alt="app-owner-config-icon"
                        className="me-2"
                      />
                      App Owner Config
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="security-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#security"
                    type="button"
                    role="tab"
                    aria-controls="security"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/securityConfig')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/lock-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/lock-icon.svg"
                        alt="security-icon"
                        className="me-2"
                      />
                      Security
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="policy-config-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#policy-config"
                    type="button"
                    role="tab"
                    aria-controls="policy-config"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/policyConfig')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/privacy-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/privacy-icon.svg"
                        alt="policy-config-icon"
                        className="me-2"
                      />
                      Policy Configuration
                    </span>
                  </button>
                </li>
                <li className="nav-item ms-4" role="presentation">
                  <button
                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                    id="billing-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#billing"
                    type="button"
                    role="tab"
                    aria-controls="billing"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => navigate('/adminmanagement/billing')}
                  >
                    <span className="tab-img">
                      {" "}
                      <img
                        src="/images/bill-icon-filled.svg"
                        alt="filled-icon"
                        className="me-2"
                      />{" "}
                      <img
                        src="/images/bill-icon.svg"
                        alt="billing-icon"
                        className="me-2"
                      />
                      Billing
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            {/*tabs ends here*/}

            {/*Permissions tab starts here*/}
            <div
              className="tab-pane  mt-3"
              id="permissions"
              role="tabpanel"
              aria-labelledby="permissions-tab"
            >
              <div className="d-flex justify-content-between align-items-center">
                
                <div>
                  <h5 className="font-medium font-24 primary-textcolor">Permissions</h5>
                  <p className="font-regular font-14 custom-grey-text">
                      Manage privileged AGov users and their level of access. <a href="#" className="font-medium text-decoration-none" 
                       data-bs-toggle="modal" data-bs-target="#exampleModal2">Permission Guide</a>
                  </p>
                </div>

          {/* Button trigger modal */}
          
          {/* Modal */}
          <div className="modal fade" id="exampleModal2" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content p-4">
                <div className="modal-header justify-content-between align-items-start border-0 p-0">
                  <div className="pb-4">
                    <h5 className="modal-title font-bold font-20 primary-textcolor mb-2" id="exampleModalLabel">
                      Roles
                    </h5>
                    <p className="font-14 font-regular grey-light-color">Roles are bundles of permissions
                      related to managing your security and compliance
                      program. They ensure that team members have access to only what they need to
                      complete their work.</p>
                  </div>
                  <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body p-0 modal-container">
                  <div className="wrapper">
                    <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                      <thead>
                        <tr className="sticky-top">
                          <th className="first-table-data" />
                          <th className="font-14 text-center">Dashboard
                          </th>
                          <th className="font-14 text-center">Compliance
                          </th>
                          <th className="font-14 text-center">Access Governance
                          </th>
                          <th className="font-14 text-center">Risk Management</th>
                          <th className="font-14 text-center">Task</th>
                          <th className="font-14 text-center">Reports</th>
                          <th className="font-14 text-center">LMS</th>
                          <th className="font-14 text-center">User Profile</th>
                          <th className="font-14 text-center">Notification</th>
                          <th className="font-14 text-center">Admin</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="font-medium font-14 first-table-data">Super Admin</td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                        </tr>
                        <tr>
                          <td className="font-medium font-14 first-table-data">Security Admin</td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                        </tr>
                        <tr>
                          <td className="font-medium font-14 first-table-data">GRC Admin</td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                        </tr>
                        <tr>
                          <td className="font-medium font-14 first-table-data">GRC Auditor</td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                        </tr>
                        <tr>
                          <td className="font-medium font-14 first-table-data">LMS Admin</td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                        </tr>
                        <tr>
                          <td className="font-medium font-14 first-table-data">Manager</td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                        </tr>
                        <tr>
                          <td className="font-medium font-14 first-table-data">End User</td>
                          <td className="text-center"><img src="/images/table-check-green.svg" alt="tick-icon-green" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/remove.svg" alt="cross-icon-red" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                          <td className="text-center"><img src="/images/table-partial-permission.svg" alt="orange-icon" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

      
                <div className="d-flex">
                  <div className="input-group flex-nowrap search-group me-2">
                    <input
                      type="search"
                      className="form-control search-align"
                      placeholder="Search"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                      value={searchText}
                      onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                          handleSearch()
                        }
                      }}
                      onChange={(e) => { setSearchText(e.target.value) }}
                    />
                    <button
                      className="input-group-text btn btn-search-custom"
                      id="addon-wrapping"
                    >
                      <img src="/images/search-icon.svg" alt="search-icon" onClick={() => { handleSearch() }} />
                    </button>
                  </div>
                  <button
                    className="cust-filter-btn d-flex align-items-center  cursor-pointer"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/images/filter-icon.svg"
                      alt="filter-icon"
                      className="filt-icon me-2"
                      onClick={() => { setHidePopUp(false) }} />
                    Filter
                  </button>

                  {/* filter pop up starts */}
                  <div className="custom-filter dropdown-menu border-0"> {/** dropdown-menu  */}
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="font-18 font-bold text-black mb-0">
                          Advanced Filter
                        </h5>
                        <button
                          type="button"
                          className="btn-close font-12"
                          aria-label="Close"
                          onClick={() => setHidePopUp(true)}
                        />
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label text-black font-12 font-medium">
                              Department
                            </label>
                            <select
                              className="form-select cust-input-sty font-14 py-2 font-regular"
                              aria-label="Default select example"
                              value={filter.filterDepartment}
                              name="filterDepartment"
                              onChange={(e) => {
                                handleOnChange(e);
                              }}
                            >
                              <option name="filterDepartment" value={""} selected="">Select</option>
                              {bindDepartment()}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label text-black font-12 font-medium">
                              Role
                            </label>
                            <select
                              className="form-select cust-input-sty font-14 py-2 font-regular"
                              aria-label="Default select example"
                              value={filter.filterRole}
                              name="filterRole"
                              onChange={(e) => {
                                handleOnChange(e);
                              }}
                            >
                              <option name="filterRole" value={""} selected="">Select</option>
                              {bindRoles()}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="text-end mt-4 mb-2">
                        <button
                          type="button"
                          className="primary-btn font-14 font-medium me-2  cursor-pointer"
                          onClick={() => handleClear()}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="dark-btn font-14 font-medium px-4  cursor-pointer"
                          onClick={() => {
                            setFilter(filter);
                            fetchAdminPermission();
                            setHidePopUp(true);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* filter pop up ends */}
                </div>
              </div>
              {/*Permissions table*/}
              
                <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                  <thead>
                    <tr>
                      <th className="font-14 px-4 position-relative">
                        Username
                        <span className="position-absolute">
                          <a className="sort-up position-absolute ms-2" onClick={() => setOrderColn("employee_name ASC")}>
                            <img
                              className="sort-img-size"
                              src="/images/sort-up.svg"
                              alt="sort-up-icon"
                            />
                          </a>{" "}
                          <a className="sort-down position-absolute ms-2" onClick={() => setOrderColn("employee_name DESC")}>
                            <img
                              className="sort-img-size"
                              src="/images/sort-down.svg"
                              alt="sort-down-icon"
                            />
                          </a>
                        </span>
                      </th>
                      <th className="font-14 position-relative">
                        Department
                      </th>
                      <th className="font-14 position-relative">
                        Role
                      </th>
                      <th className="font-14 position-relative text-center">Actions</th>
                    </tr>
                  </thead>
                  {count > 0 ? (
                    <tbody>{gridDynamicBind()}</tbody>
                  ) : (
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}>
                        <NoDataFound />
                      </td>
                    </tr>
                  )}
                </table>
             
              <div className="mt-3">
                <p className="font-12 grey-primary">
                  Showing <span className="font-medium primary-textcolor">{totalRec} </span>
                   out of <span className="font-medium primary-textcolor"> {count}</span> items
                </p>
              </div>
              <div className="text-center mt-5 mb-4">
                {totalRec < count && (
                  <button
                    className="primary-btn font-medium  cursor-pointer"
                    onClick={() => handleLoadMore()}
                  >
                    Load More
                  </button>
                )}
              </div>
            </div>
            {/*Permissions table ends here*/}
          </div>
        </div>
      </div >
    </>

  )
}

export default AdminPermissionGrid;