import { format } from "date-fns";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { encryptStorage } from '../../Constant';
import {
    createEvidenceOrPolicy,
    getStatusDetails,
    getEvidencesGrid,
    getRecurrenceData,
    getUserLists,
} from "../../Services/GRC/GrcService";
import AddEvidencePopup from "../Compliance/AddEvidencePopup";
import ControlAuditStatus from "./ControlAuditStatus";
import { agovContext } from '../../Constant'
import { Loader } from "../Loader/Loader";
import NoDataFound from "../LMS/NoDataFound";
import RiskNoDataFound from "../Risk/RiskNoDataFound";

const EvidenceGrid = () => {
    // PC_EG_02
    // create the respective state variables
    const navigate = useNavigate();
    const [evidenceGridData, setEvidenceGridData] = useState([]);
    const [filterData, setFilterData] = useState({
        sortColumn: "e.created_on",
        sortOrder: "desc",
        limit: 10,
        startDate: encryptStorage.getItem('commencer'),
        endDate: encryptStorage.getItem('fin'),
        searchTerm: "",
        fromDate: "",
        toDate: "",
        status: "",
        assignedTo: "",
    });
    const [count, setCount] = useState(0);
    const [filterPopupData, setFilterPopupData] = useState({
        status: null,
        assignedTo: null,
        fromDate: null,
        toDate: null,
    });
    const [showAddEvidencePopup, setShowAddEvidencePopup] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [auditorList, setAuditorList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [recurrenceList, setRecurrenceList] = useState([]);
    const [auditStatus, setAuditStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { roles, userId } = useContext(agovContext);

    const isAdmin = roles?.includes('Super Admin') || roles?.includes('GRC Admin')
    const viewAsAuditor = encryptStorage.getItem('viewAsAuditor') == "true" ? true : false

    let response;

    /**
 * PC_EG_03
 * The useEffect calls the API to get the data for the grid when ever the filterData changes
 */
    useEffect(() => {
        fetchEvidenceGridData();
    }, [filterData]);

    /**
 * PC_EG_04
 * The useEffect calls the API to get the data for the dropdown
 */
    useEffect(() => {
        // fetchDropdownDetails();
    }, []);

    /**
 * PC_EG_07
 * Get the evidence grid data
 */
    const fetchEvidenceGridData = async () => {
        setIsLoading(true)
        try {
            response = await getEvidencesGrid(filterData);
            setEvidenceGridData(response.responseData.data);
            setCount(response.responseData.count);
            const response1 =  await getUserLists();
            const response2 = await getStatusDetails({identifier: 'evidence'});
            const response3 = await getRecurrenceData();
            setAuditorList(response1.responseData[0]);
            setStatusList(response2.responseData[0]);
            setRecurrenceList(response3.responseData[0]);
        } catch (error) {
            //console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    };

    /**
 * PC_PG_20
 * Make the API calls to get all the user lists, recurrence list, and evidence status list to bind it in the dropdown
 */
    const fetchDropdownDetails = async () => {
        try {
            setIsLoading(true)
            const response1 =  await getUserLists();
            const response2 = await getStatusDetails({identifier: 'evidence'});
            const response3 = await getRecurrenceData();
            setAuditorList(response1.responseData[0]);
            setStatusList(response2.responseData[0]);
            setRecurrenceList(response3.responseData[0]);
            setIsLoading(false)
        } catch (error) {
            //console.log(error, "error");
        }
        finally {
            
        }
    };

    const handleSearch = () => {
        setFilterData({ ...filterData, searchTerm: filterData.searchTerm.trim() });
    };

    /**
 * PC_PG_52
 * -Filter the table data according to the searched value
 */
    const formatTimeline = (startDate) => {
        const date = new Date(startDate);
        return format(date, "MM/dd/yyyy");
    };

    /**
 * PC_PG_40
 * Close the add evidence popup
 */
    const handleClosePopup = () => {
        setShowAddEvidencePopup(false);
    };

    /**
 * PC_PG_73
 * Create a new evidence
 */
    const handleAddEvidence = async (data) => {
        setIsLoading(true)
        const addEvidenceData = {
            evidenceName: data.evidenceName,
            userId: userId,
            evidenceDescription: data.description,
            endDate: filterData.endDate,
            startDate: filterData.startDate,
            evidenceType: "Evidence",
            recurrenceId: data.recurrenceId,
        };
        try {
            setShowAddEvidencePopup(false);
            await createEvidenceOrPolicy(addEvidenceData);
            await fetchEvidenceGridData();
        } catch (error) {
            //console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    };

    /**
 * PC_PG_97
 * Filter the grid data according to the data entered in the filter by the user
 */
    const handleFilter = () => {
        setFilterData({ ...filterData, assignedTo: filterPopupData.assignedTo, fromDate: filterPopupData.fromDate, status: filterPopupData.status, toDate: filterPopupData.toDate })
        setShowFilterPopup(false)
    }

    /**
 * PC_PG_92
 * Clear all the filters in the grid
 */
    const handleClearFilter = () => {
        setFilterPopupData({ ...filterPopupData, status: null, assignedTo: null, fromDate: null, toDate: null })
        setFilterData({ ...filterData, status: null, assignedTo: null, fromDate: null, toDate: null })
        setShowFilterPopup(false)
    }

    return (
        <div className="container-fluid mt-4 mt-custom">
            <Loader isLoading={isLoading} />
            <div className="col-md-12 px-lg-5">
                <div className="d-xl-flex d-block justify-content-between mt-4 mb-3">
                    <h1 className="font-bold font-24 primary-textcolor d-flex  align-items-center mb-xl-0 mb-3">
                        <a>
                            <img src="/images/back-arrow.svg" alt="Back" className="me-3 pointer" onClick={() => navigate(-1)} />
                        </a>{" "}
                        <span>SOC 2</span>
                    </h1>
                    <div className="me-2">
                        {/*Evidences structure starts here*/}
                        <div className="d-lg-flex d-block">
                            <ControlAuditStatus setAuditStatus={setAuditStatus} isAuditor={!isAdmin}/>
                            <div className="d-flex">
                                <div className="input-group flex-nowrap search-group me-2">
                                    <input
                                        type="text"
                                        className="form-control search-align"
                                        placeholder="Search Evidence Name"
                                        aria-label="search"
                                        aria-describedby="addon-wrapping"
                                        onChange={(e) => (filterData.searchTerm = e.target.value)}
                                        onKeyDownCapture={(e) => {
                                            if (e.key == "Enter") {
                                                handleSearch();
                                            }
                                        }}
                                    />
                                    <button
                                        className="input-group-text btn btn-search-custom"
                                        id="addon-wrapping"
                                        onClick={() => handleSearch()}
                                    >
                                        <img src="/images/search-icon.svg" alt="search-icon" />
                                    </button>
                                </div>
                            </div>
                            <div className="me-2 position-relative">
                                <button
                                    className="cust-filter-btn d-flex align-items-center"
                                    type="button"
                                    onClick={() => setShowFilterPopup(!showFilterPopup)}
                                >
                                    <img
                                        src="/images/filter-icon.svg"
                                        alt="filter-icon"
                                        className="filt-icon me-2"
                                    />
                                    Filter
                                </button>
                                {showFilterPopup ? (
                                    <div className="z-index-9 custom-filter custom-filter-container border-0">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="font-18 font-bold text-black mb-0">
                                                    Advanced Filter
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close font-12"
                                                    aria-label="Close"
                                                    onClick={() => setShowFilterPopup(false)}
                                                ></button>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">
                                                            Assigned To
                                                        </label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 py-2 font-regular"
                                                            aria-label="Default select example"
                                                            onChange={(event) =>
                                                                setFilterPopupData({
                                                                    ...filterPopupData,
                                                                    assignedTo: event.target.value,
                                                                })
                                                            }
                                                            value={filterPopupData.assignedTo}
                                                        >
                                                            <option selected value="">Select</option>
                                                            {auditorList.map((data) => (
                                                                <option value={data.userId}>
                                                                    {data.employeename}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">
                                                            Status
                                                        </label>
                                                        <select
                                                            className="form-select cust-input-sty font-14 py-2 font-regular"
                                                            aria-label="Default select example"
                                                            onChange={(event) => setFilterPopupData({ ...filterPopupData, status: event.target.value })}
                                                            value={filterPopupData.status}
                                                        >
                                                            <option selected value="">Select</option>
                                                            {statusList.map((data) => (
                                                                <option value={data.evidenceStatus}>
                                                                    {data.evidenceStatus}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    Date Range
                                                </div>
                                                <div className="col-md-6 col-sm-12">

                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">
                                                            From
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control cust-input-sty font-14 py-2 font-regular"
                                                            value={filterPopupData.fromDate}
                                                            onChange={(event) => setFilterPopupData({ ...filterPopupData, fromDate: event.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-black font-12 font-medium">
                                                            To
                                                        </label>
                                                        <input
                                                            type="date"
                                                            min={filterPopupData.fromDate}
                                                            className="form-control cust-input-sty font-14 py-2 font-regular"
                                                            value={filterPopupData.toDate}
                                                            onChange={(event) => setFilterPopupData({ ...filterPopupData, toDate: event.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-end mt-4 mb-2">
                                                <button
                                                    type="button"
                                                    className="primary-btn font-14 font-medium me-2"
                                                    onClick={() => handleClearFilter()}
                                                >
                                                    Clear
                                                </button>
                                                <button
                                                    type="button"
                                                    className="dark-btn font-14 font-medium px-4"
                                                    onClick={() => handleFilter()}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {isAdmin ? <div>
                                <button
                                    className="create-task-button medium-text customflip-btn"
                                    type="button"
                                    id="offcanvasLmsBtn"
                                    aria-expanded="false"
                                    data-bs-target="#offcanvasLms"
                                    aria-controls="offcanvasLms"
                                    disabled={viewAsAuditor}
                                    onClick={() => setShowAddEvidencePopup(true)}
                                >
                                    <img
                                        src="/images/Plus-icon.svg"
                                        alt="Plus-icon"
                                        className="plus-icon me-2"
                                    />
                                    Add Evidence
                                </button>
                            </div> : null}
                        </div>
                        {/*Evidences tab structure Ends here*/}
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-12">
                        {/*Compliance tab starts here*/}
                        <div className="d-flex justify-content-between align-items-center ps-1 my-3 mb-4 custom-tabs">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item me-2" role="presentation">
                                    <button
                                        className="nav-link px-0  font-14 tab-style rounded-0"
                                        id="Compliancecontrols-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#Compliancecontrols"
                                        type="button"
                                        role="tab"
                                        aria-controls="Compliancecontrols"
                                        aria-selected="false"
                                        onClick={() => { navigate('/compliance/controls') }}
                                    >
                                        <span className="tab-img">
                                            <img src="/images/control-icon.svg" className="me-2" />
                                        </span>
                                        Controls
                                    </button>
                                </li>
                                <li className="nav-item ms-4 me-2" role="presentation">
                                    <button
                                        className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                                        id="Compliancepolicies-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#Compliancepolicies"
                                        type="button"
                                        role="tab"
                                        aria-controls="Compliancepolicies"
                                        aria-selected="false"
                                        onClick={() => navigate('/compliance/policyGrid')}
                                    >
                                        <span className="tab-img">
                                            {" "}
                                            <img
                                                src="/images/policy-active-icon.svg"
                                                className="me-2"
                                            />{" "}
                                            <img src="/images/policies-icon.svg" className="me-2" />{" "}
                                        </span>
                                        Policies
                                    </button>
                                </li>
                                <li className="nav-item ms-4 me-2" role="presentation">
                                    <button
                                        className="nav-link px-0 font-14 tab-style bold-text rounded-0 active"
                                        id="Complianceevidences-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#Complianceevidences"
                                        type="button"
                                        role="tab"
                                        aria-controls="Complianceevidences"
                                        aria-selected="true"
                                    >
                                        <span className="tab-img">
                                            {" "}
                                            <img
                                                src="/images/evidence-active-icon.svg"
                                                className="me-2"
                                            />{" "}
                                            <img src="/images/evidence-icon.svg" className="me-2" />{" "}
                                        </span>
                                        Evidences
                                    </button>
                                </li>
                                <li className="nav-item ms-4" role="presentation">
                                    <button
                                        className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                                        id="Complianceotherdocs-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#Complianceotherdocs"
                                        type="button"
                                        role="tab"
                                        aria-controls="Complianceotherdocs"
                                        aria-selected="false"
                                        onClick={() => navigate('/compliance/otherDocumentsGrid')}
                                    >
                                        <span className="tab-img">
                                            {" "}
                                            <img
                                                src="/images/other-docs-active-icon.svg"
                                                className="me-2"
                                            />{" "}
                                            <img
                                                src="/images/other-docs-icon.svg"
                                                className="me-2"
                                            />{" "}
                                        </span>
                                        Other Documents
                                    </button>
                                </li>
                            </ul>
                        </div>
                        {/* Compliance tab ends here */}
                    </div>
                    <div className="col-md-12">
                        <div className="tab-content" id="pills-tabContent">
                            <div>
                                <div className="col-md-12">
                                    {/*Evidence table*/}
                                    <h5 className="font-bold font-20 primary-textcolor">
                                        Evidences
                                    </h5>
                                    <div className="table-responsive">
                                        {evidenceGridData.length ? <table className="table mb-0 custom-table-grid table-borderless mt-3">
                                            <thead>
                                                <tr>
                                                    <th className="font-14 px-4 position-relative">
                                                        Evidence Name
                                                        <span className="position-absolute">
                                                            <a
                                                                onClick={() =>
                                                                    setFilterData({
                                                                        ...filterData,
                                                                        sortColumn: "EvidenceName",
                                                                        sortOrder: "asc",
                                                                    })
                                                                }
                                                                className="sort-up position-absolute ms-2 pointer"
                                                            >
                                                                <img
                                                                    className="sort-img-size"
                                                                    src="/images/sort-up.svg"
                                                                    alt="sort-up-icon"
                                                                />
                                                            </a>{" "}
                                                            <a
                                                                onClick={() =>
                                                                    setFilterData({
                                                                        ...filterData,
                                                                        sortColumn: "EvidenceName",
                                                                        sortOrder: "desc",
                                                                    })
                                                                }
                                                                className="sort-down position-absolute ms-2 pointer"
                                                            >
                                                                <img
                                                                    className="sort-img-size"
                                                                    src="/images/sort-down.svg"
                                                                    alt="sort-down-icon"
                                                                />
                                                            </a>
                                                        </span>
                                                    </th>
                                                    <th className="font-14 position-relative">
                                                        Date Uploaded
                                                        <span className="position-absolute">
                                                            <a
                                                                className="sort-up position-absolute ms-2 pointer"
                                                                onClick={() =>
                                                                    setFilterData({
                                                                        ...filterData,
                                                                        sortColumn: "UploadedOn",
                                                                        sortOrder: "asc",
                                                                    })
                                                                }
                                                            >
                                                                <img
                                                                    className="sort-img-size"
                                                                    src="/images/sort-up.svg"
                                                                    alt="sort-up-icon"
                                                                />
                                                            </a>{" "}
                                                            <a
                                                                className="sort-down position-absolute ms-2 pointer"
                                                                onClick={() =>
                                                                    setFilterData({
                                                                        ...filterData,
                                                                        sortColumn: "UploadedOn",
                                                                        sortOrder: "desc",
                                                                    })
                                                                }
                                                            >
                                                                <img
                                                                    className="sort-img-size"
                                                                    src="/images/sort-down.svg"
                                                                    alt="sort-down-icon"
                                                                />
                                                            </a>
                                                        </span>
                                                    </th>
                                                    <th className="font-14 position-relative">
                                                        Assigned to
                                                        <span className="position-absolute">
                                                            <a
                                                                className="sort-up position-absolute ms-2 pointer"
                                                                onClick={() =>
                                                                    setFilterData({
                                                                        ...filterData,
                                                                        sortColumn: "EmployeeName",
                                                                        sortOrder: "asc",
                                                                    })
                                                                }
                                                            >
                                                                <img
                                                                    className="sort-img-size"
                                                                    src="/images/sort-up.svg"
                                                                    alt="sort-up-icon"
                                                                />
                                                            </a>{" "}
                                                            <a
                                                                className="sort-down position-absolute ms-2 pointer"
                                                                onClick={() =>
                                                                    setFilterData({
                                                                        ...filterData,
                                                                        sortColumn: "EmployeeName",
                                                                        sortOrder: "desc",
                                                                    })
                                                                }
                                                            >
                                                                <img
                                                                    className="sort-img-size"
                                                                    src="/images/sort-down.svg"
                                                                    alt="sort-down-icon"
                                                                />
                                                            </a>
                                                        </span>
                                                    </th>
                                                    <th className="font-14 position-relative">
                                                        Status
                                                        <span className="position-absolute">
                                                            <a
                                                                className="sort-up position-absolute ms-2 pointer"
                                                                onClick={() =>
                                                                    setFilterData({
                                                                        ...filterData,
                                                                        sortColumn: "EvidenceStatus",
                                                                        sortOrder: "asc",
                                                                    })
                                                                }
                                                            >
                                                                <img
                                                                    className="sort-img-size"
                                                                    src="/images/sort-up.svg"
                                                                    alt="sort-up-icon"
                                                                />
                                                            </a>{" "}
                                                            <a
                                                                className="sort-down position-absolute ms-2 pointer"
                                                                onClick={() =>
                                                                    setFilterData({
                                                                        ...filterData,
                                                                        sortColumn: "EvidenceStatus",
                                                                        sortOrder: "desc",
                                                                    })
                                                                }
                                                            >
                                                                <img
                                                                    className="sort-img-size"
                                                                    src="/images/sort-down.svg"
                                                                    alt="sort-down-icon"
                                                                />
                                                            </a>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {evidenceGridData.map((gridData) => (
                                                    <tr>
                                                        <td className="font-12 font-medium secondary-textcolor d-flex align-items-center">
                                                            <a
                                                                className="text-decoration-none me-3 pointer wrap-text" style={{cursor : 'pointer'}}
                                                                onClick={() =>
                                                                    navigate("/compliance/evidenceDetails", {
                                                                        state: {
                                                                            evidenceId: gridData.evidenceId,
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                {gridData.EvidenceName}
                                                            </a>
                                                            {gridData.evidenceType === 'custom' ? <span className="policy-custom-btn d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">Custom
                                                    </span> : null}
                                                        </td>
                                                        <td className="font-12">
                                                            {gridData.UploadedOn ? formatTimeline(gridData.UploadedOn) : 'Nill'}
                                                        </td>
                                                        <td className="font-12">
                                                            <div className="d-flex align-items-center">
                                                                {gridData.EmployeeName ? <img
                                                                    src={gridData.profileUrl}
                                                                    className="table-prof"
                                                                /> : null}
                                                                <p className="font-12 primary-textcolor mb-0 ms-2">
                                                                    {gridData.EmployeeName || 'Unassigned'}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td className="font-12">
                                                            <span className={gridData?.EvidenceStatus === "Accepted" ? "green-dot big-dot me-2" : gridData?.EvidenceStatus === "Upload Pending" ? "orange-dot big-dot me-2" : "grey-dot big-dot me-2"} />
                                                            {gridData.EvidenceStatus}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> : <RiskNoDataFound data={"No Records Found"}/>}
                                        {showAddEvidencePopup ? (
                                            <AddEvidencePopup
                                                recurrenceList={recurrenceList}
                                                onClose={handleClosePopup}
                                                onSave={handleAddEvidence}
                                            />
                                        ) : null}
                                    </div>
                                    <div className="mt-3">
                                        <p className="font-12 grey-primary">
                                            Showing{" "}
                                            <span className="font-medium primary-textcolor">
                                                {count >= filterData.limit ? filterData.limit : count}
                                            </span>
                                            {" "}out of{" "}
                                            <span className="font-medium primary-textcolor">
                                                {count}
                                            </span>{" "}
                                            items
                                        </p>
                                    </div>
                                    {filterData.limit < count ? <div className="text-center mt-3">
                                        <button className="primary-btn font-medium" onClick={() => {
                                            setFilterData({
                                                ...filterData,
                                                limit: filterData.limit + 10,
                                            });
                                        }}>
                                            Load More
                                        </button>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvidenceGrid;
