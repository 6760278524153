import { useNavigate } from "react-router-dom";
import { countDetails, fetchUserDetails, getConnectorData, insertConnectorData, updateConnectorData } from "../../Services/Admin/AdminService";
import { ConnectorPopUp } from "../Admin/SecurityConnectorPopUp";
import { Loader } from "../Loader/Loader";
import { useState, useEffect } from "react";
import axios from "axios";
import { encryptStorage } from "../../Constant";

export default function InitialConnector() {
  //PC_SC_5 to PC_SC_9
  const navigate = useNavigate();
  const [showPopUp, setShowPopUp] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  let azureConnectorObj = {
    orgId: null,
    connectorTypeId: null,
    connectorId: null,
    tenantId: "",
    clientId: "",
    clientSecret: "",
    microsoftDomain: "",
    cloudAppstoken: "",
    connectorTypeId: 1,
  };
  //PC_SC_35 to PC_SC_36
  const [formData, setFormData] = useState(azureConnectorObj);
  const [isLoading, setIsLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(true);
  const [validate, setValidate] = useState(true);
  const [nextButton, setNextButton] = useState(true);
  const [infovalidate, setInfoValidate] = useState(true);
  const [isTenantIdDisabled, setIsTenantIdDisabled] = useState(true);
  let interval;

  useEffect(() => {
    fetchConnector();
  }, []);

  const increasePercentage = () => {
    if (Number(percentage) < 98) {
      setPercentage((prevPercentage) => Number(prevPercentage) + 1);
    } else {
      clearInterval(interval);
    }
  };

  const insertConnectorDetails = async (formData) => {
    try {
      setIsLoading(true);
      let connectorObj = formData;
      if(nextButton == true){
        const result = await updateConnectorData(connectorObj);
        if (result.statusCode === 200) {
          setIsLoading(false);
          setShowPopUp(false);
          setShowProgress(false);
          interval = setInterval(increasePercentage, 2000);
  
          let fetchUser = {
            orgId: connectorObj.orgId,
            connectorTypeId: connectorObj.connectorTypeId,
            connectorId: connectorObj.connectorId,
            tenantId: connectorObj.tenantId,
            clientId: connectorObj.clientId,
            clientSecret: connectorObj.clientSecret,
            microsoftDomain: connectorObj.microsoftDomain,
            cloudAppstoken: connectorObj.cloudAppstoken,
            connectorTypeId: 1,
            emailValue: encryptStorage.getItem("emailId"),
          };
  
         let userResponse = await fetchUserDetails(fetchUser);
         let countResponse = await countDetails(fetchUser);
  
          clearInterval(interval);
  
          let newPercentage = 0;
            if (
              userResponse.statusCode === 200 &&
              countResponse.statusCode === 200
            ) {
              newPercentage = 100; // Set to 100% when both responses are successful
            } else if (
              userResponse.statusCode === 200 ||
              countResponse.statusCode === 200
            ) {
              newPercentage = 50; // Set to 50% when one response is successful
            }
            setPercentage(newPercentage); // Update the percentage state with the new value
            navigate("/InitialPermission"); //navigate to Initial Permission Page
          } else if ((result.responseData = "Permission denied")) {
            setValidate(false);
            setIsLoading(false);
          } else if (result.statusCode === 400) {
            setInfoValidate(false);
            setIsLoading(false);
          }
      }else{
        const result = await insertConnectorData(connectorObj);
        if (result.statusCode === 200) {
          setIsLoading(false);
          setShowPopUp(false);
          setShowProgress(false);
          interval = setInterval(increasePercentage, 2000);
  
          let fetchUser = {
            orgId: connectorObj.orgId,
            connectorTypeId: connectorObj.connectorTypeId,
            connectorId: connectorObj.connectorId,
            tenantId: connectorObj.tenantId,
            clientId: connectorObj.clientId,
            clientSecret: connectorObj.clientSecret,
            microsoftDomain: connectorObj.microsoftDomain,
            cloudAppstoken: connectorObj.cloudAppstoken,
            connectorTypeId: 1,
            emailValue: encryptStorage.getItem("emailId"),
          };
  
         let userResponse = await fetchUserDetails(fetchUser);
         let countResponse = await countDetails(fetchUser);
  
          clearInterval(interval);
  
          let newPercentage = 0;
            if (
              userResponse.statusCode === 200 &&
              countResponse.statusCode === 200
            ) {
              newPercentage = 100; // Set to 100% when both responses are successful
            } else if (
              userResponse.statusCode === 200 ||
              countResponse.statusCode === 200
            ) {
              newPercentage = 50; // Set to 50% when one response is successful
            }
            setPercentage(newPercentage); // Update the percentage state with the new value
            navigate("/InitialPermission"); //navigate to Initial Permission Page
          } else if ((result.responseData = "Permission denied")) {
            setValidate(false);
            setIsLoading(false);
          } else if (result.statusCode === 400) {
            setInfoValidate(false);
            setIsLoading(false);
          }
      }

    } catch (error) {
      //console.log("Error inserting connector information:", error);
      clearInterval(interval);
    }
  };
  const showConnectorPopUp = () => {
    setShowPopUp(true);
  };

  const fetchConnector = async () => {
    try {
      setIsLoading(true);
      let result = await getConnectorData();
      
      if(result.responseData == "newLogin"){
        setNextButton(false)
        setIsTenantIdDisabled(false)
      }
      if (result.statusCode === 200 & result.responseData !="newLogin" ) {
        let azureConnectorObj = {
          orgId: result.responseData.organization_id,
          connectorTypeId: result.responseData.connector_type_id,
          connectorId: result.responseData.connector_id,
          tenantId: result.responseData.tenant_id,
          clientId: result.responseData.client_id,
          clientSecret: result.responseData.client_secret,
          microsoftDomain: result.responseData.microsoft_domain,
          cloudAppstoken: result.responseData.cloudapps_token,
          connectorTypeId: result.responseData.connector_type_id,
        };
        setFormData(azureConnectorObj);
        setIsTenantIdDisabled(true)
      } else {
        //console.log("Something went wrong!");
      }
    } catch (error) {
      //console.log("Error updating connector information:", error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10 col-xl-6">
              <div className="mx-5 mt-5">
                <div className="progress-container">
                  <div className="progress-step active">
                    <div className="step-icon active" />
                    <div className="text-center m-0 step-label-active font-regular font-16 grey-light">
                      Connectors
                    </div>
                  </div>
                  <div className="progress-step">
                    <div className="step-icon" />
                    <div className="text-cente  Zr m-0 font-regular font-16 grey-light">
                      Permissions
                    </div>
                  </div>
                  <div className="progress-step">
                    <div className="step-icon" />
                    <div className="text-center m-0 font-regular font-16 grey-light">
                      Security
                    </div>
                  </div>
                  <div className="progress-step">
                    <div className="step-icon" />
                    <div className="text-center m-0 font-regular font-16 grey-light">
                      AG Configs
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="font-medium font-24 primary-textcolor text-center mt-5">
            First, let us know Which system is your Company’s Identity provider.
          </h5>
          <p className="font-regular font-16 grey-light-color text-center mb-5 mt-3">
            We request information about your Identity Provider (IDP) details to
            facilitate seamless employee integration into{" "}
            <span className="d-block">
              AGov, enabling robust Single Sign-On (SSO) capabilities and
              effective enforcement of security policies.
            </span>
            &nbsp;
          </p>
          <div className="row mb-5 justify-content-center">
            <div className="col-md-9 col-xl-6 col-lg-6">
              <div className="row justify-content-center">
                <a
                  // href="#"
                  className="col-md-5 connector-container cursor-pointer"
                  onClick={() => {
                    showConnectorPopUp();
        
                  }}
                >
                  <span className="d-flex justify-content-center me-3">
                    <img src="images/azure-logo-img.svg" alt="azure-logo" />
                  </span>
                </a>
              </div>
            </div>
            {showPopUp && (
              <ConnectorPopUp
                disableTenantiD={isTenantIdDisabled}
                setShowPopUp={setShowPopUp}
                insertConnector={insertConnectorDetails}
                formData={formData}
                setFormData={setFormData}
              ></ConnectorPopUp>
            )}
            {!validate ? (
              <div>
                <div className="container">
                  <p>
                    <p
                      style={{
                        color: "red",
                        padding: "0 480px",
                        textalign: "left",
                      }}
                    >
                      Please enter the following permissions in AD *
                      "DeviceManagementManagedDevices.Read.All",
                      "User.ReadWrite.All", "Directory.ReadWrite.All",
                      "Directory.Read.All", "User.Read.All",
                      "DeviceManagementConfiguration.Read.All",
                      "IdentityProvider.Read.All", "IdentityRiskyUser.Read.All",
                      "IdentityRiskEvent.Read.All", "AuditLog.Read.All",
                      "ThreatHunting.Read.All"
                    </p>
                  </p>
                </div>
              </div>
            ) : null}

            {!infovalidate ? (
              <div>
                <p
                  style={{
                    color: "red",
                    padding: "0 480px",
                    textalign: "left",
                  }}
                >
                  "Given Connector Information is Invaild"
                </p>
              </div>
            ) : null}

            <div className="col-md-10 py-5">
              {/* navigate to permission */}
              {nextButton == true ? <div className="text-end mt-5">
                <button
                  type="button"
                  className="dark-btn font-14 font-medium py-2"
                  onClick={() => navigate(`/InitialPermission`)}
                >
                  Next
                </button>
              </div> :<></>}
              
            </div>
          </div>
        </div>
      </div>
      {showProgress == false && (
        <div className="col-md-12 my-2">
          <div
            className="modal fade show"
            id="staticBackdrop10"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.6)" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between align-items-center p-4 border-0">
                  <h5
                    className="modal-title font-14 font-medium primary-textcolor"
                    id="staticBackdropLabel"
                  >
                    Please wait, while we are fetching the data
                  </h5>
                </div>
                <div className="modal-body px-4 pb-5 pt-0">
                  <div className="progress payment-pg">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${percentage}%` }}
                      aria-valuenow={percentage}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <span style={{color: "#000",
                      position: "absolute",
                       right: "15px", 
                      textalign: "right",
                       top: "12px",
                       fontsize: "12px",
                       fontweight: "500"}}>{percentage}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}
    </>
  );
}
