// IMPORTS :
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import {
  getAdminPermission,
  updateUserRoles,
} from "../../Services/Admin/AdminService";
import NoDataFound from "../LMS/NoDataFound";
import { agovContext, encryptStorage } from "../../Constant";
import { Loader } from "../Loader/Loader";
import { useContext, useEffect, useState } from "react";

export default function IntitialPermission() {
  const { userId, reportsToId, employeeName } = useContext(agovContext);
  //PC_GSC_6 to PC_GSC_21
  const [orderColn, setOrderColn] = useState("employee_name ASC");
  const [permissionValue, setPermissionValue] = useState([]);
  const [count, setCount] = useState(0);
  const [loadMore, setLoadMore] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [department, setDepartment] = useState([]);
  const [role, setRole] = useState([]);
  const [totalRec, setTotalRec] = useState(0);
  const [hidePopUp, setHidePopUp] = useState(true);

  const [editUserId, setEditUserId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rolesOptions, setRolesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let filterPermissionObj = {
    filterDepartment: "",
    filterRole: "",
    searchValue: searchText,
    sortColn: orderColn,
    updateLoadMore: loadMore,
  };
  const [filter, setFilter] = useState(filterPermissionObj);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAdminPermission();
  }, [orderColn, loadMore]);

  //PC_GSC_22 to PC_GSC_25
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value, searchValue: "" });
  };

  //PC_GSC_26 to PC_GSC_27
  const handleLoadMore = () => {
    setLoadMore((prevLoadMore) => prevLoadMore + 10); // Increment loadMore by 5
  };
  //PC_GSC_28 to PC_GSC_29
  const handleSearch = () => {
    fetchAdminPermission();
  };
  //PC_GSC_30 to PC_GSC_32
  const handleClear = () => {
    let clearObj = {
      filterDepartment: "",
      filterRole: "",
    };
    setFilter(clearObj);
    fetchAdminPermission();
  };

  //PC_GSC_33
  //roles
  const transformedResponse = role.map((item) => ({
    value: item.role_id,
    label: item.role_name,
  }));
  //PC_GSC_34 to PC_GSC_35
  const handleEditClick = (value) => {
    setEditUserId(value.employee_id);
    setIsEditing(true);
    setRolesOptions(transformedResponse);
  };
  //PC_GSC_36 to PC_GSC_37
  const handleCrossClick = () => {
    navigate(0)
    // setIsEditing(false);
    // setEditUserId(null);
    // setPermissionValue((prevUsers) =>
    //   prevUsers.map((user) => ({
    //     ...user,
    //     roles: role[user.employee_id] || user.roles,
    //   }))
    // );
    // setErrorMessage("");
    // fetchAdminPermission();
  };
  //PC_GSC_38 to PC_GSC_44
  const handleTickClick = async (employeeId, userName, updatedRoles) => {
    try {
      setIsLoading(true);
      // Perform actions for tick icon
      let roles = updatedRoles;
      if (roles.length == 0) {
        setErrorMessage("There is no roles");
        setIsLoading(false);
        return;
      }
      let updateRoleObj = {
        employeeId: employeeId,
        userName: userName,
        roles: roles,
        userId: userId,
        managerId: reportsToId,
        appliedBy: employeeName,
        appliedOn: userName,
      };


      const updatePermissionRoles = await updateUserRoles({ updateRoleObj });
      setEditUserId(null);
      setPermissionValue([...permissionValue]);
      setIsEditing(false);
      setErrorMessage("");
      fetchAdminPermission();
    } catch (error) {
      console.error(error);
      //console.log("Something went wrong!");
    }
    setIsLoading(false);
  };
  //PC_GSC_45 to PC_GSC_54
  const handleRolesChange = (selectedRoles, userId) => {
    // Check if 'Super Admin' or 'User' is selected
    const isSuperAdminSelected = selectedRoles.some(
      (role) => role.label === "Super Admin"
    );
    const isUserSelected = selectedRoles.some((role) => role.label === "User");
    // Update disabled property based on selected roles
    const updatedRolesOptions = rolesOptions.map((option) => {
      const isSuperAdminOrUser = (role) =>
        role.label === "Super Admin" || role.label === "User";
      const isDisabled =
        (isSuperAdminSelected || isUserSelected) && !isSuperAdminOrUser(option);
      return { ...option, disabled: isDisabled };
    });
    // Update roles options and user data
    setRolesOptions(updatedRolesOptions);
    // If 'Super Admin' or 'User' is selected, uncheck and disable all other roles
    // If both 'Super Admin' and 'User' are selected, keep only the first selected role
    const filteredSelectedRoles =
      isSuperAdminSelected || isUserSelected
        ? [
            selectedRoles.find((role) => role.label === "Super Admin") ||
              selectedRoles.find((role) => role.label === "User") ||
              selectedRoles[0],
          ]
        : selectedRoles;
    const updatedUsers = permissionValue.map((user) =>
      user.employee_id === userId
        ? { ...user, roles: filteredSelectedRoles }
        : user
    );
    setPermissionValue(updatedUsers);
    // Clear the error message on role change
    setErrorMessage("");
  };
  //PC_GSC_55 to PC_GSC_79
  const fetchAdminPermission = async () => {
    setIsLoading(true);
    let filterPermissionObj = { ...filter };
    filterPermissionObj.sortColn = orderColn;
    filterPermissionObj.updateLoadMore = loadMore;
    filterPermissionObj.searchValue = searchText;
    try {
      let result = await getAdminPermission(filterPermissionObj);

      if (result.statusCode === 200) {
        let permissionGrid = result.responseData.gridData;
        let count = result.responseData.count;
        let roles = result.responseData.roles;
        let department = result.responseData.domain;
        setPermissionValue(permissionGrid);
        setTotalRec(permissionGrid.length);
        setCount(count);
        setDepartment(department);
        setRole(roles);
      } else {
        //console.log("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      //console.log("Something went wrong!");
    }
    setIsLoading(false);
  };

  function bindDepartment() {
    return department?.map((value, index) => {
      return (
        <option value={value.employee_domain} name="filterDepartment">
          {value.employee_domain}
        </option>
      );
    });
  }

  function bindRoles() {
    return role?.map((value, index) => {
      return (
        <option value={value.role_id} name="filterRole">
          {value.role_name}
        </option>
      );
    });
  }

  function gridDynamicBind() {
    return permissionValue?.map((value, index) => {

      return (
        <tr>
          <td className="font-12">
            <div className="d-flex align-items-center">
              <img src={value.profile_url} className="table-prof" />
              <p
                className="font-13 font-medium secondary-textcolor mb-0 ms-2 "
               
              >
                {value?.employee_name}
              </p>
            </div>
          </td>
          <td className="font-13">{value?.employee_domain}</td>
          <td className="font-13">
            {value.employee_id === editUserId && isEditing ? (
              <>
                <MultiSelect
                  options={rolesOptions}
                  // // Custom styles to hide the clear icon
                  styles={{
                    multiValueRemove: (base) => ({
                      ...base,
                      display: "none", // Hide the clear icon
                    }),
                  }}
                  value={value.roles}
                  onChange={(selectedRoles) =>
                    handleRolesChange(selectedRoles, value.employee_id)
                  }
                  labelledBy="Select"
                  hasSelectAll={false}
                  isCreatable={true}
                />
                {errorMessage && (
                  <div style={{ color: "red" }}>{errorMessage}</div>
                )}
              </>
            ) : (
              value.roles.map((role) => role.label).join(", ")
            )}
          </td>
          <td className="text-center">
            {value.employee_id === editUserId && isEditing ? (
              <>
                <a
                  className="me-3"
                  onClick={() => {
                    handleTickClick(
                      value.employee_id,
                      value.employee_name,
                      value.roles
                    );
                  }}
                >
                  <img src="/images/tick-icon.svg" alt="tick-icon" />
                </a>
                <a className="me-3" onClick={handleCrossClick}>
                  <img src="/images/cross-icon.svg" alt="cross-icon" />
                </a>
              </>
            ) : (
              <a
                className="me-2"
                onClick={() => {
                  if (
                    value.employee_id !== encryptStorage.getItem("employeeId")
                  ) {
                    handleEditClick(value);
                  }
                }}
              >
                <img src="/images/edit-icon.svg" alt="edit-icon" />
              </a>
            )}
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10 col-xl-6">
              <div className="mx-5 mt-5">
                <div className="progress-container">
                  <div className="progress-step completed">
                    <div className="step-icon completed" />
                    <div className="text-center m-0 step-label-completed font-regular font-16 grey-light">
                      Connectors
                    </div>
                  </div>
                  <div className="progress-step active">
                    <div className="step-icon active" />
                    <div className="text-center m-0 step-label-active font-regular font-16 grey-light">
                      Permissions
                    </div>
                  </div>
                  <div className="progress-step">
                    <div className="step-icon" />
                    <div className="text-center m-0 font-regular font-16 grey-light">
                      Security
                    </div>
                  </div>
                  <div className="progress-step">
                    <div className="step-icon" />
                    <div className="text-center m-0 font-regular font-16 grey-light">
                      AG Configs
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="font-medium font-24 primary-textcolor text-center mt-5">
            Grant permissions to your peers to delegate your responsibilities to
            them efficiently!&nbsp;
          </h5>
          <p className="font-regular font-16 grey-light-color text-center mb-5 mt-3">
            Empower your team by granting precise permissions to effectively
            delegate responsibilities and streamline{" "}
            <span className="d-block">
              collaboration within your organization.
            </span>
          </p>
          <div className="d-flex justify-content-end align-items-center mb-4 px-5">
            <div className="d-flex">
              {/* handle search function starts here  */}
              <div className="input-group flex-nowrap search-group me-2">
                <input
                  type="search"
                  className="form-control search-align"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                  value={searchText}
                  onKeyDownCapture={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                <button
                  className="input-group-text btn btn-search-custom"
                  id="addon-wrapping"
                >
                  <img
                    src="/images/search-icon.svg"
                    alt="search-icon"
                    onClick={() => {
                      handleSearch();
                    }}
                  />
                </button>
              </div>
              {/* handle search function ends here */}

              {/* handle filter button */}
              <button
              
                className="cust-filter-btn d-flex align-items-center  cursor-pointer"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
              >
                <img
                  src="images/filter-icon.svg"
                  alt="filter-icon"
                  className="filt-icon me-2"
                  onClick={() => {
                    setHidePopUp(false);
                  }}
                />
                Filter
              </button>
              {/* handle filter button */}
              {/* filter pop-up starts here  */}

              <div className="custom-filter dropdown-menu border-0"> {/** dropdown-menu  */}
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="font-18 font-bold text-black mb-0">
                          Advanced Filter
                        </h5>
                        <button
                          type="button"
                          className="btn-close font-12"
                          aria-label="Close"
                          onClick={() => setHidePopUp(true)}
                        />
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label text-black font-12 font-medium">
                              Department
                            </label>
                            <select
                              className="form-select cust-input-sty font-14 py-2 font-regular"
                              aria-label="Default select example"
                              value={filter.filterDepartment}
                              name="filterDepartment"
                              onChange={(e) => {
                                handleOnChange(e);
                              }}
                            >
                              <option name="filterDepartment" value={""} selected="">Select</option>
                              {bindDepartment()}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label text-black font-12 font-medium">
                              Role
                            </label>
                            <select
                              className="form-select cust-input-sty font-14 py-2 font-regular"
                              aria-label="Default select example"
                              value={filter.filterRole}
                              name="filterRole"
                              onChange={(e) => {
                                handleOnChange(e);
                              }}
                            >
                              <option name="filterRole" value={""} selected="">Select</option>
                              {bindRoles()}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="text-end mt-4 mb-2">
                        <button
                          type="button"
                          className="primary-btn font-14 font-medium me-2  cursor-pointer"
                          onClick={() => handleClear()}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="dark-btn font-14 font-medium px-4  cursor-pointer"
                          onClick={() => {
                            setFilter(filter);
                            fetchAdminPermission();
                            setHidePopUp(true);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
              {/* filter pop  ends here */}
            </div>
          </div>
          {/*Permissions table*/}
          <div className="px-5">
            <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
              <thead>
                <tr>
                  <th className="font-14 px-4 position-relative">
                    Username
                    <span className="position-absolute">
                      <a  className="sort-up position-absolute ms-2" onClick={() => setOrderColn("employee_name ASC")}>
                        <img
                          className="sort-img-size"
                          src="images/sort-up.svg"
                          alt="sort-up-icon"
                        />
                      </a>{" "}
                      <a  className="sort-down position-absolute ms-2"  onClick={() => setOrderColn("employee_name DESC")}>
                        <img
                          className="sort-img-size"
                          src="images/sort-down.svg"
                          alt="sort-down-icon"
                        />
                      </a>
                    </span>
                  </th>
                  <th className="font-14 position-relative">
                    Department
                    {/* Not sortable column */}
                  </th>
                  <th className="font-14 position-relative">
                    Role
                    {/*   Not sortable column */}
                  </th>
                  <th className="font-14 position-relative text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              {count > 0 ? (
                    <tbody>{gridDynamicBind()}</tbody>
                  ) : (
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}>
                        <NoDataFound />
                      </td>
                    </tr>
                  )}
            </table>

            <div className="mt-3 ">
            <p className="font-12 grey-primary">
              Showing <span className="font-medium primary-textcolor">{totalRec}</span>
               {" "}out of{" "}<span className="font-medium primary-textcolor">
                {count}
              </span>{" "}
               items
            </p>
          </div>
          
          </div>
        
          <div className="text-center mt-5 mb-4">
                {totalRec < count && (
                  <button
                    className="primary-btn font-medium  cursor-pointer"
                    onClick={() => handleLoadMore()}
                  >
                    Load More
                  </button>
                )}
              </div>
          <div className="d-flex justify-content-between px-5 mt-5 mb-4">
            <button
              type="button"
              className="primary-btn font-14 font-medium me-2"
              onClick={() => navigate("/InitialConnector")}
            >
              Previous
            </button>
            <button
              type="button"
              className="dark-btn font-14 font-medium px-4"
              onClick={() => navigate("/InitialSecurity")}
            >
              Next
            </button>
          </div>
          {/*Permissions table ends here*/}
        </div>
        
      </div>
    </>
  );
}
