import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
export function ChartComponent({ data1, data2 }) {
  const COLORS = ["#78DC8E", "#FFBF84"];
  return (
    <>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie
            data={data1}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            paddingAngle={2}
            strokeWidth={2}
            cornerRadius={4}
            dataKey="value"
          >
            {data1.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <Label
              value="Total Count"
              position="center"
              fill="#696969"
              fontSize={14}
              dy={-10}
            />
            <Label
              value={data2.toString().padStart(2, "0")}
              position="center"
              fill="#00000"
              fontSize={14}
              fontWeight="bold"
              dy={15}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {data1.map((item, index) => (
        <div
          key={index}
          className="d-flex align-items-center mt-1 justify-content-between cursor-pointer"
        >
        </div>
      ))}
    </>
  );
};








