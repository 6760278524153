import axios from "axios";
import { encryptStorage } from "../Constant";
 
export const client = async (config) => {
    try {
        config.headers.username = encryptStorage.getItem('username');
        config.headers.authorization = `Bearer ${localStorage.getItem('token')}`;
        // if(config.data == undefined){
        //     config.data = {};
        // }  
            config.data.userValue = encryptStorage.getItem('userValue');
            config.data.employeeValue = encryptStorage.getItem('employeeId');
            config.data.roleValue = encryptStorage.getItem('roleValue');
            config.data.emailId = encryptStorage.getItem('emailId');
            config.data.emailValue = encryptStorage.getItem('emailId');
        let response = await axios(config);
        return response.data;
    }
    catch (error) {
        console.log(error.message, "error in client ");
    }
}