import PopupNonCompliantUsersOverview from "./PopupNonCompliantUsersOverview";

const NonCompliantUsersHeader = ({
  nonCompliantUsers,
  onTabChange,
  onFilterChange,
  filter,
  roles,
  gridActiveTab,
  isPopupClicked,
  onIsPopupClicked,
  managerId,
  isSuperAdminAndManager,
  onClose,
  riskCount,
  abbreviateNumber
}) => {
  const validObject = nonCompliantUsers || {}

  const isDataEmpty =
    nonCompliantUsers?.users?.length === 0 ||
    Object.keys(validObject).length === 0;

  const formatValue = (value) => {
    if (value === 0) {
      return "0";
    } else if (value > 0 && value < 10) {
      return `0${value}`;
    } else {
      return value;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h3 className="font-14 font-medium primary-textcolor mb-0">
          Non Compliant Users
        </h3>
        <div className="d-flex justify-content-between align-items-center ps-1 custom-tabs ms-2">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link px-0 active font-14 tab-style rounded-0 d-flex align-items-center"
                id="overdue-tab"
                data-bs-toggle="pill"
                data-bs-target="#overdue"
                type="button"
                role="tab"
                aria-controls="information"
                aria-selected="true"
                onClick={() => onTabChange("overdue")}
              >
                <span className="tab-img d-flex align-items-center">
                  {" "}
                  <img
                    src="images/media-roll-active.svg"
                    alt="filled-icon"
                    className="me-2"
                  />{" "}
                  <img
                    src="images/media-roll-inactive.svg"
                    alt="information-icon"
                    className="me-2"
                  />
                </span>
                Overdue
                <span className="badges-blue border-radius-8 ms-1">
                  {nonCompliantUsers?.totalCount?.overdueUsers
                    ? abbreviateNumber(formatValue(nonCompliantUsers?.totalCount?.overdueUsers))
                    : 0}
                </span>
              </button>
            </li>
            <li className="nav-item ms-4" role="presentation">
              <button
                className="nav-link px-0 font-14 tab-style rounded-0 d-flex align-items-center"
                id="risk-tab"
                data-bs-toggle="pill"
                data-bs-target="#risk"
                type="button"
                role="tab"
                aria-controls="permissions"
                aria-selected="false"
                tabIndex={-1}
                onClick={() => onTabChange("risk")}
              >
                <span className="tab-img d-flex align-items-center">
                  {" "}
                  <img
                    src="images/risk-active-filled.svg"
                    alt="filled-icon"
                    className="me-2"
                  />{" "}
                  <img
                    src="images/risk-inactive-nonfilled.svg"
                    alt="permissions-icon"
                    className="me-2"
                  />
                </span>
                Risk
                <span className="badges-blue border-radius-8 ms-1">
                  {nonCompliantUsers?.totalCount?.riskUsers
                    ? abbreviateNumber(formatValue(nonCompliantUsers?.totalCount?.riskUsers))
                    : 0}
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div className="align-items-center d-flex gap-3">
          {roles === "superAdmin" && gridActiveTab === "overdue" && (
            <select
              value={filter}
              onChange={(e) => onFilterChange(e.target.value)}
              className="form-select custom-form-control py-2 font-12 font-regular primary-textcolor h-33 me-1"
              aria-label="Default select example"
            >
              <option value={"team"} selected>
                Team
              </option>
              <option value={"organization"}>Organization</option>
            </select>
          )}
          <div
            className={`d-flex align-items-center`}
            data-bs-toggle={(isDataEmpty || ((gridActiveTab === "overdue" && nonCompliantUsers?.totalCount?.overdueUsers <= 4) || (gridActiveTab === "risk" && nonCompliantUsers?.totalCount?.riskUsers <= 4))) ? "" : "modal"}
            data-bs-target={(isDataEmpty || ((gridActiveTab === "overdue" && nonCompliantUsers?.totalCount?.overdueUsers <= 4) || (gridActiveTab === "risk" && nonCompliantUsers?.totalCount?.riskUsers <= 4))) ? "" : "#staticBackdrop2"}
            onClick={(isDataEmpty || ((gridActiveTab === "overdue" && nonCompliantUsers?.totalCount?.overdueUsers <=4) || (gridActiveTab === "risk" && nonCompliantUsers?.totalCount?.riskUsers <= 4))) ? null : () => onIsPopupClicked(true)}
          >
            <img
              className="cursor-pointer"
              src="images/expand-icon.svg"
              alt="expand-icon"
              style={{
                cursor: (isDataEmpty || ((gridActiveTab === "overdue" && nonCompliantUsers?.totalCount?.overdueUsers <= 4) || (gridActiveTab === "risk" && nonCompliantUsers?.totalCount?.riskUsers <= 4))) ? "not-allowed" : "pointer",
                filter: (isDataEmpty || ((gridActiveTab === "overdue" && nonCompliantUsers?.totalCount?.overdueUsers <= 4) || (gridActiveTab === "risk" && nonCompliantUsers?.totalCount?.riskUsers <= 4))) ? "grayscale(100%)" : "none",
                opacity: (isDataEmpty || ((gridActiveTab === "overdue" && nonCompliantUsers?.totalCount?.overdueUsers <= 4) || (gridActiveTab === "risk" && nonCompliantUsers?.totalCount?.riskUsers <= 4))) ? 0.5 : 1,
              }}
            />
          </div>
          <div
            className="modal fade"
            id="staticBackdrop2"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                {isPopupClicked && (
                  <PopupNonCompliantUsersOverview
                    isPopupClicked={isPopupClicked}
                    managerId={managerId}
                    roles={roles}
                    isSuperAdminAndManager={isSuperAdminAndManager}
                    gridActiveTab={gridActiveTab}
                    onClose={onClose}
                    gridFilter={filter}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NonCompliantUsersHeader;