// PC_COM_TS_02 to PC_COM_TS_05 -> IMport statements
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { getComplainceData } from "../../Services/GRC/GrcService";
// import { getComplainceData } from "../../../Services/Agov/governanceService";
import { getTaskData } from "../../Services/GRC/GrcService";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
 
import NoRecordFound from "./dashboardNoRecordFound";
import { Loader } from "../Dashboard/complainceLoader";
import { abbreviateNumber } from '../../Constant';

 
// PC_COM_TS_12 -> Component Creation
export default function ComplianceTaskSummary({data}){

    // PC_COM_TS_13 -> create the state variable with initial values
    const [reqTaskDuration, setReqTaskDuration] = useState("Monthly");
    const [dateTimeColumnName, setDateTimeColumnName] = useState('created_on');
    const [totalTaskData, setTotalTaskData] = useState(0);
    const [allTaskData, setAllTaskData] = useState([])
    const [transformData, setTransformData] = useState([])
    const [noDataFound, setNodataFound] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    //   task Data state variables ends                                                                                                             
 
    // PC_COM_TS_14 -> useEffect declaration dependent with reqTaskDuration
    useEffect(() => {
        onInitialPageLoad();
    }, [reqTaskDuration,data]);
 
    // PC_COM_TS_30 -> PC_COM_TS_33 -> useEffect declaration dependent with allTaskData
    useEffect(() => {
        setTransformData(taskTransformingData())
    }, [allTaskData]);
 
    // PC_COM_TS_15 -> Function declaration
    async function onInitialPageLoad() {
        
        // PC_COM_TS_15 -> Variable initialization
        let payload = {
            startDate : data.startDate,
            endDate : data.endDate,
            reqTaskDuration: reqTaskDuration,
            dateTimeColumnName: dateTimeColumnName
        }
        
        // PC_COM_TS_16 -> set state a variable isLoading as true and API function call
        setIsLoading(true)
        const TaskResponse = await getTaskData(payload)
        
        // PC_COM_TS_24 -> set state a varibale if response is received from backend
        if (TaskResponse.statusCode === 200) {
            if (TaskResponse.responseData.totalTaskCount != 0) {
                setNodataFound(false)
                setAllTaskData(TaskResponse.responseData || []);
                setTotalTaskData(TaskResponse.responseData.totalTaskCount)
            }
            else {
                setNodataFound(true)
            }
 
        }
        // PC_COM_TS_28 -> navigate to error page when statusCode in not equals to 200
        else {
            navigate('/error');
        }
        // PC_COM_TS_29 -> set state a variable isLoading as false
        setIsLoading(false)
    }
 
    // PC_COM_TS_34 to PC_COM_TS_35 -> handle the dropdown options in this function
    function OnHandleTaskDropDown(value) {
        setReqTaskDuration(value)
        setNodataFound(false)
    }
 
    // PC_COM_TS_30 to PC_COM_TS_33 -> taskTransformingData() function declaration
    const taskTransformingData = () => {
        const transformedData = Object.keys(allTaskData).filter((ele) => ele != 'totalTaskCount').map((key) => {
            return {
                name: key,
                YetToStart: allTaskData[key].YetToStart,
                inProgress: allTaskData[key].inProgress,
                Completed: allTaskData[key].Completed
            };
        });
        return transformedData
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>
               
                <div className="custom-tooltip-Dashboard" >
                <p className="labeltitle">{label}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ color: entry.color }}>
                            {entry.name}: {entry.value}
                        </p>
                    ))}
                </div>
                </>
            );
        }
   
        return null;
    };

    return (
        <>
            <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3 pe-2" style={{ position: 'relative' }}>
                <Loader isLoading={isLoading} />
                <div className="bg-white py-2 px-3 border-radius-8 ">
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <h3 className="font-14 font-medium primary-textcolor mb-0">Compliance Task <span className="badges-blue border-radius-8 ms-2 font-12">{!noDataFound ? abbreviateNumber(totalTaskData) : 0}</span></h3>
                        <div className="align-items-center d-flex gap-3">
                            <div className="d-flex">
                                <select className="form-select custom-form-control py-2 font-12 font-regular" onChange={(e) => { OnHandleTaskDropDown(e.target.value) }}>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Today">Today</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Quaterly">Quaterly</option>
                                    
                                </select>
                            </div>
                        </div>
                    </div>
                    {!noDataFound ? <div>
                        <div className="d-flex py-4 px-2">
                            <ResponsiveContainer width={'100%'} height={210}>
                                <BarChart
                                    data={transformData}
                                    margin={{
                                        top: 10,
                                        right: 10,
                                        left: 10,
                                        bottom: 10,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" scale="none" padding={{ left: 120, right: 120 }}/>
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Bar
                                        dataKey="Completed"
                                        stackId="a"
                                        fill="#78DC8E"
                                        name="Completed"
                                        barSize={20} // Adjust the width of the bars here
                                    />
                                    <Bar
                                        dataKey="inProgress"
                                        stackId="a"
                                        fill="#FACC9E"
                                        name="In Progress"
                                        barSize={20} // Adjust the width of the bars here
                                    />
                                    <Bar
                                        dataKey="YetToStart"
                                        stackId="a"
                                        fill="#ced4da"
                                        name="Open"
                                        barSize={20} // Adjust the width of the bars here
                                    />
 
 
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="align-items-center justify-content-center d-flex gap-2">
                            <div className="align-items-baseline d-flex">
                                <span className="legends legend-completed me-1" />
                                <label className="me-2 font-12 grey-light">Completed</label>
                            </div>
                            <div className="align-items-baseline d-flex">
                                <span className="legends legend-medium me-1" />
                                <label className="me-2 font-12 grey-light">In Progress</label>
                            </div>
                            <div className="align-items-baseline d-flex">
                                <span className="legends legend-open me-1" />
                                <label className="me-2 font-12 grey-light">Open</label>
                            </div>
                        </div>
                    </div> : <NoRecordFound data={"No Task Assigned"} />}
 
                </div>
            </div>
        </>
    )
}
