/**
 * AG_PC_VQ_02 - AG_PC_VQ_06
 * Import statements of this component has been declared here
 */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { agovContext } from '../../Constant';
import { getVendorQuestions, insertVendorQuestions, getQuestionaries } from '../../Services/GRC/GrcService';
import { handleUpload } from '../../Configs/BlobContainer';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import { Loader } from '../Loader/Loader';
import ExcelJS from 'exceljs';

/**
 * AG_PC_VQ_12 - AG_PC_VQ_21
 * State variable declarations and Parent function declaration has been stated here
 */
export function VendorQuestionary() {
    const navigate = useNavigate();
    const location = useLocation();
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAuditor, setIsAuditor] = useState(false);
    const [vendorGridId, setVendorGridId] = useState("")
    const [ClickFrom, setClickFrom] = useState('')
    const [vendorName, setVendorName] = useState('')
    const [questionData, setQuestionData] = useState([]);
    const [commonQuestionData, setCommonQuestionData] = useState([])
    const [validationFailed, setValidationFailed] = useState(false);
    const [excelFile, setExcelFile] = useState('');
    const [valueChange, setValueChange] = useState(false);
    const [isToast, setIsToast] = useState(false)
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null,
    });
    const [isLoading, setIsLoading] = useState(false)

    /**
     * AG_PC_VQ_22 - AG_PC_VQ_57
     * Check for the isAdmin and isAuditor has been made
     * Set stating the vendorId and clickFrom details from the location state
     * If the Id present fetching the data based on the vendorID else fetching the common questions data
     * Set stating the values to the respective state variables
     */
    useEffect(() => {
        if (roles?.includes("GRC Admin") || roles?.includes("Super Admin")) {
            setIsAdmin(true);
        }
        else if (roles?.includes("Auditor")) {
            setIsAuditor(true);
        } else {
            navigate('/')
        }
        const vendorGridID = location?.state?.vendor_id || '';
        const clickFrom = location?.state?.clickFrom
        const vendorname = location?.state?.vendorname
        setClickFrom(clickFrom)
        setVendorGridId(vendorGridID);
        setVendorName(vendorname)


        if (vendorGridID) {
            fetchData(vendorGridID);
        }
    }, []);

    const fetchData = async (ID) => {

        const requestBody = { vendorId: ID }
        setIsLoading(true)
        const result = await getVendorQuestions(requestBody)

        if (result.statusCode == 200 && result.responseData.length > 0) {
            setQuestionData(result.responseData)
            setIsLoading(false)


        }
        const result1 = await getQuestioanriesData(result)
    }

    const getQuestioanriesData = async (res) => {
        setIsLoading(true);
        const result = await getQuestionaries();
        setIsLoading(false);
        if (result.statusCode === 200) {
            const convertedData = result.responseData.map((item) => ({
                category: item.category,
                questions: item.questions
                    .filter((question) => question.is_disable !== 1) // Filter out questions with is_disable = 0
                    .map((question) => ({
                        questionary_name: question.questionary_name,
                        answer: '',
                        comment: ''
                    }))
            }));
            setCommonQuestionData(convertedData);

            if (res.statusCode !== 200 || res.responseData.length === 0) {
                setQuestionData(convertedData);
            }
        }
    };

    /**
     * AG_PC_VQ_76 - AG_PC_VQ_90
     * Check for the imported file and upload it to the blob , retrive the url to store in BE
     * Check for the validation on missing comments for the No and Not applicable selected questions
     * Form the requestBody and insert the values to the BE
     */
    const handleSave = async () => {
        // debugger
        if (excelFile) {
            var bloburl = await handleUpload(excelFile)
        }
        let hasMissingComments = false;
        // Iterate through questionData to check for missing comments
        questionData.forEach((category) => {
            category.questions.forEach((question) => {
                if ((question.questionary_value.toLowerCase() === 'no' || question.questionary_value.toLowerCase() === 'not applicable' ||
                    !question.questionary_value) &&
                    // Check if the comment is missing or consists only of whitespace
                    (!question.questionary_comments)) {
                    hasMissingComments = true;
                }

            });
        });

        // If any missing comments found, display the popup
        if (hasMissingComments) {
            setValidationFailed(true);

        } else {
            const requestBody = {
                vendorID: vendorGridId,
                data: questionData,
                blobURL: bloburl ? bloburl : "",
                vendorName: vendorName,
                userID: userId

            }
            setIsLoading(true)
            const result = await insertVendorQuestions(requestBody)
            setIsLoading(false)
            setExcelFile('')
            if (result.statusCode == 200) {
                const message = valueChange ? "Updated Succesfully" : "Saved Succesfully"
                setToastMessage({
                    path: "/images/Success-icon.svg",
                    header: "Success",
                    message: message,
                    color: "success-toast"
                })
                setIsToast(true);
                setValueChange(false);
                // navigate(0)
            }
            else {
                navigate('/error')
            }
        }

    }
    /**
     * AG_PC_VQ_91 
     * Navigate to the vendor form component onclick of this event
     */
    const handleNavigatePrimaryInfo = async () => {

        navigate('/compliance/vendorForm', {
            state: {
                vendor_id: vendorGridId,
                clickFrom: ClickFrom
            }

        })
    }

    /**
     * AG_PC_VQ_96
     * Navigate to the questionarie history component onclick of this event
     */
    const handleNavigateQuestionarieHistory = async () => {

        navigate('/compliance/questionaryhistory', {
            state: {
                vendor_id: vendorGridId,
                clickFrom: ClickFrom,
                vendorname: vendorName
            }

        })
    }
    /**
     * AG_PC_VQ_58 - AG_PC_VQ_75
     * Read the file and store in a state variable
     * Validate the missing comments for the answers selected as No or Not applicable
     * Check for the common questions matches the imported questions, if not set the toast
     * set state the imported questions to the question state variable
     */
    const handleFileUpload = (e) => {
        // setQuestionData([]);
        setValidationFailed(false); // Reset validation flag

        const file = e.target.files[0];
        const reader = new FileReader();
        setExcelFile(file);
        let categoriesMatch = true;
        let hasMissingComments = false

        try {
            reader.onload = (event) => {
                try {
                    const workbook = new ExcelJS.Workbook();
                    workbook.xlsx.load(event.target.result).then(() => {
                        const worksheet = workbook.getWorksheet(1); // Assuming data is in the first worksheet
                        const rows = worksheet.getRows(2, worksheet.actualRowCount - 1); // Skip the header row

                        const importedSections = [];

                        rows.forEach((row) => {
                            const category = row.getCell(2).value;
                            const questionary_name = row.getCell(3).value;
                            const answer = row.getCell(4).value;
                            const comment = row.getCell(5).value;

                            if ((answer?.toLowerCase() === "no" || answer?.toLowerCase() === "not applicable" || answer === "") && (!comment || comment?.trim() === '')) {
                                hasMissingComments = true
                                setValidationFailed(true);
                                return
                            }

                            const existingCategoryIndex = importedSections.findIndex((section) => section.questionary_category === category);

                            if (existingCategoryIndex !== -1) {
                                importedSections[existingCategoryIndex].questions.push({
                                    questionary_name,
                                    questionary_value: answer,
                                    questionary_comments: comment
                                });
                            } else {
                                importedSections.push({
                                    questionary_category: category,
                                    questions: [{
                                        questionary_name,
                                        questionary_value: answer,
                                        questionary_comments: comment
                                    }]
                                });
                            }
                        });

                        commonQuestionData.forEach((commonCategory, index) => {
                            const category = importedSections[index];

                            if (!category || commonCategory.category !== category.questionary_category) {
                                categoriesMatch = false;
                                return; // Exit the loop early if categories don't match
                            }

                            commonCategory.questions.forEach((commonQuestion) => {
                                const question = category.questions.find(q => q.questionary_name === commonQuestion.questionary_name);

                                if (!question) {
                                    categoriesMatch = false;
                                    return; // Exit the loop early if a question doesn't match
                                }
                            });
                        });

                        if (hasMissingComments) {
                            return;
                        }

                        if (!categoriesMatch) {
                            setValidationFailed(false)
                            setToastMessage({
                                ...toastMessage,
                                path: "/images/error-toast-icon.svg",
                                header: "Data Mismatch",
                                message: "The Imported File does not match Questionnaires",
                                color: "error-toast",
                            });
                            setIsToast(true);
                            return;
                        }
                        if (categoriesMatch) {
                            setQuestionData(importedSections);
                        }
                    });
                } catch (error) {
                    navigate('/error')
                    // Handle the error, e.g., display an error message to the user
                }
            };

            reader.readAsArrayBuffer(file);
        } catch (error) {
            navigate('/error')
            // Handle the error, e.g., display an error message to the user
        }
    };



    const handleValueChange = (categoryIndex, questionIndex, newValue) => {
        setValueChange(true)
        const updatedQuestionData = [...questionData];
        updatedQuestionData[categoryIndex].questions[questionIndex].questionary_value = newValue;
        setQuestionData(updatedQuestionData);
    };

    const handleCommentChange = (categoryIndex, questionIndex, newComment) => {
        setValueChange(true)
        const updatedQuestionData = [...questionData];
        updatedQuestionData[categoryIndex].questions[questionIndex].questionary_comments = newComment;
        setQuestionData(updatedQuestionData);
    };


    return (
        <> <Loader isLoading={isLoading} />
            <div className="container-fluid mt-4 mt-custom">
                <div className="px-5">
                    {/*forms starts here*/}
                    <div className="row">
                        {/*heading starts here*/}
                        <div className="col-md-12 mb-4">
                            <h5 className="font-bold font-24" style={{ 'word-break': 'break-all' }}><img className="me-3 mb-1" src="/images/back-arrow.svg" alt="back-arrow" onClick={() => navigate('/compliance/vendor')} style={{ cursor: 'pointer', 'word-break': 'break-all' }} />{vendorName}</h5>
                        </div>
                        {/*heading starts here*/}
                        <div>
                            <ul className="nav nav-tabs border-0 mb-4" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button class="nav-link border-0 font-14 px-0 me-4 compliance-custom-tab " id="Primaryinfo-tab" data-bs-toggle="tab" data-bs-target="#Primaryinfo" type="button" role="tab" aria-controls="Primaryinfo" aria-selected="true" onClick={() => { handleNavigatePrimaryInfo() }}>
                                        Primary Information
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link border-0 font-14 px-0 compliance-custom-tab me-4 active" id="Questionnaire-tab" data-bs-toggle="tab" data-bs-target="#Questionnaire" type="button" role="tab" aria-controls="Questionnaire" aria-selected="false">
                                        Questionnaire
                                    </button>

                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link border-0 font-14 px-0 compliance-custom-tab me-4" id="Queshistory-tab" data-bs-toggle="tab" data-bs-target="#Queshistory" type="button" role="tab" aria-controls="Queshistory" aria-selected="false" onClick={() => { handleNavigateQuestionarieHistory() }}>
                                        Questionnaire History
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div id="myTabContent">
                            <div id="Questionnaire" role="tabpanel" aria-labelledby="Questionnaire-tab">
                                <div className="col-md-12 d-flex justify-content-end">
                                    {ClickFrom === 'vendorNameClick' || isAuditor ? null : (
                                        <>
                                            <label htmlFor="fileInput" className="import-btn customflip-btn px-3 font-14">
                                                <img className="me-1" src="/images/import-answers-icon.svg" alt="import-answers-icon" /> Import Answers
                                            </label>
                                            <input type="file" id="fileInput" accept=".xlsx,.xls" onChange={(e) => handleFileUpload(e)} style={{ display: 'none' }} />
                                        </>
                                    )}
                                </div>
                                {questionData.map((category, index) => {
                                    // Check if the category should be rendered
                                    const shouldRenderCategory = category.questions.length > 1 || (category.questions.length === 1 && category.questions[0].is_disable !== 1);

                                    // Render the category if it should be rendered
                                    return shouldRenderCategory && (
                                        <div key={index} className="mb-5">
                                            <h6 className="font-medium font-16 primary-textcolor mb-4">{category.category ? category.category : category.questionary_category}</h6>
                                            {category.questions.map((question, questionIndex) => (
                                                <div key={questionIndex} className="mb-4">
                                                    <p className="font-medium font-14">{question.questionary_name}</p>
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input custom-radio-input" type="radio" name={`ques${index}-${questionIndex}`} id={`ques${index}-${questionIndex}opt1`} checked={question.questionary_value ? question.questionary_value.toLowerCase() === 'yes' : false} onChange={() => handleValueChange(index, questionIndex, 'Yes')} disabled={ClickFrom === 'vendorNameClick' || isAuditor} style={{ color: 'inherit', opacity: 1 }} />
                                                        <label className="form-check-label font-regular font-14" htmlFor={`ques${index}-${questionIndex}opt1`}>
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input custom-radio-input" type="radio" name={`ques${index}-${questionIndex}`} id={`ques${index}-${questionIndex}opt2`} checked={question.questionary_value ? question.questionary_value.toLowerCase() === 'no' : false} onChange={() => handleValueChange(index, questionIndex, 'No')} disabled={ClickFrom === 'vendorNameClick' || isAuditor} style={{ color: 'inherit', opacity: 1 }} />
                                                        <label className="form-check-label font-regular font-14" htmlFor={`ques${index}-${questionIndex}opt2`}>
                                                            No
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input custom-radio-input" type="radio" name={`ques${index}-${questionIndex}`} id={`ques${index}-${questionIndex}opt3`} checked={question.questionary_value ? question.questionary_value.toLowerCase() === 'not applicable' : false}
                                                            onChange={() => handleValueChange(index, questionIndex, 'Not Applicable')} disabled={ClickFrom === 'vendorNameClick' || isAuditor} style={{ color: 'inherit', opacity: 1 }} />
                                                        <label className="form-check-label font-regular font-14" htmlFor={`ques${index}-${questionIndex}opt3`}>
                                                            Not Applicable
                                                        </label>
                                                    </div>
                                                    <textarea type="text" className="form-control font-regular font-14 custom-form-control py-2 resize-none" rows={4} id={`description${index}-${questionIndex}`} placeholder="Enter Description" spellCheck="false" value={question.questionary_comments} onChange={(e) => handleCommentChange(index, questionIndex, e.target.value)} disabled={ClickFrom === 'vendorNameClick' || isAuditor} style={{ color: 'inherit', opacity: 1, backgroundColor: 'inherit', borderColor: 'inherit' }} />
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div class="col-md-12 py-5">
                            <div class="d-flex justify-content-end">
                                {(!questionData || Object.keys(questionData).length === 0) ? null : (
                                    <>
                                        {ClickFrom === 'vendorNameClick' || isAuditor ? null : (
                                            <>
                                                <button type="button" class="primary-btn font-14 font-medium py-2 me-3" onClick={() => { navigate(0) }}>Cancel</button>
                                                {valueChange ? (
                                                    <button type="button" class="dark-btn font-14 font-medium py-2" onClick={() => { handleSave() }}>Update</button>
                                                ) : (
                                                    <button type="button" class="dark-btn font-14 font-medium py-2" onClick={() => { handleSave() }}>Save</button>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {isToast && (
                                    <ToastMessageComponent
                                        toastResponse={toastMessage}
                                        setIsShowToastMessage={setIsToast}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {validationFailed && (
                    <div class="modal fade show" id="missing-inputs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                        data-bs-backdrop="static" role="dialog" style={{ display: "block" }}>
                        <div class="modal-dialog modal-dialog-centered audit-custom-popup-width">
                            <div class="modal-content">
                                {/* //  <!--modal content starts here--> */}
                                <div class="modal-header border-0 pb-2 px-4">
                                    <h5 class="modal-title font-bold" id="exampleModalLabel"><img class="me-3" src="/images/missing-inputs-popup.svg" alt="missing-inputs-popup" />Missing Inputs</h5>
                                    <button type="button" class="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => setValidationFailed(false)}></button>
                                </div>
                                <div class="modal-body border-0 font-16 px-4">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <p class="font-16 primary-textcolor custom-line-height">Need to fill comments if <span class="font-medium">No/Not Applicable</span> is selected.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer border-0 mb-3 px-4">
                                    <button type="button" class="dark-btn font-14 font-medium py-2" data-bs-dismiss="modal" onClick={() => setValidationFailed(false)}>OK</button>
                                </div>
                                {/* //  <!--modal content ends here--> */}
                            </div>
                        </div>
                    </div>
                )}
                {validationFailed ? <div class="modal-backdrop fade show"></div> : <></>}
                {isToast && (
                    <ToastMessageComponent
                        toastResponse={toastMessage}
                        setIsShowToastMessage={setIsToast}
                    />
                )}
            </div>
        </>
    )
}



