//PC_SCP_1 to PC_SCP_3
import React, { useState } from "react";
//PC_SCP_4
const ConnectorPopUp = (props) => {
  const {
    disableTenantiD,
    setShowPopUp,
    updateConnector,
    insertConnector,
    formData,
    setFormData, } = props;

  //PC_SCP_5
  let errorObj = {
    orgIdError: false,
    connectorIdError: false,
    connectorTypeIdError: false,
    tenantIdError: false,
    clientIdError: false,
    clientSecretError: false,
    microsoftDomainError: false,
    cloudAppstokenError: false,
  };
  //PC_SCP_6
  const [errors, setErrors] = useState(errorObj);
  //PC_SCP_8 to //PC_SCP_11
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  //PC_SCP_12 to //PC_SCP_20
  const validateConnectorData = () => {
    let isValid = true;
    let connectorErrorObj = { ...errors };

    if (formData.orgId === "") {
      connectorErrorObj.orgIdError = true;
      isValid = false;
    }
    if (formData.connectorId === "") {
      connectorErrorObj.connectorIdError = true;
      isValid = false;
    }
    if (formData.connectorTypeId === "") {
      connectorErrorObj.connectorTypeIdError = true;
      isValid = false;
    }

    if (formData.tenantId === "") {
      connectorErrorObj.tenantIdError = true;
      isValid = false;
    }

    if (formData.clientId === "") {
      connectorErrorObj.clientIdError = true;
      isValid = false;
    }

    if (formData.clientSecret === "") {
      connectorErrorObj.clientSecretError = true;
      isValid = false;
    }

    if (formData.microsoftDomain === "") {
      connectorErrorObj.microsoftDomainError = true;
      isValid = false;
    }

    if (formData.cloudAppstoken === "") {
      connectorErrorObj.cloudAppstokenError = true;
      isValid = false;
    }

    setErrors(connectorErrorObj);
    return isValid;
  };
  //PC_SCP_21 to //PC_SCP_23
  const handleConnect = () => {
    if (validateConnectorData()) {
      props.setFormData(formData);
      props.setShowPopUp(false);
      props.insertConnector(formData);
     
      // else{
      //   props.updateConnector(formData);
      // }
    
    } else {
      console.log("all fields are mandatory");
    }
  };
  return (
    <>
      <div
        className="modal"
        id="admin-connect-btn"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
          <div className="modal-content">
            {/*modal content starts here*/}
            <div className="modal-header border-0 pb-2 px-4">
              <h5 className="modal-title font-bold" id="exampleModalLabel">
                Connect Azure
              </h5>
              <button
                type="button"
                className="btn-close ms-auto"
                aria-label="Close"
                onClick={() => { props.setShowPopUp(false) ; setFormData("") }}
              />
            </div>
            <div className="modal-body border-0 font-16 px-4">
              <div className="row">
                <label className="font-12 grey-light mb-3">
                  Effortlessly integrate Azure services with AGov using our
                  Azure connector.
                </label>
                <div className="col-md-12">
                  <label
                    htmlFor="tenant"
                    className="form-label font-medium font-14 primary-textcolor mb-1"
                  >
                    Tenant ID<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control font-regular font-14 custom-form-control py-2"
                    id="tenant"
                    placeholder="Enter Tenant ID"
                    name="tenantId"
                    value={formData.tenantId}
                    disabled={disableTenantiD == true ? "tenant" :""}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  <h6>
                    {errors.tenantIdError ? (
                      <span
                        id="alertMessage"
                        style={{ color: "red", font: "small-caption" }}
                      >
                        Enter TenantId
                      </span>
                    ) : null}
                  </h6>
                </div>
                <div className="col-md-12 mt-3">
                  <label
                    htmlFor="application"
                    className="form-label font-medium font-14 primary-textcolor mb-1"
                  >
                    Application ID / Client ID<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control font-regular font-14 custom-form-control py-2"
                    id="application"
                    placeholder="Enter Application ID / Client ID"
                    name="clientId"
                    value={formData.clientId}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  <h6>
                    {errors.clientIdError ? (
                      <span
                        id="alertMessage"
                        style={{ color: "red", font: "small-caption" }}
                      >
                        Enter clientId
                      </span>
                    ) : null}
                  </h6>
                </div>
                <div className="col-md-12 mt-3">
                  <label
                    htmlFor="Client"
                    className="form-label font-medium font-14 primary-textcolor mb-1"
                  >
                    Client Secret<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control font-regular font-14 custom-form-control py-2"
                    id="Client"
                    placeholder="Enter Client Secret"
                    name="clientSecret"
                    value={formData.clientSecret}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  <h6>
                    {errors.clientSecretError ? (
                      <span
                        id="alertMessage"
                        style={{ color: "red", font: "small-caption" }}
                      >
                        Enter Client Secret
                      </span>
                    ) : null}
          
                  </h6>
                </div>
                <div className="col-md-12 mt-3">
                 <h5 className="modal-title font-bold my-4" id="exampleModalLabel">
                    Microsoft CloudApps Connection
                  </h5>
                  <label
                    htmlFor="microsoft"
                    className="form-label font-medium font-14 primary-textcolor mb-1"
                  >
                    Microsoft Domain<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control font-regular font-14 custom-form-control py-2"
                    id="microsoft"
                    placeholder="Example: https://<domain name>us2.portal.cloudappsecurity.com"
                    name="microsoftDomain"
                    value={formData.microsoftDomain}
                      onChange={(e) => { handleInputChange(e) }}
                  />
                  <h6>
                    {errors.microsoftDomainError ? (
                      <span
                        id="alertMessage"
                        style={{ color: "red", font: "small-caption" }}
                      >
                        Enter Microsoft Domain
                      </span>
                    ) : null}
                  </h6>
                </div>
                <div className="col-md-12 mt-3">
                  <label
                    htmlFor="CloudApps"
                    className="form-label font-medium font-14 primary-textcolor mb-1"
                  >
                    Microsoft CloudApps Token<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control font-regular font-14 custom-form-control py-2"
                    id="CloudApps"
                    placeholder="Enter CloudApps Token"
                    name="cloudAppstoken"
                    value={formData.cloudAppstoken}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  <h6>
                    {errors.cloudAppstokenError ? (
                      <span
                        id="alertMessage"
                        style={{ color: "red", font: "small-caption" }}
                      >
                        Enter CloudAppsToken
                      </span>
                    ) : null}
                  </h6>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 my-3 px-4">
              <button
                type="button"
                className="primary-btn font-14 font-medium py-2"
                onClick={() => {
                  props.setShowPopUp(false);
                  ; setFormData("") }}
               >
                Cancel
              </button>
              <button
                type="button"
                className="dark-btn font-14 font-medium py-2"
                onClick={() => handleConnect()}
              >
                Connect
              </button>
            </div>
            {/*modal content ends here*/}
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show"></div>
    </>
    )
}

export { ConnectorPopUp }