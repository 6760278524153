import React, { useState, useEffect } from 'react';
import { requestDocument } from '../../Services/GRC/GrcService';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import { encryptStorage } from '../../Constant';
import { Loader } from '../Loader/Loader';


const RequestDocument = ({ controlInformation, setReRender, reRender }) => {
    const [formData, setFormData] = useState({
        evidenceType: '',
        evidenceName: '',
        evidenceDescription: ''
    });

    const [validationMessages, setValidationMessages] = useState({
        evidenceType: '',
        evidenceName: '',
        evidenceDescription: ''
    });

    const [showToast, setShowToast] = useState(false);
    const [requestDocumentResponse, setRequestDocumentResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        //console.log(formData);
    }, [formData])


    /**
     *PC_CC_434 - PC_CC_437
     The state of the input variables change when the values are changed in the popup
     */
    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({ ...prevState, [id]: value }));

        // Reset validation message for the field
        setValidationMessages(prevState => ({ ...prevState, [id]: '' }));
    };


    /**
     * PC_CC_438 - PC_CC_450
     * The auditor clicks on the submit button, it will create an evidence or a policy depending on the selection of the auditor
     */
    const handleSubmit = async () => {
        // Validate fields
        setIsLoading(true);
        let isValid = true;
        let newValidationMessages = {};

        if (!formData.evidenceType) {
            isValid = false;
            newValidationMessages.evidenceType = 'Please enter Type';
        }

        if (!formData.evidenceName) {
            isValid = false;
            newValidationMessages.evidenceName = 'Please enter Title';
        }

        if (!formData.evidenceDescription) {
            isValid = false;
            newValidationMessages.evidenceDescription = 'Please enter Description';
        }

        setValidationMessages(newValidationMessages);

        if (isValid) {
            let result = await requestDocument({
                "evidenceType": formData.evidenceType,
                "evidenceName": formData.evidenceName,
                "evidenceDescription": formData.evidenceDescription,
                "startDate": encryptStorage.getItem('commencer'),
                "endDate": encryptStorage.getItem('fin'),
                "controlId": controlInformation?.controlDetails?.controlId
            })
            setReRender(!reRender)
            if (result?.statusCode == 200) {
                setRequestDocumentResponse({
                    path: "/images/Success-icon.svg",
                    header: "Document has been requested Successfully",
                    message: "Document requested successfully.",
                    color: "success-toast"
                }
                );
            } else if (result?.statusCode != 200) {
                setRequestDocumentResponse({
                    path: "/images/error-toast-icon.svg",
                    header: "Failed to add the request document",
                    message: "request Document has not been created",
                    color: "error-toast"
                }
                );
            }
            setFormData({
                evidenceType: '',
                evidenceName: '',
                evidenceDescription: ''
            });
            setIsLoading(false);
            setShowToast(true)
        }
    };


    /**
     * PC_CC_451 - PC_CC_453
     * The popup is closed when the auditor clicks on the cancel button
     */
    const cancelRequest = () => {
        setFormData({
            evidenceType: '',
            evidenceName: '',
            evidenceDescription: ''
        });
        setValidationMessages({
            evidenceType: '',
            evidenceName: '',
            evidenceDescription: ''
        })
    }



    return (
        <>
            <div className="modal fade" id="request-document" tabIndex={-1} aria-labelledby="exampleModalLabel" data-bs-backdrop="static" aria-hidden="true" style={{ display: 'none' }}>
                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 pb-2 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">Request Document</h5>
                            <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="type" className="form-label font-medium font-14 primary-textcolor mb-1">Type<span className="required-text">*</span></label>
                                    <select className="form-select" aria-label="Default select example" id="evidenceType" value={formData.evidenceType} onChange={handleChange}>
                                        <option selected>Select</option>
                                        <option value='Policy'>Policy</option>
                                        <option value='Evidence'>Evidence</option>
                                    </select>
                                    <div className="text-danger">{validationMessages.evidenceType}</div>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <label className="form-label font-medium font-14 primary-textcolor mb-2 w-100">Control Owner</label>
                                    <span>{controlInformation?.controlDetails?.controlOwner ? <img className="custom-img-style me-2" src={controlInformation?.controlDetails?.profileUrl} alt="profile-img" /> : null}{controlInformation?.controlDetails?.controlOwner || 'UnAssigned'}</span>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label htmlFor="evidenceName" className="form-label font-medium font-14 primary-textcolor mb-1 w-100">Title<span className="required-text">*</span></label>
                                    <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="evidenceName" maxLength={250} placeholder="Enter Title" value={formData.evidenceName} onChange={handleChange} />
                                    <div className="text-danger">{validationMessages.evidenceName}</div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="evidenceDescription" className="form-label font-medium font-14 primary-textcolor mb-1 w-100">Description<span className="required-text">*</span></label>
                                    <textarea type="text" className="form-control font-regular font-14 custom-form-control py-2 resize-none" rows={4} id="evidenceDescription" placeholder="Enter your reason" value={formData.evidenceDescription} onChange={handleChange} />
                                    <div className="text-danger">{validationMessages.evidenceDescription}</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 mt-2 mb-3 px-4">
                            <button type="button" className="primary-btn font-14 font-medium py-2" data-bs-dismiss="modal" onClick={() => { cancelRequest(); }}>Cancel</button>
                            <button type="button"
                                className={!formData.evidenceDescription || !formData.evidenceName || !formData.evidenceType ? "disabled-btn font-14 font-medium py-2" : "dark-btn font-14 font-medium py-2"}
                                disabled={!formData.evidenceDescription || !formData.evidenceName || !formData.evidenceType} data-bs-dismiss={formData.evidenceType != '' && formData.evidenceName != '' && formData.evidenceDescription != '' ? "modal" : ""} onClick={handleSubmit}>Request</button>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
            <Loader isLoading={isLoading} />
            {showToast && <ToastMessageComponent toastResponse={requestDocumentResponse} setIsShowToastMessage={setShowToast} />}
        </>

    )
}

export default RequestDocument;
