import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { agovContext, defaultThumbnailBlobUrl, getFileDetails } from '../../Constant';
import { handleUpload } from '../../Configs/BlobContainer';
import DeletePopup from '../LMS/DeletePopup';
import { getCampaignDetails, deleteCampaignDetails, getAllCourses, getAllRecurrenceConditions, getAllPriorityDetails, postCampaignDetails } from '../../Services/LMS/LmsService';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import TotalUserDetailsPopUp from './TotalUserDetailsPopUp';
import CreateCampaignWizardForm1 from './CreateCampaignWizardForm1';
import NoRecordsPage from './NoRecordsPage';
import CreateCampaignWizardForm2 from './CreateCampaignWizardForm2';
import NoDataFound from './NoDataFound';
import { Loader } from '../Loader/Loader';
import { isObject } from 'lodash';

const CampaignGrid = () => {

    //PC_GCDFE_NO_14
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, roles, employeeName } = useContext(agovContext);

    const navigatedCampaignDetails = location?.state?.value;

    //PC_GCDFE_NO_9
    const campaignSearchRequestObject = {
        campaignName: "",
        sortField: 'tcamp.created_on',
        sortOrder: 'desc',
        limit: 10,
        filterValue: {
            campaignType: '',
            status: navigatedCampaignDetails?.status || ''
        },
        reqDuration: navigatedCampaignDetails?.reqDuration || ''
    };

    //PC_GCDFE_NO_10
    const campaignDataObject = {
        campaignId: "",
        campaignName: "",
        campaignDescription: "",
        recurrenceCondition: "",
        recurrenceEndDate: "",
        courseId: "",
        campaignThumbnail: "",
        isRecursive: "",
        nextRecurrenceDate: ""
    };

    //PC_GCDFE_NO_11
    const taskDataObject = {
        priorityId: 0,
        startDate: "",
        endDate: "",
        assigneeDetails: {
            userIds: [],
            domainNames: []
        },

    };

    /*
    * PC_GCDFE_NO_12 - Initialzing the state variable
    */
    const [campaignSearchRequest, setCampaignSearchRequest] = useState(campaignSearchRequestObject);
    const [campaignDeletedResponse, setCampaignDeletedResponse] = useState({});
    const [campaignDetails, setCampaignDetails] = useState([]);
    const [campaignSearchName, setCampaignSearchName] = useState('');
    const [totalCampaignsCount, setTotalCampaignsCount] = useState(0);
    const [isLoadMoreVisible, setIsLoadMoreVisible] = useState(true);
    const [isCreateCampaignPopvisible, setIsCreateCampaignPopvisible] = useState(false);
    const [isDeleteApiFailed, setIsDeleteApiFailed] = useState(false);
    const [currentForm, setCurrentForm] = useState(0);
    const [isDeleteCampaignPopupVisible, setIsDeleteCampaignPopupVisible] = useState(false);
    const [deletedCampaignAssigneeCount, setDeletedCampaignAssigneeCount] = useState(0);
    const [isTotalUserPopUpVisible, setIsTotalUserPopUpVisible] = useState(false);
    const [selectedUserRequest, setSelectedUserRequest] = useState('');
    const [deleteCampaignRequest, setDeleteCampaignRequest] = useState({});
    const [recurrenceConditionDropdownValues, setRecurrenceConditionDropdownValues] = useState([]);
    const [allCoursesDropdownValues, setAllCoursesDropdownValues] = useState([]);
    const [allPriorityDropdownValues, setAllPriorityDropdownValues] = useState([]);
    const [campaignData, setCampaignData] = useState(campaignDataObject);
    const [taskData, setTaskData] = useState(taskDataObject);
    const [thumbnailData, setThumbnailData] = useState(null);
    const [excelData, setExcelData] = useState(null);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [isNorecordPageVisible, setIsNorecordPageVisible] = useState(false);
    const [isNoDataFoundPageVisible, setIsNoDataFoundPageVisible] = useState(false);
    const [isAssigneeTypeVisible, setIsAssigneeTypeVisible] = useState(false);
    const [isImportExcelVisible, setIsImportExcelVisible] = useState(false);
    const [isSelectAssigneeVisible, setIsSelectAssigneeVisible] = useState(false);
    const [selectAssigneeDropdownValues, setSelectedAssigneeDropdownValues] = useState([]);
    const [isAssigneeTypeIndividual, setIsAssigneeTypeIndividual] = useState(null);
    const [selectedAssigneeDetails, setSelectedAssigneeDetails] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState("");
    const [selectedAssigneeType, setSelectedAssigneeType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isNextLoading, setIsNextLoading] = useState(false);
    const [isFilterPopup, setIsFilterPopup] = useState(false);
    const [selectedCampaignType, setSelectedCampaignType] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(navigatedCampaignDetails?.status||"");
    const [isAdmin, setIsAdmin] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);

    /*
    * PC_GCDFE_NO_15
    * Initializing the useEffect hook with dependencies of state variable campaignSearchRequest.
    */
    useEffect(() => {
        if (roles?.includes("Super Admin") || roles?.includes("LMS Admin")) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
       
        populateCampaignDetails();
    }, [campaignSearchRequest,navigatedCampaignDetails]);



    const handleApplyFilter = () => {
        setCampaignSearchRequest((prevState) => ({
            ...prevState,
            filterValue: {
                status: selectedStatus,
                campaignType: selectedCampaignType,
            },
        }));
        setIsFilterPopup(false);
        // Clear the selected values
        setSelectedStatus(selectedStatus);
        setSelectedCampaignType(selectedCampaignType);
    };

    //Funstion to handle clear button in advanced filter.
    const handleClearFilter = () => {
        setSelectedStatus('');
        setSelectedCampaignType('');
        setCampaignSearchRequest((prevState) => ({
            ...prevState,
            filterValue: {
                status: '',
                campaignType: '',
            },
        }));
        setIsFilterPopup(false);
    };
    /*
    * PC_GCDFE_NO_18
    * Declaring populatecampaignDetails method to get all the required data in a initial page load.
    */
    const populateCampaignDetails = async () => {
        
        try {
            setIsLoading(true);
            const result = await getCampaignDetails(campaignSearchRequest);
            if (result.statusCode !== 200) {
                setIsLoading(false);
                navigate('/error');
                return;
            }
            

            setCampaignDetails(result.response);
            setTotalCampaignsCount(result.totalResultCount);
             const isFilterApplied = campaignSearchRequest.campaignName || 
                            campaignSearchRequest.filterValue.campaignType || 
                            campaignSearchRequest.filterValue.status;
            if (result.response.length === 0) {
                if (isFilterApplied) {
                    setIsNoDataFoundPageVisible(true)
                }
                else {
                    setIsNorecordPageVisible(true);
                }
            }
            else {
                setIsNoDataFoundPageVisible(false)
            }

            if (result.totalResultCount > 10 && result.response.length != result.totalResultCount) {
                setIsLoadMoreVisible(false);
            } else {
                setIsLoadMoreVisible(true);
            }

            if (campaignSearchRequest.filterValue.campaignType|| campaignSearchRequest.filterValue.statusatus) {
                setNoDataFound(true)
                // setTaskSearchRequest(requestDetails)
              }
              else {
                setNoDataFound(false)
              }
        }
        catch (error) {
            console.error("An error occurred:", error);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    /*
    * PC_GCDFE_NO_38 - Declaring generateCampaignDetails method to bind the dynamic rows data in the table.
    */
    const generateCampaignData = () => {
        return campaignDetails?.map((item) => (
            <tr>
                <td className="font-12 font-medium">
                    <a className="text-decoration-none pointerCursor multiTab-column" onClick={() => navigate('/lmsmanagement/viewmedia', { state: { value: { courseId: "", videoId: "", campaignId: item.campaignId, taskId: "" } } })}>
                        {item.campaignName}
                    </a>
                </td>
                <td className="font-12">{item.courseName}</td>
                <td className="font-12">{item.recurrenceCondition}</td>
                <td className="font-12 font-medium text-end">
                    <a className="text-decoration-none cursor-pointer" onClick={() => handleTotalUser(item.campaignId)}>
                        {item.assigneeCount}
                    </a>
                </td>
                <td className="font-12">{moment(item.campaignStartDate).format('MM/DD/YYYY')}</td>
                <td className="font-12">{moment(item.campaignEndDate).format('MM/DD/YYYY')}</td>
                <td className="text-center">
                    <a className="me-3 text-decoration-none" onClick={() => handleEditCampaign(item.campaignId)}>
                        <img style={{ cursor: "pointer" }} src="/images/edit-icon.svg" alt="edit-icon" />
                    </a>
                    <a className="text-decoration-none" onClick={() => handleDeleteCampaign(item.campaignId, item.campaignName, item.assigneeCount)}>
                        <img style={{ cursor: "pointer" }} src="/images/delete-icon.svg" alt="delete-icon" />
                    </a>
                </td>
            </tr>
        ))
    };

    /* 
    * PC_GCDFE_NO_162 - PC_GCDFE_NO_217
    * Declaring handleEditCampaign to get the campaign details and prepopulated in the edit campaign popup.
    */
    const handleEditCampaign = async (campaignId) => {
        const req = {
            "userId": userId
        };
        setIsLoading(true);
        const courseResult = await getAllCourses(req);
        if (courseResult.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }

        const courseDropdownValues = courseResult.response.map(item => ({
            label: item.courseName,
            value: item.courseId
        }));

        setAllCoursesDropdownValues(courseDropdownValues);

        const recurrenceConditionResult = await getAllRecurrenceConditions(req);
        if (recurrenceConditionResult.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }
        setRecurrenceConditionDropdownValues(recurrenceConditionResult.response);

        const campaignReq = {
            campaignId: campaignId
        };
        const result = await getCampaignDetails(campaignReq);
        if (result.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }

        const campaignDetails = result.response[0];

        try {
            const mediaData = await getFileDetails(campaignDetails.campaignThumbnail);
            setThumbnailData(mediaData);
        } catch (error) {
            console.error("Error fetching media data:", error);
        }

        setTaskData({
            ...taskData,
            priorityId: campaignDetails.taskPriorityId.toString(),
            startDate: moment(campaignDetails.campaignStartDate).format('YYYY-MM-DD'),
            endDate: moment(campaignDetails.campaignEndDate).format('YYYY-MM-DD')
        });

        setCampaignData({
            ...campaignData,
            campaignId: campaignDetails.campaignId,
            campaignName: campaignDetails.campaignName,
            campaignDescription: campaignDetails.campaignDescription,
            campaignThumbnail: campaignDetails.campaignThumbnail,
            isRecursive: campaignDetails.isRecursive ? 1 : 0,
            nextRecurrenceDate: campaignDetails.nextRecurrenceDate !== null ? moment(campaignDetails.nextRecurrenceDate).format('YYYY-MM-DD') : null,
            courseId: campaignDetails.courseId,
            courseName: campaignDetails.courseName,
            recurrenceCondition: campaignDetails.recurrenceConditionId.toString(),
            recurrenceConditionName: campaignDetails.recurrenceCondition,
            recurrenceEndDate: campaignDetails.nextRecurrenceDate !== null ? moment(campaignDetails.recurrenceEndDate).format('YYYY-MM-DD') : null
        })
        setSelectedCourses([campaignDetails.courseName])
        setIsLoading(false);
        setCurrentForm(0);
        setIsCreateCampaignPopvisible(true);
    }

    const handleLoadMore = () => {
        setCampaignSearchRequest(prevState => ({ ...prevState, limit: prevState.limit + 10 }));
    };

    /*
    * PC_GCDFE_NO_46 - PC_GCDFE_NO_50
    * Declaring handleOnclickSearch method to perform campaign search when user use enter key for search.
    */
    const handleOnKeyDownSearch = (event) => {
        setCampaignSearchName(event.target.value.trim() === "" ? "" : event.target.value);
        if (event.key === "Enter") {
            setCampaignSearchRequest({
                ...campaignSearchRequest,
                campaignName: event.target.value.trim() === "" ? "" : event.target.value,
                sortField: 'tcamp.created_on',
                sortOrder: 'desc',
                limit: 10
            });
        }
    };

    /*
    * PC_GCDFE_NO_89 - PC_GCDFE_NO_92
    * Declaring handleOnclickSearch method to perform campaign search when user click on the search icon.
    */
    const handleOnclickSearch = () => {
        setCampaignSearchRequest({
            ...campaignSearchRequest,
            campaignName: campaignSearchName,
            sortField: 'tcamp.created_on',
            sortOrder: 'desc',
            limit: 10
        });
    };

    /*
    * PC_GCDFE_NO_56 - PC_GCDFE_NO_60
    * Declaring handleDeleteCampaign method to display the delete campaign confirmation popup and set the deleted campaign details request and assignee count.
    */
    const handleDeleteCampaign = (campaignId, campaignName, assigneeCount) => {
        setDeleteCampaignRequest({
            userId: userId,
            userName: employeeName,
            campaignIds: [campaignId],
            campaignNames: [campaignName]
        });
        setIsDeleteCampaignPopupVisible(true);
        setDeletedCampaignAssigneeCount(assigneeCount);
    };

    /*
    * PC_GCDFE_NO_66 - PC_GCDFE_NO_81
    * Declaring handleConfirmDeleteCampaign method to delete the campaign details and displayed with the campaign grid page.
    */
    const handleConfirmDeleteCampaign = async () => {
        setIsDeleteCampaignPopupVisible(false);
        setIsLoading(true);
        const result = await deleteCampaignDetails(deleteCampaignRequest);
        if (result.statusCode !== 200) {
            setCampaignDeletedResponse({
                path: "/images/error-toast-icon.svg",
                header: "Failed to delete campaign",
                message: "Failed to delete campaign",
                color: "error-toast"
            });
        } else {
            setCampaignDeletedResponse({
                path: "/images/Success-icon.svg",
                header: "Deleted Successfully",
                message: "Campaign Successfully Deleted.",
                color: "success-toast"
            });
        }
        setIsLoading(false);
        setIsDeleteApiFailed(true);
        setTimeout(() => {
            setCampaignSearchRequest(campaignSearchRequestObject);
        }, 3500);
    };

    /*
    * PC_GCDFE_NO_83 - PC_GCDFE_NO_87
    * Declaring handleSortColumns method to perform sort on the columns.
    */
    const handleSortColumns = (column, order) => {
        setCampaignSearchRequest({
            ...campaignSearchRequest,
            sortOrder: order,
            sortField: column
        });
    };

    /*
    * PC_GCDFE_NO_128 - PC_GCDFE_NO_160
    * Delcaring handleCreateCampaign method to get all required dropdown data such as courses, recurrence condition dropdown values and dispaly the create campaign popup.
    */
    const handleCreateCampaign = async () => {
        setIsLoading(true);
        const req = {
            "userId": userId
        };
        const courseResult = await getAllCourses(req);
        if (courseResult.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }

        const courseDropdownValues = courseResult.response.map(item => ({
            label: item.courseName,
            value: item.courseId
        }));

        setAllCoursesDropdownValues(courseDropdownValues);

        const recurrenceConditionResult = await getAllRecurrenceConditions(req);
        if (recurrenceConditionResult.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }
        setRecurrenceConditionDropdownValues(recurrenceConditionResult.response);
        setIsLoading(false);
        setIsCreateCampaignPopvisible(true);
    };

    /*
    * PC_GCDFE_NO_111 - PC_GCDFE_NO_126
    * Delcaring handleNextButton method to display the task form and get the priority details.
    */
    const handleNextButton = async () => {
        setIsNextLoading(true);
        const req = {
            "userId": userId
        }
        const priorityResult = await getAllPriorityDetails(req);
        if (priorityResult.statusCode !== 200) {
            setIsNextLoading(false);
            navigate('/error');
            return;
        }
        setAllPriorityDropdownValues(priorityResult.response);
        setIsNextLoading(false);
        setCurrentForm(1);
    };

    /*
    * PC_GCDFE_NO_95 - PC_GCDFE_NO_108
    * Delcaring handleCloseCampaignPopUp method to clear all form field state value and campaign popup is closed.
    */
    const handleCloseCampaignPopUp = () => {
        setCampaignData(campaignDataObject);
        setTaskData(taskDataObject);
        setCurrentForm(0);
        setSelectedAssigneeType("");
        setSelectedUserType("");
        setSelectedAssigneeDetails([]);
        setIsCreateCampaignPopvisible(false);
        setIsImportExcelVisible(false);
        setIsAssigneeTypeIndividual(false);
        setIsAssigneeTypeVisible(false);
        setIsSelectAssigneeVisible(false);
        setThumbnailData(null);
        setExcelData(null);
        setSelectedCourses([]);
        setIsNextLoading(false);
    }

    /*
    * PC_GCDFE_NO_219 - PC_GCDFE_NO_245
    * Delcaring handlePostCampaignDetails method to post the campaign details.
    */
    const handlePostCampaignDetails = async () => {
        setIsCreateCampaignPopvisible(false);
        setIsLoading(true);
        let req = { ...campaignData, ...taskData };
        req.userId = userId;
        req.userName = employeeName;
        if (campaignData.campaignThumbnail !== "" && campaignData.campaignThumbnail !== undefined) {
            
            req.campaignThumbnail = await handleUpload(campaignData.campaignThumbnail);
        } else {
            req.campaignThumbnail = defaultThumbnailBlobUrl;
        }

        if (isObject(campaignData.campaignThumbnail)) {
            req.campaignThumbnail = await handleUpload(campaignData.campaignThumbnail);
        } else if (campaignData.campaignThumbnail !== "" && campaignData.campaignThumbnail !== undefined) {
            req.campaignThumbnail = campaignData.campaignThumbnail.split('?')[0];
        }

        if (campaignData.campaignThumbnail === "") {
            req.campaignThumbnail = defaultThumbnailBlobUrl;
        }

        const postedCampaignResult = await postCampaignDetails(req);
        if (postedCampaignResult.statusCode !== 200) {
            setCampaignDeletedResponse({
                path: "/images/error-toast-icon.svg",
                header: req.campaignId === "" ? "Failed to create" : "Failed to update",
                message: req.campaignId === "" ? "Failed to create campaign" : "Failed to update campaign",
                color: "error-toast"
            });
        } else {
            setCampaignDeletedResponse({
                path: "/images/Success-icon.svg",
                header: req.campaignId === "" ? "Created Successfully" : "Updated Successfully",
                message: req.campaignId === "" ? "Campaign Successfully Created." : "Campaign Successfully Updated.",
                color: "success-toast"
            });
        }

        handleCloseCampaignPopUp();
        setIsLoading(false);
        setIsDeleteApiFailed(true);
        setTimeout(() => {
            setCampaignSearchRequest(campaignSearchRequestObject);
        }, 3500);

    }

    //To show the total user pop up
    const handleTotalUser = async (campaignId) => {
        setSelectedUserRequest(campaignId);
        setIsTotalUserPopUpVisible(true);
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            {!isNorecordPageVisible ? <div className="container-fluid mt-4">
                <div className="col-md-12 px-5" style={{ marginTop: '5%' }}>
                    <div className="d-flex justify-content-between mt-4">
                        <h1 className="font-bold font-24 primary-textcolor">
                            Learning Management System
                        </h1>
                    </div>

                    {/* LMS tab starts here */}
                    <div className="d-md-block d-lg-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
                        <ul className="nav mb-lg-0 mb-3 nav-pills" id="pills-tab" role="tablist">

                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0  font-14 tab-style bold-text rounded-0" id="Overview-tab" data-bs-toggle="pill" data-bs-target="#Overview" type="button" role="tab" aria-controls="Overview" aria-selected="true" onClick={() => isAdmin ? navigate('/lmsmanagement') : navigate('/lmsmanagement/myLearnings')}>
                                    <span className="tab-img"> <img src="images/lms-overview-active.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/lms-overview.svg" alt="Overview-icon" className="me-2" /></span >Overview</button>
                            </li>

                            <li className="nav-item ms-4" role="presentation">
                                <button
                                    className="nav-link px-0 font-14 tab-style rounded-0"
                                    id="MyLearning-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#MyLearning"
                                    type="button"
                                    role="tab"
                                    aria-controls="MyLearning"
                                    aria-selected="true"
                                    onClick={() =>  navigate('/lmsmanagement/myLearnings')}
                                >
                                    <span className="tab-img">
                                        {" "}
                                        <img
                                            src="/images/my-learning-active.svg"
                                            alt="filled-icon"
                                            className="me-2"
                                        />
                                        <img
                                            src="/images/my-learning-inactive.svg"
                                            alt="MyLearning-icon"
                                            className="me-2"
                                        />
                                    </span>
                                    My Learning
                                </button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button
                                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                                    id="Courses-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Courses"
                                    type="button"
                                    role="tab"
                                    aria-controls="Courses"
                                    aria-selected="false"
                                    tabIndex={-1}
                                    onClick={() => { navigate("/lmsmanagement/course") }}
                                >
                                    <span className="tab-img">
                                        {" "}
                                        <img
                                            src="/images/course-active.svg"
                                            alt="filled-icon"
                                            className="me-2"
                                        />{" "}
                                        <img
                                            src="/images/course-inactive.svg"
                                            alt="Courses-icon"
                                            className="me-2"
                                        />
                                    </span>
                                    Courses
                                </button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button
                                    className="nav-link px-0 font-14 active tab-style bold-text rounded-0"
                                    id="Campaigns-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Campaigns"
                                    type="button"
                                    role="tab"
                                    aria-controls="Campaigns"
                                    aria-selected="false"
                                    tabIndex={-1}
                                    onClick={() => { navigate("/lmsmanagement/campaign") }}
                                >
                                    <span className="tab-img">
                                        <img
                                            src="/images/campaign-active.svg"
                                            alt="Campaigns-icon"
                                            className="me-2"
                                        />
                                        <img
                                            src="/images/campaign-inactive.svg"
                                            alt="filled-icon"
                                            className="me-2"
                                        />
                                        Campaigns
                                    </span>
                                </button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button
                                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                                    id="MediaLibrary-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#MediaLibrary"
                                    type="button"
                                    role="tab"
                                    aria-controls="MediaLibrary"
                                    aria-selected="false"
                                    tabIndex={-1}
                                    onClick={() => { navigate("/lmsmanagement/medialibrary") }}
                                >
                                    <span className="tab-img">
                                        <img
                                            src="/images/media-lib-active.svg"
                                            alt="MediaLibrary-icon"
                                            className="me-2"
                                        />
                                        <img
                                            src="/images/media-lib-inactive.svg"
                                            alt="filled-icon"
                                            className="me-2"
                                        />
                                        Media Library
                                    </span>
                                </button>
                            </li>
                        </ul>
                        {/* Campaigns tab filter starts here */}
{/* 
                        {noDataFound ? (
            <NoDataFound />
          ) : (  )} */}
                          <div className="d-flex">
                            <div className="input-group flex-nowrap search-group me-3">
                                <input
                                    type="search"
                                    className="form-control search-align"
                                    placeholder="Search"
                                    aria-label="Username"
                                    aria-describedby="addon-wrapping"
                                    name="campaignName"
                                    value={campaignSearchName}
                                    onChange={(e) => setCampaignSearchName(e.target.value)}
                                    onKeyDownCapture={(e) => handleOnKeyDownSearch(e)}
                                />
                                <button
                                    className="input-group-text btn btn-search-custom pointer"
                                    id="addon-wrapping"
                                    onClick={() => handleOnclickSearch()}
                                >
                                    <img src="/images/search-icon.svg" alt="search-icon" />
                                </button>
                            </div>
                            <div className="ms-2 position-relative">
                                <button className="cust-filter-btn d-flex align-items-center" type="button" onClick={() => { setIsFilterPopup(!isFilterPopup) }}>
                                    <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                                </button>
                                {isFilterPopup ? <div className="dropdown-menu custom-filter pop border-0 show">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                                            <button type="button" className="btn-close font-12" onClick={() => { setIsFilterPopup(false) }} />
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label text-black font-12 font-medium">Status</label>
                                                    <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example" value ={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}> 
                                                        <option value="">Select</option>
                                                        <option value='Active'>Active</option>
                                                        <option value='Completed'>Completed</option>
                                                        <option value='Overdue'>Overdue</option>
                                                        <option value = 'Upcoming'>Upcoming</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label text-black font-12 font-medium">Campaign type
                                                    </label>
                                                    <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example" value={selectedCampaignType} onChange ={(e)=> setSelectedCampaignType(e.target.value)}>
                                                        <option selected>Select</option>
                                                        <option value='Security'>security</option>
                                                        <option value='Others'>others</option>
                                                       
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end mt-4 mb-2">
                                            <button type="button" className="primary-btn font-14 font-medium me-2" onClick={handleClearFilter}>
                                                Clear
                                            </button>
                                            <button type="button" className="dark-btn font-14 font-medium px-4" onClick={handleApplyFilter}>
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div> : <></>}
                            </div>
                            <button
                                type="button"
                                className="dark-btn font-14 font-medium py-2 text-nowrap align-items-center pointer ms-4"
                                onClick={() => handleCreateCampaign()}
                            >
                                <span>
                                    <img
                                        src="/images/create-course-icon.svg"
                                        alt="create-course-icon"
                                        className="me-1 "
                                    />
                                </span>
                                Create Campaigns
                            </button>
                        </div>
                        {/* Campaigns tab filter ends here */}
                    </div>
                    {/* LMS tab ends here */}

                    {/*Campaigns tab starts here*/}
                    <div
                        className="tab-pane fade show"
                        id="Campaigns"
                        role="tabpanel"
                        aria-labelledby="Campaigns-tab"
                    >
                        {/*Campaign table*/}
                        {isNoDataFoundPageVisible ? (
                            <NoDataFound />
                        ) : (<div className="table-responsive">
                            <table className="table text-nowrap mb-0 custom-table-grid table-borderless mt-0">
                                <thead>
                                    <tr>
                                        <th className="font-14 px-4 position-relative">
                                            Campaign Name
                                            <span className="position-absolute">
                                                <a className="sort-up position-absolute ms-2 " onClick={() => handleSortColumns("tcamp.campaign_name", 'asc')}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        className="sort-img-size"
                                                        src="/images/sort-up.svg"
                                                        alt="sort-up-icon"
                                                    />
                                                </a>{" "}
                                                <a className="sort-down position-absolute ms-2" onClick={() => handleSortColumns("tcamp.campaign_name", 'desc')}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        className="sort-img-size"
                                                        src="/images/sort-down.svg"
                                                        alt="sort-down-icon"
                                                    />
                                                </a>
                                            </span>
                                        </th>
                                        <th className="font-14 position-relative">
                                            Course Name
                                            <span className="position-absolute">
                                                <a className="sort-up position-absolute ms-2" onClick={() => handleSortColumns("tc.course_name", 'asc')}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        className="sort-img-size"
                                                        src="/images/sort-up.svg"
                                                        alt="sort-up-icon"
                                                    />
                                                </a>{" "}
                                                <a className="sort-down position-absolute ms-2" onClick={() => handleSortColumns("tc.course_name", 'desc')}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        className="sort-img-size"
                                                        src="/images/sort-down.svg"
                                                        alt="sort-down-icon"
                                                    />
                                                </a>
                                            </span>
                                        </th>
                                        <th className="font-14 position-relative">
                                            Recursive Condition
                                        </th>
                                        <th className="font-14 position-relative text-end">
                                            Assignees
                                        </th>
                                        <th className="font-14 position-relative">
                                            Start Date
                                            <span className="position-absolute">
                                                <a className="sort-up position-absolute ms-2" onClick={() => handleSortColumns("tcamp.campaign_start_date", 'asc')}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        className="sort-img-size"
                                                        src="/images/sort-up.svg"
                                                        alt="sort-up-icon"
                                                    />
                                                </a>{" "}
                                                <a className="sort-down position-absolute ms-2" onClick={() => handleSortColumns("tcamp.campaign_start_date", 'desc')}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        className="sort-img-size"
                                                        src="/images/sort-down.svg"
                                                        alt="sort-down-icon"
                                                    />
                                                </a>
                                            </span>
                                        </th>
                                        <th className="font-14 position-relative">
                                            End Date
                                            <span className="position-absolute">
                                                <a className="sort-up position-absolute ms-2" onClick={() => handleSortColumns("tcamp.campaign_end_date", 'asc')}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        className="sort-img-size"
                                                        src="/images/sort-up.svg"
                                                        alt="sort-up-icon"
                                                    />
                                                </a>{" "}
                                                <a className="sort-down position-absolute ms-2" onClick={() => handleSortColumns("tcamp.campaign_end_date", 'desc')}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        className="sort-img-size"
                                                        src="/images/sort-down.svg"
                                                        alt="sort-down-icon"
                                                    />
                                                </a>
                                            </span>
                                        </th>
                                        <th className="font-14 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {generateCampaignData()}
                                </tbody>
                            </table>
                        </div>)}

                        {!isNoDataFoundPageVisible && <p className="font-12 mt-2 grey-primary">
                            Showing <span className="font-medium primary-textcolor"> {campaignDetails.length} </span>
                            out of <span className="font-medium primary-textcolor">{totalCampaignsCount}</span> items
                        </p>}
                        <div className="text-center mt-3 mb-4">
                            <button className="primary-btn font-medium" hidden={isLoadMoreVisible} onClick={() => handleLoadMore()}>Load More</button>
                        </div>
                    </div>
                    {/*Campaigns tab end here*/}
                </div>
            </div> : <NoRecordsPage
                message={"Currently, there are no campaigns created, Please create a campaign."}
                isButtonVisible={true}
                buttonName={"Create Campaign"}
                handleButtonSubmit={handleCreateCampaign}
                isMyLearningTab={false}
                isCourseTab={false}
                isCampaignTab={true}
                isMediaLibraryTab={false}
            />}

            {isDeleteCampaignPopupVisible && <DeletePopup
                isCourseDelete={false}
                setIsDeleteCampaignPopupVisible={setIsDeleteCampaignPopupVisible}
                handleConfirm={handleConfirmDeleteCampaign}
                assigneeCount={deletedCampaignAssigneeCount}
            />}

            {isDeleteApiFailed && <ToastMessageComponent
                toastResponse={campaignDeletedResponse}
                setIsShowToastMessage={setIsDeleteApiFailed}
            ></ToastMessageComponent>}

            {isTotalUserPopUpVisible && <TotalUserDetailsPopUp
                onClose={setIsTotalUserPopUpVisible}
                selectedUserRequest={selectedUserRequest}
            />}

            {(isCreateCampaignPopvisible && currentForm == 0) && <CreateCampaignWizardForm1
                setIsCreateCampaignPopvisible={setIsCreateCampaignPopvisible}
                handleNextButton={handleNextButton}
                recurrenceConditionsDropdownValues={recurrenceConditionDropdownValues}
                allCoursesDropdownValues={allCoursesDropdownValues}
                setCampaignData={setCampaignData}
                campaignData={campaignData}
                setTaskData={setTaskData}
                taskData={taskData}
                close={handleCloseCampaignPopUp}
                thumbnailData={thumbnailData}
                setThumbnailData={setThumbnailData}
                selectedCourses={selectedCourses}
                setSelectedCourses={setSelectedCourses}
                isNextLoading={isNextLoading}
            >
            </CreateCampaignWizardForm1>
            }

            {(isCreateCampaignPopvisible && currentForm == 1) && <CreateCampaignWizardForm2
                setIsCreateCampaignPopvisible={setIsCreateCampaignPopvisible}
                setCurrentForm={setCurrentForm}
                allPriorityDropdownValues={allPriorityDropdownValues}
                setTaskData={setTaskData}
                taskData={taskData}
                campaignData={campaignData}
                handlePostCampaignDetails={handlePostCampaignDetails}
                close={handleCloseCampaignPopUp}
                excelData={excelData}
                setExcelData={setExcelData}
                isAssigneeTypeVisible={isAssigneeTypeVisible}
                setIsAssigneeTypeVisible={setIsAssigneeTypeVisible}
                isImportExcelVisible={isImportExcelVisible}
                setIsImportExcelVisible={setIsImportExcelVisible}
                isSelectAssigneeVisible={isSelectAssigneeVisible}
                setIsSelectAssigneeVisible={setIsSelectAssigneeVisible}
                selectAssigneeDropdownValues={selectAssigneeDropdownValues}
                setSelectedAssigneeDropdownValues={setSelectedAssigneeDropdownValues}
                isAssigneeTypeIndividual={isAssigneeTypeIndividual}
                setIsAssigneeTypeIndividual={setIsAssigneeTypeIndividual}
                selectedAssigneeDetails={selectedAssigneeDetails}
                setSelectedAssigneeDetails={setSelectedAssigneeDetails}
                selectedUserType={selectedUserType}
                setSelectedUserType={setSelectedUserType}
                selectedAssigneeType={selectedAssigneeType}
                setSelectedAssigneeType={setSelectedAssigneeType}
            >
            </CreateCampaignWizardForm2>
            }
        </>
    );
}

export default CampaignGrid;
