import React, { useContext } from "react";
import "../../App.css";
import { agovContext } from "../../Constant";
import LearningSummary from './widgets/LearningsSummary';
import MyLearnings from './widgets/MyLearningsSlider';
import SuperAdminTileData from "./widgets/SuperAdminTileData";
import TaskSummary from "./widgets/TaskSummary";
import UserTileData from "./widgets/UserTileData";

import NonCompliantUsersOverview from "../Dashboard/UMS/nonCompliantUsers/NonCompliantUsersOverview";
import UserApplicationAccessOverview from "../Dashboard/UMS/usersApplicationAccess/UserApplicationAccessOverview";
import WelcomeAndVerification from "../Dashboard/UMS/verifyItsYou/WelcomeAndVerification";


const AgovDashboard = () => {

    //Pc_07 , PS_TD_07 ,  PS_CAM_07 , PS_LMS_07
    // TO get the required user information from localstorage using useContext
    let { employeeName, userId, roles, employeeId, employeeValue } = useContext(agovContext);
    

    const isManager = roles?.includes("Manager");
    const isSuperAdmin = roles?.includes("Super Admin");
    const isSuperAdminAndManager = isManager && isSuperAdmin;
  
    const userRoles = isSuperAdmin ? "superAdmin" : isManager ? "manager" : "";
    const managerId = isManager ? employeeId : "";

    return (
        <>
            
            
            <div className="container-fluid bg-dashboard px-0 dashboard-inner-container">
                {/* Welcome Note and Verify It's You Starts Here */}
                <WelcomeAndVerification userName={employeeName} userId={userId} />

                {/* Welcome Note and Verify It's You End Here */}
                {/* Dashboard Other Component */}
                <div className="custom-container px-5">
                    <div className="row">

                        {/* Dashboard Title Data Starts Here */}
                        {!roles?.includes("Super Admin") && !roles?.includes("Manager") ?
                            <UserTileData /> :
                             <SuperAdminTileData /> }
                        {/* Dashbaord Tile Data ends Here */}

                        <div className="row pe-0 mt-3">
                            <div className="col-md-12 pe-0">
                                <div className="row pe-0 dashboard-widgets">

                                    {/* Dashbaord User Application Access or Learning Summary In User Dashboard  */}
                                    {/* If User = Bind Learning summary ; manager and Super Admin bind Application Access */}
                                    {!roles?.includes("Super Admin") && !roles?.includes("Manager") ? <LearningSummary/> 
                                   : <UserApplicationAccessOverview roles={userRoles} userId={userId} managerId={managerId} isSuperAdminAndManager={isSuperAdminAndManager} />} 

                                    {/* Dasboard User Appliction Access or Learning Summary In User Dashbaord  */}

                                    {/* User = Task Summary ; Manger and Super Admin =  NoN Compliant Overdue/Risk*/}
                                    {!roles?.includes("Super Admin") && !roles?.includes("Manager") ? <TaskSummary />
                                        :<NonCompliantUsersOverview roles={userRoles} isSuperAdminAndManager={isSuperAdminAndManager} managerId={managerId} />
                                     } 

                                    {/* User = Task Summary ; Manger and Super Admin =  NoN Compliant Overdue/Risk*/}

                                    {/*User = my Learning Manager and Super Admin=Learning Summary   */}
                                    {!roles?.includes("Super Admin") && !roles?.includes("Manager") ? <MyLearnings /> :
                                        <>
                                            <LearningSummary/>
                                            <TaskSummary/>
                                         </>} 
                                    {/*User = my Learning Manager and Super Admin=Learning Summary   */}

                                    {/* Task Summary Manger and Super Admin */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Dashboard Other Component */}
            </div>
        </>

       

    )

}
export default AgovDashboard