/**
 * AG_PC_VG_01 - AG_PC_VG_05 
 * Import Statement for the component
 */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NoDataFound from '../LMS/NoDataFound';
import RiskNoDataFound from "../Risk/RiskNoDataFound";
import { getVendorData, deleteVendorData, getQuestionaryHistory } from '../../Services/GRC/GrcService';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import { Loader } from '../Loader/Loader';
import { agovContext } from '../../Constant';
import { saveAs } from 'file-saver';

/**
 * AG_PC_VG_06 - AG_PC_VG_23
 * Define the main function and required state variables for this component
 */
export function VendorGridComponent() {
    const navigate = useNavigate();
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAuditor, setIsAuditor] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [gridData, setGridData] = useState([]);
    const [popUp, setPopUp] = useState(false);
    const [filterData, setFilterData] = useState({ column: 'modifiedDate', sort: 'desc' })
    const [totalFetchCount, setTotalFetchCount] = useState('');
    const [deleteId, setDeleteId] = useState('')
    const [loadMore, setLoadMore] = useState(10);
    const [search, setSearch] = useState("");
    const [isToast, setIsToast] = useState(false)
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null,
    });
    const [advancedFilterData, setAdvancedFilterData] = useState('')
    const [onApply, setOnApply] = useState(false)
    const [onClear, setOnClear] = useState(false)
    const statusData = useLocation();
    
    /**
     * AG_PC_VG_24 
     * Check for the location state to view the toast Message
     */
    useEffect(() => {
        const istoast = location?.state?.isToast
        const message = location?.state?.toastResponse
        if (istoast) {
            setToastMessage(message)
            setIsToast(true)
        }

    }, []);

  const hasRole = (rolesToCheck) => {
    for (const role of rolesToCheck) {
      if (roles?.includes(role)) {
        return true;
      }
    }
    return false;
  };

    /**
    * AG_PC_VG_25 
    * Check for the roles from the agovContext and call for the fetchAllData() 
    */

    useEffect(() => {
        if (roles?.includes("GRC Admin") || roles?.includes("Super Admin")) {
            setIsAdmin(true);
        }
        else if (roles?.includes("Auditor")) {
            setIsAuditor(true);
        } else {
            navigate('/')
        }
        fetchAllData()

    }, [loadMore, filterData, onApply, onClear]);

    /**
     * AG_PC_VG_96 
     * Triggeres the handle Search function on click or on enter key of the search field.
     */
    const handleSearch = async () => {
        setLoadMore(10)
        fetchAllData()
    }

    /**
     * AG_PC_VG_27 - AG_PC_VG_41, AG_PC_VG_92 - AG_PC_VG_110, AG_PC_VG_157 - AG_PC_VG_174
     * Form the requestBody with the loadMore, search and filterData state Variable and pass as in parameters to the getVendorData
     * Set the resposne to the gridData state variable
     * Set the total count from the response to the totalFetchCount state variable
     */
    const fetchAllData = async () => {
        try {
            let requestBody;
            
            if(location.state === 'Completed' || location.state === 'In Progress') {
                    requestBody = {
                    loadMore: loadMore,
                    searchValue: search,
                    filterData: { column : filterData.column, sort: filterData.sort },
                    questionaryStatus : location.state
                }
            }
            else{
                requestBody = {
                    loadMore: loadMore,
                    searchValue: search,
                    filterData: { column : filterData.column, sort: filterData.sort },
                    questionaryStatus : advancedFilterData
                }
            }
            setIsLoading(true)
            const data = await getVendorData(requestBody);
            setIsLoading(false)

            if (data.responseData.length === 0) {
                setGridData([]);
            } else {
                setGridData(data.responseData);
                setTotalFetchCount(data.responseData[0].TotalFetchCount);
            }



        } catch (error) {
            navigate('/error');
        }
    };

    /**
     * AG_PC_VG_46 - AG_PC_VG_68
     * On click of the delete icon initialize the popup and when the user clicks on the delete button this function get triggered
     * Checks for the ID of the specified vendor and sent in params to delete the vendor
     * clears the vendor id from the deleteId state variable after deleting the record from BE.
     */
    const deleteVendor = async (vendorId) => {
        try {
            setIsLoading(true)
            const data = await deleteVendorData(vendorId);
            setIsLoading(false)
            if (data.statusCode == 200) {
                setDeleteId('')
            }
            fetchAllData();

        } catch (error) {
            // navigate('/error');
        }
    };

    /**
     * AG_PC_VG_117 - AG_PC_VG_118
     * Navigates to the vendorForm page with the defined state values
     */
    const handleName = async (vendor_id) => {
        navigate('/compliance/vendorForm', {
            state: {
                vendor_id: vendor_id,
                clickFrom: "vendorNameClick"
            }
        });
    }
    /**
     * AG_PC_VG_114 - AG_PC_VG_116
     * Navigates to the vendorForm page with the defined state values
     */
    const handleEdit = async (vendor_id) => {
        navigate('/compliance/vendorForm', {
            state: {
                vendor_id: vendor_id,
                clickFrom: "vendorEditClick"
            }
        });
    };

    /**
     * AG_PC_VG_121
     * Navigate the user to the Creating a new vendor Page
     */
    const handleNewVendor = () => {
        navigate('/compliance/vendorForm');
    };

    /**
     * AG_PC_VG_126 - AG_PC_VG_157
     * Form the requestBody to send to the getQuestionariesHistory to download the file of the questioanry
     * 
     */
    const handleDownload = async (vendorId) => {
        const requestBody = {
            vendorId: vendorId,
            isDownload: true,
            userId: userId
        }
        setIsLoading(true)
        const data = await getQuestionaryHistory(requestBody)
        setIsLoading(false)
        if (data.statusCode === 200) {
            if (data.responseData.length > 0) {
                const file = data.responseData[0].historyFile
                const name = data.responseData[0].questionaryHistoryName
                let fileUrl = file;
                saveAs(fileUrl, `${name}`)
            }
            else {
                setIsToast(true)
                setToastMessage(
                    {
                        path: "/images/error-toast-icon.svg",
                        header: "No File to Save",
                        message: "There are no file to download for this vendor",
                        color: "error-toast"
                    })
                return
            }
        }
        else {
            navigate('/error')
        }
    }


    /**
     *AG_PC_VG_111 - AG_PC_VG_116
     Bind the gridData to the table div of the vendor Grid
     Check for the length and if the length is null display the no data found img
     */
    const bindTable = () => {
        return gridData?.map((obj, index) => (
            <tr key={index}>
                <td className="font-12 font-medium wrap-text" style={{ "text-align": "left" }}>
                    <a className="text-decoration-none cursor-pointer" onClick={() => handleName(obj?.vendorID)}>{obj?.vendorName}</a>
                </td>
                <td className="font-12" style={{ "text-align": "center" }}>{obj?.LastModifiedDate}</td>
                <td className={`font-12 font-medium`} style={{ "text-align": "center" }}>
                    <span className={obj.status.toLowerCase() === 'completed' ? 'green-dot me-2' : 'orange-dot me-2'}></span>
                    {obj.status.toLowerCase() === 'completed' ? 'Completed' : 'In Progress'}
                </td>
                {!isAuditor ? <td className="text-center" style={{ "text-align": "center" }}>
                    <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleEdit(obj.vendorID, 'vendorEditClick')}>
                        <img src="/images/edit-icon.svg" alt="edit-icon" />
                    </button>
                    <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => { setDeleteId(obj.vendorID); setPopUp(true) }}>
                        <img src="/images/delete-icon.svg" alt="delete-icon" />
                    </button>
                    <div className="btn-group">
                        <button type="button" className="me-2 btn shadow-none border-0 p-0" onClick={() => handleDownload(obj?.vendorID)}>
                            <img src="/images/download-icon.svg" alt="download-icon" />
                        </button>
                    </div>
                </td> : null}
            </tr>
        ));
    }

    // AG_PC_VG_211 and AG_PC_VG_212 -> handleFilter function is invoked.
    const handleFilter = () =>{
        if(location.state){
            location.state =''
        }
        setOnApply(!onApply)
    }




    return (
        <> <Loader isLoading={isLoading} />
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center px-4">
                            {/*tabs starts here*/}
                            {hasRole(["Super Admin", "GRC Admin"]) ? (
                            <div className="custom-tabs">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link px-0 font-14 tab-style rounded-0 mt-custom" id="dashboard-tab" data-bs-toggle="pill" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false" onClick={() => navigate('/compliance')}>
                                            <span className="tab-img"> <img src="/images/dashboard-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/dashboard-icon.svg" alt="dashboard-icon" className="me-2" /></span>Dashboard</button>
                                    </li>
                                    <li className="nav-item ms-4" role="presentation">
                                        <button className="nav-link px-0  font-14 tab-style bold-text rounded-0 mt-custom" id="audit-tab" data-bs-toggle="pill" data-bs-target="#audit" type="button" role="tab" aria-controls="audit" aria-selected="false" tabIndex={-1} onClick={() => navigate('/compliance/AuditCycleGrid')}>
                                            <span className="tab-img"> <img src="/images/manage-audit-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/manage-audit-icon.svg" alt="manage-audit-icon" className="me-2" /> </span>Manage Audit</button>
                                    </li>
                                    <li className="nav-item ms-4" role="presentation">
                                        <button className="nav-link px-0 active font-14 tab-style rounded-0 mt-custom" id="vendors-tab" data-bs-toggle="pill" data-bs-target="#vendors" type="button" role="tab" aria-controls="vendors" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/compliance/vendor'); }}>
                                            <span className="tab-img"> <img src="/images/vendor-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/vendor-icon.svg" alt="vendor-icon" className="me-2" />Vendors</span></button>
                                    </li>
                                </ul>
                            </div>) : (
                                <div className="custom-tabs">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item ms-4" role="presentation">
                                        <button className="nav-link px-0  font-14 tab-style bold-text rounded-0 mt-custom" id="audit-tab" data-bs-toggle="pill" data-bs-target="#audit" type="button" role="tab" aria-controls="audit" aria-selected="false" tabIndex={-1} onClick={() => navigate('/compliance')}>
                                            <span className="tab-img"> <img src="/images/manage-audit-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/manage-audit-icon.svg" alt="manage-audit-icon" className="me-2" /> </span>Manage Audit</button>
                                    </li>
                                    <li className="nav-item ms-4" role="presentation">
                                        <button className="nav-link px-0 active font-14 tab-style rounded-0 mt-custom" id="vendors-tab" data-bs-toggle="pill" data-bs-target="#vendors" type="button" role="tab" aria-controls="vendors" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/compliance/vendor'); }}>
                                            <span className="tab-img"> <img src="/images/vendor-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/vendor-icon.svg" alt="vendor-icon" className="me-2" />Vendors</span></button>
                                    </li>
                                </ul>
                            </div>)
                            } 
                            {/*tabs ends here*/}
                            {/*Manage audits tab right content here*/}


                            <div className="d-flex">
                                <ul className="nav custom-filter-position mt-custom">
                                    <li className="me-3">
                                        <div className="input-group flex-nowrap search-group me-2 ">
                                            <input
                                                type="search"
                                                className="form-control search-align"
                                                placeholder="Search Vendor"
                                                aria-label="Search"
                                                aria-describedby="addon-wrapping"
                                                onChange={(e) => setSearch(e.target.value)}
                                                onKeyDownCapture={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSearch();
                                                    }
                                                }}
                                            />
                                            <button
                                                className="input-group-text btn btn-search-custom"
                                                id="addon-wrapping"
                                                onClick={handleSearch}
                                            >
                                                <img src="/images/search-icon.svg" alt="search-icon" />
                                            </button>
                                        </div>
                                    </li>
                                    <li>
                                    <div className="d-flex">
                                            <button className="cust-filter-btn d-flex align-items-center me-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                                            </button>
                                            <div className="dropdown-menu custom-filter border-0">
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                                                <button type="button" className="btn-close font-12" aria-label="Close"/>
                                                </div>
                                                <div className="row mt-3">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="mb-3">
                                                    <label className="form-label text-black font-12 font-medium">Questionnaire Status
                                                    </label>
                                                    <select className="form-select cust-input-sty font-14 py-2 font-regular" aria-label="Default select example" onClick={(event) =>{
                                                            setAdvancedFilterData(event.target.value)
                                                        }}>
                                                        {!location.state || !advancedFilterData ? <option selected value = "">Select</option> : <option value = "">Select</option>}
                                                        {location.state==='Completed' || advancedFilterData === 'completed' ? <option value='completed' selected>Completed</option> : <option value='completed' >Completed</option>}
                                                        {location.state === 'In Progress' || advancedFilterData === 'In Progress' ? <option value='In Progress' selected>In progress</option> : <option value='In Progress'>In progress</option>}
                                                    </select>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="text-end mt-4 mb-2">
                                                <button type="button" className="primary-btn font-14 font-medium me-2 py-2 me-3" onClick={() =>{
                                                    setOnClear(!onClear)
                                                    setAdvancedFilterData('')
                                                    location.state=''
                                                }}>
                                                    Clear
                                                </button>
                                                <button type="button" className="dark-btn font-14 font-medium py-2" onClick={() => {handleFilter()}}>
                                                    Apply
                                                </button>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </li>
                                    {!isAuditor && (
                                        <li className="me-3">
                                            <button type="button" className="cust-filter-btn px-4 pb-2" onClick={() => { navigate('/compliance/questionaries') }}>
                                                <img className="me-2" src="/images/questionnaires-icon.svg" alt="questionnaire-icon" />
                                                Questionnaires
                                            </button>
                                        </li>
                                    )}
                                    <li>
                                        {!isAuditor ? <button type="button" className="dark-btn px-4 pb-2" onClick={handleNewVendor}>
                                            <img className="me-2" src="/images/add-icon.svg" alt="add-icon" />
                                            Add New Vendor
                                        </button> : null}
                                    </li>
                                </ul>
                            </div>


                        </div>
                        <div className="row position-relative">
                            <div className="tab-content" id="pills-tabContent">
                                {/*Dashboard tab*/}
                                <div className="tab-pane fade px-4 " id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                                    <div className="row">
                                        <div className="col-md-12">
                                            dashboard
                                        </div>
                                    </div>
                                </div>
                                {/*Dashboard tab*/}

                                {/*vendor tab*/}
                                <div className="tab-pane fade px-4 show active" id="vendors" role="tabpanel" aria-labelledby="vendors-tab">
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <h5 className="font-medium font-20 mb-4">Vendors</h5>
                                            {gridData?.length ? (
                                                <><table className="table mb-0 custom-table-grid table-borderless mt-3">
                                                    <thead>
                                                        <tr style={{ "text-align": "left" }}>
                                                            <th className="font-14 px-4 position-relative">Vendor Name
                                                                <span className="position-absolute">
                                                                    <a className="sort-up position-absolute ms-2"><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => {
                                                                        setFilterData({
                                                                            ...filterData,
                                                                            column: "vendorName",
                                                                            sort: "asc",
                                                                        });
                                                                    }} /></a> <a className="sort-down position-absolute ms-2"><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => {
                                                                        setFilterData({
                                                                            ...filterData,
                                                                            column: "vendorName",
                                                                            sort: "desc",
                                                                        });
                                                                    }} /></a>
                                                                </span>
                                                            </th>
                                                            <th className="font-14 position-relative" style={{ "text-align": "center" }}>Last Uploaded Date
                                                                <span className="position-absolute">
                                                                    <a className="sort-up position-absolute ms-2"><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => {
                                                                        setFilterData({
                                                                            ...filterData,
                                                                            column: "LastModifiedDate",
                                                                            sort: "asc",
                                                                        });
                                                                    }} /></a> <a className="sort-down position-absolute ms-2"><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => {
                                                                        setFilterData({
                                                                            ...filterData,
                                                                            column: "LastModifiedDate",
                                                                            sort: "desc",
                                                                        });
                                                                    }} /></a>
                                                                </span>
                                                            </th>
                                                            <th className="font-14 position-relative" style={{ "text-align": "center" }}>Questionnaire Status
                                                                <span className="position-absolute">
                                                                    <a className="sort-up position-absolute ms-2"><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" onClick={() => {
                                                                        setFilterData({
                                                                            ...filterData,
                                                                            column: "status",
                                                                            sort: "asc",
                                                                        });
                                                                    }} /></a> <a className="sort-down position-absolute ms-2"><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" onClick={() => {
                                                                        setFilterData({
                                                                            ...filterData,
                                                                            column: "status",
                                                                            sort: "desc",
                                                                        });
                                                                    }} /></a>
                                                                </span>
                                                            </th>
                                                            {!isAuditor ? <th className="font-14 text-center" style={{ "text-align": "center" }}>Action</th> : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bindTable()}
                                                    </tbody>
                                                </table>
                                                    <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor">{gridData?.length}</span> out of <span className="font-medium primary-textcolor">{totalFetchCount}</span> items</p>
                                                    {gridData?.length < totalFetchCount && (
                                                        <div className="text-center mt-3 mb-4">
                                                            <button className="primary-btn font-medium" onClick={() => setLoadMore(loadMore + 10)}>Load More</button>
                                                        </div>
                                                    )}

                                                </>
                                            ) : <RiskNoDataFound data={"No Data Found"}/>}
                                        </div>
                                    </div>
                                </div>
                                {/*vendor tab*/}
                            </div>
                        </div>
                    </div>
                </div>

                {popUp && (<div className="modal fade show" id="delete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                        <div className="modal-content">
                            {/*modal content starts here*/}
                            <div className="modal-header border-0 pb-2 px-4">
                                <h5 className="modal-title font-bold" id="exampleModalLabel"><img className="me-3" src="/images/delete-popup-icon.svg" alt="delete-icon" /> Delete Vendor</h5>
                                <button type="button" className="btn-close ms-auto" onClick={() => { setPopUp(false) }} aria-label="Close" />
                            </div>
                            <div className="modal-body border-0 font-16 px-4">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <p className="font-16 grey-secondary custom-line-height">Are you sure you want to delete this vendor? This action will permanently remove the selected vendor's contact information and questionnaires.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 mb-3 px-4">
                                <button type="button" className="primary-btn font-14 font-medium py-2" data-bs-dismiss="modal" onClick={() => { setDeleteId(''); setPopUp(false) }}>Cancel</button>
                                <button type="button" className="btn revoke-btn font-14 font-medium py-2" onClick={() => { deleteVendor(deleteId); setPopUp(false) }}>Delete</button>
                            </div>
                            {/*modal content ends here*/}
                        </div>
                    </div>
                </div>
                )}
                {popUp ? <div class="modal-backdrop fade show"></div> : <></>}
                {isToast ? (
                    <>
                        <ToastMessageComponent
                            toastResponse={toastMessage}
                            setIsShowToastMessage={setIsToast}
                        />
                    </>
                ) : null}

            </div>
        </>
    );
}


export default VendorGridComponent