/**
 * AG_PC_QE_02 - AG_PC_QE_04
 * Required import statements for this componenet
 */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getQuestionaries, deleteSectionFromDB, deleteQuestionFromDB, postQuestionaries, insertLogs } from '../../Services/GRC/GrcService';
import { index } from 'd3-array';
import { saveAs } from 'file-saver';
import { Loader } from '../Loader/Loader';
import { agovContext } from '../../Constant';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import ExcelJS from 'exceljs';

/**
 * AG_PC_QE_05 - AG_PC_QE_13
 * State variable declarations and the export function declaration 
 */
export function QuestionariesForm() {
    const navigate = useNavigate();
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const [isLoading, setIsLoading] = useState(false)
    const [isToast, setIsToast] = useState(false)
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null,
    });
    const [section, setSection] = useState([{
        category: "",
        questions: [
            {
                questionary_name: "",
                is_disable: 0,
                commonQuestionaryID: ""
            }
        ]
    }]);
    const [deletedQuestionIds, setDeletedQuestionIds] = useState([]);
    const [deletedSectionIds, setDeletedSectionIds] = useState([]);

    /**
     * AG_PC_QE_14 - AG_PC_QE_32 
     * In the useffect the function to get the category and its questions has been called.
     * The questionary data has been set stated to the section state variable
     */
    useEffect(() => {
        getQuestionariesData();
    }, []);

    const getQuestionariesData = async () => {
        setIsLoading(true)
        const response = await getQuestionaries();
        setIsLoading(false)
        if (response.statusCode === 200) {
            setSection(response.responseData);

        } else {
            navigate('/error')
        }
    };

    /**
     * AG_PC_QE_38 - AG_PC_QE_40 
     * Store the deletedQuestions id in the state variable and delete that particular question from the form.
     * Those Id will be deleted during the onclick of the save button.
     */
    const handleDeleteQuestion = (sectionIndex, questionIndex) => {
        const deletedQuestion = section[sectionIndex].questions[questionIndex];
        const deletedQuestionId = deletedQuestion.commonQuestionaryID;
        const updatedQuestions = section[sectionIndex].questions.filter((_, index) => index !== questionIndex);

        setDeletedQuestionIds(prevIds => [...prevIds, deletedQuestionId]); // Use functional form of setState

        const updatedSection = [...section];
        updatedSection[sectionIndex].questions = updatedQuestions;
        setSection(updatedSection);

  
    };

    /**
     *  AG_PC_QE_35 - AG_PC_QE_37
     * Stores the deleted section names in the state variable and delete that particular section from the form
     * Those will be deleted from the BE onclick of the save button,
     */
    const handleDeleteSection = (sectionIndex) => {
        const deletedSection = section[sectionIndex];
        const deletedSectionId = deletedSection.category;
        setDeletedSectionIds(prevDeletedSectionIds => [...prevDeletedSectionIds, deletedSectionId]);
        const updatedSections = section.filter((_, index) => index !== sectionIndex);
        setSection(updatedSections);
    };

    /**
     * AG_PC_QE_82 - AG_PC_QE_86
     * Adds the another section in the form
     */
    const addSection = () => {
        setSection([
            ...section,
            {
                category: '',
                questions: [
                    {
                        questionary_name: '',
                        is_disable: '',
                        commonQuestionaryID: ''
                    }
                ]
            }
        ]);
    };

    /**
     * AG_PC_QE_87 - AG_PC_QE_89
     * Handles the change of values on the section name
     */
    const handleSectionNameChange = (sectionIndex, event) => {
        const updatedSections = [...section];
        updatedSections[sectionIndex].category = event.target.value;
        setSection(updatedSections);
    };
    /**
     * AG_PC_QE_95 - AG_PC_QE_97
     * Handles the changes made in the question values.
     */
    const handleQuestionChange = (sectionIndex, questionIndex, e) => {
        const value = e.target.value;
        const updatedSections = [...section];
        updatedSections[sectionIndex].questions[questionIndex].questionary_name = value;
        setSection(updatedSections);
    };

    /**
     * AG_PC_QE_90 - AG_PC_QE_94
     * Add the additional question row below the existing question
     */
    const addQuestion = (sectionIndex) => {
        const updatedSections = [...section];
        updatedSections[sectionIndex].questions.push({
            questionary_name: '',
            is_disable: 0,
            commonQuestionaryID: ''
        });
        setSection(updatedSections);
    };
    /**
     * AG_PC_QE_98 - AG_PC_QE_110
     * Disables the questions from the user interactions and change its values to the 0 or 1
     */
    const handleDisableQuestion = (sectionIndex, questionIndex, e) => {
        const updatedSections = [...section];
        updatedSections[sectionIndex].questions[questionIndex].is_disable = e.target.checked ? 1 : 0;
        setSection(updatedSections);
    };
    /**
     * AG_PC_QE_127 - AG_PC_QE_139
     * Export the excel to the user onclick of the export questionaries tab.
     * Insret call made to insert the log report.
     */
    const handleExport = async () => {
        let hasEmptySection = false;
        section.forEach(sec => {
            if (sec.questions.length === 0 || sec.questions.some(question => question.questionary_name.trim() === "") || sec.category.trim() === "") {
                hasEmptySection = true;
                return; // Exit the loop early if an empty section or question is found
            }
        });

        if (hasEmptySection) {
            setToastMessage({
                ...toastMessage,
                path: "/images/error-toast-icon.svg",
                header: "Missing Data",
                message: "The Question or Section field can't be empty",
                color: "error-toast",
            });
            setIsToast(true);
            return;
        }

        // Create workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Add headers
        worksheet.addRow(["S.no", "Category", "Questions", "Answers", "Comments"]);

        let sNo = 1;
        // Iterate through each section
        section.forEach(item => {
            if (item.questions.length === 1 && item.questions[0].is_disable === 1) {
                return; // Skip this section
            }
            item.questions.forEach(question => {
                if (question.is_disable !== 1) {
                    worksheet.addRow([
                        sNo++, // Increment S.no and use the incremented value
                        item.category, // Category
                        question.questionary_name, // Questions
                        "", // Answers (empty for now)
                        "" // Comments (empty for now)
                    ]);
                }
            });
        });

        // Generate Excel buffer
        const excelBuffer = await workbook.xlsx.writeBuffer();

        // Create a Blob containing the Excel data
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        // Save the Blob as a file
        saveAs(data, 'Questionnaries.xlsx');

        // Insert logs
        const requestBody = {
            userId: userId
        };
        await insertLogs(requestBody);
    };


    /**
     * AG_PC_QE_112 - AG_PC_QE_125
     * Checks for Section and question ids to delete and post the questions to the BE
     */
    const handleSave = async () => {
        let hasEmptySection = false;
        section.forEach(sec => {
            if (sec.questions.length === 0 || sec.questions.some(question => question.questionary_name.trim() === "") || sec.category.trim() === "") {
                hasEmptySection = true;
                return; // Exit the loop early if an empty section or question is found
            }
        });

        if (hasEmptySection) {
            setToastMessage({
                ...toastMessage,
                path: "/images/error-toast-icon.svg",
                header: "Missing Data",
                message: "The Question or Section field can't be empty",
                color: "error-toast",
            });
            setIsToast(true);
            return;
        }

        if (deletedSectionIds.length > 0 || deletedQuestionIds.length > 0) {
            if (deletedSectionIds.length > 0) {
                const deletedSectionIdsString = deletedSectionIds.map(obj => `'${obj}'`).join(',');
                const requestBody = { data: deletedSectionIdsString }
                setIsLoading(true)
                const result = await deleteSectionFromDB(requestBody)
                setIsLoading(false)
            }
            if (deletedQuestionIds.length > 0) {
                const deletedQuestionIdsString = deletedQuestionIds.map(obj => `'${obj}'`).join(',');
                const requestBody = { data: deletedQuestionIdsString }
                setIsLoading(true)
                const result = await deleteQuestionFromDB(requestBody)
                setIsLoading(false)
            }
        }
        const requestBody = {
            section: section
        }
        setIsLoading(true)
        const result = await postQuestionaries(requestBody)
        setIsLoading(false)
        if (result.statusCode === 200) {
            navigate('/compliance/vendor')
        }
        else {
            navigate('/error')
        }
    }


    return (
        <> <Loader isLoading={isLoading} />
            <div className="container-fluid mt-4 mt-custom">
                <div className="px-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="font-bold font-24 cursor-pointer"><img className="me-3 mb-1" src="/images/back-arrow.svg" alt="back-arrow" onClick={() => navigate('/compliance/vendor')} />Questionnaires</h5>
                        </div>

                        <div className="col-md-12 d-flex justify-content-end">
                            <button type="button" className="import-btn customflip-btn px-3 font-14" onClick={() => { handleExport() }}>
                                <img className="me-1" src="/images/import-answers-icon.svg" alt="import-answers-icon" /> Export Questionnaries
                            </button>
                        </div>
                        {section.map((section, sectionIndex) => (
                            <div key={sectionIndex}>
                                <div className="col-md-12 mb-3">
                                    <div className="d-flex justify-content-between py-3 align-items-center">
                                        <div>
                                            <input
                                                className="font-16 font-medium"
                                                type="text"
                                                value={section.category} // Bind the value of the input field to the category property of the section
                                                onChange={(e) => handleSectionNameChange(sectionIndex, e)} // Use onChange to capture changes
                                                placeholder="Section Name"
                                                style={{ border: 'none', outline: 'none', background: 'none', maxWidth: `${section.category.length > 0 ? section.category.length * 11 : '115'}px` }} // Remove border, outline, and background
                                            />
                                            <img className="ms-2 mb-1" src="/images/delete-icon.svg" alt="delete-icon" onClick={() => { handleDeleteSection(sectionIndex) }} />
                                        </div>
                                    </div>
                                </div>
                                {
                                    section.questions.map((question, questionIndex) => (
                                        <div key={questionIndex} className="col-md-12 mb-3">
                                            <div class="custom-border px-3 py-2 mb-4 col-md-11 d-flex">
                                                <input
                                                    className="font-12 font-medium primary-textcolor flex-grow-1"
                                                    type="text"
                                                    value={question.questionary_name} // Bind the value of the input field to the questionary_name
                                                    onChange={(e) => handleQuestionChange(sectionIndex, questionIndex, e)} // Use onChange to capture changes
                                                    placeholder="Question"
                                                    disabled={question.is_disable === 1} // Disable the input field when is_disable is 1
                                                    style={{ border: 'none', boxShadow: 'none', width: '100%', height: '40px', background: 'none' }} // Remove border and box-shadow
                                                />

                                            </div>

                                            <div className="row mb-3">
                                                <div className="d-flex align-items-center gap-3">
                                                    <div class="form-check">
                                                        <input className="form-check-input custom-radio-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" disabled />
                                                    </div>
                                                    <div className="d-d-block col-md-3">
                                                        <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="yes" placeholder="Yes" disabled style={{ color: 'inherit', opacity: 1, backgroundColor: 'inherit', borderColor: 'inherit' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="d-flex align-items-center gap-3">
                                                    <div class="form-check">
                                                        <input className="form-check-input custom-radio-input" type="radio" name="flexRadioDefault" disabled />
                                                    </div>
                                                    <div className="d-block col-md-3">
                                                        <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="no" placeholder="No" disabled style={{ color: 'inherit', opacity: 1, backgroundColor: 'inherit', borderColor: 'inherit' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="d-flex align-items-center gap-3">
                                                    <div class="form-check">
                                                        <input className="form-check-input custom-radio-input" type="radio" name="flexRadioDefault" disabled />
                                                    </div>
                                                    <div className="d-block col-md-3">
                                                        <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="na" placeholder="Not Applicable" disabled style={{ color: 'inherit', opacity: 1, backgroundColor: 'inherit', borderColor: 'inherit' }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w-100 mb-3">
                                                <textarea type="text"
                                                    class="form-control font-regular font-14 custom-form-control py-2 resize-none" rows="4"
                                                    id="description" placeholder="Enter Description" disabled style={{ color: 'inherit', opacity: 1, backgroundColor: 'inherit', borderColor: 'inherit' }}></textarea>
                                            </div>
                                            <div class="w-100 mb-3">
                                                <span class="d-flex align-items-center">
                                                    <span class="d-block me-4">
                                                        <div>
                                                            <a type="button" className="text-decoration-none red-textcolor font-medium font-14 " onClick={() => handleDeleteQuestion(sectionIndex, questionIndex)}><img className="me-2 mb-1" src="/images/delete-icon.svg" alt="delete-icon" />Delete Question</a>
                                                        </div>
                                                    </span>
                                                    <div>
                                                        <span class="d-block">
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" checked={question.is_disable === 1} type="checkbox" id={`disableQuestion-${sectionIndex}-${questionIndex}`} onChange={(e) => handleDisableQuestion(sectionIndex, questionIndex, e)} />
                                                                <label className="form-check-label font-medium font-14" htmlFor={`disableQuestion-${sectionIndex}-${questionIndex}`}>Disable Question</label>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>

                                    ))
                                }
                                < div className="w-100" >
                                    <a className="text-decoration-none font-14 font-medium" onClick={() => addQuestion(sectionIndex)}>
                                        <img className="mb-1 me-2" src="/images/blue-add-icon.svg" alt="blue-icon" />Add a Question
                                    </a>
                                </div>
                            </div>
                        ))
                        }
                        <div className="col-md-12 my-3">
                            <button type="button" className="cust-filter-btn px-4 pb-2 font-medium font-14" onClick={() => addSection()}>Add Section</button>
                        </div>
                        <div class="col-md-12 my-5">
                            <div class="d-flex justify-content-end">
                                <button type="button" class="primary-btn font-14 font-medium py-2 me-3"
                                    onClick={() => navigate('/compliance/vendor')}>Cancel</button>
                                <button type="button" class="dark-btn font-14 font-medium py-2" onClick={() => { handleSave() }}>Save</button>
                            </div>
                        </div>
                    </div >
                </div >
                {isToast && (
                    <ToastMessageComponent
                        toastResponse={toastMessage}
                        setIsShowToastMessage={setIsToast}
                    />
                )}
            </div >
        </>

    );

}
